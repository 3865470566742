import moment from "moment"

export function sumEvents(securityLogs) {
  let total_events = 0
  let total_motion_events = 0
  let total_fire_events = 0
  let total_violence_events = 0
  let total_intrusion_events = 0
  let total_blacklist_car_events = 0
  let total_blacklist_person_events = 0
  // loop through logs array and calculate cumulative counts sum of each event type
  if (securityLogs) {
    for (let i = 0; i < securityLogs?.length; i++) {
      const obj = securityLogs[i]
      total_motion_events += obj.motion_count || 0
      total_fire_events += obj.fire_count || 0
      total_violence_events += obj.violence_count || 0
      total_intrusion_events += obj.intrusion_count || 0
      total_blacklist_car_events += obj.blacklist_car_count || 0
      total_blacklist_person_events += obj.blacklist_person_count || 0

      // Calculate the overall sum of counts
      const counts = Object.values(obj)
      for (let j = 0; j < counts.length; j++) {
        const count = counts[j]
        if (typeof count === "number") {
          total_events += count
        }
      }
    }
  }
  // Event Type :: Integer Representation
  // FIRE             = 0
  // VIOLENCE         = 1
  // MOTION           = 2
  // INTRUSION        = 3
  // BLACKLIST_CAR    = 4
  // BLACKLIST_PERSON = 5
  // return it as an object with the proper order of events types
  return {
    total_fire_events,
    total_violence_events,
    total_motion_events,
    total_intrusion_events,
    total_blacklist_car_events,
    total_blacklist_person_events,
    total_events,
  }
}

function mapLogObjectToArray(securityLog) {
  return [
    securityLog.fire_count,
    securityLog.violence_count,
    securityLog.motion_count,
    securityLog.intrusion_count,
    securityLog.blacklist_car_count,
    securityLog.blacklist_person_count,
  ]
}
// Event Type :: Integer Representation
// FIRE             = 0
// VIOLENCE         = 1
// MOTION           = 2
// INTRUSION        = 3
// BLACKLIST_CAR    = 4
// BLACKLIST_PERSON = 5
const eventsColors = [
  { id: "Fire", color: "var(--navy-background-1)" },
  { id: "Violence", color: "var(--red-background-1)" },
  { id: "Motion", color: "var(--yellow-background-1)" },
  { id: "Intrusion", color: "var(--important-text-disabled)" },
  { id: "Blacklist Car", color: "var(--neutral-text-enabled)" },
  { id: "Blacklist Person", color: "var(--neutral-text-enabled)" },
]
// convert data from object form to a form that nivo line graph accept
export function convertToLineGraphData(dailyCountData) {
  let tmp = eventsColors.map((event) => {
    return { ...event, data: [] }
  })
  dailyCountData.sort((a, b) => moment(a.day).diff(moment(b.day)))
  for (let log of dailyCountData) {
    const dayValue = log.day
    const dayLogArray = mapLogObjectToArray(log)
    dayLogArray.map((value, index) => tmp[index].data.push({ x: new Date(dayValue), y: value || 0 }))
  }
  return tmp
}
