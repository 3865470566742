import React, { FC, Fragment } from "react"

import { Grid, CircularProgress } from "@mui/material"

import { Typography } from "@synapse-analytics/synapse-ui"
import moment from "moment"

import Podium from "../../../components/Podium"
import { default as LeaderBoardTable } from "../../../components/tables/LeaderBoardTable"
import { TableColumn } from "../../../types/Custom/Types"
import { definitions } from "../../../types/Generated/apiTypes"

import styles from "./Leaderboard.module.scss"

type BranchData = definitions["BranchesAnalytics"]

interface Props {
  data?: BranchData[]
  isLoading?: boolean
}

/**
 * Sorts and indexes branch data based on the specified sorting criteria.
 *
 * @param {BranchData[]} data - The array of branch data to be sorted.
 * @param {string} sortingBy - The sorting criteria: "Visitors counts", "Average casher time", or "Customer satisfaction".
 * @returns {BranchData[]} The sorted and indexed array of branch data.
 */
const sortBranchesData = (data?: BranchData[], sortingBy?: string): BranchData[] => {
  if (!data) return []
  // TODO: Bring back sorting when new fields are added to data
  const sorted = [...data].sort((a, b) => {
    switch (sortingBy) {
      case "Visitors counts":
        return b.total_count_in_sum - a.total_count_in_sum
      // case "Average casher time":
      //   return a.avg_cashier_time - b.avg_cashier_time
      // case "Customer satisfaction":
      //   return b.customer_satisfaction - a.customer_satisfaction
      default:
        return 0
    }
  })
  return sorted.map((item, index) => ({ ...item, index }))
}
const sortingBy = "Visitors counts"

const Leaderboard: FC<Props> = ({ data, isLoading }) => {
  // const [sortingBy, setSortingBy] = useState<"Visitors counts" | "Average casher time" | "Customer satisfaction">(
  //   "Visitors counts"
  // )

  // const [indexedSortedData, setIndexedSortedData] = useState<BranchData[]>(sortBranchesData(data, "Visitors counts"))
  const indexedSortedData = sortBranchesData(data, "Visitors counts")

  const isEmptyTable = (data && data?.filter((branch) => branch?.total_count_in_sum > 0)?.length < 1) || !data

  const tableColumns: TableColumn[] = [
    {
      title: "Place",
      field: "index",
      searchable: false,
      render: (rowData: BranchData & { index: number }) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <div className={styles.ordinaryTableRow}>{moment.localeData().ordinal(rowData.index + 1)}</div>
        </div>
      ),
    },
    {
      title: "Branch Name",
      field: "name",
      searchable: false,
      render: (rowData: BranchData) => (
        <div className={styles.ordinaryTableRow} title={rowData.branch_name}>
          {rowData.branch && rowData.branch_name.length > 19
            ? rowData.branch_name.slice(0, 17) + ".."
            : rowData.branch_name}
        </div>
      ),
    },
    {
      title: "Visitor Counts",
      field: "count",
      searchable: false,
      render: (rowData: BranchData) => (
        <div className={styles.ordinaryTableRow}>
          {rowData.total_count_in_sum && rowData.total_count_in_sum > 0 ? rowData.total_count_in_sum : "No Data"}
        </div>
      ),
    },
    // TODO: Bring back other columns when new fields are added to data
    // {
    //   title: "Avg. Cashier Time",
    //   field: "avg_cashier_time",
    //   searchable: false,
    //   render: (rowData: BranchData) => (
    //     <div className={styles.ordinaryTableRow}>{(rowData.avg_cashier_time / 60000).toFixed(0)} minutes</div>
    //   ),
    //   cellStyle: {
    //     textAlign: "left",
    //   },
    //   headerStyle: {
    //     textAlign: "left",
    //   },
    // },
    // {
    //   title: "Customer Satisfaction",
    //   field: "customer_satisfaction",
    //   searchable: false,
    //   render: (rowData: BranchData) => (
    //     <div className={styles.ordinaryTableRow}>{rowData.customer_satisfaction * 100}%</div>
    //   ),
    // },
  ]

  // TODO:: Bring back sorting function when new fields added to data
  // set sorting state and sort and index data based on selected sorting criteria
  // const handleSortingByChange = (event: ChangeEvent<HTMLInputElement>) => {
  //   const sortingCriteria = event.target.value as "Visitors counts" | "Average casher time" | "Customer satisfaction"

  //   setSortingBy(sortingCriteria)

  //   setIndexedSortedData(sortBranchesData(data, sortingCriteria))
  // }

  // Create the podium data based on the sorting criteria
  const getPodiumData = () => {
    const getPodiumItemData = (index: number) => {
      switch (sortingBy) {
        case "Visitors counts":
          return {
            name: indexedSortedData[index]?.branch_name,
            value: indexedSortedData[index]?.total_count_in_sum,
          }
        // case "Average casher time":
        //   return {
        //     name: indexedSortedData[index]?.name,
        //     value: Math.round(indexedSortedData[index]?.avg_cashier_time / 60000),
        //     unit: "(min)",
        //   }
        // case "Customer satisfaction":
        //   return {
        //     name: indexedSortedData[index]?.name,
        //     value: indexedSortedData[index]?.customer_satisfaction * 100,
        //     unit: "%",
        //   }
        default:
          return {}
      }
    }

    return {
      first: getPodiumItemData(0),
      second: getPodiumItemData(1),
      third: getPodiumItemData(2),
    }
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>
        <Typography variant="h2-bold" variantColor={2} align="left">
          Branches Leaderboard
        </Typography>
        {/* TODO:: Bring back sorting mode select when new fields are added to data  */}
        {/* <Select
          isNotchedLabel
          label="Sort By"
          options={["Visitors counts", "Average casher time", "Customer satisfaction"]}
          handleChange={handleSortingByChange}
          value={sortingBy}
          size={220}
          menuProps={{
            menuMaxContent: true,
          }}
        /> */}
      </div>
      {isLoading ? (
        <Grid container direction="row" justifyContent="center" alignItems="center" className={styles.loadingContainer}>
          <Grid item>
            <CircularProgress />
          </Grid>
        </Grid>
      ) : (
        <Fragment>
          {!isEmptyTable && <Podium data={getPodiumData()} />}
          <LeaderBoardTable
            isLoading={!!isLoading}
            data={indexedSortedData}
            columns={tableColumns}
            title="LeaderBoard"
            isEmpty={isEmptyTable}
          />
        </Fragment>
      )}
    </div>
  )
}
export default Leaderboard
