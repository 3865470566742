import { FC } from "react"

import ContentCopyIcon from "@mui/icons-material/ContentCopy"

import { Button, NotificationUtils, Typography } from "@synapse-analytics/synapse-ui"

import styles from "./CopyToClipboard.module.scss"

interface Props {
  value: string
  name: string
  additionalNotToCopy?: string
  actionAfterCopy?: () => void
}

const CopyToClipboard: FC<Props> = ({ value, additionalNotToCopy, name, actionAfterCopy }) => {
  const handleCopyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(value)
      NotificationUtils.toast(`${name} copied to clipboard successfully`, {
        snackBarVariant: "positive",
      })
      actionAfterCopy?.()
    } catch (error) {
      NotificationUtils.toast(`Failed to copy ${name} to clipboard. Please try again.`, {
        snackBarVariant: "negative",
      })
    }
  }
  return (
    <div className={styles.container}>
      <Typography variant="label">
        {value}
        {additionalNotToCopy}
      </Typography>
      <Button
        variant="ghost"
        size="small"
        startIcon={<ContentCopyIcon fontSize="small" />}
        onClick={handleCopyToClipboard}
        tooltip={`Copy ${name} to clipboard`}
      />
    </div>
  )
}
export default CopyToClipboard
