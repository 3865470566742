import React, { useState, Fragment } from "react"
import { useQuery } from "react-query"

import AddCircleIcon from "@mui/icons-material/AddCircle"
import { Grid, useMediaQuery } from "@mui/material"

import { Typography, Button, Skeleton } from "@synapse-analytics/synapse-ui"
import { AxiosError } from "axios"

import { VisionAPI } from "../../API/VisionAPI"
import NoLogsPlaceholder from "../../assets/NoHistoryLogs.svg"
import { definitions } from "../../types/Generated/apiTypes"
import AddCategory from "./components/AddCategory"
import CategoryCard from "./components/CategoryCard"

import styles from "./CategoriesList.module.scss"

type Category = definitions["Category"]

const CategoriesList = () => {
  const [open, setOpen] = useState(false)
  const [selectedCategory, setSelectedCategory] = useState<Category>()
  const [isEdit, setIsEdit] = useState(false)
  const xLargeScreen = useMediaQuery("(min-width:1900px)")

  const { data: categories, isLoading } = useQuery<Category[], AxiosError>("fetchCategories", VisionAPI.fetchCategories)

  const handleClose = () => {
    setOpen(false)
    setIsEdit(false)
    setSelectedCategory(undefined)
  }

  const handleOpenAddNewCategoryDialog = () => {
    setIsEdit(false)
    setOpen(true)
  }
  const handleOpenEditCategoryDialog = (categoryToEdit: Category) => {
    setSelectedCategory(categoryToEdit)
    setIsEdit(true)
    setOpen(true)
  }
  // loading placeholders
  const loadingPlaceholders = new Array(20).fill(null).map((r, i) => (
    <Grid item xs={12} lg={4} xl={xLargeScreen ? 3 : 4} key={i}>
      <Skeleton variant="rectangular" height={142} width={"auto"} />
    </Grid>
  ))
  return (
    <div className={styles.wrapper}>
      <Typography
        variant="h2-regular"
        tooltip="Category management for your assets"
        tooltipPlacement="right"
        tooltipIconSize={22}
        gutterBottom
        variantColor={2}
      >
        Categories Management
      </Typography>

      <div className={styles.header}>
        {/* add new category */}
        <Button
          disabled={isLoading}
          onClick={handleOpenAddNewCategoryDialog}
          style={{
            display: !isLoading && (!categories || (categories && categories.length < 1)) ? "none" : "flex",
          }}
          startIcon={<AddCircleIcon fontSize="small" />}
          variant="primary"
        >
          Add New Category
        </Button>
      </div>
      <Grid container spacing={2}>
        {/* categories list */}
        {isLoading ? (
          <Fragment>{loadingPlaceholders}</Fragment>
        ) : categories && categories?.length > 0 ? (
          <Fragment>
            {categories?.map((category: Category) => {
              return (
                <Grid item xs={12} lg={4} xl={xLargeScreen ? 3 : 4} key={category?.id!}>
                  <CategoryCard key={category?.id} category={category} handleOpenEdit={handleOpenEditCategoryDialog} />
                </Grid>
              )
            })}
          </Fragment>
        ) : (
          <Grid item xs={12} className={styles.categoriesPlaceholderWrapper}>
            <img src={NoLogsPlaceholder} className={styles.categoriesPlaceholder} alt="No categories found" />
            <div>
              <Typography variant="h2-bold" align="center" className={styles.noCategoriesText}>
                No Categories Yet.
              </Typography>
            </div>
            <Button
              disabled={isLoading}
              onClick={handleOpenAddNewCategoryDialog}
              startIcon={<AddCircleIcon fontSize="small" />}
              variant="primary"
            >
              Add New Category
            </Button>
          </Grid>
        )}

        {/* Add category modal */}
        <AddCategory
          handleClose={handleClose}
          open={open}
          isEdit={isEdit}
          categoryData={selectedCategory}
          key={"isEdit" ? `${selectedCategory?.id}_edit` : "Create"}
        />
      </Grid>
    </div>
  )
}

export default CategoriesList
