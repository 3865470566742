import React, { useState, Fragment } from "react"
import { useMutation, useQuery, useQueryClient } from "react-query"
import { useNavigate } from "react-router-dom"

import { CircularProgress } from "@mui/material"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogTitle from "@mui/material/DialogTitle"

import { NotificationUtils, Typography } from "@synapse-analytics/synapse-ui"
import { StringParam, useQueryParam, withDefault } from "use-query-params"

import { VisionAPI } from "../../API/VisionAPI"
import Search from "../../components/Search"
import TableDataList from "../../components/tables/TableDataList"
import { routes } from "../../routes/routes"
import { definitions } from "../../types/Generated/apiTypes"
import ActionButton from "./components/ActionButton"

import styles from "./AllUsers.module.scss"

type UserList = definitions["UserList"]

const AllUsers = () => {
  const queryClient = useQueryClient()
  const [open, setOpen] = useState(false)
  const [searchValue, setSearchValue] = useQueryParam("search", withDefault(StringParam, ""))
  const [userId, setUserId] = useState<number | null>(null)
  const [deletedUserName, setDeletedUserName] = useState("")
  const navigate = useNavigate()
  const handleSearch = (value: string) => {
    setSearchValue(value)
  }

  // get all users
  const { data: allUsers, isLoading: usersLoading } = useQuery<UserList[]>("fetchUsers", VisionAPI.fetchUsers)

  // open dialog
  const handleClickOpen = (id: number | null, username: string) => {
    setOpen(true)
    setUserId(id)
    setDeletedUserName(username)
  }

  // close dialog
  const handleClose = () => {
    setOpen(false)
  }

  const { mutate } = useMutation((id: number) => VisionAPI.deleteUser(id), {
    onSuccess: () => {
      NotificationUtils.toast(`User ${deletedUserName} has been successfully deleted.`, {
        snackBarVariant: "positive",
      })
      queryClient.invalidateQueries("fetchUsers")
      setOpen(false)
    },
  })

  // filter Users
  let filteredData = allUsers!?.filter((user) => {
    return (
      user?.username?.toLowerCase().includes(searchValue.toLowerCase()) ||
      user?.email?.toLowerCase().includes(searchValue.toLowerCase())
    )
  })

  return (
    <Fragment>
      <Typography
        variant="h2-regular"
        tooltip="Manage users and permissions"
        tooltipPlacement="right"
        tooltipIconSize={22}
        gutterBottom
        variantColor={2}
      >
        User List
      </Typography>

      <div className={styles.header}>
        <Search
          topHeader={false}
          placeholder="E.g. Ahmed, Mohamed"
          handleSearch={handleSearch}
          loading={usersLoading}
          searchValue={searchValue}
        />
        <div>
          <ActionButton
            title="New User"
            isLoading={usersLoading}
            onClick={() =>
              navigate(`/${routes?.createUser}`, {
                state: { edit: false },
              })
            }
          />
        </div>
      </div>

      {/* Users Table */}
      {!usersLoading ? (
        <TableDataList data={filteredData!} handleClickOpen={handleClickOpen} />
      ) : (
        <div className={styles.loading}>
          <CircularProgress size={40} />
        </div>
      )}

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{`Are you sure to delete user ${deletedUserName} ?`}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            You're about to delete user {deletedUserName}.
          </DialogContentText>
          <DialogContentText id="alert-dialog-description">
            Be aware when deleting Users this action can't be undone
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={() => mutate(userId as number)} color="primary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  )
}

export default AllUsers
