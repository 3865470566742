import React, { FC, useEffect, useState } from "react"

import MuiAlert from "@mui/material/Alert"
import Snackbar from "@mui/material/Snackbar"

import { Typography } from "@synapse-analytics/synapse-ui"

import NoCamera from "../assets/NoCamera.svg?react"
import NoHistoryLogs from "../assets/NoHistoryLogs.svg?react"

import styles from "./Placeholder.module.scss"

function Alert(props: any) {
  return <MuiAlert elevation={6} variant={"filled"} {...props} />
}

interface Props {
  alertMessage?: string
  openSnackbar?: boolean
  selectionType?: "history" | "camera"
  description?: string
  isScreenPlaceholder?: boolean
  svgHeight?: string
  svgWidth?: string
}
const Placeholder: FC<Props> = ({
  alertMessage,
  selectionType = "history",
  description,
  openSnackbar,
  isScreenPlaceholder,
  svgWidth = "182px",
  svgHeight = "220px",
}) => {
  const EmptyStates = {
    history: <NoHistoryLogs className={styles.placeholder} height={svgHeight} width={svgWidth ?? ""} />,
    camera: <NoCamera className={styles.placeholder} height={svgHeight} width={svgWidth ?? ""} />,
  }

  const [open, setOpen] = useState(false)

  useEffect(() => {
    if (openSnackbar) {
      setOpen(openSnackbar)
    }
  }, [openSnackbar])

  const handleClose = (event: any, reason: any) => {
    if (reason === "clickaway") {
      return
    }
    setOpen(false)
  }

  return (
    <div
      className={styles.placeholderWrapper}
      style={{
        height: isScreenPlaceholder ? "70vh" : "100%",
      }}
    >
      {EmptyStates[selectionType]}
      <Typography variant="label" variantColor={2}>
        {description}
      </Typography>
      {alertMessage && (
        <div>
          <Snackbar
            open={open}
            classes={{ anchorOriginBottomCenter: styles.snackBar }}
            autoHideDuration={5000}
            onClose={handleClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          >
            <div>
              <Alert severity="warning" onClose={handleClose}>
                {alertMessage}
              </Alert>
            </div>
          </Snackbar>
        </div>
      )}
    </div>
  )
}
export default Placeholder
