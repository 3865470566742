import { useEffect, useState } from "react"

/**
 * Custom hook to handle text streaming effect.
 *
 * @param {string} text - The text to be streamed.
 * @param {boolean} isActive - Flag to indicate if the streaming effect should be active.
 * @param {number} [delay=30] - Delay in milliseconds between each character being displayed.
 * @param {function} setIsTextStreaming - Function to set the streaming state.
 * @param {React.RefObject<HTMLTextAreaElement>} textAreaRef - Reference to the text area element.
 * @returns {string} - The currently displayed content.
 */
export const useTextStream = (
  text?: string,
  isActive?: boolean,
  delay: number = 30,
  setIsTextStreaming?: (isStreaming: boolean) => void,
  textAreaRef?: React.RefObject<HTMLTextAreaElement>
) => {
  const [displayedContent, setDisplayedContent] = useState(isActive ? "" : text)

  /**
   * This effect is responsible for streaming the text when the isActive and text are both true.
   * It uses the useTextStream hook to achieve this effect.
   */
  useEffect(() => {
    if (isActive && text) {
      let index = 0
      // Reset content before streaming starts
      setDisplayedContent("")
      setIsTextStreaming?.(true) // Set streaming state to true

      // Use 'any' to accommodate both Node.js and browser environments for animationFrameId and indexAnimationFrameId
      let animationFrameId: any
      let indexAnimationFrameId: any

      const streamText = () => {
        if (index < text.length) {
          setDisplayedContent((prev) => prev + text.charAt(index))
          // Two timeouts are needed to achieve the desired text streaming effect.
          // The first timeout, `indexAnimationFrameId`, increments the index after a short delay (10ms) to simulate the typing effect.
          // This allows the text to be displayed character by character, creating a streaming effect.
          // The second timeout, `animationFrameId`, schedules the next frame of the animation using `requestAnimationFrame`.
          // This ensures that the next character is displayed after a delay specified by the `delay` parameter, which controls the speed of the streaming effect.
          indexAnimationFrameId = setTimeout(() => index++, delay)
          animationFrameId = setTimeout(() => requestAnimationFrame(streamText), delay)
        } else {
          // Set streaming state to false after completion
          setIsTextStreaming?.(false)
          // Focus the text area after streaming is complete
          textAreaRef?.current?.focus()
        }
      }
      // Start streaming the text
      streamText()

      // Cleanup to avoid memory leaks
      return () => {
        clearTimeout(animationFrameId)
        clearTimeout(indexAnimationFrameId)
      }
    }
  }, [text, isActive, delay, setIsTextStreaming, textAreaRef])

  return displayedContent
}
