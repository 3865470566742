// TODO:: Refactor this component to be generic , eliminate prop drilling and extract bar into a reusable component 'DistributionBar'
import { FC, useMemo } from "react"

import { Card, Divider } from "@mui/material"

import { Typography } from "@synapse-analytics/synapse-ui"

import adultsIcon from "../assets/adultsIcon.svg"
import childrenIcon from "../assets/childrenIcon.svg"
import femaleIcon from "../assets/femaleIcon.svg"
import maleIcon from "../assets/maleIcon.svg"
import DistributionBar from "./DistributionBar"

import styles from "./GenderAgeCard.module.scss"

interface Props {
  femaleStat?: number
  maleStat?: number
  unknownGenderStat?: number
  childCount?: number
  adultCount?: number
  unknownAgeStat?: number
  dwellAndEnter?: number
  dwellAndLeft?: number
  stoodAndEnter?: number
  stoodAndLeft?: number
  categoryFairShare?: number
  subcategoryFairShare?: number
  graphTooltip?: boolean
  includeDwelling?: boolean
  includeFairShare?: boolean
  pointDate?: string
  isLoading?: boolean
}
const GenderAgeCard: FC<Props> = ({
  femaleStat,
  maleStat,
  unknownGenderStat = 0,
  childCount,
  adultCount,
  unknownAgeStat = 0,
  dwellAndEnter,
  dwellAndLeft,
  stoodAndEnter,
  stoodAndLeft,
  categoryFairShare,
  subcategoryFairShare,
  isLoading,
  graphTooltip,
  includeDwelling,
  includeFairShare,
  pointDate,
}) => {
  const femalePercentage = useMemo(() => {
    return maleStat && femaleStat ? (femaleStat / (maleStat + femaleStat + unknownGenderStat)) * 100 : 50
  }, [maleStat, femaleStat, unknownGenderStat])

  const malePercentage = useMemo(() => {
    return maleStat && femaleStat ? (maleStat / (maleStat + femaleStat + unknownGenderStat)) * 100 : 100
  }, [maleStat, femaleStat, unknownGenderStat])

  const childrenPercentage = useMemo(() => {
    return childCount && adultCount ? (childCount / (childCount + adultCount + unknownAgeStat)) * 100 : 50
  }, [childCount, adultCount, unknownAgeStat])

  const adultPercentage = useMemo(() => {
    return childCount && adultCount ? (adultCount / (childCount + adultCount + unknownAgeStat)) * 100 : 100
  }, [childCount, adultCount, unknownAgeStat])

  const dwellAndEnterPercentage = useMemo(() => {
    return dwellAndEnter && dwellAndLeft ? (dwellAndEnter / (dwellAndLeft + dwellAndEnter)) * 100 : 50
  }, [dwellAndEnter, dwellAndLeft])

  const stoodAndEnterPercentage = useMemo(() => {
    return stoodAndEnter && stoodAndLeft ? (stoodAndEnter / (stoodAndLeft + stoodAndEnter)) * 100 : 50
  }, [stoodAndEnter, stoodAndLeft])

  const categoryFairSharePercentage = useMemo(() => {
    return categoryFairShare && categoryFairShare > 0 ? (categoryFairShare / (1 + categoryFairShare)) * 100 : 0
  }, [categoryFairShare])

  const subcategoryFairSharePercentage = useMemo(() => {
    return subcategoryFairShare && subcategoryFairShare > 0
      ? (subcategoryFairShare / (subcategoryFairShare + 1)) * 100
      : 0
  }, [subcategoryFairShare])

  return (
    <Card className={`${styles.distributionCard} ${graphTooltip ? styles.graphToolTipWrapper : ""}`}>
      {graphTooltip && pointDate && (
        <Typography variant="label" variantColor={2} gutterBottom align="center">
          {pointDate}
        </Typography>
      )}
      {/* Gender Distribution */}
      <DistributionBar
        leftBar={{
          percentage: femalePercentage,
          label: "Female",
          value: femaleStat,
          color: "var(--pink-background-2)",
          tooltipTitle: `Female : ${femaleStat?.toLocaleString()}`,
          icon: femaleIcon,
        }}
        rightBar={{
          percentage: malePercentage,
          label: "Male",
          value: maleStat,
          color: "var(--blue-background-2)",
          tooltipTitle: `Male : ${maleStat?.toLocaleString() || "No data"}`,
          icon: maleIcon,
        }}
        isLoading={isLoading}
        title="Gender Distribution"
        unknown={unknownGenderStat}
      />
      {/* Age Distribution */}
      <DistributionBar
        leftBar={{
          percentage: childrenPercentage,
          label: "Children",
          value: childCount,
          color: "var(--teal-background-2)",
          tooltipTitle: `Children : ${childCount?.toLocaleString()}`,
          icon: childrenIcon,
        }}
        rightBar={{
          percentage: adultPercentage,
          label: "Adults",
          value: adultCount,
          color: "var(--purple-background-2)",
          tooltipTitle: `Adults : ${adultCount?.toLocaleString()}`,
          icon: adultsIcon,
        }}
        isLoading={isLoading}
        title="Age Distribution"
        unknown={unknownAgeStat}
      />
      {/* Dwelling Distribution */}
      {includeDwelling && (
        <DistributionBar
          leftBar={{
            percentage: dwellAndEnterPercentage,
            label: "Dwelled and entered",
            value: dwellAndEnter,
            color: "#FFDBCC",
            tooltipTitle: `Dwelled and entered : ${dwellAndEnter?.toLocaleString()}`,
          }}
          rightBar={{
            percentage: 100 - dwellAndEnterPercentage,
            label: "Dwelled and left",
            value: dwellAndLeft,
            color: "#CCFFD1",
            tooltipTitle: `Dwelled and left : ${dwellAndLeft?.toLocaleString() || "No data"}`,
          }}
          isLoading={isLoading}
          title="Dwelling Distribution"
        />
      )}
      {includeDwelling && (
        <DistributionBar
          leftBar={{
            percentage: stoodAndEnterPercentage,
            label: "Stood and entered",
            value: stoodAndEnter,
            color: "#F7F4A6",
            tooltipTitle: `Stood and entered : ${stoodAndEnter?.toLocaleString()}`,
          }}
          rightBar={{
            percentage: 100 - stoodAndEnterPercentage,
            label: "Stood and left",
            value: stoodAndLeft,
            color: "#DCCCFF",
            tooltipTitle: `Stood and left : ${stoodAndLeft?.toLocaleString() || "No data"}`,
          }}
          isLoading={isLoading}
          title="Standing Distribution"
        />
      )}
      {/* Standing Distribution : Tenant Details */}

      {includeFairShare && (
        <div>
          <Divider className={styles.divider} />
          <Typography variant="p" variantColor={2} className={styles.cardTitle} align="center">
            Fair Share
          </Typography>
          <DistributionBar
            isRaceBar
            leftBar={{
              percentage: categoryFairSharePercentage,
              label: "0",
              value: categoryFairShare,
              color: categoryFairShare! < 1 ? "var(--red-background-2)" : "var(--green-background-2)",
              tooltipTitle: `Category Fair Share : ${categoryFairShare?.toLocaleString()}`,
            }}
            rightBar={{
              percentage: 100 - categoryFairSharePercentage,
              label: "",
              value: "",
              color: "var(--indigo-background-2)",
              tooltipTitle: "",
            }}
            isLoading={isLoading}
            title="Fair Share Category Distribution"
          />
          <DistributionBar
            leftBar={{
              percentage: subcategoryFairSharePercentage,
              label: "0",
              value: subcategoryFairShare,
              color: subcategoryFairShare! < 1 ? "var(--red-background-2)" : "var(--green-background-2)",
              tooltipTitle: `Sub-Category Fair Share : ${subcategoryFairShare?.toLocaleString()}`,
            }}
            rightBar={{
              percentage: 100 - subcategoryFairSharePercentage,
              label: "",
              value: "",
              color: "var(--indigo-background-2)",
              tooltipTitle: "",
            }}
            isLoading={isLoading}
            isRaceBar
            title="Fair Share Sub-Category Distribution"
          />
        </div>
      )}
    </Card>
  )
}
export default GenderAgeCard
