import React, { FC, useState, useEffect } from "react"
import { useNavigate } from "react-router-dom"

import Paper from "@mui/material/Paper"

import MaterialTable, { MTableToolbar } from "@material-table/core"
import { Typography } from "@synapse-analytics/synapse-ui"
import { Moment } from "moment"

import NoLogs from "../../assets/NoHistoryLogs.svg"
import { routes } from "../../routes/routes"
import { definitions } from "../../types/Generated/apiTypes"
import CustomPagination from "./components/CustomPagination"

import styles from "./EntitiesList.module.scss"

type DwellingAreaData = definitions["DwellingAreaAnalytics"]
type shopData = definitions["ShopsAnalytics"] & {
  categoryFairShare: number
}

interface Props {
  title: string
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any[]
  isLoading: boolean
  maxBodyHeight: string
  startDate?: Moment | null
  endDate?: Moment | null
  interval: Duration
  isDwelling?: boolean
}
const EntitiesList: FC<Props> = ({
  title,
  data,
  isLoading,
  maxBodyHeight,
  startDate,
  endDate,
  interval,
  isDwelling,
}) => {
  const [columns, setColumns] = useState<any>([])
  const navigate = useNavigate()

  /**
   * Handles navigation based on the provided rowData.
   *
   * @param {Object} rowData - The data of the row.
   */
  const handleNavigation = (rowData: shopData | DwellingAreaData): void => {
    let path = ""
    let state = {}

    if (!isDwelling) {
      // tenantData is for casting shopData type
      const tenantData = rowData as shopData
      path = `/${routes?.tenantsList}/${routes?.tenantDetails}/${tenantData.entity_id}`
      state = {
        startDate: startDate?.toDate(),
        endDate: endDate?.toDate(),
        tenantId: tenantData.entity_id,
        tenantName: tenantData.name,
        area: tenantData.area,
        category: tenantData.category,
        subcategory: tenantData.subcategory,
      }
    } else {
      path = `/${routes?.dwellingAreas}/${routes?.dwellingAreaDetails}/${rowData.entity_id}`
    }

    navigate(path, { state })
  }

  useEffect(() => {
    // Tmp -> handle NaN shops
    let columns = [
      {
        title: (
          <Typography variant="label" align="center">
            {isDwelling ? "Area" : "Tenant"} Name
          </Typography>
        ),
        field: "name",
        render: (rowData: shopData | DwellingAreaData) => {
          return (
            <Typography
              className={styles.tenantName}
              variant="p"
              color="important"
              variantColor={2}
              onClick={() => {
                handleNavigation(rowData)
              }}
            >
              {rowData.name}
            </Typography>
          )
        },
      },
      {
        title: "Category",
        field: "category",
        searchable: false,
        headerStyle: { textAlign: "center" },
        cellStyle: { textAlign: "center" },
        render: (rowData: shopData | DwellingAreaData) => (
          <Typography
            title={rowData.category ? rowData.category : "category"}
            color={!rowData.category ? "neutral" : "orange"}
            variantColor={2}
            align="center"
            variant="p"
          >
            {!!rowData.category
              ? rowData.category?.length >= 18
                ? rowData.category.slice(0, 15) + ".."
                : rowData.category
              : " ــــ "}
          </Typography>
        ),
      },
      {
        title: "Sub-category",
        field: "subcategory",
        searchable: false,
        headerStyle: { textAlign: "center" },
        cellStyle: { textAlign: "center" },
        render: (rowData: shopData | DwellingAreaData) => (
          <Typography
            title={rowData.subcategory ? rowData.subcategory : "sub-category"}
            color={!rowData.subcategory ? "neutral" : "pink"}
            variantColor={2}
            align="center"
            variant="p"
          >
            {!!rowData.subcategory
              ? rowData.subcategory?.length >= 18
                ? rowData.subcategory.slice(0, 15) + ".."
                : rowData.subcategory
              : " ــــ "}
          </Typography>
        ),
      },
      {
        title: "Total Count",
        field: isDwelling ? "total_dwelling_count" : "in_count",
        searchable: false,
        cellStyle: { textAlign: "center" },
        headerStyle: { textAlign: "center" },
      },
      !isDwelling && {
        title: (
          <Typography variant="label" align="center">
            Area
          </Typography>
        ),
        field: "area",
        render: (rowData: shopData) => (
          <Typography variant="p" align="center" variantColor={!!rowData.area ? 1 : 2}>
            {!!rowData.area ? rowData.area + " m²" : " ــــ "}
          </Typography>
        ),
        searchable: false,
        cellStyle: { textAlign: "center" },
        headerStyle: { textAlign: "center" },
      },
      !isDwelling && {
        title: (
          <Typography
            variant="label"
            align="center"
            tooltip="Percentage of visitors that entered the tenant after dwelling"
            tooltipPlacement="top"
            tooltipIconSize={16}
          >
            Dwelling Convergence Rate
          </Typography>
        ),
        field: "dwelling_convergence_rate",
        render: (rowData: shopData) => (
          <Typography
            variant="p"
            align="center"
            variantColor={
              !!rowData.dwelling_convergence_rate && Math.ceil(rowData?.dwelling_convergence_rate) !== 0 ? 1 : 2
            }
          >
            {!!rowData.dwelling_convergence_rate && Math.ceil(rowData?.dwelling_convergence_rate) !== 0
              ? (rowData.dwelling_convergence_rate * 100).toFixed(2).replace(/[.,]00$/, "") + " %"
              : " ــــ "}
          </Typography>
        ),
        searchable: false,
        cellStyle: { textAlign: "center" },
        headerStyle: { textAlign: "center" },
      },
      !isDwelling && {
        title: (
          <Typography
            variant="label"
            align="center"
            tooltip="Count in / (category count /(area / category area))"
            tooltipPlacement="top"
            tooltipIconSize={16}
          >
            Fair Share
          </Typography>
        ),
        field: "categoryFairShare",

        searchable: false,
        render: (rowData: shopData) => (
          <Typography variant="p" variantColor={!!rowData.categoryFairShare ? 1 : 2} align="center">
            {!!rowData.categoryFairShare ? +rowData.categoryFairShare : " ــــ "}
          </Typography>
        ),
        cellStyle: { textAlign: "center" },
        headerStyle: { textAlign: "center" },
      },
      !isDwelling && {
        title: (
          <Typography
            variant="label"
            align="center"
            tooltip="Count in / area"
            tooltipPlacement="top"
            tooltipIconSize={16}
          >
            Occupancy Rate
          </Typography>
        ),
        field: "occupancy_rate",
        render: (rowData: shopData) => (
          <Typography
            variant="p"
            variantColor={+rowData.occupancy_rate <= 0 || isNaN(+rowData.occupancy_rate) ? 2 : 1}
            align="center"
          >
            {+rowData.occupancy_rate <= 0 || isNaN(+rowData.occupancy_rate)
              ? " ــــ "
              : typeof rowData.occupancy_rate === "string"
              ? rowData.occupancy_rate
              : rowData.occupancy_rate + " Person / m²"}
          </Typography>
        ),
        searchable: false,
        cellStyle: { textAlign: "center" },
        headerStyle: { textAlign: "center" },
      },
      {
        title: (
          <Typography
            variant="label"
            align="center"
            tooltip="Average dwelling time in minutes"
            tooltipPlacement="top"
            tooltipIconSize={16}
          >
            Avg. Dwelling
          </Typography>
        ),
        field: "avg_dwelling_duration",
        render: (rowData: shopData) => (
          <Typography variant="p" variantColor={!!rowData.avg_dwelling_duration ? 1 : 2} align="center">
            {!!rowData.avg_dwelling_duration
              ? typeof rowData.avg_dwelling_duration === "string"
                ? rowData.avg_dwelling_duration
                : `${(rowData.avg_dwelling_duration / 60).toFixed(2)} Minutes`
              : " ــــ "}
          </Typography>
        ),
        searchable: false,
        cellStyle: { textAlign: "center" },
        headerStyle: { textAlign: "center" },
      },
      // Add other columns in a similar way
    ].filter(Boolean) // Removes falsy values (in this case, null)

    setColumns(columns)
    //eslint-disable-next-line
  }, [data, startDate, endDate, interval])

  return (
    <MaterialTable
      isLoading={isLoading}
      title={title}
      columns={columns}
      actions={[]}
      data={data ?? []}
      options={{
        padding: "default",
        maxBodyHeight: maxBodyHeight,
        defaultExpanded: () => false,
        paging: true,
        pageSize: 15,
        exportAllData: false,
        search: false,
        selection: false,
        actionsColumnIndex: -1,
        showTitle: false,
        toolbar: false,
        headerStyle: {
          backgroundColor: "#e0eaff",
          color: "var(--neutral-text-enabled)",
          fontSize: 12,
          fontWeight: 700,
          padding: "8px",
        },
        rowStyle: (rowData, index, level) => {
          if (index % 2) {
            return {
              padding: "8px 19px !important",
              backgroundColor: "var(--greyScale-background-2)",
              borderBottom: "1px solid var(--grayscale-border-light-purple)",
              borderRadius: "2px 2px 2px 2px",
              color: "var(--neutral-text-enabled)",
              fontSize: 16,
              fontWeight: 400,
            }
          } else {
            return {
              padding: "8px 19px !important",
              borderBottom: "1px solid var(--grayscale-border-light-purple)",
              backgroundColor: "var(--greyScale-background-3)",
              borderRadius: "2px 2px 2px 2px",
              color: "var(--neutral-text-enabled)",
              fontSize: 16,
              fontWeight: 400,
            }
          }
        },
      }}
      localization={{
        header: {
          actions: "",
        },
        body: {
          emptyDataSourceMessage: !isLoading && (
            <div className={styles.noLogs}>
              <img src={NoLogs} alt="No Entity Logs" className={styles.noLogsImage} />
              <div>
                <Typography variant="h2-regular" variantColor={2} align="center">
                  No logs yet
                </Typography>
              </div>
            </div>
          ),
        },
      }}
      components={{
        Toolbar: (props) => (
          <div
            style={{
              height: "0px",
            }}
          >
            <MTableToolbar {...props} />
          </div>
        ),
        Container: (props) => <Paper {...props} elevation={0} className={styles.table} />,
        Pagination: (props) => {
          return <CustomPagination {...props} />
        },
      }}
    />
  )
}

export default EntitiesList
