import { useMemo, FC } from "react"

import { Typography } from "@synapse-analytics/synapse-ui"

import BusPlate from "../components/assets/licenses/busPlate.svg?react"
import DiplomaticPlate from "../components/assets/licenses/diplomaticPlate.svg?react"
import PrivatePlate from "../components/assets/licenses/privatePlate.svg?react"
import StandardPlate from "../components/assets/licenses/standardPlate.svg?react"
import TaxiPlate from "../components/assets/licenses/taxiPlate.svg?react"
import TruckPlate from "../components/assets/licenses/truckPlate.svg?react"

import styles from "./CarLicensePlate.module.scss"

interface PlateProps {
  plateType: "prv" | "dip" | "taxi" | "trk" | "bus" | "standard"
}

const Plate: FC<PlateProps> = ({ plateType }) => {
  switch (plateType) {
    case "prv":
      return <PrivatePlate className={styles.plate} />
    case "taxi":
      return <TaxiPlate className={styles.plate} />
    case "bus":
      return <BusPlate className={styles.plate} />
    case "trk":
      return <TruckPlate className={styles.plate} />
    case "dip":
      return <DiplomaticPlate className={styles.plate} />
    default:
      return <StandardPlate className={styles.plate} />
  }
}
interface Props {
  type?: "prv" | "dip" | "taxi" | "trk" | "bus"
  licensePlateData?: string
}
interface LicensePlateInfo {
  licenseLetters: string
  licenseNumbers: string
  isForeign: boolean
  areShortNumbers: boolean
  areShortLetters: boolean
}

function parseLicensePlate(licensePlate?: string): LicensePlateInfo | null {
  if (!licensePlate) {
    return null // Return null for undefined or empty input
  }

  // Check if the license plate contains Arabic characters
  const isArabic: boolean = /[\u0600-\u06FF]/.test(licensePlate)

  // Separate letters and numbers
  const letters: string = licensePlate.replace(/[0-9]/g, "").trim()
  const numbers: string = licensePlate.replace(/[a-zA-Z\u0600-\u06FF-]/g, "").trim()

  // Check if numbers are short (less than 3)
  const areShortNumbers: boolean = numbers.length < 4

  // Check if letters are short (less than 4)
  const areShortLetters: boolean = letters.length < 4

  // Create and return the result object
  const result: LicensePlateInfo = {
    licenseLetters: letters,
    licenseNumbers: numbers,
    isForeign: !isArabic,
    areShortNumbers: areShortNumbers,
    areShortLetters: areShortLetters,
  }

  return result
}

const CarLicensePlate: FC<Props> = ({ licensePlateData, type }) => {
  const parsedLicensePlate = useMemo(() => {
    return parseLicensePlate(licensePlateData)
  }, [licensePlateData])

  return (
    <div className={styles.container}>
      {parsedLicensePlate?.isForeign || !type ? (
        <div className={styles.wrapper}>
          <Plate plateType="standard" />
          <Typography noWrap variant="h2-bold" className={`${styles.lettersAndNumbers} ${styles.foreignLetters}`}>
            {licensePlateData}
          </Typography>
        </div>
      ) : (
        <div className={styles.wrapper}>
          <Plate plateType={type} />
          <div className={styles.lettersAndNumbers}>
            <Typography
              variant="h2-bold"
              style={{
                marginLeft: parsedLicensePlate?.areShortNumbers ? 12 : 6,
              }}
              className={styles.numbers}
            >
              {parsedLicensePlate?.licenseNumbers}
            </Typography>
            <Typography
              variant="h2-bold"
              className={styles.letters}
              style={{
                marginRight: parsedLicensePlate?.areShortLetters ? 8 : 4,
                letterSpacing: parsedLicensePlate?.areShortLetters ? 3 : 0.4,
              }}
            >
              {parsedLicensePlate?.licenseLetters}
            </Typography>
          </div>
        </div>
      )}
    </div>
  )
}
export default CarLicensePlate
