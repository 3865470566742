import React, { useState, useEffect, FC, Fragment } from "react"

import { Grid } from "@mui/material"

import { Typography } from "@synapse-analytics/synapse-ui"

import GraphEmptyState from "../../../components/graphs/GraphEmptyState"
import barChartLoading from "../../../components/graphs/assets/barChartLoading.mp4"
import { definitions } from "../../../types/Generated/apiTypes"
import CarsNewReturnGraph from "./components/CarsNewReturnGraph"

import styles from "./CarsNewRetruning.module.scss"

type NewReturning = definitions["NewReturning"]

interface Props {
  refCountsVisits: React.MutableRefObject<any>
  isLoading: boolean
  carTotalCounts: NewReturning
}

const CarsNewReturning: FC<Props> = ({ refCountsVisits, isLoading, carTotalCounts }) => {
  const [totalCarsCount, setTotalCarsCount] = useState(0)
  const [newAndReturning, setNewAndReturning] = useState<NewReturning>({ n_new: 0, n_returning: 0, n_unknown: 0 })
  useEffect(() => {
    if (!isLoading && carTotalCounts) {
      setTotalCarsCount(carTotalCounts?.n_new + carTotalCounts?.n_returning + carTotalCounts?.n_unknown)
      setNewAndReturning(carTotalCounts)
    }
  }, [isLoading, carTotalCounts])
  return (
    <Grid item xs={12} md={3}>
      <div className={styles.stats} ref={refCountsVisits}>
        <Typography variant="p" variantColor={2} gutterBottom style={{ marginTop: 14 }}>
          Total car counts
        </Typography>
        {!isLoading && totalCarsCount === 0 ? (
          <GraphEmptyState />
        ) : isLoading ? (
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            className={styles.loadingContainer}
          >
            <Grid item>
              <video autoPlay={true} loop={true} width="300" height="200">
                <source src={barChartLoading} type="video/mp4" />
              </video>
            </Grid>
          </Grid>
        ) : (
          <Fragment>
            <Typography variant="h1-bold" variantColor={2}>
              {totalCarsCount.toLocaleString()}
            </Typography>

            {/*  New Vs. Returning Graph */}
            <CarsNewReturnGraph carTotalCounts={newAndReturning} />
          </Fragment>
        )}
      </div>
    </Grid>
  )
}

export default CarsNewReturning
