import React, { useState, useEffect, Fragment, useRef } from "react"

import GetAppIcon from "@mui/icons-material/GetApp"

import { Button, DateRangePicker, Typography } from "@synapse-analytics/synapse-ui"
import intervalToDuration from "date-fns/intervalToDuration"

import { useDateQuery } from "../../hooks/useDateQuery"
import { exportGraphs } from "../../utils/genericHelpers"
import ShopStatistics from "./components/ShopStatistics"

import styles from "./Tenants.module.scss"

const Tenants = () => {
  const [startDate, setStartDate, endDate, setEndDate] = useDateQuery()
  const [timeGrain, setTimeGrain] = useState<"hour" | "day" | null>(null)
  const [interval, setInterval] = useState<Duration>()
  const [isLoading, setIsLoading] = useState(true)
  const cardsRefs = {
    categoriesOverTimeRef: useRef(),
    categoriesPerformanceRef: useRef(),
    subcategoriesPerformanceRef: useRef(),
    hourlyAvgRef: useRef(),
    weekDayAvgRef: useRef(),
  }

  useEffect(() => {
    if (endDate !== null && startDate !== null) {
      const interval = intervalToDuration({
        start: startDate!.toDate(),
        end: endDate!.toDate(),
      })
      setInterval(interval)
      if (interval.days! > 1 || interval.months! >= 1) {
        setTimeGrain("day")
      } else {
        setTimeGrain("hour")
      }
    }
  }, [startDate, endDate, timeGrain])

  return (
    <Fragment>
      <div className={styles.wrapper}>
        <div className={styles.titleWrapper}>
          <Typography
            variant="h2-regular"
            tooltip="Comprehensive analytics dashboard showcasing counts and trends across various categories and subcategories."
            tooltipPlacement="right"
            tooltipIconSize={22}
            gutterBottom
            variantColor={2}
          >
            Categories
          </Typography>
          <div className={styles.datePicker}>
            <DateRangePicker
              startDate={startDate}
              endDate={endDate}
              disabled={isLoading}
              onStartDateChange={setStartDate}
              onEndDateChange={setEndDate}
              disableFuture
            />
            <Button
              startIcon={<GetAppIcon fontSize="small" />}
              variant="secondary"
              onClick={() => exportGraphs(cardsRefs, startDate, endDate, "Tenants")}
              disabled={!startDate || !endDate || isLoading}
            >
              Export
            </Button>
          </div>
        </div>

        <ShopStatistics
          startDate={startDate}
          endDate={endDate}
          interval={interval!}
          setLoadingState={setIsLoading}
          timeGrain={timeGrain!}
          refs={cardsRefs}
        />
      </div>
    </Fragment>
  )
}
export default Tenants
