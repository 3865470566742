import React, { FC, useEffect, Fragment } from "react"
import { useQuery } from "react-query"

import { Grid } from "@mui/material"

import { Skeleton } from "@synapse-analytics/synapse-ui"
import { AxiosError } from "axios"
import { Moment } from "moment"

import { VisionAPI } from "../../../API/VisionAPI"
import { definitions } from "../../../types/Generated/apiTypes"
import CarsListsCard from "./CarsListsCard"

type ListsOfInterest = definitions["ListOfInterest"]
type CarsCount = definitions["NewReturning"]

interface Props {
  startDate: Moment | null
  endDate: Moment | null
  selectedListOfInterest: string
  selectedCarBrand: string
  selectedCarType: string
  listsOfInterest: ListsOfInterest[]
  listsOfInterestLoading: boolean
  loadingSetter: (isLoading: boolean) => void
}

const loadingPlaceholders = new Array(1).fill(null).map((_r, i) => (
  <Grid item xs={12} md={2} sm={6} key={i}>
    <Skeleton variant="rectangular" height={101} width="100%" />
  </Grid>
))

const CarsLists: FC<Props> = ({
  startDate,
  endDate,
  selectedListOfInterest,
  selectedCarBrand,
  selectedCarType,
  listsOfInterestLoading,
  loadingSetter,
}) => {
  // fetch all car counts
  const { data: numOfCars, isLoading: carsTotalCountsLoading } = useQuery<CarsCount, AxiosError>(
    [
      "fetchCarsTotalCounts",
      startDate?.format("YYYY-MM-DD"),
      endDate?.clone().add(1, "days").format("YYYY-MM-DD"),
      selectedListOfInterest,
      selectedCarBrand,
      selectedCarType,
    ],
    ({ queryKey }) =>
      VisionAPI.fetchCarsTotalCounts({
        start_dt: queryKey[1] as string,
        end_dt: queryKey[2] as string,
        list_of_interest: queryKey[3] as number,
        brand: queryKey[4] as string,
        car_type: queryKey[5] as string,
      }),
    {
      enabled: !!endDate,
    }
  )

  // disable date picker while loading and handle loading states
  useEffect(() => {
    if (carsTotalCountsLoading || listsOfInterestLoading) {
      loadingSetter(true)
    } else {
      loadingSetter(false)
    }
  }, [loadingSetter, carsTotalCountsLoading, listsOfInterestLoading])

  return (
    <Grid container spacing={2}>
      {/* Car lists */}
      {carsTotalCountsLoading ? (
        <Grid container spacing={2}>
          {loadingPlaceholders}
        </Grid>
      ) : (
        <Fragment>
          {/* total number of cars */}
          <CarsListsCard carsCounts={numOfCars!} carsCountsLoading={carsTotalCountsLoading} />
          {/* TODO:: Lists of interest should be retrieved as each list and its counts from backend */}

          {/* {listsOfInterest &&
            Object.keys(listsOfInterest).length !== 0 &&
            listsOfInterest.map((list) => (
              <CarsListsCard
                key={list?.id!}
                list={list}
                carsCounts={numOfCars}
                carsCountsLoading={carsTotalCountsLoading}
                listsOfInterestLoading={listsOfInterestLoading}
                carsLists={true}
              />
            ))} */}
        </Fragment>
      )}
    </Grid>
  )
}

export default CarsLists
