import React, { FC, useEffect, useState, useMemo } from "react"

import { Paper } from "@mui/material"

import HeatmapJS from "keli-heatmap.js"

import styles from "./LiveHeatmap.module.scss"

interface StreamData {
  camera?: string
  value: number
  x: number
  y: number
}

interface Props {
  streamData: StreamData
  liveHeatmapFloor: string
}

const LiveHeatmap: FC<Props> = ({ streamData, liveHeatmapFloor }) => {
  const [heatmapInstance, setHeatmapInstance] = useState<any>()
  const [heatmapPoints, setHeatmapPoints] = useState<StreamData[]>([])

  useEffect(() => {
    setTimeout(() => {
      setHeatmapInstance(
        HeatmapJS.create({
          container: document.getElementById("heatmap")!,
          radius: 25,
        })
      )
    }, 1000)
  }, [])

  const allData = useMemo(() => {
    return {
      max: 300,
      min: 0,
      data: heatmapPoints,
    }
  }, [heatmapPoints])

  // customize heatmap points
  useEffect(() => {
    if (heatmapInstance && streamData) {
      delete streamData?.camera
      let newDataPoint = streamData
      newDataPoint.value = 100

      // add new point to heatmap points array
      setHeatmapPoints((prevState) => [...prevState, newDataPoint])

      // reduce value [heat] of the points by 1 [Fading effect]
      if (heatmapPoints && heatmapPoints?.length > 0) {
        setHeatmapPoints((prevState) => {
          return prevState.map((points) => {
            return {
              ...points,
              value: points.value - 1,
            }
          })
        })
      }
      setHeatmapPoints((prevState) => prevState.filter((point) => point.value > 0))
      heatmapInstance.setData(allData)
    }
    // eslint-disable-next-line
  }, [heatmapInstance, streamData])

  useEffect(() => {
    if (heatmapInstance) {
      // reduce value [heat] of the points by 5 [Fading effect]
      setInterval(() => {
        setHeatmapPoints((prevState) => {
          return prevState.map((points) => {
            return {
              ...points,
              value: points.value - 3,
            }
          })
        })
      }, 1000)
    }
  }, [heatmapInstance])

  useEffect(() => {
    if (heatmapPoints && heatmapPoints?.length > 0) {
      // always represent heatmap points [according to fading effect]
      heatmapInstance.setData(allData)
    }
    // eslint-disable-next-line
  }, [heatmapPoints])

  return (
    <Paper
      className={styles.wrapper}
      sx={{
        height: streamData ? "auto" : "400px",
      }}
    >
      <div id="heatmap" style={{ position: "relative" }}>
        <img src={liveHeatmapFloor} alt="heatmapImage" />
      </div>
    </Paper>
  )
}

export default LiveHeatmap
