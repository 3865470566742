import React, { FC, useState, cloneElement, ReactElement, useRef, useEffect } from "react"

import styles from "./DropDownButtons.module.scss"

interface Props {
  trigger: ReactElement
  menu: ReactElement[]
}
const DropDownButtons: FC<Props> = ({ trigger, menu }) => {
  const [open, setOpen] = useState(false)
  const wrapperRef = useRef(null)
  //close if clicked outside.
  function useOutsideAlerter(ref: any) {
    useEffect(() => {
      function handleClickOutside(event: any) {
        if (ref.current && !ref.current.contains(event.target)) {
          setOpen(false)
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside)
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside)
      }
    }, [ref])
  }
  useOutsideAlerter(wrapperRef)
  const handleOpen = () => {
    setOpen((prevValue) => !prevValue)
  }
  return (
    <div className={styles.dropdown} ref={wrapperRef}>
      {cloneElement(trigger, {
        onClick: handleOpen,
      })}
      {open && (
        <ul className={styles.menu}>
          {menu.map((menuItem, index) => (
            <li key={index}>
              {cloneElement(menuItem, {
                onClick: () => {
                  menuItem.props.onClick()
                  setTimeout(() => setOpen(false), 300)
                },
              })}
            </li>
          ))}
        </ul>
      )}
    </div>
  )
}
export default DropDownButtons
