import { FC, Fragment, useState } from "react"
import { useQuery } from "react-query"

import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline"
import VideocamIcon from "@mui/icons-material/Videocam"

import { Skeleton, Typography } from "@synapse-analytics/synapse-ui"

import { VisionAPI } from "../../../../../../API/VisionAPI"
import NoCameraFrame from "../../../../../../assets/noFrame.svg?react"
import WarningDialog from "../../../../../../components/WarningDialog"
import { definitions } from "../../../../../../types/Generated/apiTypes"

import styles from "./CameraCard.module.scss"

type Camera = definitions["CameraRetrieveUpdate"]

interface Props {
  isSelected?: boolean
  isDone?: boolean
  camera: number
  color?: string
  handleSelectCamera: (camera: number) => void
  handleDeleteCamera: (camera: number, cameras?: number[]) => void
}

const CameraCard: FC<Props> = ({ isSelected, camera, color, isDone, handleSelectCamera, handleDeleteCamera }) => {
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)

  const { data: cameraData, isLoading: isCameraDataLoading } = useQuery<Camera>(
    ["fetchSingleCamera", camera],
    ({ queryKey }) => VisionAPI.fetchSingleCamera(queryKey[1] as number),
    {
      enabled: !!camera,
    }
  )

  if (isCameraDataLoading) return <Skeleton width={167} height={125} variant="rectangular" />

  return (
    <Fragment>
      <WarningDialog
        isOpen={isDeleteDialogOpen}
        actionTitle="Delete"
        content="This camera includes drawn regions. If you delete it, the drawn data will be lost."
        onConfirm={() => {
          handleDeleteCamera(camera)
          setIsDeleteDialogOpen(false)
        }}
        onCancel={() => setIsDeleteDialogOpen(false)}
        dialogTitle={`You're about to delete “${cameraData?.name}” camera`}
      />
      <div
        className={styles.wrapper}
        style={{
          background: isSelected ? "var(--selected-background-secondary)" : undefined,
        }}
        onClick={(e) => {
          e.stopPropagation()
          handleSelectCamera(camera)
        }}
      >
        <div className={`${styles.cardHeader} ${isSelected ? styles.selected : ""}`}>
          <div className={styles.nameWrapper}>
            <VideocamIcon
              style={{
                color: color ?? "var(--gray-background-1)",
              }}
              fontSize="small"
            />
            <Typography variant="label" variantColor={isSelected ? 2 : 1} color={isSelected ? "important" : "neutral"}>
              {cameraData?.name}
            </Typography>
          </div>
          <DeleteOutlineIcon
            fontSize="small"
            className={styles.deleteIcon}
            onClick={(e) => {
              e.stopPropagation()
              if (isDone) {
                setIsDeleteDialogOpen(true)
              } else {
                handleDeleteCamera(camera)
              }
            }}
          />
        </div>

        {cameraData?.sample_frame ? (
          <div className={styles.frameWrapper}>
            <img
              src={cameraData?.sample_frame}
              alt="camera_sample_frame"
              height="100%"
              width="100%"
              className={styles.frame}
            />

            {isDone && (
              <div className={styles.doneStatusWrapper}>
                <Typography variant="h3-bold" color="positive" variantColor={2} className={styles.doneStatus}>
                  <CheckCircleIcon />
                  Done
                </Typography>
              </div>
            )}
          </div>
        ) : (
          <div className={styles.noFrameWrapper}>
            <NoCameraFrame width={25} height={13} />
            <Typography variant="label" variantColor={2}>
              No Frame Captured
            </Typography>
          </div>
        )}
      </div>
    </Fragment>
  )
}
export default CameraCard
