import React, { FC, Fragment } from "react"

import { Box } from "@mui/material"
import CircularProgress from "@mui/material/CircularProgress"
import Grid from "@mui/material/Grid"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"

import GraphEmptyState from "../../../components/graphs/GraphEmptyState"
import { definitions } from "../../../types/Generated/apiTypes"
import { humanReadableDuration } from "../../../utils/carsUtils"

import styles from "./CarsDetailsTable.module.scss"

type CarLicensePlate = definitions["CarVisit"]

interface Props {
  data?: CarLicensePlate[]
  isLoading?: boolean
}
type rowsData = {
  date?: string
  duration?: string
  entity: string
}

const CarsDetailsTable: FC<Props> = ({ data, isLoading }) => {
  function createData(date: string, duration: string, entity: string) {
    return { date, duration, entity }
  }
  const rowsArray: rowsData[] = []
  data?.map((element) => rowsArray.push(createData(element.date!, element["duration"]!, element["entity_name"])))

  return (
    <Box style={{ height: 300 }}>
      {data && data?.length < 1 && !isLoading ? (
        <GraphEmptyState />
      ) : isLoading ? (
        <Grid container direction="row" justifyContent="center" alignItems="center" className={styles.loadingContainer}>
          <Grid item>
            <CircularProgress />
          </Grid>
        </Grid>
      ) : (
        <Fragment>
          <TableContainer
            className={styles.tableContainer}
            sx={{
              maxHeight: { md: "295px", xs: "265px" },
            }}
          >
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow
                  classes={{
                    root: styles.rowRoot,
                  }}
                >
                  <TableCell
                    classes={{
                      root: styles.cellRoot,
                      head: styles.head,
                      body: styles.body,
                    }}
                  >
                    Date
                  </TableCell>
                  <TableCell
                    classes={{
                      root: styles.cellRoot,
                      head: styles.head,
                      body: styles.body,
                    }}
                    align="left"
                  >
                    Time Spent
                  </TableCell>
                  <TableCell
                    classes={{
                      root: styles.cellRoot,
                      head: styles.head,
                      body: styles.body,
                    }}
                    align="left"
                  >
                    Entrance Entity
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rowsArray.map((row, index) => (
                  <TableRow
                    classes={{
                      root: styles.rowRoot,
                    }}
                    key={index}
                  >
                    <TableCell
                      classes={{
                        root: styles.cellRoot,
                        head: styles.head,
                        body: styles.body,
                      }}
                      component="th"
                      scope="row"
                    >
                      {row.date}
                    </TableCell>

                    <TableCell
                      classes={{
                        root: styles.cellRoot,
                        head: styles.head,
                        body: styles.body,
                      }}
                      align="left"
                    >
                      {!!row.duration ? humanReadableDuration(row.duration) : "No data"}
                    </TableCell>
                    <TableCell
                      classes={{
                        root: styles.cellRoot,
                        head: styles.head,
                        body: styles.body,
                      }}
                      align="left"
                    >
                      {row.entity}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Fragment>
      )}
    </Box>
  )
}
export default CarsDetailsTable
