import { FC, useState, Fragment, useRef } from "react"
import { useMutation, useQueryClient } from "react-query"
import { useNavigate } from "react-router-dom"

import CheckIcon from "@mui/icons-material/Check"
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined"
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline"
import LinkOffIcon from "@mui/icons-material/LinkOff"
import LoyaltyIcon from "@mui/icons-material/Loyalty"
import MoreVertIcon from "@mui/icons-material/MoreVert"
import NearbyErrorIcon from "@mui/icons-material/NearbyError"
import UploadOutlinedIcon from "@mui/icons-material/UploadOutlined"
import VideocamOutlinedIcon from "@mui/icons-material/VideocamOutlined"
import WarningAmberIcon from "@mui/icons-material/WarningAmber"
import { Card, CardContent, CardMedia, CardActionArea, Grid } from "@mui/material"
import { useTheme } from "@mui/material/styles"
import useMediaQuery from "@mui/material/useMediaQuery"

import ChunkedUploady from "@rpldy/chunked-uploady"
import {
  Typography,
  Chip,
  NotificationBadge,
  Menu,
  MenuItem,
  Tooltip,
  NotificationUtils,
} from "@synapse-analytics/synapse-ui"
import { format } from "date-fns"
import moment from "moment"

import { VisionAPI } from "../../../API/VisionAPI"
import ServiceCard from "../../../components/ServiceEventTag"
import WarningDialog from "../../../components/WarningDialog"
import { routes } from "../../../routes/routes"
import { definitions } from "../../../types/Generated/apiTypes"
import UploadFootage from "../UploadFootage/components/UploadFootage"
import CameraNeedsAttention from "../assets/cameraAttention.svg?react"
import CameraDown from "../assets/cameraDown.svg?react"
import CameraPlaceholder from "../assets/cameraPlaceholder.svg?react"
import CameraUp from "../assets/cameraUp.svg?react"
import CheckMark from "../assets/checkmark.svg?react"
import EditCamera from "../components/CameraAddEdit"
import FootageContextProvider from "../footageContext/FootageContext"
import CameraStatus from "./CameraStatus"

import styles from "./CameraCard.module.scss"

const getCameraStatusIcon = (status: string) => {
  switch (status) {
    case "up":
      return <CameraUp className={styles.cameraStatusIcon} />
    case "down":
      return <CameraDown className={styles.cameraStatusIcon} />
    case "needs_attention":
      return <CameraNeedsAttention className={styles.cameraStatusIcon} />
    default:
      return null
  }
}

type PaginatedCamera = definitions["PaginatedCamerasList"]
type Alerts = definitions["CameraAlerts"]
type CameraHealthStats = definitions["CamerasHealthStatistics"]

interface Props {
  camera: PaginatedCamera
  hasMargin?: boolean
  hasSelect?: boolean
  isSelected?: boolean
  isSelectMode?: boolean
  healthStats?: CameraHealthStats
  handleSelectToggle?: (cameraId: number) => void
  isCameraDetails?: boolean
}

const CameraCard: FC<Props> = ({
  camera,
  hasMargin,
  hasSelect,
  isSelected,
  isSelectMode,
  isCameraDetails,
  healthStats,
  handleSelectToggle,
}) => {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)
  const [isFootageDialogOpen, setIsFootageDialogOpen] = useState(false)
  const [isHoveringOnCard, setIsHoveringOnCard] = useState(false)
  const [actionsMenuAnchor, setActionsMenuAnchor] = useState<null | SVGSVGElement>(null)
  const [isEditCameraOpen, setIsEditCameraOpen] = useState(false)
  const actionsIconRef = useRef<SVGSVGElement>(null)
  const isActionsMenuOpen = Boolean(actionsMenuAnchor)
  const theme = useTheme()

  const cameraStatus =
    ["up", "needs_attention", "down"].find(
      (status: "up" | "needs_attention" | "down") => healthStats && healthStats[status]?.includes(camera.id!)
    ) || "not_included"

  const xSmallScreen = useMediaQuery(theme.breakpoints.down("md"))
  const { placed, parking_region, counting_region, projection, view_change, active_with_service }: Alerts =
    camera?.alerts!

  const handleCloseActionsMenu = (): void => setActionsMenuAnchor(null)

  const handleOpenFootageUploadDialog = (): void => {
    setIsFootageDialogOpen(true)
    handleCloseActionsMenu()
  }

  const handleOpenDeleteDialog = (): void => {
    setIsDeleteDialogOpen(true)
    handleCloseActionsMenu()
  }

  const handleCloseDeleteDialog = (): void => setIsDeleteDialogOpen(false)

  const handleCloseFootageDialog = (): void => setIsFootageDialogOpen(false)

  const handleCloseEditDialog = (): void => setIsEditCameraOpen(false)

  const handleOpenEditDialog = (): void => {
    setIsEditCameraOpen(true)
    handleCloseActionsMenu()
  }

  const handleToggleSelection = (): void => {
    handleSelectToggle?.(camera?.id!)
    isActionsMenuOpen && handleCloseActionsMenu()
  }

  const handleActionsIconClick = (): void => {
    if (actionsIconRef?.current) {
      if (isActionsMenuOpen) {
        handleCloseActionsMenu()
      } else {
        setActionsMenuAnchor(actionsIconRef.current)
      }
    }
  }

  const { mutate: deleteCamera, isLoading: isLoadingCameraDelete } = useMutation(
    () => VisionAPI.deleteCamera({ id: camera?.id }),
    {
      onSuccess: () => {
        handleCloseDeleteDialog()
        NotificationUtils.toast("Camera deleted successfully", {
          snackBarVariant: "positive",
        })
        queryClient?.invalidateQueries("fetchCamerasPaginated")
      },
    }
  )

  const resetScrollAndInvalidateQueries = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
    queryClient.invalidateQueries("fetchSingleCamera")
    queryClient.invalidateQueries("fetchCameraLogs")
  }

  const handleNavigateToCameraDetails = () => {
    if (!isSelectMode) {
      navigate(`/${routes?.cameraList}/admin-camera-details/${camera?.id}`, {
        state: camera,
      })
      isCameraDetails && resetScrollAndInvalidateQueries()
    }
  }

  const conditions = [placed, parking_region, counting_region, projection, view_change, active_with_service]
  const showAlert = conditions.some((condition) => condition === false)

  return (
    <Fragment>
      <Card
        key={camera.id}
        className={styles.wrapper}
        onMouseEnter={() => setIsHoveringOnCard(true)}
        onMouseLeave={() => setIsHoveringOnCard(false)}
        sx={{
          marginRight: `${hasMargin ? "8px" : "0px"} !important`,
          marginLeft: `${hasMargin ? "5px" : "0px"} !important`,
        }}
      >
        {((isHoveringOnCard && isSelectMode) || isSelected) && (
          <div
            className={styles.backdrop}
            onClick={(event) => {
              event.stopPropagation()
              handleToggleSelection()
            }}
          >
            {isSelected && <CheckMark />}
          </div>
        )}
        {/* Camera Sample Frame */}
        <div style={{ display: "flex" }}>
          <CardActionArea onClick={handleNavigateToCameraDetails}>
            {camera?.sample_frame! === null ? (
              <div className={styles.media}>
                <CameraPlaceholder />
                <Typography className={styles.noFrame} variant="small" variantColor={2}>
                  No Frame Captured
                </Typography>
              </div>
            ) : (
              <CardMedia className={styles.media} image={camera?.sample_frame!} title={camera?.name!} />
            )}

            {cameraStatus !== "not_included" && getCameraStatusIcon(cameraStatus)}

            {/* notification */}
            {showAlert && (
              <div className={styles.notification}>
                <NotificationBadge NotificationsCount={1} variant="dot">
                  <WarningAmberIcon sx={{ fontSize: "1.6em" }} />
                </NotificationBadge>
              </div>
            )}
          </CardActionArea>
          <MoreVertIcon className={styles.openActionsMenu} onClick={handleActionsIconClick} ref={actionsIconRef} />
        </div>

        <CardContent style={{ padding: 0 }}>
          {/* Camera Name */}
          <div className={styles.cameraNameStatus}>
            <Typography
              variant="a"
              title={camera?.name}
              variantColor={2}
              color="important"
              className={styles.cameraName}
              onClick={handleNavigateToCameraDetails}
              noWrap
            >
              “{camera?.name}”
            </Typography>

            {/* camera [ON/OFF] */}
            <CameraStatus camera={camera} />
          </div>

          {/* Camera Last Updated */}
          <Tooltip title={format(new Date(camera?.updated_at!), "dd/MM/yyyy, p")} placement="right">
            <Typography variant="p" color="neutral" variantColor={2} style={{ width: "fit-content" }}>
              Updated {moment(camera?.updated_at!).fromNow()}
            </Typography>
          </Tooltip>

          {/* Camera URL */}
          <div className={styles.infoSection}>
            <Typography variant="label">Camera URL</Typography>
            {camera?.url ? (
              <Typography variant="span" variantColor={2} title={camera?.url || "url"} noWrap>
                {camera?.url}
              </Typography>
            ) : (
              <Typography variant="label" variantColor={2} className={styles.noData}>
                <LinkOffIcon className={styles.noDataIcon} />
                No URL added yet
              </Typography>
            )}
          </div>
          {/* camera Tags */}
          <div
            className={styles.infoSection}
            style={{ width: camera?.tags && camera?.tags?.length > 0 ? "max-content" : "" }}
          >
            <Typography variant="label">Camera tags</Typography>
            <div>
              {camera?.tags && camera?.tags?.length > 0 ? (
                camera?.tags?.length > 4 || xSmallScreen ? (
                  <Grid container spacing={1} alignItems="center">
                    {camera?.tags?.slice(0, xSmallScreen ? 3 : 4).map((tag, i) => (
                      <Grid item>
                        <Chip key={i} size="small" clickable={false}>
                          {tag}
                        </Chip>
                      </Grid>
                    ))}
                    {camera?.tags?.length - (xSmallScreen ? 3 : 4) > 0 && (
                      <Grid item>
                        <Typography variant="span" display="inline">
                          + {camera?.tags?.length - (xSmallScreen ? 3 : 4)}
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                ) : (
                  camera?.tags?.map((tag, i) => (
                    <Chip key={i} size="small" className={styles.tag} clickable={false}>
                      {tag}
                    </Chip>
                  ))
                )
              ) : (
                <Typography variant="label" variantColor={2} className={styles.noData}>
                  <LoyaltyIcon className={styles.noDataIcon} />
                  No tags added yet
                </Typography>
              )}
            </div>
          </div>

          {/* Camera Node */}
          <div className={styles.infoSection}>
            <Typography variant="label">Node</Typography>
            <Typography variant="span" variantColor={2}>
              {camera?.node_info?.name}
            </Typography>
          </div>

          {/* Camera Services */}
          <div
            className={styles.infoSection}
            style={{ width: camera?.services && camera?.services?.length > 0 ? "max-content" : "100%" }}
          >
            <Typography variant="label">Services</Typography>
            <div>
              {camera?.services && camera?.services?.length > 0 ? (
                camera?.services?.length > 3 || xSmallScreen ? (
                  <Grid container spacing={1} alignItems="center">
                    {camera?.services?.slice(0, xSmallScreen ? 2 : 3).map((service) => (
                      <Grid item>
                        <ServiceCard key={service} serviceEventType={service} />
                      </Grid>
                    ))}
                    {camera?.services?.length - (xSmallScreen ? 2 : 3) > 0 && (
                      <Grid item>
                        <Typography variant="span" className={styles.tagsCaption}>
                          + {camera?.services?.length - (xSmallScreen ? 2 : 3)}
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                ) : (
                  camera?.services?.map((service) => <ServiceCard key={service} serviceEventType={service} hasMargin />)
                )
              ) : (
                <Typography variant="label" variantColor={2} className={styles.noData}>
                  <NearbyErrorIcon className={styles.noDataIcon} />
                  No services selected yet
                </Typography>
              )}
            </div>
          </div>

          {/* Camera Footage */}
          <div className={styles.infoSection}>
            <Typography variant="label">Latest footage</Typography>
            {camera?.footages && camera?.footages?.length > 0 ? (
              <Typography variant="span" variantColor={2}>
                {moment(camera?.footages?.slice(-1)[0]?.start_dt!).format("L")} -
                {moment(camera?.footages?.slice(-1)[0]?.start_dt!).format("LT")}
              </Typography>
            ) : (
              <Typography variant="label" variantColor={2} className={styles.noData}>
                <VideocamOutlinedIcon className={styles.noDataIcon} />
                No footage added yet
              </Typography>
            )}
          </div>
        </CardContent>
      </Card>
      {/* Dialogs */}

      {/* Upload Footage Dialog */}
      <ChunkedUploady
        chunkSize={50000000} //50MB
        retries={5}
        parallel={5}
        accept="video/*"
      >
        <FootageContextProvider>
          <UploadFootage
            handleClose={handleCloseFootageDialog}
            open={isFootageDialogOpen}
            camera={camera as PaginatedCamera}
          />
        </FootageContextProvider>
      </ChunkedUploady>

      <WarningDialog
        isOpen={isDeleteDialogOpen}
        isLoading={isLoadingCameraDelete}
        actionTitle="Delete"
        content="Be aware by deleting this camera this action can't be undone."
        onConfirm={deleteCamera}
        onCancel={handleCloseDeleteDialog}
        dialogTitle={`Delete “${camera?.name!}” camera?`}
      />

      {/* Actions Menu */}
      <Menu
        open={isActionsMenuOpen}
        onClose={handleCloseActionsMenu}
        anchorEl={actionsMenuAnchor}
        menuMaxContent
        key="actions-menu"
        className={styles.actionsMenu}
      >
        {hasSelect && (
          <MenuItem className={styles.actionMenuItem} onClick={handleToggleSelection}>
            <CheckIcon fontSize="small" />
            Select
          </MenuItem>
        )}
        <MenuItem className={styles.actionMenuItem} onClick={handleOpenEditDialog}>
          <CreateOutlinedIcon fontSize="small" />
          Edit
        </MenuItem>
        <MenuItem className={styles.actionMenuItem} onClick={handleOpenDeleteDialog}>
          <DeleteOutlineIcon fontSize="small" />
          Delete
        </MenuItem>
        <MenuItem className={styles.actionMenuItem} onClick={handleOpenFootageUploadDialog}>
          <UploadOutlinedIcon fontSize="small" />
          Upload footage
        </MenuItem>
      </Menu>

      {/* Edit Camera */}
      {isEditCameraOpen && (
        <EditCamera isEdit handleClose={handleCloseEditDialog} isOpen camera={camera} key={camera?.id} />
      )}
    </Fragment>
  )
}

export default CameraCard
