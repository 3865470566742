import { useState, FC, ReactNode } from "react"

import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import { CircularProgress } from "@mui/material"

import { Button } from "@synapse-analytics/synapse-ui"
import { Navigation } from "swiper/modules"
import { Swiper } from "swiper/react"
import { Swiper as SwiperType } from "swiper/types"

import { SwiperBreakpoints } from "../types/Custom/Types"

import styles from "./SwiperContainer.module.scss"
import "swiper/scss"

interface Props {
  children: ReactNode
  slidesPerView: number
  spaceBetweenSlides?: number
  slidesToScroll?: number
  prefetchNext?: (nextPage: number) => void
  fetchNextPage?: () => void
  isLoading?: boolean
  count?: number
  breakpoints?: SwiperBreakpoints
}
// to use this component you need to wrap your children elements with <SwiperSlide>{children mapped}</SwiperSlide> component
const SwiperContainer: FC<Props> = ({
  children,
  slidesPerView,
  fetchNextPage,
  isLoading,
  count,
  breakpoints,
  slidesToScroll = 1,
  spaceBetweenSlides = 8,
}) => {
  const [prevEl, setPrevEl] = useState<HTMLElement | null>(null)
  const [nextEl, setNextEl] = useState<HTMLElement | null>(null)
  const [showNavigation, setShowNavigation] = useState<boolean>(false)
  const [height, setHeight] = useState<number>(0)
  const [activeIndex, setActiveIndex] = useState(0)
  const [dataLength, setDataLength] = useState(0)

  const handleInitializeNavButtons = (e: SwiperType) => {
    const slidesCount = count || e?.slides?.length
    const slideHeight = e?.height
    setHeight(slideHeight)
    setDataLength(slidesCount)
    if (slidesCount > slidesPerView) {
      setShowNavigation(true)
      setHeight(slideHeight)
    } else {
      setShowNavigation(false)
    }
  }

  return (
    <div
      style={{
        display: showNavigation ? "flex" : "block",
      }}
    >
      {showNavigation && (
        <Button
          variant="secondary"
          ref={(node) => setPrevEl(node)}
          className={styles.arrow}
          disabled={activeIndex === 0}
          style={{
            marginRight: spaceBetweenSlides,
            height: height,
          }}
        >
          <ChevronLeftIcon />
        </Button>
      )}
      <Swiper
        spaceBetween={spaceBetweenSlides}
        slidesPerView={slidesPerView}
        modules={[Navigation]}
        className={styles.swiper}
        navigation={{ prevEl, nextEl }}
        slidesPerGroup={slidesToScroll}
        onSwiper={handleInitializeNavButtons}
        breakpoints={breakpoints}
        onActiveIndexChange={(swiper) => setActiveIndex(swiper.activeIndex)}
        onReachEnd={() => fetchNextPage?.()}
      >
        {children}
      </Swiper>
      {showNavigation && (
        <Button
          ref={(node) => setNextEl(node)}
          variant="secondary"
          className={styles.arrow}
          disabled={activeIndex + slidesPerView >= dataLength}
          style={{
            marginLeft: spaceBetweenSlides,
            height: height,
          }}
        >
          {isLoading ? <CircularProgress size={24} /> : <ChevronRightIcon />}
        </Button>
      )}
    </div>
  )
}

export default SwiperContainer
