import React, { useState, Fragment } from "react"

import { DateRangePicker, Tab, Tabs, Typography } from "@synapse-analytics/synapse-ui"
import { NumberParam, useQueryParam } from "use-query-params"

import CameraSearch from "../../../components/CameraSearch"
import Placeholder from "../../../components/Placeholder"
import { useDateQuery } from "../../../hooks/useDateQuery"
import HeatmapContainer from "./HeatmapContainer"

import styles from "./CamerasHeatmap.module.scss"

const CamerasHeatmap = () => {
  const [startDate, setStartDate, endDate, setEndDate] = useDateQuery()
  const [selectedCamera, setSelectedCamera] = useQueryParam("camera", NumberParam)

  const [isLoading, setIsLoading] = useState(false)
  const [disableDateRange, setDisableDateRange] = useState(false)
  const [timeGrain, setTimeGrain] = useState<"hourly" | "daily">("hourly")

  const handleLoading = (loadingState: boolean) => {
    setIsLoading(loadingState)
  }

  const handleDisableDateRange = (disableState: boolean) => {
    setDisableDateRange(disableState)
  }

  return (
    <Fragment>
      <div className={styles.wrapper}>
        <div>
          <Typography
            variant="h2-regular"
            tooltip="Where most visitors tend to go , visualized on camera's snapshot"
            tooltipPlacement="right"
            tooltipIconSize={22}
            gutterBottom
            variantColor={2}
          >
            Camera Heatmap
          </Typography>
        </div>
        <div className={styles.header}>
          <CameraSearch
            setSelectedCamera={setSelectedCamera}
            services="heatmap"
            shouldSearchActive
            selectedCamera={selectedCamera as number}
          />

          <div className={styles.datePicker}>
            <DateRangePicker
              startDate={startDate}
              endDate={endDate}
              disabled={isLoading || disableDateRange}
              onStartDateChange={setStartDate}
              onEndDateChange={setEndDate}
              disableFuture
            />
          </div>
        </div>
        <Tabs value={timeGrain} className={styles.tabs}>
          <Tab
            label="Heatmap per hour"
            onClick={() => setTimeGrain("hourly")}
            value="hourly"
            selected={timeGrain === "hourly"}
            id="0"
          />
          <Tab
            label="Heatmap per day"
            onClick={() => setTimeGrain("daily")}
            value="daily"
            selected={timeGrain === "daily"}
            id="1"
          />
        </Tabs>
        {/* if no camera is selected yet , or no end date is selected , show placeholder */}
        <Fragment>
          {selectedCamera ? (
            <HeatmapContainer
              startDate={startDate}
              endDate={endDate}
              selectedCamera={selectedCamera}
              loadingSetter={handleLoading}
              disableSetter={handleDisableDateRange}
              timeGrain={timeGrain}
              key={`Heatmap_Of_${selectedCamera}_${timeGrain}`}
            />
          ) : (
            <Placeholder selectionType="camera" isScreenPlaceholder />
          )}
        </Fragment>
      </div>
    </Fragment>
  )
}
export default CamerasHeatmap
