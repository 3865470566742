import React, { useEffect, useState, Fragment, useMemo } from "react"
import { useQuery } from "react-query"
import { useParams } from "react-router-dom"

import { CircularProgress } from "@mui/material"

import { DateRangePicker, Typography } from "@synapse-analytics/synapse-ui"
import { Tag } from "@synapse-analytics/synapse-ui"
import { AxiosError } from "axios"
import intervalToDuration from "date-fns/intervalToDuration"

import { VisionAPI } from "../../../API/VisionAPI"
import { useDateQuery } from "../../../hooks/useDateQuery"
import { definitions } from "../../../types/Generated/apiTypes"
import { getCategoryAndSubcategoryNames } from "../../../utils/shopUtils"
import AreaDetails from "./components/AreaDetails"

import styles from "./DwellingArea.module.scss"

type Params = {
  id?: number
}

type Category = definitions["Category"]
type Area = definitions["DwellingArea"]

const DwellingArea = () => {
  const [startDate, setStartDate, endDate, setEndDate] = useDateQuery()
  const [timeGrain, setTimeGrain] = useState<"hour" | "day" | null>(null)
  const [interval, setInterval] = useState<Duration>()
  const [isLoading, setIsLoading] = useState(false)
  const params: Params = useParams() //to extract dwelling area id from pathname

  useEffect(() => {
    if (endDate !== null && startDate !== null) {
      const interval = intervalToDuration({
        start: startDate?.toDate(),
        end: endDate?.toDate(),
      })
      setInterval(interval)
      if (interval.days! > 1 || interval.months! >= 1) {
        setTimeGrain("day")
      } else {
        setTimeGrain("hour")
      }
    }
  }, [startDate, endDate, timeGrain])

  const { data: categories, isLoading: categoriesLoading } = useQuery<Category[], AxiosError>(
    "fetchCategories",
    VisionAPI.fetchCategories
  )

  const { data: area, isLoading: areaDataLoading } = useQuery<Area, AxiosError>(
    ["fetchDwellingArea", params?.id],
    ({ queryKey }) => VisionAPI.fetchDwellingArea(queryKey[1] as number),
    {
      enabled: !categoriesLoading,
    }
  )

  const areaData = useMemo(() => {
    const [categoryName, subcategoryName] = getCategoryAndSubcategoryNames(
      categories,
      area?.category,
      area?.subcategory
    )
    return { ...area, category: categoryName, subcategory: subcategoryName }
  }, [area, categories])

  return (
    <div>
      {areaDataLoading ? (
        <CircularProgress size={50} className={styles.loading} />
      ) : (
        <Fragment>
          <Typography
            variant="h2-regular"
            gutterBottom
            variantColor={2}
            tooltip={`${areaData?.name} analytics over selected date range`}
            tooltipPlacement="right"
            tooltipIconSize={22}
          >
            {areaData?.name}
          </Typography>
          <DateRangePicker
            startDate={startDate}
            endDate={endDate}
            disabled={isLoading}
            onStartDateChange={setStartDate}
            onEndDateChange={setEndDate}
            disableFuture
          />
          <div className={styles.infoTags}>
            {areaData?.category && (
              <div className={styles.tagWrapper}>
                <Typography variant="label" variantColor={2} gutterBottom>
                  Category
                </Typography>
                <Tag key={`${areaData?.category}`} variant="orange" size="regular">
                  {areaData?.category}
                </Tag>
              </div>
            )}
            {areaData?.subcategory && (
              <div className={styles.tagWrapper}>
                <Typography variant="label" variantColor={2} gutterBottom>
                  Sub-category
                </Typography>
                <Tag key={`${areaData?.subcategory}`} variant="pink" size="regular">
                  {areaData?.subcategory}
                </Tag>
              </div>
            )}
          </div>
          {/* start and end date and area id should be sent for fetching in area details */}
          <AreaDetails
            startDate={startDate}
            endDate={endDate}
            timeGrain={timeGrain!}
            interval={interval!}
            setLoadingState={setIsLoading}
          />
        </Fragment>
      )}
    </div>
  )
}
export default DwellingArea
