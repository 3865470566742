import { useMutation, useQuery } from "react-query"

import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline"
import { Box, CircularProgress, Grid } from "@mui/material"

import { Button, Snackbar, Typography } from "@synapse-analytics/synapse-ui"
import { AxiosError, AxiosResponse } from "axios"
import { OpenAPIV2 } from "openapi-types"
import SwaggerUI from "swagger-ui-react"
import "swagger-ui-react/swagger-ui.css"

import { VisionAPI } from "../../../API/VisionAPI"
import { storeApi } from "../../../store"
import { CustomTokenRefresh } from "../../../types/Generated/authentication/CustomTokenRefresh"
import auth from "../../../utils/auth"
import { swaggerTemplate } from "./swaggerTemplate"

import styles from "../APIIntegration.module.scss"

interface Request {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [k: string]: any
}
const SwaggerDocs = () => {
  const [apiProtocol, apiHostname, apiPort] = [
    storeApi.getState().apiProtocol,
    storeApi.getState().apiHostname,
    storeApi.getState().apiPort,
  ]

  const { data: swaggerDocs, isLoading } = useQuery<OpenAPIV2.Document, AxiosError>(
    "fetchSwaggerDocs",
    VisionAPI.fetchSwaggerDocs
  )

  const refreshTokenMutation = useMutation<AxiosResponse<CustomTokenRefresh>, AxiosError, string>(
    VisionAPI.refreshToken
  )

  // injecting request headers and to pass it later to swagger UI
  const injectRequestHeaders = async (req: Request): Promise<Request> => {
    if (!auth.isAuthenticated()) {
      const refreshResponse = await refreshTokenMutation.mutateAsync(auth.getRefreshToken() as string)
      await auth.login(refreshResponse.data.access)
    }
    req.headers.Authorization = auth.getAuthHeaderValue()
    return req
  }

  const handleDownloadDocs = (): void => {
    if (swaggerDocs) {
      // Extract project title from response
      const title = `Azka Vision - REST API Documentation`

      // Turn API spec into string
      const stringifiedResponse = JSON.stringify(swaggerDocs)

      // Pass stringified API Spec to the swagger template
      const html = swaggerTemplate(title, stringifiedResponse)

      // Creating Anchor element to be downloaded
      const element = document.createElement("a")
      element.setAttribute("href", "data:html/text;charset=utf-8, " + encodeURIComponent(html))
      element.setAttribute("download", title + ".html")

      // Download the element / api doc
      document.body.appendChild(element)
      element.click()

      // removing element after execution from the dom
      document.body.removeChild(element)
    }
  }

  // Modify the swaggerDocs to use the correct host
  if (swaggerDocs) {
    swaggerDocs.host = `${apiHostname}:${apiPort}`
    swaggerDocs.schemes = [`${apiProtocol}`]
  }

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Typography variant="h3-bold" variantColor={2}>
          API Documentation
        </Typography>
        <Button
          variant="primary"
          size="regular"
          startIcon={<DownloadForOfflineIcon fontSize="small" />}
          onClick={handleDownloadDocs}
          disabled={!swaggerDocs}
        >
          Download API Docs
        </Button>
      </div>
      {isLoading ? (
        <Grid container direction="row" justifyContent="center" alignItems="center" style={{ minHeight: "100%" }}>
          <CircularProgress />
        </Grid>
      ) : (
        <Grid container direction="row" justifyContent="center" alignItems="center" style={{ minHeight: "100%" }}>
          <Grid item xs={12}>
            <Snackbar variant="important" fullWidth id="snackbar-auth-restApi">
              When trying out the Swagger document through <strong>Azka Vision's portal</strong>, authentication is
              <strong> not required</strong>.
              <br />
              However, if you attempt to try it out on your machine, you'll
              <strong> have to</strong> authenticate yourself (by using a generated access token) before sending any
              requests.
              <br />
              Note: to authenticate using generated access token you should use keyword <strong>Token </strong>followed
              by the access token
            </Snackbar>
          </Grid>
          <Box mt={2} />
          <Grid container className={styles.swaggerContainer}>
            <Grid item xs={12}>
              <SwaggerUI spec={swaggerDocs} requestInterceptor={injectRequestHeaders} />
            </Grid>
          </Grid>
        </Grid>
      )}
    </div>
  )
}
export default SwaggerDocs
