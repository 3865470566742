import React, { FC, useState, useContext, ChangeEvent, useEffect } from "react"

import { Paper } from "@mui/material"

import { Typography, Tabs, Tab } from "@synapse-analytics/synapse-ui"

import Placeholder from "../../../assets/placeholder.svg"
import Search from "../../../components/Search"
import { CampaignContext } from "../CampaignContext/CampaignContext"
import CampaignEntity from "./CampaignEntity"

import styles from "./CampaignEntities.module.scss"

type entity = {
  name: string
  id: number
}

interface Props {
  gatesEntities: any[]
  carsEntities: any[]
  tenantsEntities: any[]
  combinedEntities: any[]
}

const CampaignEntities: FC<Props> = ({ gatesEntities, carsEntities, tenantsEntities, combinedEntities }) => {
  const { formik } = useContext(CampaignContext)
  const [searchGatesEntities, setSearchGatesEntities] = useState("")
  const [searchCarsEntities, setSearchCarsEntities] = useState("")
  const [searchTenantsEntities, setSearchTenantsEntities] = useState("")
  const [tabValue, setTabValue] = useState(0)
  const [selectedEntities, setSelectedEntities] = useState<entity[]>(formik?.values?.selected_entities!)
  const [allEntities, setAllEntities] = useState({})

  // get checked entities when [edit]
  const matchEntities = (entityName: string) => {
    const filteredEntities = selectedEntities?.some((ent) => ent?.name === entityName)
    return filteredEntities
  }

  // convert all entities to one big object for [toggle checked]
  useEffect(() => {
    if (combinedEntities && combinedEntities?.length > 0) {
      if (formik?.values?.edit) {
        let filteredEntities = Object.assign({}, ...combinedEntities.map((x) => ({ [x.name]: matchEntities(x?.name) })))
        setAllEntities(filteredEntities)
      } else {
        let filteredEntities = Object.assign({}, ...combinedEntities.map((x) => ({ [x.name]: false })))
        setAllEntities(filteredEntities)
      }
    }
    // eslint-disable-next-line
  }, [gatesEntities, carsEntities, tenantsEntities])

  const checkSelectedEntities = formik?.values?.edit ? formik?.values?.selected_entities : selectedEntities

  // handle Search entities
  const handleSearchEntities = (value: string) => {
    if (tabValue === 0) {
      setSearchGatesEntities(value)
    } else if (tabValue === 1) {
      setSearchCarsEntities(value)
    } else {
      setSearchTenantsEntities(value)
    }
  }

  // filter gates entities
  let filteredGates = gatesEntities!?.filter((entity: entity) => {
    return entity?.name?.toLowerCase().includes(searchGatesEntities.toLowerCase())
  })

  // filter gates entities
  let filteredCars = carsEntities!?.filter((entity: entity) => {
    return entity?.name?.toLowerCase().includes(searchCarsEntities.toLowerCase())
  })

  // filter gates entities
  let filteredTenants = tenantsEntities!?.filter((entity: entity) => {
    return entity?.name?.toLowerCase().includes(searchTenantsEntities.toLowerCase())
  })

  const handleChangeTabs = (event: React.MouseEvent<HTMLDivElement>) => {
    const value = +(event.target as HTMLButtonElement).value
    setTabValue(value)
  }

  const handleEntities = (event: ChangeEvent<HTMLInputElement>, id: number, name: string) => {
    setAllEntities({ ...allEntities, [event.target.id]: event.target.checked })
    if (selectedEntities?.length > 0) {
      const filterEntities = selectedEntities?.filter((ent) => ent?.id === id)
      if (filterEntities?.length > 0) {
        setSelectedEntities(selectedEntities?.filter((ent) => ent?.id !== id))
      } else {
        setSelectedEntities([...selectedEntities, { id, name }])
      }
    } else {
      setSelectedEntities([...selectedEntities, { id, name }])
    }
  }

  // add entities [IDS] to formik
  useEffect(() => {
    if (selectedEntities && selectedEntities?.length > 0) {
      formik?.setFieldValue(
        "target_entities",
        selectedEntities?.map((ent) => ent?.id)
      )
      formik?.setFieldValue("selected_entities", selectedEntities)
    }
    //eslint-disable-next-line
  }, [selectedEntities])

  return (
    <Paper elevation={0} className={styles.wrapper}>
      <Typography variant="a" variantColor={2} className={styles.inputTitle}>
        <span className={styles.step}>6</span>
        Entities Observed
      </Typography>

      {/* Search Entities */}
      <Search
        placeholder="E.g. FRONT-MALL"
        handleSearch={handleSearchEntities}
        searchValue={tabValue === 0 ? searchGatesEntities : tabValue === 1 ? searchCarsEntities : searchTenantsEntities}
        topHeader={false}
      />

      {/* Entities Tabs */}
      <Tabs value={`${tabValue}`} onClick={handleChangeTabs} style={{ marginBottom: 20 }}>
        <Tab label="Gates" value="0" selected={tabValue === 0 ? true : false} />
        <Tab label="Cars" value="1" selected={tabValue === 1 ? true : false} />
        <Tab label="Tenants" value="2" selected={tabValue === 2 ? true : false} />
      </Tabs>

      {/* Entities */}
      {tabValue === 0 ? (
        filteredGates && filteredGates?.length > 0 ? (
          <CampaignEntity
            entities={gatesEntities}
            filterEntities={filteredGates}
            searchEntities={searchGatesEntities}
            entityName="gates"
            handleEntities={handleEntities}
            allEntities={allEntities}
          />
        ) : (
          <div className={styles.noEntitiesWrapper}>
            <img src={Placeholder} alt="No campaigns" className={styles.noEntitiesImage} />
            <Typography variant="span" align="center">
              Nothing to show here
            </Typography>
          </div>
        )
      ) : tabValue === 1 ? (
        filteredCars && filteredCars?.length > 0 ? (
          <CampaignEntity
            entities={carsEntities}
            filterEntities={filteredCars}
            searchEntities={searchGatesEntities}
            entityName="cars"
            handleEntities={handleEntities}
            allEntities={allEntities}
          />
        ) : (
          <div className={styles.noEntitiesWrapper}>
            <img src={Placeholder} alt="No campaigns" className={styles.noEntitiesImage} />
            <Typography variant="span" align="center">
              Nothing to show here
            </Typography>
          </div>
        )
      ) : filteredTenants && filteredTenants?.length > 0 ? (
        <CampaignEntity
          entities={tenantsEntities}
          filterEntities={filteredTenants}
          searchEntities={searchGatesEntities}
          entityName="tenants"
          handleEntities={handleEntities}
          allEntities={allEntities}
        />
      ) : (
        <div className={styles.noEntitiesWrapper}>
          <img src={Placeholder} alt="No campaigns" className={styles.noEntitiesImage} />
          <Typography variant="span" align="center">
            Nothing to show here
          </Typography>
        </div>
      )}

      {checkSelectedEntities && checkSelectedEntities?.length > 0 && (
        <div className={styles.selectedEntities}>
          <Typography variant="span" variantColor={2} className={styles.selectedEntitiesTitle}>
            Selected Entities
          </Typography>
          <CampaignEntity entities={checkSelectedEntities} />
        </div>
      )}
    </Paper>
  )
}

export default CampaignEntities
