import React, { FC, useEffect, useState } from "react"
import { UseMutateFunction } from "react-query"

import DeleteIcon from "@mui/icons-material/DeleteOutline"
import { IconButton, Paper, CircularProgress } from "@mui/material"
import Pagination from "@mui/material/Pagination"

import { Typography } from "@synapse-analytics/synapse-ui"
import { AxiosResponse } from "axios"

import Placeholder from "../../../assets/placeholder.svg"
import { definitions } from "../../../types/Generated/apiTypes"
import CarsDeletePopup from "./CarsDeletePopup"

import styles from "./CarsLicenseTable.module.scss"

type ListsOfLicensePlates = definitions["CarListMembershipLicensePlate"]

let pageSize = 9

interface Props {
  data: ListsOfLicensePlates[]
  loading: boolean
  searchValue: string
  deleteLicense: UseMutateFunction<AxiosResponse<any> | undefined, unknown, any, unknown>
}

const CarsLicenseTable: FC<Props> = ({ data, loading, deleteLicense, searchValue }) => {
  const [open, setOpen] = useState(false)
  const [licensePlate, setLicensePlate] = useState("")
  const [pageOffset, setPageOffset] = useState(0)

  useEffect(() => {
    setPageOffset(0)
  }, [data])
  const handleClose = () => {
    setOpen(false)
    setLicensePlate("")
  }

  const handleOpen = (licensePlate: string) => {
    setOpen(true)
    setLicensePlate(licensePlate)
  }

  // filter license plates
  let filterLicense =
    data &&
    data!?.filter((license) => {
      return license?.license_plate?.toLowerCase().includes(searchValue.toLowerCase())
    })

  return (
    <div className={styles.licenseList}>
      {loading ? (
        <div className={styles.loading}>
          <CircularProgress size={40} />
        </div>
      ) : filterLicense && filterLicense.length > 0 ? (
        filterLicense?.slice(pageOffset * pageSize, (pageOffset + 1) * pageSize).map((plate, i) => (
          <Paper elevation={0} key={i} className={styles.license}>
            <Typography variant="p">{plate?.license_plate!}</Typography>
            <IconButton
              aria-label="add"
              color="primary"
              className={styles.button}
              onClick={() => handleOpen(plate?.license_plate)}
            >
              <DeleteIcon className={styles.icon} />
            </IconButton>
          </Paper>
        ))
      ) : (
        <div className={styles.noLicenseWrapper}>
          <img src={Placeholder} alt="No Licenses" />
          <Typography variant="p" align="center" className={styles.notLicenseTitle}>
            Nothing to show here
          </Typography>
        </div>
      )}

      {!loading && filterLicense && filterLicense?.length > 0 && (
        <Pagination
          count={Math.ceil(filterLicense?.length! / pageSize)}
          classes={{ root: styles.rootPagination }}
          page={pageOffset + 1}
          siblingCount={2}
          color="standard"
          shape="rounded"
          onChange={(e, offset) => {
            setPageOffset(offset - 1)
          }}
        />
      )}

      <CarsDeletePopup
        open={open}
        handleClose={handleClose}
        handleDelete={() => {
          deleteLicense(licensePlate)
          setOpen(false)
        }}
        name={licensePlate}
      />
    </div>
  )
}

export default CarsLicenseTable
