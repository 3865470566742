import React, { FC } from "react"
import { UseMutateFunction } from "react-query"

import { Dialog, DialogActions, DialogTitle, DialogContent, DialogContentText } from "@mui/material"

import { Typography, Button } from "@synapse-analytics/synapse-ui"
import { AxiosResponse } from "axios"

interface Props {
  open: boolean
  handleClose: () => void
  name?: string
  handleDelete: UseMutateFunction<AxiosResponse<any> | undefined, unknown, void, unknown>
}

const CarsDeletePopup: FC<Props> = ({ open, handleClose, handleDelete, name }) => {
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle
        sx={{
          paddingBottom: "5.6px",
        }}
        id="alert-dialog-title"
      >
        <Typography variant="h3-regular" variantColor={2}>
          Are you sure to delete {name}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Be aware when deleting this action can't be undone
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="secondary">
          Cancel
        </Button>
        <Button onClick={() => handleDelete()} variant="dangerous">
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default CarsDeletePopup
