import React, { FC, useContext, useState, useEffect, Fragment } from "react"
import { useQueryClient } from "react-query"

import HighlightOffIcon from "@mui/icons-material/Close"
import { Dialog, CircularProgress, MobileStepper, DialogContent, SlideProps, Slide, useMediaQuery } from "@mui/material"

import { Typography, Button } from "@synapse-analytics/synapse-ui"

import WarningDialog from "../../../../components/WarningDialog"
import { definitions } from "../../../../types/Generated/apiTypes"
import { FloorsContext } from "../../floorsContext/FloorsContext"
import CamerasSelection from "../floorSetup/CamerasSelection"
import FloorCreator from "../floorSetup/FloorCreator"
import FloorDetails from "./FloorDetails"

import styles from "./FloorAddEdit.module.scss"

type FloorList = definitions["FloorPlanList"]

const Transition = React.forwardRef(function Transition(props: SlideProps, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

interface Props {
  handleClose: () => void
  open: boolean
  edit?: boolean
  floor?: FloorList
}

const FloorAddEdit: FC<Props> = ({ handleClose, open, edit, floor }) => {
  const {
    formik,
    floorEditSuccuss,
    activeStep,
    setActiveStep,
    resetAllData,
    cameraProperties,
    addUpdateCamerasLocating,
    setFloorEditSuccuss,
    floorVersionId,
  } = useContext(FloorsContext)

  const [isCancelMessageOpen, setIsCancelMessageOpen] = useState(false)
  const queryClient = useQueryClient()
  const smallScreens = useMediaQuery("(max-width:1610px)")

  useEffect(() => {
    if (edit && floorEditSuccuss) handleClose()
    // eslint-disable-next-line
  }, [edit, floorEditSuccuss])

  // closing confirmation message pop up
  const handleTriggerCloseConfirmation = () => {
    setIsCancelMessageOpen(true)
  }

  const handleCancelCloseConfirmation = () => {
    setIsCancelMessageOpen(false)
  }

  const handleCloseDialog = () => {
    // Empty All States of all the chosen inputs
    handleClose()
    resetAllData()
    handleCancelCloseConfirmation()
    queryClient.invalidateQueries("fetchFloors")
  }

  const handleSubmitEdit = () => {
    formik.submitForm()
    if (formik.values?.floorPreview) {
      queryClient.invalidateQueries("fetchFloorVersions")
    } else {
    }
    setFloorEditSuccuss(true)
    setTimeout(() => {
      handleCloseDialog()
      setFloorEditSuccuss(false)
      resetAllData()
    }, 1000)
  }

  const handleNextStep = () => {
    if (activeStep === 0) {
      formik.submitForm()
      return
    } else if (activeStep === 1) {
      // going from camera selection to camera locating
      // fetch camera locations first if in edit mode
      formik?.values?.floorEdit && queryClient.invalidateQueries("fetchCameraLocatingCoors")
    } else if (activeStep === 2) {
      // going from camera locating to camera mapping
      // fetch mapped cameras first if in edit mode
      formik?.values?.floorEdit && queryClient.invalidateQueries("fetchCameraMappingCoors")
      addUpdateCamerasLocating()
    } else {
      setTimeout(() => {
        // fetch floors before closing the dialog
        queryClient.invalidateQueries("fetchFloors")
        handleCloseDialog()
        resetAllData()
      }, 1000)
    }
    setActiveStep(activeStep + 1)
  }
  const handleBack = () => {
    setActiveStep(activeStep - 1)
  }

  return (
    <Fragment>
      <WarningDialog
        confirmationText={`cancel floor ${edit ? "editing" : "setup"} process?`}
        onCancel={handleCancelCloseConfirmation}
        onConfirm={handleCloseDialog}
        isOpen={isCancelMessageOpen}
      />

      <div style={{ overflow: "clip" }}>
        <Dialog
          open={open}
          maxWidth={activeStep > 2 ? "xl" : "md"}
          className={styles.dialog}
          fullWidth
          TransitionComponent={Transition}
          PaperProps={{
            sx: {
              minWidth: activeStep < 2 ? 950 : activeStep === 2 ? 1250 : !smallScreens ? 1870 : "",
              minHeight: { xl: activeStep > 1 ? "927px" : "", xs: "0px !important" },
            },
          }}
          onClose={handleClose}
          disableEscapeKeyDown
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent className={styles.wrapper} style={{ paddingTop: 32, overflow: "visible" }}>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <Typography variant="h2-bold" className={styles.title}>
                {activeStep === 0
                  ? edit
                    ? "Edit Floor"
                    : "Create Floor"
                  : activeStep === 1
                  ? "Select Cameras"
                  : `Floor Setup : ${activeStep === 2 ? "Locating Cameras" : "Mapping Cameras"}`}
              </Typography>
              <HighlightOffIcon onClick={handleTriggerCloseConfirmation} className={styles.iconContainer} />
            </div>
            {activeStep === 0 ? (
              <FloorDetails handleClose={handleClose} open={open} edit={edit} floor={floor} />
            ) : activeStep === 1 ? (
              <CamerasSelection />
            ) : (
              <FloorCreator />
            )}
            {/* Mobile Stepper pagination*/}
            <MobileStepper
              variant="dots"
              steps={4}
              position="static"
              activeStep={activeStep}
              className={styles.stepper}
              nextButton={
                <Button
                  size="regular"
                  variant="primary"
                  onClick={activeStep === 0 && edit ? handleSubmitEdit : handleNextStep}
                  disabled={
                    activeStep === 0 &&
                    (formik?.isSubmitting ||
                      (!edit && !formik?.values.floorName) ||
                      (!edit && !formik?.values.floorPreview))
                      ? true
                      : activeStep === 1 && formik.values.ValidCameras.length < 1
                      ? true
                      : activeStep === 2 && cameraProperties?.length === 0
                      ? true
                      : false
                  }
                >
                  {
                    <Fragment>
                      <span style={{ marginRight: formik?.isSubmitting ? 10 : 0 }}>
                        {activeStep === 0 && edit ? "Save Changes" : activeStep === 3 ? "Finish" : "Next"}
                      </span>
                      {formik?.isSubmitting && <CircularProgress size={20} />}
                    </Fragment>
                  }
                </Button>
              }
              backButton={
                <Button
                  onClick={edit && activeStep === 0 ? handleNextStep : handleBack}
                  size="regular"
                  variant={activeStep === 0 && edit ? "secondary" : "primary"}
                  style={{ visibility: (edit && activeStep === 0) || activeStep >= 2 ? "visible" : "hidden" }}
                  disabled={edit && activeStep === 0 && floorVersionId === 0 && !formik.values.floorPreview}
                >
                  {
                    <Fragment>
                      <span style={{ marginRight: formik?.isSubmitting ? 10 : 0 }}>
                        {edit && activeStep === 0
                          ? formik.values.floorPreview && floorVersionId === 0
                            ? "Create New Version"
                            : "Edit Mapping"
                          : "Back"}
                      </span>
                      {formik?.isSubmitting && <CircularProgress size={20} />}
                    </Fragment>
                  }
                </Button>
              }
            />
          </DialogContent>
        </Dialog>
      </div>
    </Fragment>
  )
}

export default FloorAddEdit
