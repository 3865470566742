import { useQuery } from "react-query"
import { useParams } from "react-router-dom"

import BlockIcon from "@mui/icons-material/Block"
import HeatmapIcon from "@mui/icons-material/BlurLinear"
import CarIcon from "@mui/icons-material/CommuteOutlined"
import CounterIcon from "@mui/icons-material/Group"
import CameraIcon from "@mui/icons-material/VideocamOutlined"
import { Grid } from "@mui/material"

import { Typography } from "@synapse-analytics/synapse-ui"
import { AxiosError } from "axios"

import { VisionAPI } from "../../../API/VisionAPI"
import { cameraAlert } from "../../../types/Custom/Types"
import { definitions } from "../../../types/Generated/apiTypes"
import CameraAlerts from "./CameraAlerts"
import CameraDownTime from "./CameraDownTime"
import CameraFootages from "./CameraFootages"
import CameraInfo from "./CameraInfo"
import CameraLogs from "./CameraLogs"
import LiveStream from "./LiveStream"
import OtherCameras from "./OtherCameras"

import styles from "./CameraDetails.module.scss"

type Alerts = definitions["CameraAlerts"]
type Camera = definitions["CameraRetrieveUpdate"]
type LogsHistory = definitions["LogsHistory"]
type Params = {
  id?: number
}
const CameraDetails = () => {
  const params: Params = useParams() //to extract camera id from pathname

  const { data: camera, isLoading } = useQuery<Camera, AxiosError>(["fetchSingleCamera", params?.id], ({ queryKey }) =>
    VisionAPI.fetchSingleCamera(queryKey[1] as number)
  )

  const { data: CameraLogsData } = useQuery<LogsHistory[], AxiosError>(
    ["fetchCameraLogs", params?.id],
    ({ queryKey }) => VisionAPI.fetchCameraLogs(queryKey[1] as number),
    {
      enabled: !!params?.id,
    }
  )

  const getAlerts = (): cameraAlert[] => {
    const alerts: cameraAlert[] = []
    const { placed, parking_region, counting_region, projection, view_change, active_with_service }: Alerts =
      camera?.alerts || {}

    if (placed === false) {
      alerts.push({
        icon: <HeatmapIcon fontSize="small" />,
        service: "heatmap",
        issue: "not placed on a floor.",
      })
    }

    if (parking_region === false) {
      alerts.push({
        icon: <CarIcon fontSize="small" />,
        service: "car",
        issue: "no registered regions.",
      })
    }

    if (counting_region === false) {
      alerts.push({
        icon: <CounterIcon fontSize="small" />,
        service: "counter",
        issue: "no registered regions.",
      })
    }

    if (projection === false) {
      alerts.push({
        icon: <HeatmapIcon fontSize="small" />,
        service: "heatmap",
        issue: "no floor projections are created.",
      })
    }

    if (view_change === false) {
      alerts.push({
        icon: <CameraIcon fontSize="small" />,
        issue: "Camera position has changed.",
      })
    }

    if (active_with_service === false) {
      alerts.push({
        icon: <BlockIcon fontSize="small" />,
        issue: "The camera is active with no services.",
      })
    }

    return alerts
  }

  return (
    <div className={styles.wrapper}>
      <Typography
        variant="h2-regular"
        tooltip="Manage camera information and edit camera details"
        tooltipPlacement="right"
        tooltipIconSize={22}
        gutterBottom
        style={{ marginBottom: 24 }}
      >
        {camera?.name || "Camera Details"}
      </Typography>

      <Grid container spacing={2}>
        <Grid container item xs={12} spacing={2}>
          <Grid container spacing={2} item xs={12} md={9}>
            <Grid item xs={12} sm={12} md={6}>
              <CameraInfo camera={camera!} isLoading={isLoading} />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <LiveStream camera={camera} isLoading={isLoading} key={camera?.id} />
            </Grid>
            {/* Alerts */}
            <Grid item xs={12}>
              <CameraAlerts alerts={getAlerts()} isLoading={isLoading} />
            </Grid>
          </Grid>
          {/* Activity logs */}
          <Grid item xs={12} md={3}>
            <CameraLogs logs={CameraLogsData!} isLoading={isLoading} />
          </Grid>
        </Grid>
        {/* Down time */}
        <Grid item xs={12} md={12}>
          <CameraDownTime cameraId={params?.id} />
        </Grid>
        {/* Camera footages list */}
        <Grid item xs={12} md={12}>
          <CameraFootages camera={camera} />
        </Grid>
        {/* Other cameras section */}
        <Grid item xs={12} md={12}>
          <OtherCameras />
        </Grid>
      </Grid>
    </div>
  )
}

export default CameraDetails
