import React, { useContext, Fragment, useMemo } from "react"

import { Typography } from "@mui/material"

import { ICameraProperties } from "../../../../types/Custom/Interfaces"
import CameraIcon from "../../assets/camera.svg?react"
import { FloorsContext } from "../../floorsContext/FloorsContext"

import styles from "./SingleCameraIcon.module.scss"

// step is 45 degrees
const rotationAngles = [0, 45, 90, 135, 180, 225, 270, 315, 360]

const SingleCameraIcon = ({ cameraProps }: { cameraProps: ICameraProperties }) => {
  const { cameraProperties, setCameraProperties, selectedCamera } = useContext(FloorsContext)
  const offset = useMemo(() => rotationAngles.indexOf(cameraProps?.rotation_angle || 0), [cameraProps?.rotation_angle])

  // Function to handle camera click and update rotation angle
  const handleClickCamera = () => {
    const selectedCameraProps = cameraProperties?.find((cameraProp) => cameraProp?.camera === cameraProps?.camera)

    if (selectedCameraProps) {
      // Calculate the new rotation angle based on the current angle
      const rotationAngle = cameraProps.rotation_angle === 360 ? 45 : cameraProps.rotation_angle! + 45

      // Update camera properties with the new rotation angle
      setCameraProperties((prevState) =>
        prevState.map((cameraProperties: ICameraProperties) =>
          cameraProperties.camera === cameraProps?.camera
            ? { ...cameraProperties, rotation_angle: rotationAngle }
            : cameraProperties
        )
      )
    }
  }

  // Calculate the top position of the camera shadow based on the rotation angle
  const calculateTopPosition = () => {
    if (offset !== -1) {
      const topOffsets = [17, -44, -91, -96, -57, 4, 51, 57, 17]
      return cameraProps?.y_cam_resized! + topOffsets[offset]
    }

    return cameraProps?.y_cam_resized!
  }

  // Calculate the left position of the camera shadow based on the rotation angle
  const calculateLeftPosition = () => {
    if (offset !== -1) {
      const leftOffsets = [-95, -100, -61, 0, 48, 52, 13, -47, -92]
      return cameraProps?.x_cam_resized! + (cameraProps?.rotation_angle === 360 ? leftOffsets[0] : leftOffsets[offset])
    }

    return cameraProps?.x_cam_resized!
  }

  return (
    <Fragment>
      {/* Camera Shadow */}
      <div
        className={styles.cameraShadow}
        style={{
          background: `linear-gradient(to bottom, rgba(${cameraProps?.colorCode},0.6) , var(--neutral-background-default))`,
          top: calculateTopPosition(),
          left: calculateLeftPosition(),
          transform: `rotate(${cameraProps?.rotation_angle! + 106}deg)`,
        }}
      >
        {/* Camera Name */}
        <Typography
          className={styles.cameraName}
          sx={{
            color: selectedCamera.cameraId === cameraProps.camera ? "var(--red-background-1)" : "",
          }}
        >
          {cameraProps?.name}
        </Typography>
      </div>
      {/* Camera Icon */}
      <CameraIcon
        className={`${styles.cameraIcon} ${
          selectedCamera.cameraId === cameraProps.camera ? styles.filledCameraIcon : ""
        }`}
        style={{
          top: cameraProps?.y_cam_resized!,
          left: cameraProps?.x_cam_resized!,
          transform: `rotate(${cameraProps.rotation_angle}deg)`,
        }}
        onClick={handleClickCamera}
      />
    </Fragment>
  )
}

export default SingleCameraIcon
