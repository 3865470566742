import React, { FC, useState, useEffect, Fragment } from "react"

import StatsCard from "../../../components/StatsCard"
import { definitions } from "../../../types/Generated/apiTypes"
import { calculateCountTotals } from "../../../utils/counterUtils"

import styles from "./NumericStats.module.scss"

type CounterLogs = definitions["CounterLogs"]
type EntityGroupsCounts = definitions["EntityGroupsCounts"]
interface Props {
  isLive?: boolean
  logsData?: CounterLogs[]
  loading: boolean
  lastWeekData?: CounterLogs[]
  entityGroupsCounts?: EntityGroupsCounts
  visitorsRef: any
  occRef: any
}
type NumericStatsObj = {
  count_in_sum: number
  count_out_sum: number
  male_count_in: number
  female_count_in: number
  male_count_out: number
  female_count_out: number
  inside: number
  inside_male: number
  inside_female: number
  adult_count: number
  child_count: number
  dwell_left: number
  dwell_entered: number
  stood_left: number
  stood_entered: number
  unknown_age_count_sum?: number
  unknown_gender_count_sum?: number
}

const NumericStats: FC<Props> = ({
  isLive,
  logsData,
  loading,
  lastWeekData,
  visitorsRef,
  occRef,
  entityGroupsCounts,
}) => {
  const [numericStat, setNumericStat] = useState<NumericStatsObj>({
    count_in_sum: 0,
    count_out_sum: 0,
    male_count_in: 0,
    female_count_in: 0,
    male_count_out: 0,
    female_count_out: 0,
    inside: 0,
    inside_male: 0,
    inside_female: 0,
    adult_count: 0,
    child_count: 0,
    dwell_left: 0,
    dwell_entered: 0,
    stood_left: 0,
    stood_entered: 0,
    unknown_age_count_sum: 0,
    unknown_gender_count_sum: 0,
  })
  const [comparisonStat, setComparisonStat] = useState<NumericStatsObj>({
    count_in_sum: 0,
    count_out_sum: 0,
    male_count_in: 0,
    female_count_in: 0,
    male_count_out: 0,
    female_count_out: 0,
    inside: 0,
    inside_male: 0,
    inside_female: 0,
    adult_count: 0,
    child_count: 0,
    dwell_left: 0,
    dwell_entered: 0,
    stood_left: 0,
    stood_entered: 0,
  })
  // formatting and calculating cumulative count
  useEffect(() => {
    if (loading === false && logsData) {
      setNumericStat(calculateCountTotals(logsData))
    }
  }, [logsData, loading])
  // formatting and calculating cumulative count for data from a week
  //  (used for comparison and calculating increase/decrease percentages)
  useEffect(() => {
    if (loading === false && lastWeekData && isLive) {
      setComparisonStat(calculateCountTotals(lastWeekData))
    }
  }, [lastWeekData, loading, isLive])

  return (
    <Fragment>
      <div className={styles.statsCardsWrapper}>
        {isLive ? (
          <Fragment>
            {/* Visitors In Card */}
            <StatsCard
              title="Visitors In Today"
              numericStat={numericStat?.count_in_sum!}
              historicalStat={comparisonStat?.count_in_sum!}
              femaleStat={numericStat?.female_count_in!}
              childCount={numericStat?.child_count}
              adultCount={numericStat?.adult_count}
              maleStat={numericStat?.male_count_in!}
              unknownAgeStat={numericStat?.unknown_age_count_sum}
              unknownGenderStat={numericStat?.unknown_gender_count_sum}
              entityGroupsCounts={entityGroupsCounts}
              marginBottom={true}
              isLoading={loading}
              reference={visitorsRef}
            />
            {/* Occupancy In Card */}
            <StatsCard
              title="Occupancy In Today"
              numericStat={numericStat?.inside!}
              historicalStat={comparisonStat?.inside!}
              femaleStat={numericStat?.inside_female!}
              maleStat={numericStat?.inside_male!}
              isLoading={loading}
              reference={occRef}
              isSecondCard
            />
          </Fragment>
        ) : (
          <Fragment>
            {/* Visitors In Overall Card */}
            <StatsCard
              title="Visitors In Overall"
              numericStat={numericStat?.count_in_sum!}
              femaleStat={numericStat?.female_count_in!}
              maleStat={numericStat?.male_count_in!}
              childCount={numericStat?.child_count}
              adultCount={numericStat?.adult_count}
              dwellAndEnter={numericStat?.dwell_entered}
              dwellAndLeft={numericStat?.dwell_left}
              stoodAndEnter={numericStat?.stood_entered}
              stoodAndLeft={numericStat?.stood_left}
              unknownAgeStat={numericStat?.unknown_age_count_sum}
              unknownGenderStat={numericStat?.unknown_gender_count_sum}
              fullHeight={true}
              includeDwelling
              entityGroupsCounts={entityGroupsCounts}
              isLoading={loading}
              reference={visitorsRef}
            />
          </Fragment>
        )}
      </div>
    </Fragment>
  )
}
export default NumericStats
