import { definitions } from "../../types/Generated/apiTypes"

type CampaignDetail = definitions["CampaignDetail"]

type days = {
  day: string
  count: number
  weatherCondition?: string
}

type calculatedDays = {
  day: string
  count: number
  countPercentage: number
  countPosition: number
  weatherCondition?: string
  exceed: boolean
  order: number
}

export const calculateLabelPositions = (days: days[], campaign: CampaignDetail) => {
  if (days && days?.length > 0) {
    const filteredDays = days?.map((day, i, arr): calculatedDays => {
      // calculate goal value percentage
      const calc = (index: number) => {
        return Math.round((arr[index]?.count / campaign?.goal!) * 100)
      }
      if (i === 0) {
        const position = 0
        const exceed = position + calc(0) > 100 ? true : false
        return {
          count: arr[0]?.count,
          countPercentage: calc(0),
          countPosition: position,
          day: arr[0]?.day,
          weatherCondition: day?.weatherCondition,
          exceed,
          order: 0,
        }
      } else if (i === 1) {
        const position = calc(0)
        const exceed = position + calc(1) > 100 ? true : false
        return {
          count: arr[1]?.count,
          countPercentage: calc(1),
          countPosition: calc(0),
          day: arr[1]?.day,
          weatherCondition: day?.weatherCondition,
          exceed,
          order: 1,
        }
      } else if (i === 2) {
        const position = calc(1) + calc(2)
        const exceed = position + calc(0) > 100 ? true : false
        return {
          count: arr[2]?.count,
          countPercentage: calc(2),
          countPosition: position,
          day: arr[2]?.day,
          weatherCondition: day?.weatherCondition,
          exceed,
          order: 2,
        }
      } else if (i === 3) {
        const position = calc(1) + calc(2) + calc(3)
        const exceed = position + calc(0) > 100 ? true : false
        return {
          count: arr[3]?.count,
          countPercentage: calc(3),
          countPosition: position,
          day: arr[3]?.day,
          weatherCondition: day?.weatherCondition,
          exceed,
          order: 3,
        }
      } else if (i === 4) {
        const position = calc(1) + calc(2) + calc(3) + calc(4)
        const exceed = position + calc(0) > 100 ? true : false
        return {
          count: arr[4]?.count,
          countPercentage: calc(4),
          countPosition: position,
          day: arr[4]?.day,
          weatherCondition: day?.weatherCondition,
          exceed,
          order: 4,
        }
      } else if (i === 5) {
        const position = calc(1) + calc(2) + calc(3) + calc(4) + calc(5)
        const exceed = position + calc(0) > 100 ? true : false
        return {
          count: arr[5]?.count,
          countPercentage: calc(5),
          countPosition: position,
          day: arr[5]?.day,
          weatherCondition: day?.weatherCondition,
          exceed,
          order: 5,
        }
      } else if (i === 6) {
        const position = calc(1) + calc(2) + calc(3) + calc(4) + calc(5) + calc(6)
        const exceed = position + calc(0) > 100 ? true : false
        return {
          count: arr[6]?.count,
          countPercentage: calc(6),
          countPosition: position,
          day: arr[6]?.day,
          weatherCondition: day?.weatherCondition,
          exceed,
          order: 6,
        }
      } else {
        const position = calc(1) + calc(2) + calc(3) + calc(4) + calc(5) + calc(6) + calc(7)
        const exceed = position + calc(0) > 100 ? true : false
        return {
          count: arr[7]?.count,
          countPercentage: calc(7),
          countPosition: position,
          day: arr[7]?.day,
          weatherCondition: day?.weatherCondition,
          exceed,
          order: 7,
        }
      }
    })
    return filteredDays
  }
}

export const weatherCodes = (weatherCode: number): string => {
  let weatherTitle: string
  switch (weatherCode) {
    case 0:
      weatherTitle = "sunny"
      break

    case 1:
      weatherTitle = "clear"
      break

    case 2:
    case 3:
      weatherTitle = "cloud"
      break

    case 45:
    case 48:
      weatherTitle = "fog"
      break

    case 51:
    case 52:
    case 55:
    case 56:
    case 57:
      weatherTitle = "drizzle"
      break

    case 61:
    case 63:
    case 65:
    case 66:
    case 67:
    case 80:
    case 81:
      weatherTitle = "rain"
      break

    case 71:
    case 73:
    case 75:
    case 77:
    case 85:
    case 86:
      weatherTitle = "snow"
      break

    case 95:
    case 96:
    case 99:
      weatherTitle = "thunderStorm"
      break

    default:
      break
  }

  return weatherTitle!
}
