import React, { useState, useContext, Fragment } from "react"
import { useQuery } from "react-query"

import AddCircleIcon from "@mui/icons-material/AddCircle"
import { Grid, useMediaQuery } from "@mui/material"

import { Typography, Button, Skeleton } from "@synapse-analytics/synapse-ui"
import { AxiosError } from "axios"
import { shallow } from "zustand/shallow"

import { VisionAPI } from "../../API/VisionAPI"
import NoLogsPlaceholder from "../../assets/NoHistoryLogs.svg"
import { useBranchesStore } from "../../store"
import { definitions } from "../../types/Generated/apiTypes"
import FloorAddEdit from "./components/floorsList/FloorAddEdit"
import FloorCard from "./components/floorsList/FloorCard"
import { FloorsContext } from "./floorsContext/FloorsContext"

import styles from "./FloorsList.module.scss"

type FloorList = definitions["FloorPlanList"]

const FloorsList = () => {
  const [selectedBranch] = useBranchesStore((state: { selectedBranch: number }) => [state.selectedBranch], shallow)

  const { data: floors, isLoading } = useQuery<FloorList[], AxiosError>(
    ["fetchFloors", selectedBranch],
    ({ queryKey }) => VisionAPI.fetchFloorPlans({ branch: queryKey[1] as number }),
    {
      enabled: !!selectedBranch,
    }
  )

  const { formik } = useContext(FloorsContext)
  const [open, setOpen] = useState(false)
  const xLargeScreen = useMediaQuery("(min-width:1900px)")

  const handleClose = () => {
    setOpen(false)
    formik.resetForm()
  }
  // loading placeholders
  const loadingPlaceholders = new Array(8).fill(null).map((r, i) => (
    <Grid item xs={12} md={3} key={i}>
      <Skeleton variant="rectangular" height={311} width="auto" />
    </Grid>
  ))
  return (
    <div className={styles.wrapper}>
      <Typography
        variant="h2-regular"
        tooltip="Floors lists with description about every floor"
        tooltipPlacement="right"
        tooltipIconSize={22}
        gutterBottom
        variantColor={2}
      >
        Floors
      </Typography>
      <div className={styles.header}>
        {/* add new floor */}
        <Button
          variant="primary"
          disabled={isLoading || !selectedBranch}
          onClick={() => {
            setOpen(true)
            formik.resetForm()
          }}
          startIcon={<AddCircleIcon fontSize="small" />}
        >
          Add New Floor
        </Button>
      </div>
      <Grid container spacing={2}>
        {/* floors list */}
        {isLoading ? (
          <Fragment>{loadingPlaceholders}</Fragment>
        ) : floors && floors?.length > 0 ? (
          floors?.map((floor: FloorList) => {
            return (
              <Grid item xs={12} lg={4} xl={xLargeScreen ? 3 : 4} key={floor?.id!}>
                <FloorCard key={floor?.id} floor={floor} />
              </Grid>
            )
          })
        ) : (
          <Grid item xs={12} className={styles.floorsPlaceholderWrapper}>
            <img src={NoLogsPlaceholder} className={styles.floorsPlaceholder} alt="No Floors found" />
            <div>
              <Typography variant="h2-bold" align="center" className={styles.noFloorsText}>
                No Floors Yet.
              </Typography>
            </div>
            <Button
              disabled={isLoading || !selectedBranch}
              onClick={() => {
                setOpen(true)
                formik.resetForm()
              }}
              startIcon={<AddCircleIcon fontSize="small" />}
              variant="primary"
            >
              Add New floor
            </Button>
          </Grid>
        )}

        <FloorAddEdit handleClose={handleClose} open={open} />
      </Grid>
    </div>
  )
}

export default FloorsList
