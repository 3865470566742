import React from "react"
// necessary for date picker imported from SUI
import "react-datepicker/dist/react-datepicker.css"
import { createRoot } from "react-dom/client"
import { QueryClient, QueryClientProvider } from "react-query"
import { ReactQueryDevtools } from "react-query/devtools"

import { StyledEngineProvider } from "@mui/material"
import { LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"

import * as Sentry from "@sentry/react"
import { lightTheme, NotificationProvider, ThemeProvider as SynapseThemeProvider } from "@synapse-analytics/synapse-ui"
import "slick-carousel/slick/slick-theme.css"
import "slick-carousel/slick/slick.css"

import packageJson from "../package.json"
import App from "./screens/App"
import * as serviceWorker from "./serviceWorker"

// necessary for latest version , react dates picker used in campaign planner create/edit dialog
import "./index.scss"

const hostname = window.location?.hostname.split(".")[0]

// Sentry setup
if (hostname !== "localhost" && hostname !== "backend") {
  Sentry.init({
    dsn: "https://bac10d4e1c6e5bae45a8670c24aa9856@o1005988.ingest.sentry.io/4506025414885376",
    integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
    environment: "production",
    release: packageJson.version,
    // Was 1.0 but lowered to 0.75 in production
    tracesSampleRate: 0.75,
    //capture replay for 100% of sessions with errors
    replaysOnErrorSampleRate: 1.0,
  })
}

const root = createRoot(document.getElementById("root") as HTMLElement)

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 1,
    },
    mutations: {
      retry: false,
    },
  },
})

root.render(
  <QueryClientProvider client={queryClient}>
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <StyledEngineProvider injectFirst>
        <SynapseThemeProvider theme={lightTheme}>
          <NotificationProvider maxSnack={3} />
          <App />
        </SynapseThemeProvider>
      </StyledEngineProvider>
    </LocalizationProvider>
    <ReactQueryDevtools initialIsOpen={false} />
  </QueryClientProvider>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
