import { FC, useState, useContext, Fragment } from "react"

import EditIcon from "@mui/icons-material/EditOutlined"
import { Paper, Slider } from "@mui/material"

import { Typography, Tooltip, InputText, InputChangeEvent } from "@synapse-analytics/synapse-ui"

import { CampaignContext } from "../CampaignContext/CampaignContext"
import CampaignEntities from "./CampaignEntities"

import styles from "./CampaignSecondStep.module.scss"

interface Props {
  gatesEntities: any[]
  carsEntities: any[]
  tenantsEntities: any[]
}

const CampaignSecondStep: FC<Props> = ({ gatesEntities, carsEntities, tenantsEntities }) => {
  const { formik } = useContext(CampaignContext)
  const [editGoal, setEditGoal] = useState(false)
  const [goalMax, setGoalMax] = useState(10000)

  const handleGoal = (event: Event, newValue: number | number[]) => {
    if (newValue) formik?.setFieldValue("goal", newValue)
  }
  const handleChangeGoal = (event: InputChangeEvent) => {
    const targetValue = event.target as HTMLInputElement
    let goal = Number(targetValue.value)
    setGoalMax(goal)
  }

  return (
    <Fragment>
      <Paper className={styles.stepTwo} elevation={0}>
        <Typography variant="a" variantColor={2} className={styles.inputTitle}>
          <span className={styles.step}>5</span>
          People Goal Count*
        </Typography>

        {!editGoal ? (
          <Typography
            id="discrete-slider-small-steps"
            variant="h1-bold"
            gutterBottom
            className={styles.goal}
            align="center"
          >
            {formik?.values?.goal!}{" "}
            <Tooltip title="Edit goal value" placement="right">
              <EditIcon
                onClick={() => setEditGoal(true)}
                className={styles.editGoal}
                style={{
                  color: "var(--indigo-background-1)",
                }}
              />
            </Tooltip>
          </Typography>
        ) : (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <InputText
              type="number"
              value={goalMax}
              handleChange={handleChangeGoal}
              handleBlur={() => {
                setEditGoal(false)
                formik?.setFieldValue("goal", goalMax)
              }}
              placeholder="Add goal value"
              width={100}
            />
          </div>
        )}

        {/* goal slider */}
        <div style={{ display: "flex", alignItems: "center" }}>
          <Typography variant="h3-bold" variantColor={2} className={styles.goalText} style={{ right: 10 }}>
            0
          </Typography>
          <Slider
            classes={{
              root: styles.sliderRoot,
              rail: styles.rail,
              thumb: styles.thumb,
              track: styles.track,
              valueLabel: styles.valueLabel,
            }}
            valueLabelDisplay="auto"
            aria-label="custom slider"
            value={formik?.values?.goal!}
            min={0}
            max={goalMax}
            step={100}
            onChange={handleGoal}
          />
          <Typography variant="h3-bold" variantColor={2} className={styles.goalText} style={{ left: 15 }}>
            {goalMax}
          </Typography>
        </div>
        <Typography variant="span" variantColor={2}>
          Choose your goal count
        </Typography>
      </Paper>
      <Paper className={styles.entities} elevation={0}>
        {/* Entities Observed */}
        <CampaignEntities
          combinedEntities={[...gatesEntities, ...carsEntities, ...tenantsEntities]}
          gatesEntities={gatesEntities}
          carsEntities={carsEntities}
          tenantsEntities={tenantsEntities}
        />
      </Paper>
    </Fragment>
  )
}

export default CampaignSecondStep
