import React, { useContext, useEffect, Fragment } from "react"

import AccessTimeIcon from "@mui/icons-material/AccessTime"
import DateRangeIcon from "@mui/icons-material/DateRange"
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline"
import { Card, Grid } from "@mui/material"

import { Typography } from "@synapse-analytics/synapse-ui"
import moment from "moment"

import { IFootageData } from "../../../../types/Custom/Interfaces"
import { FootageContext } from "../../footageContext/FootageContext"

import styles from "./ConfirmFootage.module.scss"

const ConfirmFootage = () => {
  const { formik, services, duration, footagePreview } = useContext<IFootageData>(FootageContext)
  useEffect(() => {
    if (duration !== "") {
      // Formula : convert [string time] into [milliseconds]
      let milliseconds =
        Number(duration?.split(":")[0]) * 60 * 60 * 1000 +
        Number(duration?.split(":")[1]) * 60 * 1000 +
        Number(duration?.split(":")[2]) * 1000
      const startDate = formik!.values?.startDate!
      const startTime = formik!.values?.startTime!
      startDate.setHours(startTime.getHours())
      startDate.setMinutes(startTime.getMinutes())
      formik?.setFieldValue("startDate", startDate)
      const endDate = new Date(startDate.getTime() + milliseconds)
      formik?.setFieldValue("endDate", endDate)
    }
    // eslint-disable-next-line
  }, [duration])

  return (
    <Fragment>
      <Card className={styles.footageSumCard}>
        <Grid container spacing={2}>
          <Grid item md={6} xs={12} className={styles.footageSumContent}>
            <Typography variant="a" variantColor={2} className={styles.sumTitle}>
              START DATE AND TIME
            </Typography>
            <Typography variant="p" className={styles.footageSum} gutterBottom>
              <DateRangeIcon style={{ marginRight: 9 }} />
              {/* "SEP 14 2022" */}
              {moment(formik!.values?.startDate).format("MMM D YYYY")}
              <AccessTimeIcon style={{ margin: "0px 9px" }} />
              {/* 03:02PM */}
              {moment(formik!.values?.startTime).format("hh:mm A")}
            </Typography>
            <Typography variant="a" variantColor={2} className={styles.sumTitle}>
              END DATE AND TIME
            </Typography>
            <Typography variant="p" className={styles.footageSum} gutterBottom>
              <DateRangeIcon style={{ marginRight: 9 }} />
              {/* OCT 01 2022 */}
              {moment(formik!.values?.endDate).format("MMM D YYYY")}
              <AccessTimeIcon style={{ margin: "0px 9px" }} />
              {/* 03:02PM */}
              {moment(formik!.values?.endDate).format("hh:mm A")}
            </Typography>
            <Typography variant="a" variantColor={2} style={{ marginBottom: 8 }}>
              FOOTAGE LENGTH
            </Typography>
            <Typography variant="p" className={styles.dateTimeTitle} style={{ marginBottom: 12 }}>
              {/* 00:00:11:64 */}
              {duration}
            </Typography>
            {services?.car ||
              services?.counter ||
              (services?.heatmap && (
                <div className={styles.error}>
                  <ErrorOutlineIcon style={{ color: "var(--red-background-1)", fontSize: 16, marginRight: 8 }} />
                  <Typography variant="p" color="negative" variantColor={2}>
                    Warning : Overlap in video footage with camera services
                  </Typography>
                </div>
              ))}
          </Grid>
          <Grid item md={6} xs={12}>
            <img className={styles.footagePreview} src={footagePreview} alt="previewFootage" />
          </Grid>
        </Grid>
      </Card>
    </Fragment>
  )
}

export default ConfirmFootage
