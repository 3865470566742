import React, { createContext, ReactNode, useState, useEffect } from "react"
import { useMutation, useQuery, useQueryClient } from "react-query"

import { useMediaQuery } from "@mui/material"

import { NotificationUtils } from "@synapse-analytics/synapse-ui"
import { AxiosError } from "axios"
import { FormikProps, useFormik } from "formik"
import moment from "moment"
import * as Yup from "yup"

import { VisionAPI } from "../../../API/VisionAPI"
import { ICampaignData, ICampaignAction, PaginatedCampaignsList } from "../../../types/Custom/Interfaces"

export const CampaignContext = createContext({} as ICampaignData)

const CampaignContextProvider = ({ children }: { children: ReactNode }) => {
  const queryClient = useQueryClient()
  const [searchValue, setSearchValue] = useState("")
  const [debouncedSearchValue, setDebouncedSearchValue] = useState<string>("")
  const [compare, setCompare] = useState(false)
  const [pageNumber, setPageNumber] = useState(1)
  const [chosenCampaignId, setChosenCampaignId] = useState(0)
  const [singleCampaignId, setSingleCampaignId] = useState(0)
  const [selectSingleCampaign, setSelectSingleCampaign] = useState(false)
  const [selectChosenCampaign, setSelectChosenCampaign] = useState(false)
  const xLargeScreen = useMediaQuery("(min-width:1900px)")
  const smallScreen = useMediaQuery("(max-width:1240px)")
  const largeScreen = useMediaQuery("(max-width:1525px)")
  const xSmallScreen = useMediaQuery("(max-width:680px)")

  let limit = 5
  // search campaigns with debounce
  const handleSearch = (value: string) => {
    setSearchValue(value)
  }

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchValue(searchValue)
    }, 500)

    // Cancel the timeout if value changes (also on delay change or unmount)
    return () => {
      clearTimeout(handler)
    }
  }, [searchValue])

  const handleCompare = () => {
    setCompare(!compare)
    setPageNumber(1)
  }

  const handleCampaignsPagination = (page: number) => {
    setPageNumber(page)
    setSearchValue("")
    setDebouncedSearchValue("")
    setTimeout(() => {
      refetchPast()
    }, 300)
  }

  if (xLargeScreen) {
    limit = 5
  } else if (xSmallScreen) {
    limit = 1
  } else if (smallScreen) {
    limit = 2
  } else if (largeScreen) {
    limit = 3
  } else {
    limit = 4
  }

  const validationSchema = Yup.object({
    name: Yup.string().required("Campaign name is required"),
  })

  const formik: FormikProps<ICampaignAction> = useFormik<ICampaignAction>({
    initialValues: {
      startDate: moment().subtract(1, "months"),
      endDate: moment(),
      startDateFilter: moment().subtract(3, "months"),
      endDateFilter: moment().add(1, "months"),
      name: "",
      tags: [],
      target_entities: [],
      selected_entities: [],
      description: "",
      goal: 0,
      edit: false,
      id: 0,
    },
    validationSchema: validationSchema,
    validateOnChange: false,
    onSubmit: (values) => {
      if (formik?.values?.edit) {
        return editCampaign(values)
      } else {
        return newCampaign(values)
      }
    },
  })

  const handleChosenCampaign = async (id: number, type: string) => {
    if (type === "chosen") {
      setChosenCampaignId(id)
      setSelectChosenCampaign(true)
    } else {
      setSingleCampaignId(id)
      setSelectSingleCampaign(true)
    }
  }

  useEffect(() => {
    if (!compare) {
      setSelectChosenCampaign(false)
      setSelectSingleCampaign(false)
      setDebouncedSearchValue("")
      setSearchValue("")
    }
  }, [compare])

  const {
    mutate: newCampaign,
    isSuccess: addSuccess,
    isLoading: loadingNewCampaign,
  } = useMutation(
    (values: ICampaignAction) =>
      VisionAPI.createCampaign({
        name: values.name,
        start_date: moment(values.startDate).format("YYYY-MM-DD"),
        end_date: moment(values.endDate).format("YYYY-MM-DD"),
        tags: values.tags,
        description: values.description,
        goal: values.goal,
        target_entities: values.target_entities,
      }),
    {
      onSuccess: (data) => {
        NotificationUtils.toast(`Campaign ${data.name} created successfully`, {
          snackBarVariant: "positive",
        })
      },
      onSettled: async () => {
        await queryClient.invalidateQueries("fetchCampaignListUpcoming")
        await queryClient.invalidateQueries("fetchCampaignListProgress")
      },
    }
  )

  const {
    mutate: editCampaign,
    isSuccess: editSuccess,
    isLoading: loadingEditCampaign,
  } = useMutation(
    (values: ICampaignAction) =>
      VisionAPI.editCampaign(values.id, {
        name: values.name,
        start_date: moment(values.startDate).format("YYYY-MM-DD"),
        end_date: moment(values.endDate).format("YYYY-MM-DD"),
        tags: values.tags,
        description: values.description,
        goal: values.goal,
        target_entities: values.target_entities,
      }),
    {
      onSuccess: (data) => {
        NotificationUtils.toast(`Campaign ${data.name} updated successfully`, {
          snackBarVariant: "positive",
        })
      },
      onSettled: async () => {
        await queryClient.invalidateQueries("fetchSingleCampaign")
        await queryClient.invalidateQueries("fetchCampaignListUpcoming")
      },
    }
  )

  const fetchSearchedCampaigns = async (pageParam: number) =>
    VisionAPI.fetchPaginatedCampaigns({ limit, page: pageParam, search: debouncedSearchValue })

  const fetchCampaignListStatus = async (pageParam: number, status?: string) =>
    VisionAPI.fetchPaginatedCampaigns({
      limit,
      page: pageParam,
      start_date: moment(formik?.values?.startDateFilter).format("YYYY-MM-DD"),
      end_date: moment(formik?.values?.endDateFilter).format("YYYY-MM-DD"),
      status: status,
    })

  // fetch past Campaign to compare
  const {
    data: pastCampaigns,
    isFetching: loadingPastCampaigns,
    refetch: refetchPast,
  } = useQuery<PaginatedCampaignsList, AxiosError>(
    ["fetchPastCampaigns", pageNumber, debouncedSearchValue],
    ({ queryKey }) =>
      VisionAPI.fetchPaginatedCampaigns({
        limit: 14,
        page: queryKey[1] as number,
        search: queryKey[2] as string,
        status: "past",
      }),
    {
      enabled: compare && !selectChosenCampaign ? true : false,
    }
  )

  return (
    <CampaignContext.Provider
      value={{
        formik,
        fetchSearchedCampaigns,
        fetchCampaignListStatus,
        loadingNewCampaign,
        handleSearch,
        newCampaign,
        editCampaign,
        searchValue,
        debouncedSearchValue,
        loadingEditCampaign,
        addSuccess,
        editSuccess,
        handleCampaignsPagination,
        handleCompare,
        compare,
        pastCampaigns: pastCampaigns!,
        loadingPastCampaigns,
        chosenCampaignId,
        singleCampaignId,
        handleChosenCampaign,
        selectChosenCampaign,
        selectSingleCampaign,
      }}
    >
      {children}
    </CampaignContext.Provider>
  )
}

export default CampaignContextProvider
