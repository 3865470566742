import { Tag, Tooltip } from "@synapse-analytics/synapse-ui"

const BetaTag = () => {
  return (
    <Tooltip
      title="Currently in beta testing! This feature is being trialed to gather your feedback and improve your experience."
      placement="right"
    >
      <Tag variant="default">Beta</Tag>
    </Tooltip>
  )
}
export default BetaTag
