import { FC, useState, useMemo } from "react"

import { Slider } from "@mui/material"

import { Menu, Typography } from "@synapse-analytics/synapse-ui"
import moment from "moment"

import { definitions } from "../../types/Generated/apiTypes"
import { convertSecondsToReadableTime, convertTimeToMinutes } from "../../utils/genericHelpers"

import styles from "./DownDaySlider.module.scss"

type DailyDownTimeLog = definitions["DailyDownTimeLog"]
type DowntimeLog = definitions["DowntimeLog"]

interface Props {
  log?: DailyDownTimeLog
}

// Generate slider marks for every hour of the day using a lambda function
const hourlyMarks = Array.from({ length: 24 }, (_, i) => ({
  value: i * 60,
  label: i === 0 ? "12 AM" : i < 12 ? `${i} AM` : i === 12 ? "12 PM" : `${i - 12} PM`,
}))

interface DowntimeSliderProps {
  logs?: DowntimeLog[]
  logDate?: string
}

/**
 * Calculates slider values based on start and end times.
 *
 * @param {string} start_dt - The start datetime string in ISO 8601 format.
 * @param {string} end_dt - The end datetime string in ISO 8601 format.
 * @param {number} maxTime - The maximum time in minutes (e.g., 1440 for a full day).
 * @returns {Object} An object containing the start and end times in minutes, width percentage, and formatted start and end times.
 * @returns {number} return.startMinutes - The start time in minutes from the start of the day.
 * @returns {number} return.widthPercentage - The width percentage of the slider based on the duration.
 * @returns {string} return.startTime - The formatted start time (e.g., "h:mm a").
 * @returns {string} return.endTime - The formatted end time (e.g., "h:mm a").
 */
const getSliderValues = (start_dt: string, end_dt: string, maxTime: number) => {
  const startMinutes = convertTimeToMinutes(start_dt)
  const endMinutes = convertTimeToMinutes(end_dt)
  const widthPercentage = ((endMinutes - startMinutes) / maxTime) * 100
  const startTime = moment(start_dt).format("h:mm a")
  const endTime = moment(end_dt).format("h:mm a")

  return { startMinutes, widthPercentage, startTime, endTime }
}
/**
 * DowntimeSlider Component
 *
 * This component renders a slider that visualizes downtime periods within a day.
 * It displays downtime incidents as highlighted sections on the slider and provides
 * tooltips with detailed information about each incident.
 *
 * @param {DowntimeSliderProps} props - The component props
 * @param {DowntimeLog[]} props.logs - An array of downtime log entries
 *
 * @returns {JSX.Element | null} The rendered DowntimeSlider component or null if no logs are provided
 */
const DowntimeSlider: FC<DowntimeSliderProps> = ({ logs, logDate }) => {
  const [durationTooltipAnchor, setDurationTooltipAnchor] = useState<HTMLElement | null>(null)
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null)
  const minTime = 0 // 12 AM
  const maxTime = 1440 // 11:59 PM

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>, index: number) => {
    setDurationTooltipAnchor(event.currentTarget)
    setHoveredIndex(index)
  }

  const handlePopoverClose = () => {
    setDurationTooltipAnchor(null)
    setHoveredIndex(null)
  }

  // memoize the computed slider values to avoid unnecessary recalculations
  const sliderValues = useMemo(
    () =>
      // Flatten the logs array and map each log's start and end times to minutes
      logs?.flatMap(({ start_dt, end_dt }) => {
        // Convert the start and end times to minutes from the start of the day
        const startMinutes = convertTimeToMinutes(start_dt)
        const endMinutes = convertTimeToMinutes(end_dt)

        // Return an array containing the start and end times in minutes
        return [startMinutes, endMinutes]
      }),
    // recompute the slider values when logs change
    [logs]
  )

  const isOpen = Boolean(durationTooltipAnchor)

  return (
    <div className={styles.sliderWrapper}>
      {logs?.map(({ start_dt, end_dt, duration }, index) => {
        const { startMinutes, widthPercentage, startTime, endTime } = getSliderValues(start_dt, end_dt, maxTime)
        return (
          <div key={index}>
            <div className={styles.durationCaption} style={{ left: `${(startMinutes / maxTime) * 100}%` }}>
              <Typography variant="label" variantColor={2}>
                {convertSecondsToReadableTime(duration)}
              </Typography>
            </div>

            {/* Hover area for displaying start-end downtime tooltips */}
            <div
              onMouseEnter={(e) => handlePopoverOpen(e, index)}
              onMouseLeave={handlePopoverClose}
              className={`${styles.highlightedDuration} ${styles.hoverArea}`}
              style={{ left: `${(startMinutes / maxTime) * 100 - 0.4}%`, width: `${widthPercentage + 0.6}%` }}
            />

            <div
              className={styles.highlightedDuration}
              style={{ left: `${(startMinutes / maxTime) * 100 - 0.4}%`, width: `${widthPercentage + 0.6}%` }}
            />

            {hoveredIndex === index && (
              <Menu
                menuMaxContent
                placement="top"
                open={isOpen}
                anchorEl={durationTooltipAnchor}
                onClose={handlePopoverClose}
              >
                <div className={styles.incidentDetailWrapper}>
                  <div className={styles.incidentDetail}>
                    <Typography variant="label">Incident {index + 1}</Typography>

                    {/* Display start and end times, dates, and duration */}
                    <Typography variant="label" variantColor={2}>
                      {startTime} - {endTime} ({convertSecondsToReadableTime(duration)})
                    </Typography>
                  </div>
                </div>
              </Menu>
            )}
          </div>
        )
      })}

      <Slider
        value={sliderValues}
        min={minTime}
        max={maxTime}
        step={1}
        marks={hourlyMarks}
        disableSwap
        track={false}
        classes={{
          track: styles.track,
          thumb: styles.thumb,
          rail: styles.rail,
          mark: styles.mark,
          markLabel: styles.markLabel,
          markActive: styles.markActive,
          root: styles.slider,
        }}
      />
    </div>
  )
}

/**
 * DownDaySlider Component
 *
 * This component displays a summary of downtime incidents for a specific day.
 * It shows the date, number of incidents, total downtime, and a visual representation
 * of the downtime periods using the DowntimeSlider component.
 *
 * @param {Props} props - The component props
 * @param {DailyDownTimeLog} props.log - The daily downtime log data
 *
 * @returns {JSX.Element} The rendered DownDaySlider component
 */
const DownDaySlider: FC<Props> = ({ log }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <Typography variant="h3-bold">{moment(log?.date).format("DD MMM")}</Typography>
        {log?.downtime_logs && (
          <Typography variant="a" color="negative" variantColor={2}>
            {log?.downtime_logs?.length} incident{log?.downtime_logs?.length > 1 ? "s" : ""}
          </Typography>
        )}
        <Typography variant="label" variantColor={2}>
          {convertSecondsToReadableTime(log?.day_total_down_time)} total
        </Typography>
      </div>
      <div style={{ width: "100%" }}>
        <DowntimeSlider logs={log?.downtime_logs} logDate={log?.date} />
      </div>
    </div>
  )
}

export default DownDaySlider
