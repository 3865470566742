import { FC, useState } from "react"
import { useInfiniteQuery } from "react-query"

import { Card, CardMedia, Grid, useMediaQuery } from "@mui/material"

import { Skeleton, Typography } from "@synapse-analytics/synapse-ui"
import { SwiperSlide } from "swiper/react"
import { shallow } from "zustand/shallow"

import { VisionAPI } from "../../../API/VisionAPI"
import NoCameras from "../../../assets/NoTableLogs.svg?react"
import Search from "../../../components/Search"
import SwiperContainer from "../../../components/SwiperContainer"
import { useDebounceSearch } from "../../../hooks/useDebouncedSearch"
import { useBranchesStore } from "../../../store"
import { PaginatedCamerasList } from "../../../types/Custom/Interfaces"
import { definitions } from "../../../types/Generated/apiTypes"
import { extractPageFromBackEndPaginationLink } from "../../../utils/genericHelpers"
import CameraPlaceholder from "../../Cameras/assets/cameraPlaceholder.svg?react"

import styles from "./CamerasSelection.module.scss"

type CameraPaginated = definitions["PaginatedCamerasList"]

interface Props {
  handleSelectCamera: (camera: number) => void
  selectedCameras?: number[]
  isCarsFeed?: boolean
}

const CamerasSelection: FC<Props> = ({ handleSelectCamera, selectedCameras, isCarsFeed }) => {
  const [searchValue, setSearchValue] = useState("")
  const smallScreen = useMediaQuery("(min-width:1400px)")
  const mediumScreen = useMediaQuery("(min-width:1525px)")
  const largeScreen = useMediaQuery("(min-width:1600px)")
  const xSmallScreen = useMediaQuery("(min-width:680px)")
  let limit = 5
  if (largeScreen) {
    limit = 5
  } else if (mediumScreen) {
    limit = 4
  } else if (smallScreen) {
    limit = 3
  } else if (xSmallScreen) {
    limit = 2
  } else {
    limit = 1
  }

  const [selectedBranch] = useBranchesStore((state: { selectedBranch: number }) => [state.selectedBranch], shallow)

  const debouncedSearchValue = useDebounceSearch(searchValue, 500)

  const loadingPlaceholders = new Array(limit).fill(null).map((_r, i) => (
    <Grid item sm={6} xs={12} md={2.4} key={i}>
      <Skeleton variant="rectangular" height={200} width="100%" />
    </Grid>
  ))

  // search cameras with debounce
  const handleSearch = (value: string) => {
    setSearchValue(value)
  }

  const {
    data: paginatedCameraData,
    fetchNextPage,
    isLoading: isLoadingCameras,
    isFetchingNextPage: isFetching,
  } = useInfiniteQuery<PaginatedCamerasList>(
    ["fetchCamerasPaginated", selectedBranch, limit, debouncedSearchValue, isCarsFeed],
    async ({ queryKey, pageParam = 1 }) =>
      VisionAPI.fetchCamerasPaginated({
        branch: queryKey[1] as number,
        limit: queryKey[2] as number,
        search: queryKey[3] as string,
        services: queryKey[4] ? { car: true } : undefined,
        ordering: "-created_at,-id",
        page: pageParam,
      }),
    {
      keepPreviousData: true,
      getNextPageParam: (lastPage: PaginatedCamerasList) => {
        return lastPage?.next ? extractPageFromBackEndPaginationLink(lastPage.next) : undefined
      },
    }
  )

  return (
    <div className={styles.wrapper}>
      <div className={styles.searchCameras}>
        <Search
          placeholder="E.g. CAMERA: 1"
          handleSearch={handleSearch}
          searchValue={searchValue}
          topHeader={false}
          type="cameras"
        />
      </div>
      {isLoadingCameras ? (
        <Grid container spacing={1.5}>
          {loadingPlaceholders}
        </Grid>
      ) : paginatedCameraData && paginatedCameraData.pages[0].count > 0 ? (
        <SwiperContainer
          slidesPerView={limit}
          slidesToScroll={limit}
          fetchNextPage={fetchNextPage}
          isLoading={isFetching}
          count={paginatedCameraData.pages[0].count}
          spaceBetweenSlides={12}
          key={`${paginatedCameraData.pages[0].count}_${searchValue}`}
        >
          {paginatedCameraData?.pages.map((page) =>
            page.results.map((camera: CameraPaginated, i: number) => (
              <SwiperSlide key={i}>
                <Card
                  className={styles.cameraCard}
                  onClick={() => handleSelectCamera(camera?.id as number)}
                  style={{
                    borderColor: selectedCameras?.some((selectedCamera) => selectedCamera === camera?.id)
                      ? "var(--indigo-border-1)"
                      : undefined,
                    background: selectedCameras?.some((selectedCamera) => selectedCamera === camera?.id)
                      ? "var(--indigo-background-2)"
                      : undefined,
                  }}
                >
                  {!camera?.sample_frame ? (
                    <div className={styles.media}>
                      <CameraPlaceholder />
                      <Typography className={styles.noFrame} variant="small" variantColor={2}>
                        No Frame Captured
                      </Typography>
                    </div>
                  ) : (
                    <CardMedia className={styles.media} image={camera?.sample_frame!} title={camera?.name!} />
                  )}
                  <Typography variant="p" noWrap style={{ width: "100%" }} title={camera.name}>
                    {camera.name}
                  </Typography>
                </Card>
              </SwiperSlide>
            ))
          )}
        </SwiperContainer>
      ) : (
        <div className={styles.noData}>
          <NoCameras />
          <Typography variant="h2-regular" variantColor={2} style={{ marginTop: 16 }}>
            No Cameras yet
          </Typography>
        </div>
      )}
    </div>
  )
}
export default CamerasSelection
