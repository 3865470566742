import { forwardRef, FC } from "react"

import HighlightOffIcon from "@mui/icons-material/Close"
import { Dialog, DialogContent, Slide, SlideProps } from "@mui/material"

import { Typography } from "@synapse-analytics/synapse-ui"

import RegionsCanvas from "../../../components/RegionsCanvas"

import styles from "./FrameComparisonDialog.module.scss"

const Transition = forwardRef(function Transition(props: SlideProps, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

interface Props {
  handleCloseDialog: () => void
  open: boolean
  frameBefore?: string
  frameAfter?: string
  cameraName?: string
}

const FramesComparisonDialog: FC<Props> = ({ handleCloseDialog, open, frameBefore, frameAfter, cameraName }) => {
  return (
    <Dialog open={open} maxWidth="lg" TransitionComponent={Transition} onClose={handleCloseDialog}>
      <DialogContent className={styles.wrapper}>
        <div className={styles.header}>
          <Typography variant="h2-bold" className={styles.title}>
            {cameraName} view change preview
          </Typography>
          <HighlightOffIcon onClick={handleCloseDialog} className={styles.iconContainer} />
        </div>

        <div className={styles.content}>
          <div className={styles.frameWrapper}>
            <Typography variant="h3-regular" variantColor={2}>
              Camera before movement
            </Typography>
            <RegionsCanvas imageSrc={frameBefore} height={480} width={660} />
          </div>
          <div className={styles.frameWrapper}>
            <Typography variant="h3-regular" variantColor={2}>
              Camera after movement
            </Typography>
            <RegionsCanvas imageSrc={frameAfter} height={480} width={660} />
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}
export default FramesComparisonDialog
