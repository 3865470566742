import { FC } from "react"

import { Paper } from "@mui/material"

import MaterialTable, { Action, MTableToolbar } from "@material-table/core"
import { Typography } from "@synapse-analytics/synapse-ui"

import EmptyPlaceholder from "../../assets/noListData.svg?react"
import { TableColumn } from "../../types/Custom/Types"

import styles from "./GenericTable.module.scss"

interface Props {
  title: string
  data?: any[]
  columns: TableColumn[]
  actions?: Action<any>[]
  isLoading: boolean
  isEmpty?: boolean
}

const GenericTable: FC<Props> = ({ columns, title, data = [], isLoading, isEmpty, actions = [] }) => {
  const EmptyState = () => {
    return (
      <div className={styles.emptyStateWrapper}>
        <EmptyPlaceholder className={styles.emptyState} />
        <div>
          <Typography variant="h2-regular" variantColor={2} align="center" textTransform="capitalize">
            No {title} yet
          </Typography>
        </div>
      </div>
    )
  }

  return (
    <MaterialTable
      isLoading={isLoading}
      title={title}
      columns={columns!}
      actions={actions}
      data={data}
      options={{
        padding: "default",
        maxBodyHeight: "auto",
        defaultExpanded: () => false,
        paging: false,
        pageSize: 10,
        exportAllData: false,
        draggable: false,
        search: false,
        sorting: false,
        maxColumnSort: 0,
        selection: false,
        actionsColumnIndex: -1,
        showTitle: false,
        toolbar: false,
        headerStyle: {
          backgroundColor: "var(--base-background-3)",
          color: "var(--base-text-1)",
          fontSize: 14,
          fontWeight: 700,
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          borderTop: "1px solid var(--base-border-1)",
          borderBottom: "1px solid var(--base-border-1)",
          padding: "8px 12px",
        },
        rowStyle: (_rowData, index) => {
          if (index % 2) {
            return {
              padding: "8px 12px !important",
              backgroundColor: "var(--base-background-2)",
              borderRadius: "0px",
              color: "var(--base-text-1)",
              fontSize: "14px !important",
              fontWeight: 400,
            }
          } else {
            return {
              padding: "8px 12px !important",
              backgroundColor: "var(--base-background-3)",
              borderRadius: "0px",
              color: "var(--base-text-1)",
              fontSize: "14px !important",
              fontWeight: 400,
            }
          }
        },
      }}
      localization={{
        header: {
          actions: "Action",
        },
        body: {
          emptyDataSourceMessage: <EmptyState />,
        },
      }}
      components={{
        Toolbar: (props) => (
          <div
            style={{
              height: "0px",
            }}
          >
            <MTableToolbar {...props} />
          </div>
        ),
        Container: (props) => <Paper {...props} elevation={0} className={styles.table} />,
      }}
    />
  )
}
export default GenericTable
