import { FC } from "react"

import { Grid } from "@mui/material"

import { Chip, Typography } from "@synapse-analytics/synapse-ui"
import moment from "moment"

import styles from "./WeekDayFilter.module.scss"

const days = [
  { value: 0, label: "Sunday" },
  { value: 1, label: "Monday" },
  { value: 2, label: "Tuesday" },
  { value: 3, label: "Wednesday" },
  { value: 4, label: "Thursday" },
  { value: 5, label: "Friday" },
  { value: 6, label: "Saturday" },
]
interface Props {
  selectedDay?: number | null
  handleSelectDay: (day: number) => void
  interval?: Duration
  startDate?: string
  endDate?: string
}
const WeekDayFilter: FC<Props> = ({ handleSelectDay, selectedDay, startDate, endDate, interval }) => {
  return (
    <div className={styles.wrapper}>
      <Typography variant="span" variantColor={2}>
        Weekdays Filters
      </Typography>
      <Grid container spacing={1} className={styles.animation}>
        {days.map((day, index) => (
          <Grid key={index} item>
            <Chip
              removable={selectedDay === day.value}
              clickable
              onClick={() => handleSelectDay(day.value)}
              onRemove={() => null}
              isSelected={selectedDay === day.value}
              disabled={[
                interval?.months! < 1 && interval?.days! < 7 && endDate && day.value > moment(endDate)?.weekday(),
                interval?.months! < 1 && interval?.days! < 7 && day.value < moment(startDate)?.weekday(),
              ].some((element) => element === true)}
            >
              {day.label}
            </Chip>
          </Grid>
        ))}
      </Grid>
    </div>
  )
}
export default WeekDayFilter
