import { Point } from "../types/Custom/Types"

// Helper function to draw grid lines on a canvas
export const drawGridLines = (ctx: CanvasRenderingContext2D, canvas: HTMLCanvasElement) => {
  ctx.strokeStyle = "white"
  ctx.lineWidth = 0.7
  const cellSize = 80

  for (let c = 1; c < canvas.width / cellSize; c++) {
    ctx.beginPath()
    ctx.moveTo(c * cellSize, 0)
    ctx.lineTo(c * cellSize, canvas.height)
    ctx.stroke()
  }

  for (let r = 1; r < canvas.height / cellSize; r++) {
    ctx.beginPath()
    ctx.moveTo(0, r * cellSize)
    ctx.lineTo(canvas.width, r * cellSize)
    ctx.stroke()
  }
}

// Helper function to get the region label based on its state and index
export const getRegionLabel = (state: number, index: number): string => {
  if (state === 0) return `In (${index})`
  if (state === 1) return `Out (${index})`
  if (state === 2) return `Dwelling (${index})`
  if (state === 8) return `Cashier (${index})`

  return ""
}

// Helper function to draw a tooltip on a canvas
export const drawTooltip = (ctx: CanvasRenderingContext2D, text: string, x: number, y: number, width?: number) => {
  const tooltipWidth = width || 70
  const tooltipHeight = 29.5
  const fontSize = 12
  const fontFamily = "Inter"
  const arrowHeight = 5
  const arrowWidth = 10

  // Set font and alignment for the text
  ctx.font = `${fontSize}px ${fontFamily}`
  ctx.textAlign = "center"
  ctx.textBaseline = "middle"

  // Draw the rounded rectangle for tooltip background
  ctx.fillStyle = "rgba(0,0,0,0.6)" // Tooltip background color
  ctx.beginPath()
  ctx.moveTo(x - tooltipWidth / 2 + 5, y - tooltipHeight) // Start top-left corner
  ctx.arcTo(x + tooltipWidth / 2, y - tooltipHeight, x + tooltipWidth / 2, y - tooltipHeight + 5, 5) // Top-right corner
  ctx.arcTo(x + tooltipWidth / 2, y, x + tooltipWidth / 2 - 5, y, 5) // Bottom-right corner
  ctx.lineTo(x + arrowWidth / 2, y) // Bottom-right before triangle
  ctx.lineTo(x, y + arrowHeight) // Bottom point of triangle
  ctx.lineTo(x - arrowWidth / 2, y) // Bottom-left after triangle
  ctx.arcTo(x - tooltipWidth / 2, y, x - tooltipWidth / 2, y - tooltipHeight + 5, 5) // Bottom-left corner
  ctx.arcTo(x - tooltipWidth / 2, y - tooltipHeight, x - tooltipWidth / 2 + 5, y - tooltipHeight, 5) // Top-left corner
  ctx.closePath()
  ctx.fill()

  // Draw the text in the tooltip
  ctx.fillStyle = "#FFF" // Text color
  ctx.fillText(text, x, y - tooltipHeight / 2)
}

// Utility function to draw numbered circles at each point
export const drawPointCircles = (ctx: CanvasRenderingContext2D, points: Point[]) => {
  points.forEach((point, index) => {
    // Circle properties
    const circleRadius = 7
    const circleX = point.x
    const circleY = point.y

    // Draw circle
    ctx.beginPath()
    ctx.fillStyle = "#FFFF"
    ctx.arc(circleX, circleY, circleRadius, 0, 2 * Math.PI)
    ctx.fill()

    // Draw the text (number inside the circle)
    ctx.font = "12px Inter"
    ctx.fillStyle = "#000"
    ctx.textAlign = "center"
    ctx.textBaseline = "middle"
    ctx.fillText(`${index + 1}`, circleX, circleY)
  })
}
