import { Fragment, FC } from "react"

import { Grid } from "@mui/material"
import Divider from "@mui/material/Divider"

import { Typography, NotificationBadge, Skeleton } from "@synapse-analytics/synapse-ui"

import { cameraAlert } from "../../../types/Custom/Types"
import NoListData from "../assets/NoListData.svg?react"

import styles from "./CameraAlerts.module.scss"

interface Props {
  alerts: cameraAlert[]
  isLoading?: boolean
}

const loadingPlaceholders = new Array(4).fill(null).map((_, i) => (
  <Grid container spacing={2} item xs={12} key={i} alignItems="center">
    <Grid item>
      <Skeleton variant="circular" height={35} width={35} />
    </Grid>
    <Grid item xs={11}>
      <Skeleton variant="rectangular" height={40} width="100%" />
    </Grid>
  </Grid>
))

const CameraAlerts: FC<Props> = ({ alerts, isLoading }) => {
  const alertsCount = alerts && alerts?.length > 0 && alerts?.length

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <Typography variant="h3-bold" variantColor={2} style={{ marginRight: 6 }}>
          Alerts
        </Typography>
        <div className={styles.alertCount}>
          <NotificationBadge NotificationsCount={alertsCount || 0} />
        </div>
      </div>
      {isLoading ? (
        <Grid container spacing={3}>
          {loadingPlaceholders}
        </Grid>
      ) : alerts && alerts?.length > 0 ? (
        <div className={styles.alertsWrapper}>
          {alertsCount &&
            alerts?.map((alert, index) => (
              <Fragment>
                <div className={styles.alert} key={index}>
                  <div className={styles.icon}>{alert.icon}</div>
                  <div className={styles.contentWrapper}>
                    {alert.service ? (
                      <Typography variant="p" noWrap>
                        Camera has <span className={styles.bold}> {alert.service} service </span> but {alert.issue}
                      </Typography>
                    ) : (
                      <Typography variant="a">{alert.issue}</Typography>
                    )}
                  </div>
                </div>
                {index !== alerts.length - 1 && <Divider className={styles.divider} />}
              </Fragment>
            ))}
        </div>
      ) : (
        <div className={styles.noData}>
          <NoListData />
          <Typography variant="h2-regular" variantColor={2} style={{ marginTop: 12 }}>
            No alerts yet
          </Typography>
        </div>
      )}
    </div>
  )
}

export default CameraAlerts
