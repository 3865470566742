import React, { FC, useState } from "react"
import { useNavigate } from "react-router-dom"

import Check from "@mui/icons-material/CheckCircleOutline"
import DeleteIcon from "@mui/icons-material/DeleteOutlineOutlined"
import EditIcon from "@mui/icons-material/EditOutlined"
import Inactive from "@mui/icons-material/HighlightOffOutlined"
import {
  IconButton,
  TableHead,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Paper,
  Avatar,
} from "@mui/material"
import Pagination from "@mui/material/Pagination"

import { Typography } from "@synapse-analytics/synapse-ui"

import { routes } from "../../routes/routes"
import { definitions } from "../../types/Generated/apiTypes"

import styles from "./TableDataList.module.scss"

type UserList = definitions["UserList"]

let pageSize = 10

interface Props {
  data: UserList[]
  handleClickOpen: (id: number | null, username: string) => void
}

const TableDataList: FC<Props> = ({ data, handleClickOpen }) => {
  const [pageOffset, setPageOffset] = useState(0)
  const navigate = useNavigate()
  return (
    <div className={styles.root}>
      <TableContainer component={Paper} style={{ boxShadow: "none" }}>
        <Table className={styles.table} aria-label="simple table">
          <TableHead>
            <TableRow
              classes={{
                root: styles.rowRoot,
              }}
            >
              <TableCell
                classes={{
                  body: styles.body,
                  head: styles.head,
                }}
              >
                Username
              </TableCell>
              <TableCell
                classes={{
                  body: styles.body,
                  head: styles.head,
                }}
              >
                Email
              </TableCell>
              <TableCell
                classes={{
                  body: styles.body,
                  head: styles.head,
                }}
              >
                State
              </TableCell>
              <TableCell
                classes={{
                  body: styles.body,
                  head: styles.head,
                }}
              ></TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {data && data?.length > 0 ? (
              data?.slice(pageOffset * pageSize, (pageOffset + 1) * pageSize).map((user: UserList) => {
                return (
                  <TableRow
                    classes={{
                      root: styles.rowRoot,
                    }}
                    key={user?.username!}
                  >
                    <TableCell
                      classes={{
                        body: styles.body,
                        head: styles.head,
                      }}
                      component="th"
                      scope="row"
                      padding="none"
                      className={styles.usernameCell}
                      align="left"
                    >
                      <Avatar className={styles.avatar}>
                        <Typography variant="h3-bold" textTransform="uppercase" className={styles.avatarTitle}>
                          {user?.username![0]}
                        </Typography>
                      </Avatar>
                      <div className={styles.usernameCellTitle}>
                        <Typography variant="p" style={{ fontWeight: "bold" }}>
                          {user?.username!}
                        </Typography>
                        <Typography variant="span">@{user?.username!}</Typography>
                      </div>
                    </TableCell>
                    <TableCell
                      classes={{
                        body: styles.body,
                        head: styles.head,
                      }}
                      align="left"
                    >
                      {user?.email!}
                    </TableCell>
                    <TableCell
                      classes={{
                        body: styles.body,
                        head: styles.head,
                      }}
                      align="left"
                    >
                      {user?.is_active ? (
                        <div className={styles.state}>
                          <Check className={styles.activeState} style={{ fontSize: 18 }} />
                          <Typography variant="p" variantColor={2} color="positive" className={styles.activeState}>
                            Active
                          </Typography>
                        </div>
                      ) : (
                        <div className={styles.state}>
                          <Inactive className={styles.InactiveState} style={{ fontSize: 18 }} />
                          <Typography variant="p" variantColor={2} color="negative" className={styles.InactiveState}>
                            Inactive
                          </Typography>
                        </div>
                      )}
                    </TableCell>
                    <TableCell
                      classes={{
                        body: styles.body,
                        head: styles.head,
                      }}
                      align="center"
                    >
                      <IconButton
                        ria-label="edit"
                        style={{ padding: 7 }}
                        onClick={() =>
                          navigate(`/${routes?.users}/${user?.id!}`, {
                            state: { edit: true },
                          })
                        }
                        size="large"
                      >
                        <EditIcon />
                      </IconButton>
                      <IconButton
                        aria-label="delete"
                        style={{ padding: 7 }}
                        onClick={() => handleClickOpen(user?.id!, user?.username!)}
                        size="large"
                      >
                        <DeleteIcon className={styles.delete} />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                )
              })
            ) : (
              <TableCell
                classes={{
                  body: styles.body,
                  head: styles.head,
                }}
                component="th"
                scope="row"
                padding="none"
                className={styles.noData}
                align="center"
              >
                No data to display
              </TableCell>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {/* Table Pagination */}
      {data && data?.length > 0 && (
        <Pagination
          count={Math.ceil(data?.length! / pageSize)}
          classes={{ root: styles.rootPagination }}
          page={pageOffset + 1}
          siblingCount={2}
          color="standard"
          shape="rounded"
          onChange={(e, offset) => {
            setPageOffset(offset - 1)
          }}
        />
      )}
    </div>
  )
}

export default TableDataList
