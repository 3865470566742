import { FC } from "react"

import { Typography } from "@synapse-analytics/synapse-ui"

import NoData from "../../assets/noData.svg?react"

import styles from "./GraphEmptyState.module.scss"

interface Props {
  isRangeWarning?: boolean
  isCategories?: boolean
  isCameraDown?: boolean
  noCategorySelected?: boolean
  isAverageTimeSpentInside?: boolean
  noEnoughData?: boolean
}
const GraphEmptyState: FC<Props> = ({
  isRangeWarning,
  isCategories,
  isCameraDown,
  noCategorySelected,
  isAverageTimeSpentInside,
  noEnoughData,
}) => {
  return (
    <div className={styles.container}>
      <NoData className={styles.placeholder} />
      <Typography variant="h2-regular" variantColor={2} display="inline" className={styles.noData}>
        {isCameraDown ? "No down-time data for the selected date range" : "No data yet"}
      </Typography>
      {isRangeWarning && (
        <Typography variant="p" variantColor={2} display="inline">
          Pick a date period greater {`${isCategories ? "than a day" : "or equal to a week"}`} to display chart and
          related data
        </Typography>
      )}
      {noEnoughData && (
        <Typography variant="p" variantColor={2} display="inline">
          There is no enough data to plot the daily line graph
        </Typography>
      )}
      {noCategorySelected && (
        <Typography variant="p" variantColor={2} display="inline">
          Select a category to display chart and related data
        </Typography>
      )}
      {isAverageTimeSpentInside && (
        <Typography variant="p" variantColor={2} display="inline">
          No data found for average time spent inside
        </Typography>
      )}
    </div>
  )
}
export default GraphEmptyState
