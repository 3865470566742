import { useNavigate } from "react-router-dom"

import auth from "../../../utils/auth"
import StatusScreen from "../StatusScreen"
import SubEnded from "./assets/SubEnded.svg?react"

const SubscriptionEnded = () => {
  const navigate = useNavigate()

  return (
    <StatusScreen
      ArtComponent={<SubEnded />}
      message="Your subscription has ended."
      subMessage="Seems your last payment did not go through, You need to contact Synapse team to renew your current subscription"
      buttonText="Retry Login"
      buttonAction={() => auth.logOut(true, navigate)}
    />
  )
}
export default SubscriptionEnded
