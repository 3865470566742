import React, { FC, useState, useContext, useEffect } from "react"

import { Card, Grid } from "@mui/material"
import MuiAlert from "@mui/material/Alert"
import Snackbar from "@mui/material/Snackbar"

import { Typography } from "@synapse-analytics/synapse-ui"

import SlidingData from "../../../../components/SlidingData"
import { FloorsContext } from "../../floorsContext/FloorsContext"
import CameraPreview from "./CameraPreview"
import FloorsArtboard from "./FloorsArtboard"
import SelectCameraAlert from "./SelectCameraAlert"

import styles from "./FloorCreator.module.scss"

function Alert(props: any) {
  return <MuiAlert elevation={6} variant={"filled"} {...props} />
}

interface Props {
  edit?: boolean
}
const FloorCreator: FC<Props> = ({ edit }) => {
  const {
    formik,
    selectedCamera,
    selectedMappingCamera,
    activeStep,
    handleOnSelectCamera,
    checkCameraStatus,
    handleContinueCameraPoints,
    saveCameraChangesAlert,
    cameraProperties,
  } = useContext(FloorsContext)
  const [openErrorSnackbar, setOpenErrorSnackbar] = useState(false)
  const [openAlert, setOpenAlert] = useState(false)
  const [toSelectCamera, setToSelectCamera] = useState(formik.values.ValidCameras[0])
  const [errorMessage, setErrorMessage] = useState("")
  // closing snackbar
  const handleSnackbarClose = (event: any, reason: any) => {
    if (reason === "clickaway") {
      return
    }
    setOpenErrorSnackbar(false)
  }
  useEffect(() => {
    const selectedCamera = formik.values.ValidCameras[0]
    if (selectedCamera) {
      handleOnSelectCamera(
        true,
        selectedCamera?.id,
        selectedCamera?.colorCode,
        selectedCamera?.name,
        selectedCamera?.sample_frame
      )
    }
    // eslint-disable-next-line
  }, [activeStep])

  useEffect(() => {
    if (saveCameraChangesAlert) setOpenAlert(true)
  }, [saveCameraChangesAlert])
  const handleOpenAlert = (open: boolean) => {
    setOpenAlert(open)
  }
  const handleCloseAlert = () => {
    setOpenAlert(false)
    handleContinueCameraPoints()
  }

  return (
    <Card className={styles.entityCreatorCard}>
      <Typography variant="label" variantColor={2} gutterBottom>
        Floor Information
      </Typography>
      {/* Floors Art Board  */}
      <FloorsArtboard />
      {/* Valid Cameras mapped into CameraPreview Component, selected highlighted with a border with color code */}
      {/* Grid of mapped camera previews OR Sliding Data  if number of selected cameras < 7 use GRID*/}
      {formik.values.ValidCameras.length + formik.values.invalidCameras.length < 7 ? (
        <Grid container style={{ width: "100%" }}>
          {formik.values.ValidCameras.map((camera, index) => (
            <Grid
              key={index}
              item
              md={2}
              onClick={() => {
                if (activeStep === 3 && !cameraProperties.find((prop) => prop.camera === camera.id)) {
                  setErrorMessage("Camera must be located in order to map it")
                  setOpenErrorSnackbar(true)
                } else {
                  handleOnSelectCamera(true, camera.id, camera.colorCode, camera.name, camera.sample_frame)
                  activeStep === 3 && setToSelectCamera(camera)
                }
              }}
              style={{ width: "100%", boxSizing: "border-box" }}
            >
              <CameraPreview
                camera={camera?.id as number}
                isInvalid={activeStep === 3 && !cameraProperties.find((prop) => prop.camera === camera.id)}
                isDrawingSelection={true}
                selectedCamera={activeStep === 2 ? selectedCamera.cameraId : selectedMappingCamera.cameraId}
                checkMark={true}
                colorCode={camera.colorCode}
                margin={index === 0 || index === formik.values.ValidCameras.length - 1 ? false : true}
                isAdded={checkCameraStatus(camera.id!)}
              />
            </Grid>
          ))}
          {formik.values.invalidCameras.map((camera, index) => (
            <Grid
              key={index + "_invalid"}
              item
              md={2}
              style={{ width: "100%", boxSizing: "border-box" }}
              onClick={() => {
                setErrorMessage("Camera must have a valid sample frame to be located & mapped")
                setOpenErrorSnackbar(true)
              }}
            >
              <CameraPreview
                camera={camera?.id as number}
                isDrawingSelection={true}
                checkMark={true}
                colorCode={camera.colorCode}
                margin={index + 1 === formik.values.invalidCameras.length ? false : true}
                isAdded={checkCameraStatus(camera.id!)}
              />
            </Grid>
          ))}
        </Grid>
      ) : (
        // if number of valid cameras >= 5 use carousel
        <SlidingData
          type="floorsDrawingBoard"
          data={formik.values.ValidCameras}
          count={formik.values.ValidCameras?.length + formik.values.invalidCameras?.length}
          dots={false}
          infinite={false}
          slidesToScroll={1}
          slidesToShow={6}
        >
          {formik.values.ValidCameras.map((camera, index) => (
            <div
              key={index}
              onClick={() => {
                if (activeStep === 3 && !cameraProperties.find((prop) => prop.camera === camera.id)) {
                  setErrorMessage("Camera must be located in order to map it")
                  setOpenErrorSnackbar(true)
                } else {
                  handleOnSelectCamera(true, camera.id, camera.colorCode, camera.name, camera.sample_frame)
                  activeStep === 3 && setToSelectCamera(camera)
                }
              }}
              style={{ boxSizing: "border-box" }}
            >
              <CameraPreview
                camera={camera?.id as number}
                isDrawingSelection={true}
                selectedCamera={activeStep === 2 ? selectedCamera.cameraId : selectedMappingCamera.cameraId}
                checkMark={true}
                margin={true}
                colorCode={camera.colorCode}
                isAdded={checkCameraStatus(camera.id!)}
              />
            </div>
          ))}
          {formik.values.invalidCameras.map((camera, index) => (
            <div
              key={index + "_invalid"}
              style={{ boxSizing: "border-box" }}
              onClick={() => {
                setErrorMessage("Camera must have a valid sample frame to be located & mapped")
                setOpenErrorSnackbar(true)
              }}
            >
              <CameraPreview
                camera={camera?.id as number}
                isDrawingSelection={true}
                checkMark={true}
                margin={true}
                colorCode={camera.colorCode}
                isAdded={checkCameraStatus(camera.id!)}
              />
            </div>
          ))}
        </SlidingData>
      )}
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={openErrorSnackbar}
        autoHideDuration={1000}
        onClose={handleSnackbarClose}
        key={errorMessage}
      >
        <div>
          <Alert onClose={handleSnackbarClose} severity="warning">
            {errorMessage}
          </Alert>
        </div>
      </Snackbar>
      {/* open alert if not saved mapped points */}
      {saveCameraChangesAlert && (
        <SelectCameraAlert
          onClose={handleCloseAlert}
          open={openAlert}
          handleOpenAlert={handleOpenAlert}
          cameraId={toSelectCamera?.id!}
          colorCode={toSelectCamera?.colorCode}
          cameraSrc={toSelectCamera?.sample_frame!}
          cameraName={toSelectCamera?.name}
        />
      )}
    </Card>
  )
}
export default FloorCreator
