import React, { FC, ReactNode, useEffect, useRef, useState } from "react"
import Slider from "react-slick"

import ChevronLeftIcon from "@mui/icons-material/ChevronLeft"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import { Box, CircularProgress } from "@mui/material"
import useMediaQuery from "@mui/material/useMediaQuery"

import styles from "./SlidingData.module.scss"

interface Props {
  type: string
  data?: any[]
  count: number
  children?: ReactNode
  dots?: boolean
  infinite?: boolean
  slidesToShow?: number
  slidesToScroll?: number
  slidesPerRow?: number
  rows?: number
  nextPage?: () => void
  prevPage?: () => void
  prefetchNext?: (nextPage: number) => void
  loading?: boolean
  responsiveSlides?: any
}

const SlidingData: FC<Props> = ({
  count,
  dots,
  infinite,
  slidesToShow,
  slidesToScroll,
  slidesPerRow,
  rows,
  children,
  type,
  nextPage,
  prevPage,
  loading,
  prefetchNext,
  responsiveSlides,
}) => {
  const xSmallScreen = useMediaQuery("(max-width:1280px)")
  const xlargeScreen = useMediaQuery("(min-width:1900px)")
  const largeScreen = useMediaQuery("(max-width:1600px)")
  const mobileScreen = useMediaQuery("(max-width:800px)")
  const [currentSlide, setCurrentSlide] = useState(0)
  const sliderRef = useRef<any>(null)
  // calculate current page number (used for disable next button state)
  const currentPage =
    type === "footage" || type === "campaign" || type === "entities" || type === "camera"
      ? Math.ceil((currentSlide + slidesToShow!) / slidesToShow!)
      : (currentSlide + slidesToShow!) / slidesToShow!
  // calculate max number pages
  const maxPages =
    type === "footage" || type === "floors" || type === "entities"
      ? Math.ceil((count + 1) / slidesToShow!)
      : Math.ceil(count / slidesToShow!)

  const settings = {
    dots,
    speed: 200,
    infinite,
    rows: rows ? rows : 1,
    slidesPerRow: slidesPerRow ? slidesPerRow : 1,
    arrows: false,
    slidesToShow,
    swipeToSlide: false,
    draggable: false,
    slidesToScroll,
    afterChange: (current: number) => setCurrentSlide(current),
    responsive: responsiveSlides ? responsiveSlides : undefined,
  }

  const fetchData = () => {
    nextPage!()
    const toPrefetch = currentPage + type === "camera" ? 2 : 1
    prefetchNext!(toPrefetch)
  }

  useEffect(() => {
    if (type === "camera" || type === "footage") {
      fetchData()
    }
    //eslint-disable-next-line
  }, [])

  const handleNext = () => {
    if (type === "camera" || type === "footage" || type === "campaign" || type === "inprogressCampaign") {
      fetchData()
    }
    if (!loading) {
      sliderRef!?.current!?.slickNext()
    }
  }

  const handlePrev = () => {
    if (type === "camera" || type === "footage" || type === "campaign" || type === "inprogressCampaign") {
      prevPage!()
    }
    if (!loading) {
      sliderRef!?.current!?.slickPrev()
    }
  }
  // checking if this is last page or not
  const hasMore = () => {
    if (
      (type === "camera" && currentPage < maxPages) ||
      ((type === "footage" || type === "campaign" || type === "inprogressCampaign") && currentPage < maxPages) ||
      (type === "inprogressCampaign" && currentPage <= maxPages) ||
      (type === "floors" && count > 2 && count - currentSlide !== 2) ||
      (type === "insights" &&
        count > (xlargeScreen ? 4 : xSmallScreen ? 1 : 3) &&
        count - currentSlide !== (xlargeScreen ? 4 : xSmallScreen ? 1 : 3)) ||
      (type === "drawingBoard" && count > 4 && count - currentSlide !== 4) ||
      (type === "floorsDrawingBoard" && count > 6 && count - currentSlide !== 6) ||
      (type === "entities" && count > 6 && count - currentSlide !== 6)
    ) {
      return true
    } else {
      return false
    }
  }
  return (
    <Box
      className={styles.wrapper}
      sx={{
        right:
          currentPage === 1 && type === "footage"
            ? largeScreen && !xlargeScreen
              ? "30px"
              : "20px"
            : type === "camera"
            ? "-2px !important"
            : "auto",
        margin: type === "drawingBoard" || type === "floorsDrawingBoard" ? "0px 15px" : "",
        position: type === "insights" ? "static !important" : "relative",
        "& .slick-initialized .slick-slide": {
          display: type === "footage" ? "flex" : "block",
          height: "100%",
          justifyContent: "center",
        },
      }}
    >
      {/* prev */}
      <ChevronLeftIcon
        className={`${styles.arrow} ${currentPage <= 1 ? styles.disabled : ""}`}
        style={{
          left:
            type === "camera"
              ? "-15px"
              : type === "footage"
              ? currentSlide === 0
                ? xSmallScreen
                  ? "55px"
                  : slidesToShow === 2
                  ? "44px"
                  : "25px"
                : "-15px"
              : type === "floors" && count <= 2
              ? "-3px"
              : type === "entities"
              ? "-12px"
              : "-15px",
          bottom: type === "entities" ? "9px" : 0,
          height: type === "entities" ? "92%" : "100%",
          display:
            (type === "floors" && count <= 2) ||
            (type === "insights" && count <= (xlargeScreen ? 4 : mobileScreen ? 1 : 3)) ||
            (type === "entities" && count <= 6) ||
            ((type === "campaign" || type === "inprogressCampaign") && count <= 4) ||
            (type === "drawingBoard" && count <= 4) ||
            (type === "floorsDrawingBoard" && count <= 6)
              ? "none"
              : "block",
        }}
        onClick={!loading && currentPage > 1 ? handlePrev : undefined}
      />
      {/* main slider */}
      <Box
        sx={{
          margin:
            (type === "footage" && count && count > 2) ||
            (type === "camera" && count && count > 4) ||
            (type === "floors" && count && count > 2) ||
            (type === "insights" && count && count > (xlargeScreen ? 4 : mobileScreen ? 1 : 3)) ||
            (type === "drawingBoard" && count && count > 2) ||
            (type === "floorsDrawingBoard" && count && count > 2) ||
            ((type === "campaign" || type === "inprogressCampaign") && count && count > 4) ||
            type === "entities"
              ? "0px 24px"
              : type === " drawingBoard" && count > 4
              ? "0px 207px"
              : 0,
          // important to disable overflow from slider container [to make elements absolute]
          "overflow-x":
            type === "floors" ||
            type === "insights" ||
            type === "entities" ||
            type === "drawingBoard" ||
            type === "floorsDrawingBoard"
              ? "clip"
              : "visible",
          right:
            count && count >= 2 && type !== "campaign" && type !== "inprogressCampaign"
              ? 0
              : count && count > 1 && count < 5 && (type === "campaign" || type === "inprogressCampaign")
              ? 20
              : type === "floors" ||
                type === "insights" ||
                type === "entities" ||
                (type === "campaign" && count && count >= 5)
              ? 0
              : 0,
          "& .slick-track": {
            marginLeft:
              (count && count <= 5 && (type === "campaign" || type === "inprogressCampaign")) ||
              (count && count <= (xlargeScreen ? 4 : mobileScreen ? 1 : 3) && type === "insights")
                ? "0px !important"
                : type === "entities" && count && count < 6
                ? "unset"
                : "auto !important",
            width:
              count && count < 2 && type === "footage"
                ? type === "footage"
                  ? 222
                  : "auto !important"
                : (type === "floors" && count && count <= 2) ||
                  (type === "drawingBoard" && count && count <= 4) ||
                  (type === "floorsDrawingBoard" && count && count <= 6)
                ? "auto !important"
                : 291,
          },
          "& .slick-list": {
            overflow:
              type === "floors" ||
              type === "insights" ||
              type === "entities" ||
              type === "drawingBoard" ||
              type === "floorsDrawingBoard"
                ? "visible"
                : "clip",
          },
        }}
      >
        <Slider ref={sliderRef} {...settings}>
          {children}
        </Slider>
      </Box>

      {/* next */}
      {loading ? (
        <div
          className={styles.arrow}
          style={{
            display: "flex",
            alignItems: "center",
            right: type === "camera" ? "-10px" : "-14px",
            bottom: type === "entities" ? "9px" : 0,
            height: type === "entities" ? "92%" : "100%",
          }}
        >
          <CircularProgress size={24} />
        </div>
      ) : (
        <ChevronRightIcon
          className={`${styles.arrow} ${!hasMore() ? styles.disabled : ""}`}
          style={{
            right:
              type === "camera"
                ? "-10px"
                : type === "floors" && count <= 2
                ? "-3px"
                : type === "entities"
                ? "-11px"
                : type === "insights"
                ? mobileScreen
                  ? "-15px"
                  : "-5px"
                : "-15px",
            bottom: type === "entities" ? "9px" : 0,
            height: type === "entities" ? "92%" : "100%",
            display:
              (type === "floors" && count <= 2) ||
              (type === "insights" && count <= (xlargeScreen ? 4 : mobileScreen ? 1 : 3)) ||
              (type === "entities" && count <= 6) ||
              ((type === "campaign" || type === "inprogressCampaign") && count <= 4) ||
              (type === "drawingBoard" && count <= 4)
                ? "none"
                : "block",
          }}
          onClick={!loading && hasMore() ? handleNext : undefined}
        />
      )}
    </Box>
  )
}

export default SlidingData
