import React, { FC, useContext, Fragment } from "react"

import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline"
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline"
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord"
import HighlightOffIcon from "@mui/icons-material/HighlightOff"
import { LinearProgress } from "@mui/material"

import { Button, Typography } from "@synapse-analytics/synapse-ui"

import { IFootageData } from "../../../../types/Custom/Interfaces"
import { FootageContext } from "../../footageContext/FootageContext"

import styles from "./UploadStatus.module.scss"

interface Props {
  close: () => void
  retry: () => void
  cancel: () => void
}
const UploadStatus: FC<Props> = ({ close, retry, cancel }) => {
  const {
    uploadStatus,
    footageUploadProgress,
    originalFootageName,
    progress: processingProgress,
  } = useContext<IFootageData>(FootageContext)

  const uploadSuccess = uploadStatus !== 500 && uploadStatus !== 503 && uploadStatus !== 401

  return (
    <Fragment>
      {footageUploadProgress === 100 && processingProgress === 100 ? (
        <div
          className={styles.statusCard}
          style={{
            background: uploadSuccess ? "var(--positive-background-default)" : "var(--negative-background-default)",
            width: uploadSuccess ? 352 : "auto",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            {uploadSuccess ? (
              <CheckCircleOutlineIcon
                style={{
                  color: "var(--green-background-1)",
                  fontSize: 20,
                  marginRight: 12,
                  marginBottom: 8,
                  verticalAlign: "middle",
                }}
              />
            ) : (
              <ErrorOutlineIcon
                style={{
                  color: "var(--red-background-1)",
                  fontSize: 20,
                  marginRight: 12,
                  marginBottom: 8,
                  verticalAlign: "middle",
                }}
              />
            )}
            <Typography
              gutterBottom
              variant="h3-regular"
              color={uploadSuccess ? "positive" : "negative"}
              variantColor={2}
              style={{ wordWrap: "break-word", width: "100%" }}
            >
              {originalFootageName}
              {uploadSuccess ? " Uploaded" : " Upload Failed"}
            </Typography>
          </div>
          <Typography variant="p" variantColor={2} gutterBottom>
            {uploadSuccess
              ? " Your footage was successfully uploaded! You can access your footage from within your camera list"
              : "Something went wrong. Check your internet connection"}
          </Typography>
          {uploadSuccess ? (
            <Button size="small" variant="primary" onClick={close} style={{ width: "max-content", alignSelf: "end" }}>
              Got it
            </Button>
          ) : (
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
              <Button onClick={cancel} variant="secondary" size="small">
                Cancel
              </Button>
              <Button variant="dangerous" size="small" onClick={retry}>
                Retry
              </Button>
            </div>
          )}
        </div>
      ) : (
        <Fragment>
          <div className={styles.uploadAccordion}>
            <div>
              <Typography gutterBottom variant="h3-regular">
                Processing {originalFootageName}
              </Typography>
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Typography variant="a" style={{ alignSelf: "flex-start !important" }} variantColor={2}>
                {Math.round(processingProgress)}%
                <FiberManualRecordIcon style={{ width: "5px", height: "5px", marginRight: 8, marginLeft: 8 }} />
                {processingProgress === 100 ? "Processing Complete" : "Please Wait , Your Footage is processing....."}
              </Typography>

              <LinearProgress
                variant="determinate"
                value={processingProgress}
                style={{ width: "100%", marginTop: 12, borderRadius: "2px" }}
                color="primary"
                sx={{
                  "&.MuiLinearProgress-root": {
                    backgroundColor: "#B6BCE2",
                  },
                  "&.MuiLinearProgress-root > span": {
                    backgroundColor:
                      processingProgress === 100
                        ? "var(--green-background-1)"
                        : "linear-gradient(89.99deg, var(--indigo-background-1) 22.92%, var(--important-background-default-1) 100.76%)",
                  },
                }}
              />
            </div>
          </div>
          <div className={styles.uploadAccordion}>
            <div>
              <Typography
                variant="h3-regular"
                variantColor={processingProgress < 100 ? 2 : 1}
                gutterBottom
                style={{
                  marginBottom: 16,
                  opacity: processingProgress < 100 ? 0.33 : 1,
                }}
              >
                Uploading {originalFootageName} {processingProgress < 100 && " will start soon"}
              </Typography>
            </div>
            <div style={{ display: "flex", flexDirection: "column", opacity: processingProgress < 100 ? 0.33 : 1 }}>
              <Typography variant="a" variantColor={2} style={{ alignSelf: "flex-start !important" }}>
                {Math.round(footageUploadProgress)}%
                <FiberManualRecordIcon style={{ width: "5px", height: "5px", marginRight: 8, marginLeft: 8 }} />
                {processingProgress < 100
                  ? "Please Wait ,Upload will start once processing is done"
                  : footageUploadProgress < 100
                  ? "Please Wait , Your Footage is uploading..... "
                  : "Upload Complete"}
              </Typography>

              <LinearProgress
                variant="determinate"
                value={footageUploadProgress}
                style={{
                  width: "100%",
                  marginTop: 12,
                  borderRadius: "2px",
                  background: `${footageUploadProgress === 100 && "var(--green-background-1)"}`,
                }}
                sx={{
                  "	.MuiLinearProgress-barColorPrimary":
                    footageUploadProgress === 100
                      ? "var(--green-background-1)"
                      : "linear-gradient(89.99deg, var(--indigo-background-1) 22.92%, var(--important-background-default-1) 100.76%)",
                }}
              />
              <div style={{ alignSelf: "center", marginTop: 21 }}>
                <Button
                  size="small"
                  style={{ marginRight: 12 }}
                  onClick={cancel}
                  disabled={processingProgress < 100 ? true : false}
                >
                  Cancel
                  <HighlightOffIcon>Pause</HighlightOffIcon>
                </Button>
              </div>
            </div>
          </div>
        </Fragment>
      )}
    </Fragment>
  )
}
export default UploadStatus
