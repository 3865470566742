import React, { FC } from "react"

import { Typography } from "@mui/material"

import BlacklistCarIcon from "../assets/eventsIcons/blacklistCar.svg?react"
import BlacklistPersonIcon from "../assets/eventsIcons/blacklistPerson.svg?react"
import FireIcon from "../assets/eventsIcons/fire.svg?react"
import IntrusionIcon from "../assets/eventsIcons/intrusion.svg?react"
import MotionIcon from "../assets/eventsIcons/motion.svg?react"
import ViolenceIcon from "../assets/eventsIcons/violence.svg?react"

import styles from "./EventTypeChip.module.scss"

// events array contain the styling information of each event type
const events = [
  {
    event: "Fire",
    color: "var(--greyScale-background-3)",
    background: "var(--navy-background-1)",
    borderColor: "var(--neutral-border-disabled)",
    icon: <FireIcon width={20} height={20} />,
  },
  {
    event: "Violence",
    color: "var(--greyScale-background-3)",
    background: "var(--red-background-1)",
    borderColor: "var(--neutral-border-disabled)",
    icon: <ViolenceIcon width={20} height={20} />,
  },
  {
    event: "Motion",
    color: "var(--neutral-text-enabled)",
    background: "var(--warning-background-default)",
    borderColor: "var(--neutral-border-disabled)",
    icon: <MotionIcon width={24} height={20} />,
  },
  {
    event: "Intrusion",
    color: "var(--neutral-text-enabled)",
    background: "var(--negative-background-disabled)",
    borderColor: "var(--neutral-border-disabled)",
    icon: <IntrusionIcon width={20} height={20} />,
  },
  {
    event: "Blacklisted Car",
    color: "var(--greyScale-background-3)",
    background: "var(--neutral-text-enabled)",
    borderColor: "var(--neutral-border-disabled)",
    icon: <BlacklistCarIcon width={23} height={23} />,
  },
  {
    event: "Blacklisted Person",
    color: "var(--greyScale-background-3)",
    background: "var(--grey-background-3)",
    borderColor: "var(--neutral-border-disabled)",
    icon: <BlacklistPersonIcon width={20} height={20} />,
  },
]

// Event Type :: Integer Representation
// FIRE             = 0
// VIOLENCE         = 1
// MOTION           = 2
// INTRUSION        = 3
// BLACKLIST_CAR    = 4
// BLACKLIST_PERSON = 5
interface Props {
  type: number
  liveAlert?: boolean
  isAnalytics?: boolean
  eventCount?: number
}
const EventTypeChip: FC<Props> = ({ type, liveAlert, isAnalytics, eventCount }) => {
  return (
    <div
      className={`${styles.chipWrapper} ${liveAlert && styles.blink}`}
      style={{
        background: events[type].background,
        padding: `${liveAlert ? 8 : 4}px 12px`,
        border: `1px solid ${events[type].borderColor}`,
        marginBottom: liveAlert ? "8px" : "0px",
        width: !isAnalytics ? "max-content" : "auto",
      }}
    >
      <div className={styles.eventDescriptionWrapper}>
        <div>{events[type].icon}</div>
        {/* FIXME: use sui typography when white color variant available */}
        <Typography
          className={styles.eventTitle}
          sx={{
            color: events[type].color,
          }}
        >
          {events[type].event}
        </Typography>
      </div>
      {isAnalytics && (
        <Typography
          sx={{
            color: events[type].color,
          }}
        >
          {eventCount}
        </Typography>
      )}
    </div>
  )
}
export default EventTypeChip
