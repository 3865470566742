import { LegacyRef, forwardRef } from "react"

interface Props {
  handleAdornmentClick: () => void
  isOpen: boolean
}
const ArrowAdornment = forwardRef((props: Props, ref: LegacyRef<HTMLSpanElement>) => {
  const { handleAdornmentClick, isOpen } = props
  return (
    <span ref={ref} onClick={handleAdornmentClick} style={{ pointerEvents: "none" }}>
      {!isOpen ? (
        <svg fill="var(--greyScale-text-2)" width="12" height="8" viewBox="0 0 12 8" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M10.59 0.294922L6 4.87492L1.41 0.294922L0 1.70492L6 7.70492L12 1.70492L10.59 0.294922Z"
            className="adornment"
          />
        </svg>
      ) : (
        <svg fill="var(--greyScale-text-2)" width="12" height="8" viewBox="0 0 12 8" xmlns="http://www.w3.org/2000/svg">
          <path d="M6 0.294922L0 6.29492L1.41 7.70492L6 3.12492L10.59 7.70492L12 6.29492L6 0.294922Z" />
        </svg>
      )}
    </span>
  )
})

export default ArrowAdornment
