import React, { FC, useState, useEffect, Fragment } from "react"
import { useQueryClient } from "react-query"

import {
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material"
import Pagination from "@mui/material/Pagination"

import { Typography, Tag } from "@synapse-analytics/synapse-ui"
import moment from "moment"

import NoLogs from "../../../assets/NoHistoryLogs.svg"
import { PaginatedCarsLogs } from "../../../types/Custom/Interfaces"
import { definitions } from "../../../types/Generated/apiTypes"
import CarsBrands from "../components/CarBrandIcon"
import CarLicensePlate from "../components/CarLicensePlate"
import CarsDetails from "../components/CarsDetails"
import CarImagePlaceholder from "../components/assets/CarImagePlaceholder.svg?react"

import styles from "./CarsLogsTable.module.scss"

type CarLogsResults = definitions["CarCounterLogRetrieve"]

interface Data {
  license_plate_data: string
  state: number
  brand: string
  car_model: string
  entrance: string
  car_color: string
  n_visits: number
  car_type: string
  loi_memberships: string
  timestamp: string
  image: string
}

interface HeadCell {
  id: keyof Data
  label: string | React.ReactElement
}

interface Props {
  isFetching: boolean
  handlePageNumber: (pageNumber: number) => void
  data: PaginatedCarsLogs
  pageSize: number
  pageNumber: number
}

const CarsLogsTable: FC<Props> = ({ data, handlePageNumber, isFetching, pageSize, pageNumber }) => {
  const queryClient = useQueryClient()
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [tableData, setTableData] = useState<PaginatedCarsLogs>()
  const [selectedCarIndex, setSelectedCarIndex] = useState<number>()

  useEffect(() => {
    if (data && !isFetching) {
      setTableData(data)
    }
  }, [data, isFetching])

  const handleClose = () => {
    setIsDialogOpen(false)
    setSelectedCarIndex(undefined)
  }

  const headCells: HeadCell[] = [
    { id: "license_plate_data", label: "License Plate" },
    { id: "state", label: "IN/OUT" },
    { id: "brand", label: " Car Brand" },
    { id: "car_model", label: "Car Model" },
    { id: "entrance", label: "Entrance" },
    { id: "car_color", label: "Color" },
    { id: "n_visits", label: "No. of visits" },
    { id: "car_type", label: "Body Type" },
    { id: "loi_memberships", label: "Matchlist" },
    { id: "timestamp", label: "Timestamp" },
    { id: "image", label: "Image" },
  ]

  const handleRefetchCars = (number: number) => {
    handlePageNumber(number)
    setTimeout(async () => {
      await queryClient.invalidateQueries("fetchCarLogsListPaginated")
    }, 300)
  }

  const handleFetchDetailsPage = (direction?: "forward" | "backward") => {
    if (direction === "forward") {
      handleRefetchCars(pageNumber + 1)
      setSelectedCarIndex(0)
    } else {
      handleRefetchCars(pageNumber - 1)
      setSelectedCarIndex(9)
    }
  }

  useEffect(() => {
    handlePageNumber(1)
    // eslint-disable-next-line
  }, [])

  return (
    <Fragment>
      <div className={styles.root}>
        {isFetching ? (
          <div className={styles.loadingWrapper}>
            <CircularProgress size={40} className={styles.loading} />
          </div>
        ) : tableData && tableData?.results?.length > 0 ? (
          <TableContainer component={Paper} style={{ boxShadow: "none" }}>
            <Table>
              <TableHead>
                <TableRow classes={{ root: styles.rowRoot }}>
                  {headCells.map((headCell) => (
                    <TableCell
                      classes={{
                        head: styles.head,
                        body: styles.body,
                      }}
                      key={headCell.id}
                      align="left"
                      className={styles.tableHeader}
                    >
                      <Fragment>{headCell.label}</Fragment>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {tableData?.results.map((car: CarLogsResults, index) => {
                  return (
                    <TableRow
                      classes={{
                        root: styles.rowRoot,
                      }}
                      key={index}
                      onClick={() => {
                        setSelectedCarIndex(index)
                        setIsDialogOpen(true)
                      }}
                    >
                      {/* License Plate */}
                      <TableCell
                        classes={{
                          head: styles.head,
                          body: styles.body,
                          root: styles.cellRoot,
                        }}
                        className={styles.licensePlate}
                        component="th"
                        scope="row"
                        padding="none"
                        align="left"
                      >
                        <CarLicensePlate
                          key={car?.license_plate_data}
                          licensePlateData={car?.license_plate_data}
                          type={car?.license_plate_type}
                        />
                      </TableCell>
                      {/* IN/OUT */}
                      <TableCell
                        classes={{
                          head: styles.head,
                          body: styles.body,
                          root: styles.cellRoot,
                        }}
                        component="th"
                        scope="row"
                        padding="none"
                        align="left"
                      >
                        <Typography
                          variant="p"
                          color={car?.state! === 0 ? "positive" : "negative"}
                          variantColor={2}
                          textTransform="capitalize"
                        >
                          {car?.state! === 0 ? "in" : "out"}
                        </Typography>
                      </TableCell>
                      {/* Car Brand */}
                      <TableCell
                        classes={{
                          head: styles.head,
                          body: styles.body,
                          root: styles.cellRoot,
                        }}
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                        align="left"
                      >
                        {car?.brand && <CarsBrands carBrand={car?.brand} />}
                        {car?.brand ? car?.brand.charAt(0).toUpperCase() + car?.brand.slice(1) : "-"}
                      </TableCell>
                      {/* Car Model */}
                      <TableCell
                        classes={{
                          head: styles.head,
                          body: styles.body,
                          root: styles.cellRoot,
                        }}
                        align="left"
                      >
                        {car?.car_model ? car?.car_model.charAt(0).toUpperCase() + car?.car_model.slice(1) : "No data"}
                      </TableCell>
                      {/* Entrance */}
                      <TableCell
                        classes={{
                          head: styles.head,
                          body: styles.body,
                          root: styles.cellRoot,
                        }}
                        align="left"
                      >
                        {car?.entity!}
                      </TableCell>
                      {/* Car Color */}
                      <TableCell
                        classes={{
                          head: styles.head,
                          body: styles.body,
                          root: styles.cellRoot,
                        }}
                        align="left"
                        style={{ display: "flex" }}
                        className={styles.customCell}
                      >
                        <div
                          title={car?.car_color ? car?.car_color : "Car color"}
                          className={styles.carColor}
                          style={{
                            background:
                              car?.car_color && car?.car_color?.includes("-")
                                ? `linear-gradient(90deg, ${car?.car_color?.split("-")[0]} 7.59%,${
                                    car?.car_color?.split("-")[1]
                                  } 99.26%)`
                                : car?.car_color,
                            border: car?.car_color === "White" ? "1px solid var(--neutral-text-enabled)" : "",
                          }}
                        ></div>
                        <p>{car?.car_color}</p>
                      </TableCell>
                      {/* No. of visits */}
                      <TableCell
                        classes={{
                          head: styles.head,
                          body: styles.body,
                          root: styles.cellRoot,
                        }}
                        align="left"
                      >
                        {car?.n_visits!}
                      </TableCell>
                      {/* Car Body Type */}
                      <TableCell
                        classes={{
                          head: styles.head,
                          body: styles.body,
                          root: styles.cellRoot,
                        }}
                        align="left"
                        className={styles.customCell}
                      >
                        <p>{car?.car_type ? car?.car_type : "Car"}</p>
                      </TableCell>

                      {/* Matchlist */}
                      <TableCell
                        classes={{
                          head: styles.head,
                          body: styles.body,
                          root: styles.cellRoot,
                        }}
                        align="left"
                      >
                        <Tag variant="default" size="small">
                          {car?.loi_memberships! ? car?.loi_memberships! : "No list"}
                        </Tag>
                      </TableCell>
                      {/* Timestamp */}
                      <TableCell
                        classes={{
                          head: styles.head,
                          body: styles.body,
                          root: styles.cellRoot,
                        }}
                        align="left"
                      >
                        {moment(car?.timestamp!).format("YYYY-MM-DD hh:mm A")}
                      </TableCell>
                      {/* Image */}
                      <TableCell
                        classes={{
                          head: styles.head,
                          body: styles.body,
                          root: styles.cellRoot,
                        }}
                        align="left"
                      >
                        {car?.car_image ? (
                          <img src={car?.car_image} alt="CarImage" className={styles.image} />
                        ) : (
                          <div className={styles.noCarImage}>
                            <CarImagePlaceholder />
                          </div>
                        )}
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <div className={styles.noLogs}>
            <img src={NoLogs} alt="No Car logs" className={styles.noLogsImage} />
            <div>
              <Typography variant="h2-regular" variantColor={2} gutterBottom align="center">
                No logs yet
              </Typography>
              <Typography variant="p" variantColor={2} display="block" align="center">
                When cameras are active,
              </Typography>
              <Typography variant="p" variantColor={2} display="block" align="center">
                logs will appear accordingly in the history
              </Typography>
            </div>
          </div>
        )}

        {/* Table Pagination */}
        {!isFetching && data && data?.results?.length > 0 && (
          <Pagination
            count={Math.ceil(data?.count! / pageSize)}
            classes={{ root: styles.rootPagination }}
            page={pageNumber}
            siblingCount={2}
            color="standard"
            shape="rounded"
            onChange={(e, value) => {
              handleRefetchCars(value)
            }}
          />
        )}
      </div>

      {/* car details */}
      {isDialogOpen && selectedCarIndex !== undefined && (
        <CarsDetails
          handleClose={handleClose}
          open={isDialogOpen}
          car={data?.results[selectedCarIndex]}
          logsCount={data?.results?.length}
          selectedIndex={selectedCarIndex}
          handleChangeSelectedIndex={(index: number) => setSelectedCarIndex(index)}
          handleFetchDetailsPage={handleFetchDetailsPage}
          hasMore={!!data?.next}
          hasPrevious={!!data?.previous}
          isLoadingPage={isFetching}
        />
      )}
    </Fragment>
  )
}

export default CarsLogsTable
