import React, { FC } from "react"

import SlidingData from "../../../../components/SlidingData"
import { definitions } from "../../../../types/Generated/apiTypes"
import DragDropFloor from "./DragDropFloor"
import FloorVersionCard from "./FloorVersionCard"

type FloorPlanVersionList = definitions["FloorPlanVersionList"]

interface Props {
  floorVersions: FloorPlanVersionList[]
}

const FloorVersions: FC<Props> = ({ floorVersions }) => {
  return (
    <SlidingData
      type="floors"
      data={floorVersions}
      count={floorVersions?.length}
      dots={false}
      infinite={false}
      slidesToScroll={1}
      slidesToShow={3}
    >
      <DragDropFloor edit textPlaceholder="Add New Version" />
      {floorVersions &&
        floorVersions?.length > 0 &&
        floorVersions?.map((version) => <FloorVersionCard version={version} key={version?.id!} />)}
    </SlidingData>
  )
}

export default FloorVersions
