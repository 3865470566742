import React, { FC, useState, useEffect } from "react"
import { useQuery } from "react-query"

import Snow from "@mui/icons-material/AcUnit"
import Cloudy from "@mui/icons-material/FilterDramaOutlined"
import Sunny from "@mui/icons-material/WbSunnyOutlined"
import { Grid, LinearProgress } from "@mui/material"

import { Typography, Chip } from "@synapse-analytics/synapse-ui"
import { AxiosError } from "axios"
import moment from "moment"

import { VisionAPI } from "../../../API/VisionAPI"
import Clear from "../../../assets/clear.png"
import Drizzle from "../../../assets/drizzle.png"
import Fog from "../../../assets/fog.png"
import Separator from "../../../assets/line.svg"
import noWeather from "../../../assets/noWeather.png"
import Rain from "../../../assets/rain.png"
// Weather icons
import StarIcon from "../../../assets/star.svg"
import Thunder from "../../../assets/thunder.png"
// Weather icons
import { definitions } from "../../../types/Generated/apiTypes"
import { calculateLabelPositions, weatherCodes } from "../campaignUtils"

import styles from "./CampaignGoalProgress.module.scss"

type CampaignDetail = definitions["CampaignDetail"]
type WeatherCodes = definitions["Weather"]

interface Props {
  campaign: CampaignDetail
  title: string
}

type days = {
  day: string
  count: number
  weatherCondition?: string
}

type calculatedDays = {
  day: string
  count: number
  countPercentage: number
  countPosition: number
  weatherCondition?: string
  exceed: boolean
  order: number
}

const CampaignGoalProgress: FC<Props> = ({ campaign, title }) => {
  const campaignAchievedGoals = campaign?.goal_achieved_per_day?.length
  const [days, setDays] = useState<days[]>([])
  const [filteredDays, setFilteredDays] = useState<calculatedDays[]>([])
  const [weatherData, setWeatherData] = useState<WeatherCodes[]>([])
  const [maxGoal, setMaxGoal] = useState(0)
  const [totalCountsPercentage, setTotalCountsPercentage] = useState(0)
  const [dividerHeight, setDividerHeight] = useState(375)

  // fetch weather codes for single campaign
  const { data: weatherSingleCampaign } = useQuery<WeatherCodes[], AxiosError>(
    ["fetchWeatherSingleCampaign", campaign?.start_date, campaign?.end_date],
    ({ queryKey }) => VisionAPI.fetchWeatherCodes({ from_date: queryKey[1] as string, to_date: queryKey[2] as string }),
    {
      enabled: title !== "Upcoming Campaigns",
    }
  )

  useEffect(() => {
    if (weatherSingleCampaign && weatherSingleCampaign?.length > 0) {
      setWeatherData(weatherSingleCampaign)
    }
  }, [weatherSingleCampaign, campaign])

  useEffect(() => {
    if (campaign) {
      const campaignDays = campaign?.goal_achieved_per_day
      let convertToDays
      if (title !== "Upcoming Campaigns") {
        convertToDays = campaignDays?.slice(0, 7)?.map((res, i) => {
          const filterWeatherDays = weatherData?.some((data) => data?.date?.includes(res?.day))
          const getWeatherCode = filterWeatherDays ? weatherCodes(weatherData[i]?.weather_data?.weathercode) : "none"
          return {
            day: moment(res?.day).format("ddd"),
            count: res?.count,
            weatherCondition: getWeatherCode,
          }
        })
      } else {
        convertToDays = campaignDays?.slice(0, 7)?.map((res) => {
          return { day: moment(res?.day).format("ddd"), count: res?.count }
        })
      }

      setDays(convertToDays!)
      let goalCounts = convertToDays?.map((goal) => goal?.count)
      let totalGoals = convertToDays?.reduce((acc, currentValue) => {
        return acc + currentValue.count
      }, 0)
      let maxGoal = Math.max(...goalCounts!)
      setMaxGoal(maxGoal)
      let totalGoalsPercentage = Math.round((totalGoals! / campaign!?.goal) * 100)
      setTotalCountsPercentage(totalGoalsPercentage)
    }
  }, [campaign, weatherData, title])

  useEffect(() => {
    setFilteredDays(calculateLabelPositions(days, campaign)!)
  }, [days, campaign])

  // render weather codes of each campaign
  const renderWeatherCodes = (codeName: string) => {
    if (codeName === "sunny") {
      return <Sunny className={styles.weather} />
    } else if (codeName === "clear") {
      return <img src={Clear} alt="Clear" className={styles.weatherImg} />
    } else if (codeName === "cloud") {
      return <Cloudy className={styles.weatherImg} />
    } else if (codeName === "fog") {
      return <img src={Fog} alt="Fog" className={styles.weatherImg} />
    } else if (codeName === "drizzle") {
      return <img src={Drizzle} alt="Drizzle" className={styles.weatherImg} />
    } else if (codeName === "rain") {
      return <img src={Rain} alt="Rain" className={styles.weatherImg} />
    } else if (codeName === "snow") {
      return <Snow className={styles.weather} />
    } else if (codeName === "thunderStorm") {
      return <img src={Thunder} alt="Thunder" className={styles.weatherImg} />
    } else {
      return <img src={noWeather} alt="noWeather" className={styles.weatherImg} />
    }
  }

  useEffect(() => {
    let height = 375
    if (campaignAchievedGoals) {
      height =
        campaignAchievedGoals === 0
          ? 98
          : campaignAchievedGoals <= 2
          ? 150
          : campaignAchievedGoals <= 3
          ? 180
          : campaignAchievedGoals <= 5
          ? 260
          : campaignAchievedGoals <= 2
          ? 170
          : 375
    }

    setDividerHeight(height)
  }, [campaignAchievedGoals])
  return (
    <div className={styles.progressWrapper}>
      <div className={styles.statusWrapper}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Typography variant="a" variantColor={2} className={styles.eventStatusTitle}>
              Event Status Last week
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} className={styles.statusProgress}>
            <Chip size="small" className={styles.status}>
              {title === "Upcoming Campaigns" ? "Upcoming" : title === "Past Campaigns" ? "Past" : "In Progress"}
            </Chip>
          </Grid>
        </Grid>
      </div>

      <div className={styles.eventGoal}>
        <Typography variant="span" className={styles.goalTitle}>
          Goal
        </Typography>
        <div className={styles.progressContent}>
          <LinearProgress
            classes={{
              root: styles.linearProgressRoot,
              bar: styles.bar,
              colorPrimary: styles.colorPrimary,
            }}
            variant="determinate"
            value={totalCountsPercentage >= 100 ? 100 : totalCountsPercentage}
          />

          {totalCountsPercentage >= 100 && <div className={styles.exceed}>Goal exceed {totalCountsPercentage}% 🎉</div>}

          {/* Divider */}
          <img
            src={Separator}
            alt="divider"
            className={styles.divider}
            style={{ left: "20%", height: dividerHeight }}
          />
          <span className={styles.countPercentageText} style={{ left: "9%" }}>
            25%
          </span>
          <img src={Separator} alt="divider" className={styles.divider} style={{ left: "44%" }} />
          <span className={styles.countPercentageText} style={{ left: "23%", height: dividerHeight }}>
            50%
          </span>
          <img src={Separator} alt="divider" className={styles.divider} style={{ left: "67%" }} />
          <span className={styles.countPercentageText} style={{ left: "42%", height: dividerHeight }}>
            75%
          </span>

          {totalCountsPercentage <= 75 && (
            <Typography variant="span" className={styles.reach}>
              Almost there!
            </Typography>
          )}
        </div>

        <div className={styles.daysWrapper}>
          {filteredDays &&
            filteredDays?.map((res, i) => {
              let count = res?.count
              let countPercentage = res?.countPercentage
              let countPosition = res?.countPosition
              let weatherCondition = res?.weatherCondition!
              let order = res?.order!
              let exceed = res?.exceed!
              let maxCount = Math.max(count)

              return (
                <div key={i} className={styles.daysContent}>
                  <div className={styles.days}>
                    {title !== "Upcoming Campaigns" && renderWeatherCodes(weatherCondition)}
                    <Typography variant="span">{res?.day}</Typography>
                  </div>

                  <div className={styles.contentWrapper}>
                    <LinearProgress
                      classes={{
                        root: styles.smallerLPRoot,
                        bar: styles.smallerLPsBar,
                        colorPrimary: styles.smallerLPColorPrimary,
                      }}
                      variant="determinate"
                      value={100}
                      sx={{
                        "&.MuiLinearProgress-root": {
                          marginLeft: !exceed || countPercentage === 0 ? "unset" : "auto",
                          left: exceed || countPercentage === 0 ? 0 : `${countPosition}%`,
                        },
                        width: {
                          xl:
                            exceed && order === 0
                              ? "100%"
                              : exceed && order !== 0 && countPercentage !== 0
                              ? "20%"
                              : countPercentage === 0 || (countPercentage! <= 7 && maxGoal !== maxCount)
                              ? "7%"
                              : maxGoal === maxCount && maxGoal! > 0 && countPercentage !== 0
                              ? "13%"
                              : `${countPercentage!}%`,
                          xs:
                            exceed && order === 0
                              ? "100%"
                              : exceed && order !== 0 && countPercentage !== 0
                              ? "20%"
                              : countPercentage === 0 || (countPercentage! <= 7 && maxGoal !== maxCount)
                              ? "10%"
                              : maxGoal === maxCount && maxGoal! > 0 && countPercentage !== 0
                              ? "17%"
                              : `${countPercentage!}%`,
                        },
                      }}
                    />
                    <Typography
                      variant="span"
                      className={styles.countPercentage}
                      style={{
                        left:
                          order === 0 || countPercentage === 0
                            ? 0
                            : exceed && order !== 0
                            ? "80%"
                            : `${countPosition}%`,
                      }}
                    >
                      {`${countPercentage}%`}

                      {maxGoal === maxCount && maxGoal > 0 && countPercentage !== 0 && (
                        <img src={StarIcon} alt="StarIcon" className={styles.starIcon} />
                      )}
                    </Typography>
                  </div>
                </div>
              )
            })}
        </div>
      </div>
    </div>
  )
}

export default CampaignGoalProgress
