import React, { FC, useEffect, useState, Fragment } from "react"
import { useQuery } from "react-query"

import { Grid, CircularProgress } from "@mui/material"

import { NotificationUtils } from "@synapse-analytics/synapse-ui"

import { VisionAPI } from "../../../API/VisionAPI"
import Placeholder from "../../../components/Placeholder"
import { definitions } from "../../../types/Generated/apiTypes"
import FloorsMCPLogs from "./FloorsMCPLogs"

import styles from "../styles/MCP.module.scss"

type FloorPlan = definitions["FloorPlanVersionList"]

interface Props {
  selectedDate: Date
  selectedFloor: number
  loadingSetter: (loadingState: boolean) => void
  disableSetter: (disableState: boolean) => void
}
const FloorsMCPContainer: FC<Props> = ({ selectedDate, selectedFloor, loadingSetter, disableSetter }) => {
  const [interval, setInterval] = useState<string>("")

  // Fetch floor plan versions
  const {
    data: floorPlanVersions,
    isFetching: loadingFloorVersions,
    refetch: refetchVersions,
  } = useQuery<FloorPlan[]>(
    ["fetchFloorPlanVersions", selectedFloor],
    ({ queryKey }) => VisionAPI.fetchFloorPlanVersions({ floor_id: queryKey[1] as number }),
    {
      onSuccess: (data) => {
        if ((data && !data[0]?.image) || !data) {
          NotificationUtils.toast("Floor Plan Not Found", {
            snackBarVariant: "warning",
          })
          disableSetter(true)
        } else {
          disableSetter(false)
        }
      },
      enabled: !!selectedFloor,
    }
  )

  // setting interval key to make sure component is re rendered on every date change
  useEffect(() => {
    if (selectedDate) {
      const intervalString = `start_date=${selectedDate.toISOString()}`
      setInterval(intervalString)
    }
  }, [selectedDate])
  // set loading state to control parent's MCP component's floor select loading state
  useEffect(() => {
    if (loadingFloorVersions === true) {
      loadingSetter(true)
    } else {
      loadingSetter(false)
    }
    //eslint-disable-next-line
  }, [loadingFloorVersions])

  // refetch floor plan on each floor id select
  useEffect(() => {
    const timeout = setTimeout(() => {
      refetchVersions()
    }, 300)
    return clearTimeout(timeout)
  }, [selectedFloor, refetchVersions])
  return (
    <Fragment>
      {loadingFloorVersions || loadingFloorVersions ? (
        <Grid
          container={true}
          alignItems="center"
          direction="row"
          justifyContent="center"
          className={styles.loadingWrapper}
        >
          <Grid item>
            <CircularProgress
              size={70}
              className={styles.loading}
              style={{ color: "var(--important-background-default-1)" }}
            />
          </Grid>
        </Grid>
      ) : floorPlanVersions && floorPlanVersions[0]?.image && floorPlanVersions[0].id !== undefined ? (
        <FloorsMCPLogs
          selectedDate={selectedDate}
          floorPlanVersions={floorPlanVersions}
          floorId={selectedFloor}
          loadingSetter={loadingSetter}
          key={interval}
        />
      ) : (
        <Placeholder selectionType="history" isScreenPlaceholder />
      )}
    </Fragment>
  )
}
export default FloorsMCPContainer
