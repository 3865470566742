import React, { FC, useEffect, useState, Fragment } from "react"
import { Route, Routes, useNavigate, useLocation } from "react-router-dom"

import HistoryIcon from "@mui/icons-material/History"
import PlayCircleFilledIcon from "@mui/icons-material/PlayCircleFilled"

import ButtonSwitch from "./Buttons/ButtonSwitch"

interface Props {
  pages: string[]
  firstPage: React.ReactElement
  firstPath: string
  pathsParent: string
  secondPage?: React.ReactElement
  secondPath?: string
  thirdPage?: React.ReactElement
  thirdPath?: string
  usePageIndex?: boolean
  getPageIndex?: (index: number) => void
  liveHistory?: boolean
  setLiveHistoryStatus?: (page: string) => void
}

const TogglePages: FC<Props> = ({
  pages,
  firstPage,
  firstPath,
  pathsParent,
  secondPage,
  secondPath,
  thirdPage,
  thirdPath,
  getPageIndex,
  usePageIndex,
  liveHistory,
  setLiveHistoryStatus,
}) => {
  const [activePage, setActivePage] = useState(0)
  const navigate = useNavigate()
  const { pathname } = useLocation()
  useEffect(() => {
    setActivePage(0)
  }, [])
  useEffect(() => {
    const firstPageMatch = pathname.includes(`${firstPath}`)
    const secondPageMatch = pathname.includes(`${secondPath}`)
    if (firstPageMatch) {
      setActivePage(0)
      if (usePageIndex) getPageIndex!(0)
    } else if (secondPageMatch) {
      setActivePage(1)
      if (usePageIndex) getPageIndex!(1)
    } else {
      setActivePage(2)
      if (usePageIndex) getPageIndex!(2)
    }
  }, [firstPath, secondPath, pathname, usePageIndex, getPageIndex])

  const handleActivePage = (i: number) => {
    setActivePage(i)
  }

  const handleSelectButton = (i: number) => {
    if (i === 0) {
      navigate(`/${pathsParent}/${firstPath}`, { replace: true })
      if (liveHistory && setLiveHistoryStatus) {
        setLiveHistoryStatus("live")
      }
    } else if (i === 1) {
      navigate(`/${pathsParent}/${secondPath}`, { replace: true })
      if (liveHistory && setLiveHistoryStatus) {
        setLiveHistoryStatus("history")
      }
    } else {
      navigate(`/${thirdPath}`)
    }
    handleActivePage(i)

    if (usePageIndex) getPageIndex!(i)
  }

  return (
    <Fragment>
      <div style={{ width: "100%" }}>
        <ButtonSwitch
          liveHistory={liveHistory!}
          activePage={activePage}
          pages={pages}
          handleSelectButton={handleSelectButton}
          pagesIcons={[<PlayCircleFilledIcon />, <HistoryIcon />]}
        />
      </div>

      {
        <Routes>
          <Route path={`/${firstPath}`} element={firstPage} />

          <Route path={`/${secondPath}`} element={secondPage} />

          <Route path={`/${thirdPath}`} element={thirdPage} />
        </Routes>
      }
    </Fragment>
  )
}

export default TogglePages
