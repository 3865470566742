import React, { FC, useContext } from "react"

import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline"
import { Dialog, DialogActions, DialogContent } from "@mui/material"

import { Button, Typography } from "@synapse-analytics/synapse-ui"

import { FloorsContext } from "../../floorsContext/FloorsContext"

import styles from "./SelectCameraAlert.module.scss"

interface Props {
  cameraId: number
  colorCode: string
  cameraSrc: string
  cameraName: string
  open: boolean
  onClose: () => void
  handleOpenAlert: (bool: boolean) => void
}

const SelectCameraAlert: FC<Props> = ({
  cameraId,
  open,
  onClose,
  handleOpenAlert,
  colorCode,
  cameraSrc,
  cameraName,
}) => {
  const { handleDismissCameraPoints } = useContext(FloorsContext)
  return (
    <Dialog
      open={open}
      maxWidth="md"
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent className={styles.wrapper} style={{ paddingTop: 32, overflow: "visible" }}>
        <ErrorOutlineIcon className={styles.errorIcon} />
        <Typography variant="h2-bold" className={styles.title}>
          Are you sure?
        </Typography>
        <Typography variant="p" className={styles.warningDescription} align="center">
          You are heading to a different camera, without having saved your existing camera
        </Typography>
        <DialogActions className={styles.actionButtons}>
          <Button onClick={onClose} variant="primary" className={styles.cancelButton}>
            Cancel
          </Button>
          <Button
            onClick={() => {
              handleDismissCameraPoints(cameraId, colorCode, cameraSrc, cameraName)
              handleOpenAlert(false)
            }}
            variant="dangerous"
          >
            Yes Head to {cameraName}
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  )
}

export default SelectCameraAlert
