import { FC, useState, useEffect, Fragment } from "react"
import { CircularProgressbarWithChildren, buildStyles } from "react-circular-progressbar"

import { Typography } from "@synapse-analytics/synapse-ui"
import moment from "moment"

import GraphLoadingState from "./GraphLoadingState"

import styles from "./TotalDownTime.module.scss"

interface Props {
  totalDownTime: Duration
  totalDuration: Duration
  isLoading?: boolean
  hasCardTitle?: boolean
}
const TotalDownTime: FC<Props> = ({ totalDuration, totalDownTime, isLoading, hasCardTitle }) => {
  const [downTimePercentage, setDownTimePercentage] = useState(0)

  //calculating total down time percentage over total picked time range
  useEffect(() => {
    // converting both down time and total time range to milliseconds (to calculate a ratio)
    const downTimeInMilliseconds = moment.duration(totalDownTime, "seconds").asMilliseconds()
    const totalTimeInMilliseconds = moment.duration(totalDuration, "seconds").asMilliseconds()
    const downTimeInPercentage = totalTimeInMilliseconds ? downTimeInMilliseconds / totalTimeInMilliseconds : 0

    setDownTimePercentage(downTimeInPercentage * 100)
  }, [totalDownTime, totalDuration])

  const noData = (!totalDuration && !totalDownTime) || (totalDownTime && downTimePercentage <= 0)
  return (
    <div className={styles.container}>
      {hasCardTitle && (
        <Typography variant="h2-bold" variantColor={2} align="center">
          Total downtime
        </Typography>
      )}
      <div className={styles.progressContainer}>
        {isLoading ? (
          <GraphLoadingState />
        ) : (
          <CircularProgressbarWithChildren
            value={downTimePercentage}
            styles={buildStyles({
              strokeLinecap: "round",
              pathColor: downTimePercentage === 0 ? "var(--indigo-background-2)" : "var(--red-background-1)",
              trailColor: downTimePercentage === 0 ? "var(--indigo-background-2)" : "var(--red-background-2)",
            })}
          >
            <div className={styles.downTimeStatsWrapper}>
              {!hasCardTitle && (
                <Typography className={styles.header} variant="label" variantColor={2} gutterBottom>
                  Total downtime
                </Typography>
              )}
              {noData ? (
                <Typography variant="a" variantColor={2}>
                  No Down Time Data
                </Typography>
              ) : (
                <Fragment>
                  <Typography variant="a" variantColor={2} align="center">
                    <span className={styles.numeric}>
                      {totalDownTime.days && totalDownTime.days > 0
                        ? totalDownTime.days * 24 + (totalDownTime.hours ?? 0)
                        : totalDownTime.hours}
                    </span>
                    hrs <span className={styles.numeric}> {totalDownTime.minutes}</span>mins{" "}
                  </Typography>
                  <Typography variant="span" variantColor={2} className={styles.totalDuration}>
                    out of {moment.duration(totalDuration, "seconds").asHours().toFixed(0)} hour(s)
                  </Typography>
                </Fragment>
              )}
            </div>
          </CircularProgressbarWithChildren>
        )}
      </div>
    </div>
  )
}

export default TotalDownTime
