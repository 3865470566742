export const gridTheme = {
  fontSize: 12,
  fontFamily: "Inter",
  fontWeight: 700,
  textColor: "var(--secondary-text-light)",
  color: "var(--secondary-text-light)",
  ticks: {
    text: {
      color: "var(--secondary-text-light)",
      fontSize: 12,
      fontWeight: 700,
    },
  },
}
