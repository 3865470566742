/**
 * Maps cameras and regions to entities while preserving the original order of entities.
 *
 * @param {Array} entities - The array of entities to be mapped.
 * @param {Object} cameras - An object containing camera data, keyed by camera ID.
 * @param {Object} regions - An object containing region data, keyed by region ID.
 * @param {string} type - The type of the entities being processed (e.g., "Entrance Gate").
 * @returns {Array} The modified array of entities with camera names and region states added.
 */
export function mapCameraAndRegionsToEntities(entities = [], cameras, regions = [], type) {
  // Check if there are entities and cameras available for mapping
  if (entities.length > 0 && cameras && Object?.values(cameras).length > 0) {
    // Iterate over each entity
    for (let i = 0; i < entities.length; i++) {
      entities[i].camera_name = [] // Initialize camera_name array for the current entity
      if (type !== "") {
        entities[i].type = type // Set the type of the entity if provided
      }
      // Iterate over each camera
      for (let j = 0; j < Object.values(cameras).length; j++) {
        // Check each camera associated with the current entity
        for (let k = 0; k < entities[i].cameras.length; k++) {
          // If the camera ID matches, add the camera name to the camera_name array
          if (entities[i].cameras[k] === Object.values(cameras)[j].id) {
            if (entities[i].camera_name.length !== 0) {
              // If camera_name already has entries, prepend with " / "
              entities[i].camera_name.push(" / " + Object.values(cameras)[j].name)
            } else {
              // If camera_name is empty, just add the camera name
              entities[i].camera_name.push(Object.values(cameras)[j].name)
            }
          }
        }
      }
    }
  }

  // Check if there are entities and regions available for mapping
  if (entities.length > 0 && Object.values(regions).length > 0) {
    // Iterate over each entity again to map region states
    for (let i = 0; i < entities.length; i++) {
      // Iterate over each region
      for (let j = 0; j < Object.values(regions).length; j++) {
        // If the entity ID matches the region's entity ID
        if (entities[i].id === Object.values(regions)[j].entity) {
          // Set the inRegion, outRegion, or dwellingRegion based on the region's state
          if (Object.values(regions)[j].state === 0) {
            // In state
            entities[i].inRegion = true
          } else if (Object.values(regions)[j].state === 1) {
            // Out state
            entities[i].outRegion = true
          } else if (Object.values(regions)[j].state === 2) {
            // Dwelling state
            entities[i].dwellingRegion = true
          }
        }
      }
    }
  }

  return entities // Return the modified entities array
}

export function mapEntitiesAndRegionsToCameras(cameras, allEntities, regions, carParkingRegions) {
  const clonedCameras = Object.values(JSON.parse(JSON.stringify(cameras)))
  if (clonedCameras.length > 0 && clonedCameras && allEntities && allEntities.length) {
    //map entities to each camera
    for (let i = 0; i < clonedCameras.length; i++) {
      clonedCameras[i].entities = []
      for (let j = 0; j < allEntities.length; j++) {
        for (let k = 0; k < allEntities[j].cameras.length; k++) {
          if (clonedCameras[i].id === allEntities[j].cameras[k]) {
            clonedCameras[i].entities.push(allEntities[j])
          }
        }
      }
      //Check if it has regions or not
      if (Object.values(regions) && Object.values(regions).length > 0) {
        for (let j = 0; j < Object.values(regions).length; j++) {
          if (clonedCameras[i].id === Object.values(regions)[j].camera) {
            clonedCameras[i].regions = true
            break
          }
        }
      }
      if (carParkingRegions && Object.values(carParkingRegions) && Object.values(carParkingRegions).length > 0) {
        for (let j = 0; j < Object.values(carParkingRegions).length; j++) {
          if (clonedCameras[i].id === Object.values(carParkingRegions)[j].camera) {
            clonedCameras[i].regions = true
            break
          }
        }
      }
    }
  }
  return clonedCameras
}

export function mapRegionsToCameras(currentEntity, cameras, regions) {
  if (
    currentEntity.cameras &&
    currentEntity.cameras.length > 0 &&
    Object.values(regions).length > 0 &&
    Object.values(regions)
  ) {
    let result = []

    const clonedCameras = Object.values(JSON.parse(JSON.stringify(cameras)))
    currentEntity.cameras.forEach((cameraId) => {
      clonedCameras.forEach((camera) => {
        if (cameraId === camera.id) {
          result.push(camera)
        }
      })
    })

    for (let i = 0; i < result.length; i++) {
      for (let j = 0; j < Object.values(regions).length; j++) {
        if (result[i].id === Object.values(regions)[j].camera) {
          result[i].entityId = currentEntity.id

          if (Object.values(regions)[j].state === 0 && Object.values(regions)[j].entity === currentEntity.id) {
            //In
            result[i].inRegion = true
          } else if (Object.values(regions)[j].state === 1 && Object.values(regions)[j].entity === currentEntity.id) {
            //Out
            result[i].outRegion = true
          } else if (Object.values(regions)[j].state === 2 && Object.values(regions)[j].entity === currentEntity.id) {
            //Dwelling
            result[i].dwellingRegion = true
          }
        }
      }
    }
    return result
  }
}

export function findCenter(points) {
  let x = 0,
    y = 0,
    i,
    len = points.length

  for (i = 0; i < len; i++) {
    x += points[i].x
    y += points[i].y
  }
  return { x: x / len, y: y / len } // return average position
}
export function findAngles(c, points) {
  let i,
    len = points.length,
    p,
    dx,
    dy

  for (i = 0; i < len; i++) {
    p = points[i]
    dx = p.x - c.x
    dy = p.y - c.y
    p.angle = Math.atan2(dy, dx)
  }
}
export function isPointInside(point, polygonPoints) {
  let inside = false
  for (let i = 0, j = polygonPoints.length - 1; i < polygonPoints.length; j = i++) {
    if (
      // eslint-disable-next-line
      polygonPoints[i].y > point.y !== polygonPoints[j].y > point.y &&
      point.x <
        ((polygonPoints[j].x - polygonPoints[i].x) * (point.y - polygonPoints[i].y)) /
          (polygonPoints[j].y - polygonPoints[i].y) +
          polygonPoints[i].x
    )
      inside = !inside
  }
  return inside
}
