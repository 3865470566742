import React, { FC, useEffect, ReactNode, Fragment } from "react"

import { Typography, Button } from "@synapse-analytics/synapse-ui"

import styles from "./StatusScreen.module.scss"

interface Props {
  message: string
  subMessage?: string
  reasons?: string[]
  buttonText: string
  buttonAction: () => void
  ArtComponent: ReactNode
}

const StatusScreen: FC<Props> = ({ message, subMessage, reasons = [], buttonText, buttonAction, ArtComponent }) => {
  // change app body background to match status screens design background
  useEffect(() => {
    document.body.setAttribute(
      "style",
      "margin: 0; height: 100vh; background-image: linear-gradient(165deg , #f5f8fe 50%, #e2e9fd  50.3%);background-repeat: no-repeat; "
    )

    // remove background when component unmounts
    return () => {
      document.body.setAttribute("style", "")
    }
  }, [])

  return (
    <Fragment>
      <div className={styles.wrapper}>
        <div className={styles.contentWrapper}>
          <header className={styles.titleContainer}>
            <Typography className={styles.title} color="indigo" variantColor={2} variant="h1-bold">
              AZKAVISION
            </Typography>
          </header>
          <Typography className={styles.message} variant="h1-bold">
            {message}
          </Typography>
          <div>
            {subMessage && (
              <Typography className={styles.subMessage} variant="h2-regular">
                {subMessage}
              </Typography>
            )}
            {reasons.length > 0 && (
              <ul className={styles.reasons}>
                {reasons.map((reason, index) => (
                  <li key={index}>
                    <Typography variant="h2-regular">{reason}</Typography>
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
        <Button onClick={buttonAction} variant="primary">
          {buttonText}
        </Button>
        <div className={styles.footerContainer}>
          <a
            href="https://www.synapse-analytics.io/"
            target="_blank"
            rel="noopener noreferrer"
            className={styles.website}
          >
            <Typography className={styles.footer} variant="h1-bold" align="center">
              <span className={styles.regular}>SYNAPSE</span>
              <br />
              ANALYTICS
            </Typography>
          </a>
        </div>
      </div>
      <div className={styles.artContainer}>{ArtComponent}</div>
    </Fragment>
  )
}

export default StatusScreen
