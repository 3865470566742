import React, { FC, Fragment, ReactNode } from "react"

import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { SvgIcon } from "@mui/material"
import Accordion from "@mui/material/Accordion"
import AccordionSummary from "@mui/material/AccordionSummary"

import { Typography } from "@synapse-analytics/synapse-ui"

import { splitPersonaAndScreen } from "../../../utils/DashboardUtils"

import styles from "./AccordionItem.module.scss"

interface Props {
  ItemIcon: typeof SvgIcon
  activeScreen: string
  subRoutes?: Array<string>
  itemLabel: string
  accordionItems?: ReactNode[]
}

const AccordionItem: FC<Props> = ({ ItemIcon, activeScreen, subRoutes = [], itemLabel, accordionItems }) => {
  // checking if passed route(screen) is active or not
  // by comparing that given route with current active screen
  // to highlight the active screen item in the sidebar
  const checkActiveScreen = (route: string) => {
    // get the actual screen route not the whole parent/child route
    // E.g => heatmap-floor/analytics-heatmap-live
    const pagePathName = route?.split("/").reverse()
    // E.g => ["analytics-heatmap-live","heatmap-floor"] reversed and splitted by "/"
    const routeWithoutPersona = splitPersonaAndScreen(pagePathName[0])
    // split analytics-heatmap-live into persona : analytics , screen: heatmap-live
    return activeScreen === routeWithoutPersona.screen
  }

  return (
    <div>
      <Accordion className={styles.accordion} defaultExpanded={true}>
        <AccordionSummary
          className={styles.dropDownContent}
          expandIcon={<ExpandMoreIcon />}
          classes={{ expanded: styles.expanded, root: styles.expanded, content: styles.content }}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <ItemIcon
            className={styles.icon}
            style={{
              color: subRoutes?.map((route) => checkActiveScreen(route)).some((isActive) => isActive)
                ? "var(--blue-text-2)"
                : "var(--neutral-text-enabled)",
            }}
          />
          <Typography
            variant="a"
            style={{
              color: subRoutes?.map((route) => checkActiveScreen(route)).some((isActive) => isActive)
                ? "var(--blue-text-2)"
                : "var(--neutral-text-enabled)",
            }}
          >
            {itemLabel}
          </Typography>
        </AccordionSummary>
        <div className={styles.itemsWrapper}>
          {accordionItems
            ?.filter((item) => !!item)
            .map((item, index) => (
              <Fragment key={index}>{item}</Fragment>
            ))}
        </div>
      </Accordion>
    </div>
  )
}

export default AccordionItem
