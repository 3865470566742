import { useEffect, useRef, useState } from "react"

export const useDebounceSearch = (value: string, delay = 500) => {
  const [debouncedValue, setDebouncedValue] = useState<string>("")
  const timerRef = useRef<ReturnType<typeof setInterval>>()

  useEffect(() => {
    timerRef.current = setTimeout(() => setDebouncedValue(value), delay)

    return () => {
      clearTimeout(timerRef.current)
    }
  }, [value, delay])

  return debouncedValue
}
