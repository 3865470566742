import React, { useState, useEffect, Fragment, useContext } from "react"

import { CircularProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material"

import { Typography } from "@synapse-analytics/synapse-ui"
import moment from "moment"

import NoTableLogs from "../../../../../assets/NoTableLogs.svg?react"
import { IMissingData } from "../../../../../types/Custom/Interfaces"
import { MissingDataContext } from "../../MissingDataContext/MissingDataContext"
import EditableCell from "./EditableTableCell"

import styles from "./MissingDataTable.module.scss"

type rowsData = {
  date?: string
  time?: string
  inCount?: number
  outCount?: number
}

const MissingDataTable = () => {
  const { tableData, isLoadingEstimation, isLoadingEntityLogs, handleCellEdit, selectedEntity } =
    useContext<IMissingData>(MissingDataContext)
  const [rowsArray, setRowsArray] = useState<rowsData[]>([])

  function createMissingData(date: string, time: string, inCount: number, outCount: number) {
    return { date, time, inCount, outCount }
  }
  useEffect(() => {
    const tableRows: rowsData[] = []
    if (tableData) {
      tableData.map((element) =>
        tableRows.push(
          createMissingData(
            moment(element.datetime).format("MMM D YYYY"),
            `${moment(element.datetime).format("h A")} - ${moment(element.datetime).add(1, "hour").format("h A")}`,
            element.count_in,
            element.count_out
          )
        )
      )
    }
    setRowsArray(tableRows)
  }, [tableData])

  return (
    <Fragment>
      <div className={styles.root}>
        {isLoadingEstimation || isLoadingEntityLogs ? (
          <div className={styles.emptyStateWrapper}>
            <CircularProgress size={25} />
          </div>
        ) : tableData && tableData?.length > 0 ? (
          <TableContainer className={styles.container}>
            <Table aria-label="simple table" stickyHeader>
              <TableHead>
                <TableRow
                  classes={{
                    root: styles.rowRoot,
                  }}
                >
                  <TableCell
                    classes={{
                      root: styles.cellRoot,
                      body: styles.body,
                      head: styles.head,
                    }}
                    align="left"
                  >
                    Date
                  </TableCell>
                  <TableCell
                    classes={{
                      root: styles.cellRoot,
                      body: styles.body,
                      head: styles.head,
                    }}
                    align="left"
                  >
                    Time
                  </TableCell>
                  <TableCell
                    classes={{
                      root: styles.cellRoot,
                      body: styles.body,
                      head: styles.head,
                    }}
                    align="left"
                  >
                    In count
                  </TableCell>
                  <TableCell
                    classes={{
                      root: styles.cellRoot,
                      body: styles.body,
                      head: styles.head,
                    }}
                    align="left"
                  >
                    Out count
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rowsArray.map((row, index) => {
                  return (
                    <TableRow
                      classes={{
                        root: styles.rowRoot,
                      }}
                      key={index}
                    >
                      <TableCell
                        classes={{
                          root: styles.cellRoot,
                          body: styles.body,
                          head: styles.head,
                        }}
                        align="left"
                      >
                        {row.date}
                      </TableCell>
                      <TableCell
                        classes={{
                          root: styles.cellRoot,
                          body: styles.body,
                          head: styles.head,
                        }}
                        align="left"
                      >
                        {row.time}
                      </TableCell>
                      <EditableCell
                        value={row.inCount}
                        onEdit={(editedValue) => handleCellEdit(index, "count_in", editedValue)}
                        key={`${selectedEntity?.id}_count_in_row${index}`}
                      />
                      <EditableCell
                        value={row.outCount}
                        onEdit={(editedValue) => handleCellEdit(index, "count_out", editedValue)}
                        key={`${selectedEntity?.id}_count_out_row${index}`}
                      />
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <div className={styles.emptyStateWrapper}>
            <NoTableLogs />
            <Typography variant="h2-regular" variantColor={2} style={{ marginTop: 12 }}>
              No logs yet
            </Typography>
          </div>
        )}
      </div>
    </Fragment>
  )
}

export default MissingDataTable
