import React, { FC, useState, useEffect, Fragment } from "react"

import StackedBarChartIcon from "@mui/icons-material/StackedBarChart"
import TableViewIcon from "@mui/icons-material/TableView"
import { Card } from "@mui/material"

import { Typography } from "@synapse-analytics/synapse-ui"

import ButtonSwitch from "../../../../components/Buttons/ButtonSwitch"
import CategoriesLineGraph from "../../../../components/graphs/CheckboxesLineGraph"
import { definitions } from "../../../../types/Generated/apiTypes"
import { convertToLineGraphData } from "../../../../utils/securityUtils"
import DetectionsTable from "./DetectionsTable"

import styles from "./EventsOverTime.module.scss"

type SecurityLogs = definitions["SecurityViolationAnalytics"]

interface Props {
  logsData: SecurityLogs[]
  loading: boolean
  interval: Duration
}
type graphPoint = {
  x: Date
  y: number
}
type GraphData = {
  id: string
  color: string
  data: graphPoint[]
}
const EventsOverTime: FC<Props> = ({
  logsData,
  loading,
  interval = { days: 7, hours: 23, minutes: 59, months: 0, seconds: 0, years: 0 },
}) => {
  const [lineGraphData, setLineGraphData] = useState<GraphData[]>([
    {
      id: "",
      color: "",
      data: [{ x: new Date(), y: 0 }],
    },
  ])
  const [defaultCheckboxes, setDefaultCheckboxes] = useState<{ [key: string]: Boolean }>({ "": false })
  const [activeTab, setActiveTab] = useState(0)

  useEffect(() => {
    if (loading === false && logsData) {
      setLineGraphData(convertToLineGraphData(logsData))
    }
  }, [logsData, loading])
  // setting checkboxes state , a checkbox for each event type acts as filtering and graph legends
  useEffect(() => {
    let statesObj: { [key: string]: Boolean } = {}
    for (let log of lineGraphData) {
      statesObj[log.id] = false
    }
    setDefaultCheckboxes(statesObj)
  }, [lineGraphData])

  return (
    <Card className={styles.wrapper}>
      <Fragment>
        <div className={styles.header}>
          <Typography variant="h2-bold" variantColor={2} noWrap>
            Detections over time
            {activeTab === 0 ? " line graph" : " table"}
          </Typography>
          <ButtonSwitch
            activePage={activeTab}
            pages={["Graph", "Table"]}
            handleSelectButton={setActiveTab}
            disabled={loading === true ? true : false}
            pagesIcons={[<StackedBarChartIcon />, <TableViewIcon />]}
          />
        </div>
        {activeTab === 0 ? (
          <CategoriesLineGraph
            data={lineGraphData}
            isLoading={loading}
            graphHeight={240}
            defaultCheckboxes={defaultCheckboxes}
            isLargeInterval={interval?.months! > 1}
          />
        ) : (
          <DetectionsTable data={logsData} loading={loading} />
        )}
      </Fragment>
    </Card>
  )
}
export default EventsOverTime
