import { createContext, ReactNode, useState, ChangeEvent, useCallback, useEffect } from "react"
import { useMutation, useQuery, useQueryClient } from "react-query"

import { NotificationUtils } from "@synapse-analytics/synapse-ui"
import { FormikProps, useFormik } from "formik"
import moment from "moment"
import * as Yup from "yup"
import { shallow } from "zustand/shallow"

import { VisionAPI } from "../../../../API/VisionAPI"
import { useBranchesStore } from "../../../../store"
import {
  IMissingData,
  IMissingDataInfo,
  EntitiesTypes,
  SelectedEntity,
  MissingDataObj,
  UpdateAction,
  AlreadyFilledObj,
} from "../../../../types/Custom/Interfaces"
import { definitions } from "../../../../types/Generated/apiTypes"

type MissingCounterLog = definitions["MissingCounterLog"]
type MissingInfo = definitions["MissingDataInfo"]
type EntityMissingDataLogs = definitions["EntityMissingDataLogs"]
type MissingDataLogs = definitions["MissingDataLogs"]

/**
 * Formats the missingData object into an array of EntityMissingDataLogs objects.
 * @param {Object.<string, MissingCounterLog[]>} missingData - The missing data object to format.
 * @returns {EntityMissingDataLogs[]} An array of objects, each containing an entity ID and its corresponding logs.
 */
const formatMissingData = (missingData: MissingDataObj): EntityMissingDataLogs[] => {
  return Object.keys(missingData).map((entityId) => ({
    entity: parseInt(entityId),
    logs: missingData[entityId],
  }))
}

export const MissingDataContext = createContext({} as IMissingData)

const MissingDataContextProvider = ({
  children,
  editInfo,
  isEdit,
}: {
  children: ReactNode
  editInfo?: MissingInfo | null
  isEdit?: boolean
}) => {
  // user selected entities from available entities are stored inside this state
  const [selectedEntities, setSelectedEntities] = useState<SelectedEntity[]>([])
  const [selectedEntity, setSelectedEntity] = useState<SelectedEntity>()
  const [activeStep, setActiveStep] = useState(0)
  const [infoId, setInfoId] = useState<number | null>(editInfo?.id!)
  const [startDate, setStartDate] = useState<moment.Moment>(moment().subtract(1, "days"))
  const [startTime, setStartTime] = useState<moment.Moment>(moment().startOf("hour"))
  const [endDate, setEndDate] = useState<moment.Moment>(moment())
  const [endTime, setEndTime] = useState<moment.Moment>(moment().startOf("hour"))
  const [estimateLogs, setEstimateLogs] = useState(false)
  const [missingData, setMissingData] = useState<MissingDataObj>({})
  const [currentTableData, setCurrentTableData] = useState<MissingCounterLog[]>()
  const [fillingErrors, setFillingErrors] = useState<AlreadyFilledObj>({})
  const [monthsForAverage, setMonthsForAverage] = useState(3)
  const [selectedBranch] = useBranchesStore((state: { selectedBranch: number }) => [state.selectedBranch], shallow)

  const updatedId = selectedEntity?.id!

  const queryClient = useQueryClient()

  const validationSchema = Yup.object({
    name: Yup.string().required("Missing data file name is required"),
  })

  // start/end date/time handlers
  const handleStartDateChange = (startDate: moment.Moment | null): void => {
    setStartDate(startDate as moment.Moment)
    if (startDate && endDate <= startDate) {
      setEndDate(startDate?.add(1, "day"))
    }
    // reset filling errors for this selected entity
    setFillingErrors((prevState) => {
      return {
        ...prevState,
        [updatedId]: false,
      }
    })
  }

  const handleStartTimeChange = (startTime: Date | null): void => {
    setStartTime(moment(startTime))
    // reset filling errors for this selected entity
    setFillingErrors((prevState) => {
      return {
        ...prevState,
        [updatedId]: false,
      }
    })
  }

  const handleEndDateChange = (endDate: moment.Moment | null): void => {
    setEndDate(endDate as moment.Moment)
    // reset filling errors for this selected entity
    setFillingErrors((prevState) => {
      return {
        ...prevState,
        [updatedId]: false,
      }
    })
  }

  const handleEndTimeChange = (endTime: Date | null): void => {
    setEndTime(moment(endTime) as moment.Moment)
    // reset filling errors for this selected entity
    setFillingErrors((prevState) => {
      return {
        ...prevState,
        [updatedId]: false,
      }
    })
  }

  // select an entity to fill its missing data
  const handleSelectEntityToFill = (entity: SelectedEntity) => {
    setSelectedEntity(entity)
  }
  // handle editing an incount or outcount value
  const handleCellEdit = (index: number, columnName: string, editedValue: number) => {
    const dataToChange = missingData[updatedId]
    // Assume 'data' is the array of objects representing rows
    const newData = [...dataToChange]
    // Update the specific cell value in the row at the given index
    newData[index] = {
      ...newData[index],
      [columnName]: editedValue,
    }
    setMissingData((prevValue) => {
      return {
        ...prevValue,
        [updatedId]: newData,
      }
    })
  }

  const handleIncreaseOrDecreaseLogs = (action: UpdateAction, percentage: number = 0): void => {
    // Clone the original data to avoid mutating the state directly
    const newData = { ...missingData }
    // Check if the ID exists in the data
    if (updatedId in newData) {
      // Clone the array of MissingCounterLog objects for the given ID
      const newArray = [...newData[updatedId]]

      // Iterate over each index with data for the current ID
      newArray.forEach((log, index) => {
        // Update the specific MissingCounterLog object based on the action
        if (action === "increase") {
          newArray[index] = {
            ...log,
            count_in: Math.round(log.count_in * (1 + percentage / 100)),
            count_out: Math.round(log.count_out * (1 + percentage / 100)),
          }
        } else if (action === "decrease") {
          newArray[index] = {
            ...log,
            count_in: Math.max(0, Math.round(log.count_in * (1 - percentage / 100))),
            count_out: Math.max(0, Math.round(log.count_out * (1 - percentage / 100))),
          }
        }
      })

      // Update the data with the modified array
      newData[updatedId] = newArray
    }

    setMissingData(newData)
  }

  // to set first entity to be selected by default on second step mount
  const handleSetDefaultSelectedEntity = useCallback(() => {
    const firstNonNullEntry = selectedEntities.find((entity) => !!entity)
    if (firstNonNullEntry) handleSelectEntityToFill(firstNonNullEntry)
  }, [selectedEntities])

  const handleSetDefaultSelectedEntities = useCallback((editInfo?: MissingInfo | null) => {
    if (editInfo) {
      const defaultSelected: SelectedEntity[] = []
      const entities = editInfo?.entities
      entities?.forEach((entity) => {
        let entityType: "Gates" | "Corridors" | "Tenants"
        switch (entity?.entity_type) {
          case "gate":
            entityType = "Gates"
            break
          case "corridor":
            entityType = "Corridors"
            break
          case "shop":
            entityType = "Tenants"
            break
        }
        defaultSelected[entity?.id!] = { id: entity?.id!, name: entity?.name, type: entityType }
      })
      setSelectedEntities(defaultSelected)
    }
  }, [])

  // update missing data object on clicking autofill or already filled missing data in edit mode
  const updateMissingDataObject = (data: MissingCounterLog[]) => {
    const existingMissingData = { ...missingData } || {}

    existingMissingData[updatedId] = data
    setMissingData(existingMissingData)
    setEstimateLogs(false)
    if (!!fillingErrors[updatedId] && data) {
      setFillingErrors((prevState) => {
        return {
          ...prevState,
          [updatedId]: false,
        }
      })
    }
  }

  // Function to check if all selected entities have corresponding entries in the missingDataObj
  const checkEntitiesForMissingData = (): boolean => {
    // Iterate over each selected entity
    for (const entity of selectedEntities) {
      // Check if the entity is not null and has an id and exclude entities of already filled missing data
      if (entity && entity.id !== undefined && !checkIfEditEntity(entity.id)) {
        // Convert id to string, as keys in missingDataObj are strings
        const entityIdString = entity.id.toString()

        // Check if the entity id exists in missingDataObj
        if (!(entityIdString in missingData)) {
          // If not found, return false
          return false
        }
      }
    }

    // If all entities have corresponding entries, return true
    return true
  }

  // Check if the ID exists in the editInfo.entities array
  const checkIfEditEntity = (id: string | number) => {
    const entityExists = editInfo?.entities?.some((entity) => entity.id === +id) || false
    return entityExists
  }

  const { mutate: deleteMissingDataEntity } = useMutation((entityInfo: { entity_id: number; info_id: number }) =>
    VisionAPI.deleteMissingDataEntity(entityInfo)
  )

  const { mutate: createMissingDataFile, isLoading: isSubmittingNewEntry } = useMutation(
    async (values: MissingDataLogs) => {
      VisionAPI.createMissingDataFile(values)
    },
    {
      onSuccess: () => {
        queryClient?.invalidateQueries("fetchMissingData")
        NotificationUtils.toast("Missing data file created successfully", {
          snackBarVariant: "positive",
        })
      },

      mutationKey: "submittingEntry",
    }
  )

  const { mutate: updateMissingDataFile } = useMutation(
    (values: IMissingDataInfo) =>
      VisionAPI.updateMissingDataFile(infoId as number, { title: values.name, branch: selectedBranch }),
    {
      onSuccess: (data) => {
        setInfoId(data?.id as number)
        setActiveStep((prevValue) => prevValue + 1)
      },
    }
  )

  const { mutate: updateMissingDataEntry, isLoading: isUpdatingExistingEntry } = useMutation(
    (values: { entity_id: string; info_id: string | number; data: MissingCounterLog[] }) =>
      VisionAPI.updateMissingDataEntry(values),
    {
      onSuccess: () => {
        NotificationUtils.toast(`${formik.values.name} missing data file updated successfully`, {
          snackBarVariant: "positive",
        })
        queryClient?.invalidateQueries("fetchMissingData")
      },
      mutationKey: "updatingEntry",
    }
  )

  const { isLoading: isLoadingEstimation, isFetching: isFetchingEstimation } = useQuery<MissingCounterLog[]>(
    [
      "fetchLogsEstimation",
      infoId,
      selectedEntity?.id,
      startDate.set({ hour: startTime.hour(), minute: startTime.minute(), second: startTime.second() }).toISOString(),
      endDate.set({ hour: endTime.hour(), minute: endTime.minute(), second: endTime.second() }).toISOString(),
      monthsForAverage,
    ],
    ({ queryKey }) =>
      VisionAPI.fetchLogsEstimation({
        info_id: queryKey[1] as number,
        entity_id: queryKey[2] as number,
        start_date: queryKey[3] as string,
        end_date: queryKey[4] as string,
        months_for_average: queryKey[5] as number,
      }),
    {
      onSuccess: (data) => updateMissingDataObject(data),
      onError: () => {
        setFillingErrors((prevState) => {
          return {
            ...prevState,
            [updatedId]: true,
          }
        })
        setEstimateLogs(false)
        updateMissingDataObject([])
      },
      enabled: estimateLogs,
    }
  )
  const { isLoading: isLoadingEntityLogs } = useQuery<MissingCounterLog[]>(
    ["fetchMissingEntityLogs", infoId, selectedEntity?.id],
    ({ queryKey }) =>
      VisionAPI.fetchEntityMissingLogs({ info_id: queryKey[1] as number, entity_id: queryKey[2] as number }),
    {
      onSuccess: (data) => updateMissingDataObject(data),
      onError: () => {
        setFillingErrors((prevState) => {
          return {
            ...prevState,
            [updatedId]: true,
          }
        })
      },
      enabled: !!isEdit && !!selectedEntity && !missingData[updatedId] && checkIfEditEntity(updatedId),
    }
  )

  const formik: FormikProps<IMissingDataInfo> = useFormik<IMissingDataInfo>({
    initialValues: {
      name: (isEdit && editInfo?.title) || "",
    },
    validationSchema: validationSchema,
    validateOnChange: false,
    onSubmit: (values, actions) => {
      setTimeout(() => {
        actions.setSubmitting(false)
      }, 1000)
      if (infoId) {
        return updateMissingDataFile(values)
      }
    },
  })

  const countSelectionsOfType = (targetType: EntitiesTypes): number => {
    return selectedEntities.filter((entity) => entity?.type === targetType)?.length
  }
  const handleAutoFill = () => {
    setEstimateLogs(true)
  }

  const handleEntityCheck = (
    event: ChangeEvent<HTMLInputElement>,
    id: number,
    name: string,
    selectedType: EntitiesTypes
  ) => {
    const selected = [...selectedEntities]
    // if it does exist => remove (Uncheck)
    if (!event.target.checked && selectedEntities[`${id}`]) {
      selected[id] = null
      if (isEdit && editInfo && checkIfEditEntity(id)) {
        deleteMissingDataEntity({
          entity_id: id,
          info_id: infoId as number,
        })
      }
    }
    // if not then add it         (Check  )
    else {
      selected[id] = {
        id: id,
        name: name,
        type: selectedType,
      }
    }
    setSelectedEntities(selected)
  }

  // Function to handle sending post requests for all entries
  const handleSubmitEntries = async () => {
    if (isEdit) {
      // Iterate over each entry in missingDataObj and trigger mutations
      for (const id in missingData) {
        updateMissingDataEntry({
          entity_id: id as string,
          info_id: infoId as number,
          data: missingData[id],
        })
      }
    } else {
      // If the entity doesn't exist or isEdit is false, call createMissingDataEntry
      createMissingDataFile({
        branch: selectedBranch as number,
        title: formik.values.name,
        entities_missing_data: formatMissingData(missingData), // Format missingData
      })
    }
  }

  const handleSelectAll = (selectedType: EntitiesTypes, entities: SelectedEntity[]) => {
    const allEntities: SelectedEntity[] = selectedEntities
    entities.forEach((entity) => {
      if (!allEntities[entity?.id!]) {
        allEntities[entity?.id!] = {
          id: entity?.id,
          name: entity?.name,
          type: selectedType,
        }
      }
    })
    setSelectedEntities([...allEntities])
  }

  // clears all selected entities of all types
  const clearAllSelectedEntities = () => {
    setSelectedEntities([])
    setSelectedEntity(null)
  }
  // clears all selected entities of a specific type
  const clearEntitiesOfType = (entityType: EntitiesTypes): void => {
    setSelectedEntities((prevEntities) =>
      prevEntities.map((entity) => {
        if (entity && entity.type === entityType) {
          return null
        }
        return entity
      })
    )
  }

  const handleReset = () => {
    formik?.resetForm()
    clearAllSelectedEntities()
    setSelectedEntity(null)
    setInfoId(null)
    setStartDate(moment().subtract(1, "days"))
    setStartTime(moment().startOf("hour"))
    setEndDate(moment())
    setEndTime(moment().startOf("hour"))
    setEstimateLogs(false)
    setMissingData({})
    setCurrentTableData([])
  }

  // change current table data shown upon either changing missing data values or selecting another entity
  useEffect(() => {
    setCurrentTableData(missingData[updatedId])
  }, [missingData, selectedEntity, isLoadingEstimation, updatedId])

  useEffect(() => {
    handleSetDefaultSelectedEntities(editInfo)
  }, [handleSetDefaultSelectedEntities, editInfo])
  return (
    <MissingDataContext.Provider
      value={{
        formik,
        selectedEntities,
        selectedEntity,
        activeStep,
        infoId,
        startDate,
        startTime,
        endDate,
        endTime,
        isLoadingEstimation,
        isLoadingEntityLogs,
        missingData,
        tableData: currentTableData,
        isSubmittingNewEntry,
        isUpdatingExistingEntry,
        fillingErrors,
        isFetchingEstimation,
        isEdit,
        monthsForAverage,
        handleEntityCheck,
        countSelectionsOfType,
        handleReset,
        clearAllSelectedEntities,
        clearEntitiesOfType,
        handleSelectAll,
        handleSetDefaultSelectedEntity,
        handleSelectEntityToFill,
        setActiveStep,
        setMonthsForAverage,
        handleStartDateChange,
        handleStartTimeChange,
        handleEndDateChange,
        handleEndTimeChange,
        handleAutoFill,
        handleCellEdit,
        handleIncreaseOrDecreaseLogs,
        checkEntitiesForMissingData,
        handleSubmitEntries,
        checkIfEditEntity,
      }}
    >
      {children}
    </MissingDataContext.Provider>
  )
}

export default MissingDataContextProvider
