import { useNavigate } from "react-router-dom"

import { routes } from "../../../routes/routes"
import StatusScreen from "../StatusScreen"
import Error from "./assets/404.svg?react"

const NotFound = () => {
  const navigate = useNavigate()

  return (
    <StatusScreen
      ArtComponent={<Error />}
      message="Sorry, Page not found"
      subMessage="404 error, Possible reasons:"
      reasons={["The address may have been typed incorrectly.", "It may be a broken ot outdated link."]}
      buttonText="Back To Dashboard"
      buttonAction={() => navigate(`/${routes?.home}`)}
    />
  )
}
export default NotFound
