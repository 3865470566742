import React, { FC, useEffect, Fragment } from "react"
import InfiniteScroll from "react-infinite-scroll-component"
import { useInfiniteQuery } from "react-query"

import CircularProgress from "@mui/material/CircularProgress"
import Grid from "@mui/material/Grid"
import useMediaQuery from "@mui/material/useMediaQuery"

import { Typography, Skeleton } from "@synapse-analytics/synapse-ui"
import { AxiosError } from "axios"
import { Moment } from "moment"

import { VisionAPI } from "../../API/VisionAPI"
import { PaginatedSecurityEvents } from "../../types/Custom/Interfaces"
import { definitions } from "../../types/Generated/apiTypes"
import { extractPageFromBackEndPaginationLink } from "../../utils/genericHelpers"
import NoLogsPlaceholder from "./assets/NoHistoryLogs.svg"
import EventCard from "./components/EventCard"
import EventsSummary from "./components/EventsSummary"

import styles from "./HistoryEvents.module.scss"

type EventsPaginated = definitions["SecurityViolationList"]

interface Props {
  startDate: Moment | null
  endDate: Moment | null
  selectedCameras: number[]
  issueType: string
  interval: Duration
  setLoadingState: (isLoading: boolean) => void
}
const HistoryEvents: FC<Props> = ({ startDate, endDate, selectedCameras, issueType, setLoadingState, interval }) => {
  const xLargeScreen = useMediaQuery("(min-width:1900px)")
  const xSmallScreen = useMediaQuery("(max-width:1280px)")
  const eventsListLimit = xLargeScreen ? 18 : 12

  const {
    data: paginatedSecurityEvents,
    fetchNextPage,
    hasNextPage,
    isLoading,
  } = useInfiniteQuery<PaginatedSecurityEvents, AxiosError>(
    [
      "fetchSecurityEventsLogs",
      eventsListLimit,
      startDate?.format("YYYY-MM-DD"),
      endDate?.format("YYYY-MM-DD"),
      selectedCameras,
      issueType,
    ],
    async ({ queryKey, pageParam = 1 }) => {
      const selectedCameras = queryKey[4] as number[]
      const cameras = selectedCameras.join(",")
      return VisionAPI.fetchSecurityEventsLogsPaginated({
        page: pageParam as number,
        limit: queryKey[1] as number,
        from_date: queryKey[2] as string,
        to_date: queryKey[3] as string,
        cameras,
        type: queryKey[5] as string,
      })
    },
    {
      getNextPageParam: (lastPage: PaginatedSecurityEvents) => {
        return lastPage?.next ? extractPageFromBackEndPaginationLink(lastPage.next) : undefined
      },
      enabled: !!startDate && !!endDate,
    }
  )
  useEffect(() => {
    if (isLoading === true) {
      setLoadingState(true)
    } else {
      setLoadingState(false)
    }
  }, [isLoading, setLoadingState])
  let loadingPlaceholders = new Array(eventsListLimit).fill(null).map((r, i) => (
    <Grid item md={xSmallScreen ? 6 : 3} sm={6} xs={12} xl={xLargeScreen ? 2 : 3} key={i}>
      <Skeleton variant="rectangular" height={228} width="auto" />
    </Grid>
  ))
  return (
    <div className={styles.wrapper}>
      <EventsSummary
        startDate={startDate}
        endDate={endDate}
        selectedCameras={selectedCameras}
        issueType={issueType}
        setLoadingState={setLoadingState}
        interval={interval}
      />
      <Typography variant="h3-bold" variantColor={2} gutterBottom className={styles.title}>
        Past Events
      </Typography>
      {/* fetched event logs goes here, within infinite scrolling component , grid view of event cards */}
      {/* with spacing of 2 , grid item occupation of md = 3 , sm = 2 , xs = 1 */}
      {isLoading ? (
        <Grid container spacing={2} style={{ display: "flex" }}>
          {isLoading && <Fragment>{loadingPlaceholders}</Fragment>}
        </Grid>
      ) : paginatedSecurityEvents && paginatedSecurityEvents?.pages?.length > 0 ? (
        <InfiniteScroll
          dataLength={paginatedSecurityEvents?.pages.reduce((acc, page) => acc + page.results.length, 0)}
          hasMore={hasNextPage ? true : false}
          next={fetchNextPage}
          loader={<CircularProgress className={styles.loadingMoreEvents} />}
          endMessage={
            paginatedSecurityEvents.pages[0].count > 0 ? (
              <Typography variant="h3-regular" align="center">
                You reached the bottom
              </Typography>
            ) : (
              <div className={styles.eventsPlaceholderWrapper}>
                <img src={NoLogsPlaceholder} className={styles.eventsPlaceholder} alt="No event logs found" />
                <div>
                  <Typography variant="h2-bold" className={styles.boldTitle}>
                    No Event Logs Found.
                  </Typography>
                </div>
              </div>
            )
          }
        >
          <Grid container spacing={2} style={{ display: "flex" }}>
            {/* cameras list */}
            {paginatedSecurityEvents?.pages.map((page, i) => (
              <React.Fragment key={i}>
                {page.results.map((eventLog: EventsPaginated, i: number) => (
                  <Grid key={i} item md={xSmallScreen ? 6 : 3} sm={6} xs={12} xl={xLargeScreen ? 2 : 3}>
                    <EventCard eventLog={eventLog} isLive={false} />
                  </Grid>
                ))}
              </React.Fragment>
            ))}
          </Grid>
        </InfiniteScroll>
      ) : (
        <div className={styles.eventsPlaceholderWrapper}>
          <img src={NoLogsPlaceholder} className={styles.eventsPlaceholder} alt="No event logs found" />
          <div>
            <Typography variant="h2-bold" className={styles.boldTitle}>
              No Event Logs Found.
            </Typography>
          </div>
        </div>
      )}
    </div>
  )
}
export default HistoryEvents
