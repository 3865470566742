import React, { FC } from "react"

import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline"
import { Dialog, DialogActions, DialogContent } from "@mui/material"

import { Button, Typography } from "@synapse-analytics/synapse-ui"

import styles from "./SelectCameraAlert.module.scss"

interface Props {
  cameraName?: string
  open: boolean
  onClose: () => void
  handleDeleteCamera: () => void
}

const DeleteCameraAlert: FC<Props> = ({ open, onClose, handleDeleteCamera, cameraName }) => {
  return (
    <Dialog
      open={open}
      maxWidth="md"
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent className={styles.wrapper} style={{ paddingTop: 32, overflow: "visible" }}>
        <ErrorOutlineIcon className={styles.errorIcon} />
        <Typography variant="h2-bold" className={styles.title}>
          Are you sure?
        </Typography>
        <Typography variant="p" className={styles.warningDescription} align="center">
          You are about to delete a camera , this will result in removing it from located cameras and remove its
          mapping.
        </Typography>
        <DialogActions className={styles.actionButtons}>
          <Button onClick={onClose} variant="primary" className={styles.cancelButton}>
            Cancel
          </Button>
          <Button onClick={handleDeleteCamera} variant="dangerous">
            Delete {cameraName || ""}
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  )
}

export default DeleteCameraAlert
