import { Fragment, FC } from "react"
import { useQuery } from "react-query"

import SyncIcon from "@mui/icons-material/Sync"
import WallpaperIcon from "@mui/icons-material/Wallpaper"
import { Grid, CircularProgress } from "@mui/material"

import { Button, NotificationUtils, Typography } from "@synapse-analytics/synapse-ui"
import { AxiosError } from "axios"

import { VisionAPI } from "../../../API/VisionAPI"
import { definitions } from "../../../types/Generated/apiTypes"
import CameraPlaceholder from "../assets/cameraPlaceholder.svg?react"

import styles from "./ImagePreview.module.scss"

type CameraFrame = definitions["CameraFrame"]

interface Props {
  url?: string
}
const ImagePreview: FC<Props> = ({ url }) => {
  const handleCamera = () => {
    // show camera preview
    if (url) {
      refetch()
    } else {
      NotificationUtils.toast("Please add a valid camera URL !", {
        snackBarVariant: "negative",
      })
    }
  }

  // fetch camera URL
  const {
    data: imageURL,
    isFetching,
    error: noPreview,
    refetch,
  } = useQuery<CameraFrame, AxiosError>(
    ["showPreview", url],
    ({ queryKey }) => VisionAPI.fetchCameraFrame({ url: queryKey[1] as string }),
    {
      onSettled: (data) => {
        if (!data?.frame) {
          NotificationUtils.toast("No Preview Found", {
            snackBarVariant: "negative",
          })
        }
      },
      enabled: !!url,
    }
  )

  const shouldReload = noPreview || imageURL

  return (
    <Fragment>
      {/* camera preview */}
      <div className={styles.titleAndButton}>
        <Typography variant="span">Camera Preview</Typography>
        <Button
          onClick={handleCamera}
          size="small"
          variant="secondary"
          className={styles.previewButton}
          startIcon={
            shouldReload ? <SyncIcon className={styles.buttonIcon} /> : <WallpaperIcon className={styles.buttonIcon} />
          }
        >
          {shouldReload ? "Reload" : "load"} preview
        </Button>
      </div>
      <div className={styles.wrapper}>
        {isFetching ? (
          <Grid container direction="row" justifyContent="center" alignItems="center" sx={{ minHeight: "100%" }}>
            <Grid item alignItems="center">
              <CircularProgress />
            </Grid>
          </Grid>
        ) : imageURL ? (
          <img
            src={`data:image/jpeg;base64,${imageURL.frame}`}
            alt="cameraPreview"
            className={styles.imagePreviewCamera}
          />
        ) : (
          <div className={styles.noData}>
            <CameraPlaceholder />
            <Typography className={styles.noFrame} variant="small" variantColor={2}>
              Please add a valid URL
            </Typography>
          </div>
        )}
      </div>
    </Fragment>
  )
}

export default ImagePreview
