import React, { useContext, useEffect, Fragment } from "react"

import { Grid, useMediaQuery } from "@mui/material"
import MuiAlert from "@mui/material/Alert"
import Snackbar from "@mui/material/Snackbar"

import { FloorsContext } from "../../floorsContext/FloorsContext"
import SingleCameraPoint from "./SingleCameraPoint"

function Alert(props: any) {
  return <MuiAlert elevation={6} variant={"filled"} {...props} />
}

const MapCameraFloor = ({ versionId, floorImage }: { versionId?: number; floorImage?: string }) => {
  const {
    formik,
    canvasFloorRef,
    canvasCameraSrcRef,
    cameraMappingProperties,
    selectedMappingCamera,
    handleMapCamerasToFloor,
    cameraProperties,
    pointCameraAlert,
    setMappingFloorImage,
    floorImageSizeMapping,
    setMappingCameraImage,
    cameraImageSizeMapping,
  } = useContext(FloorsContext)
  const smallScreens = useMediaQuery("(max-width:1610px)")

  const cameraSrc = selectedMappingCamera?.cameraSrc
    ? selectedMappingCamera?.cameraSrc
    : cameraProperties[0]?.cameraSrc! === null
    ? cameraProperties[1]?.cameraSrc!
    : cameraProperties[0]?.cameraSrc!

  // camera image canvas
  useEffect(() => {
    const imageCamera = new Image()
    const canvas = canvasCameraSrcRef?.current
    const ctx = canvas?.getContext("2d")
    imageCamera.src = cameraSrc

    imageCamera.onload = () => {
      ctx?.drawImage(imageCamera, 0, 0, canvas?.width, canvas?.height)
      ctx!.strokeStyle = `rgba(${selectedMappingCamera?.colorCode!}, 0.6)`
      if (
        selectedMappingCamera?.cameraId !== 0 &&
        cameraMappingProperties &&
        cameraMappingProperties.points.length > 0
      ) {
        ctx!.fillStyle = `rgba(${selectedMappingCamera?.colorCode}, 0.6)`
        ctx?.beginPath()
        ctx?.moveTo(
          cameraMappingProperties.points[0].x_camera_resized!,
          cameraMappingProperties.points[0].y_camera_resized!
        )
        for (let i = 0; i < cameraMappingProperties.points.length; i++) {
          ctx?.lineTo(
            cameraMappingProperties.points[i].x_camera_resized!,
            cameraMappingProperties.points[i].y_camera_resized!
          )
        }
        ctx?.stroke()
        ctx?.closePath()
        ctx?.fill()
      }
    }
  }, [canvasCameraSrcRef, formik, cameraMappingProperties, selectedMappingCamera, cameraSrc])

  // floor plan canvas
  useEffect(() => {
    const imageFloor = new Image()
    const canvas = canvasFloorRef?.current
    const ctx = canvas?.getContext("2d")
    imageFloor.src = formik?.values.floorPreview
    imageFloor.onload = () => {
      ctx?.drawImage(imageFloor, 0, 0, canvas?.width, canvas?.height)
      ctx!.strokeStyle = `rgba(${selectedMappingCamera?.colorCode!}, 0.6)`

      if (
        cameraMappingProperties?.points.length > 0 &&
        cameraMappingProperties.points[0].x_floor_resized &&
        cameraMappingProperties.points[0].y_floor_resized
      ) {
        ctx!.fillStyle = `rgba(${selectedMappingCamera?.colorCode}, 0.6)`
        ctx?.beginPath()
        ctx?.moveTo(
          cameraMappingProperties.points[0].x_floor_resized!,
          cameraMappingProperties.points[0].y_floor_resized!
        )
        for (let i = 0; i < cameraMappingProperties.points.length; i++) {
          ctx?.lineTo(
            cameraMappingProperties.points[i].x_floor_resized!,
            cameraMappingProperties.points[i].y_floor_resized!
          )
        }

        ctx?.stroke()
        ctx?.closePath()
        ctx?.fill()
      }
    }
  }, [canvasFloorRef, formik, floorImage, versionId, cameraMappingProperties, selectedMappingCamera])

  // set camera image size
  useEffect(() => {
    const imageCamera = new Image()
    imageCamera.src = cameraSrc
    imageCamera.onload = () => {
      setMappingCameraImage({
        height: imageCamera.height,
        width: imageCamera.width,
      })
    }
    // eslint-disable-next-line
  }, [])

  // set floor image size
  useEffect(() => {
    const imageFloor = new Image()
    imageFloor.src = formik?.values.floorPreview
    imageFloor.onload = () => {
      setMappingFloorImage({
        height: imageFloor.height,
        width: imageFloor.width,
      })
    }
    // eslint-disable-next-line
  }, [])

  return (
    <Fragment>
      <Grid
        container
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Grid item md={6} style={{ position: "relative" }}>
          {/* camera source image */}
          <canvas
            style={{ marginBottom: 6, cursor: "pointer" }}
            ref={canvasCameraSrcRef}
            width={smallScreens ? "620" : "860"}
            height={smallScreens ? "520" : "660"}
            onMouseDown={(e) =>
              handleMapCamerasToFloor(e, "camera", {
                width: cameraImageSizeMapping.width,
                height: cameraImageSizeMapping.height,
              })
            }
          ></canvas>

          {/* Single Camera point */}
          <div>
            {cameraMappingProperties?.points?.length > 0 &&
              cameraMappingProperties?.points?.map((cameraMappingCoor, i) => (
                <SingleCameraPoint
                  mappingType="camera"
                  pointColor={`rgba(${selectedMappingCamera?.colorCode!}, 0.6)`}
                  key={i}
                  cameraMappingCoor={cameraMappingCoor}
                  coorsPosition={i + 1}
                  isEntities={false}
                />
              ))}
          </div>
        </Grid>
        <Grid item md={6} style={{ position: "relative" }}>
          {/* floor map */}
          <canvas
            ref={canvasFloorRef}
            width={smallScreens ? "620" : "860"}
            height={smallScreens ? "520" : "660"}
            style={{ cursor: "pointer" }}
            onMouseDown={(e) =>
              handleMapCamerasToFloor(e, "floor", {
                width: floorImageSizeMapping.width,
                height: floorImageSizeMapping.height,
              })
            }
          ></canvas>

          {/* Single floors point */}
          <div>
            {cameraMappingProperties?.points?.length > 0 &&
              cameraMappingProperties?.points?.map(
                (cameraMappingCoor, i) =>
                  cameraMappingCoor.x_floor_resized &&
                  cameraMappingCoor.y_floor_resized && (
                    <SingleCameraPoint
                      mappingType="floor"
                      key={i}
                      pointColor={`rgba(${selectedMappingCamera?.colorCode!}, 0.6)`}
                      cameraMappingCoor={cameraMappingCoor}
                      coorsPosition={i + 1}
                      isEntities={false}
                    />
                  )
              )}
          </div>
        </Grid>
      </Grid>
      {pointCameraAlert && (
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={pointCameraAlert}
          autoHideDuration={1000}
        >
          <div>
            <Alert severity="warning">You should place the point on the floor plan</Alert>
          </div>
        </Snackbar>
      )}
    </Fragment>
  )
}

export default MapCameraFloor
