import moment from "moment"

import { BarGraphData } from "../types/Custom/Types"
import { definitions } from "../types/Generated/apiTypes"

type DwellingAreaHourlyAVGCounts = definitions["DwellingAreaHourlyAVGCounts"]
type DwellingAreaWeekDaysCounts = definitions["DwellingAreaWeekDaysCounts"]
type DwellingAreaDailyCount = definitions["DwellingAreaDailyCounts"]
type DwellingAreaAnalytics = definitions["DwellingAreaAnalytics"]

function truncateToTwoDecimals(num: number): number {
  return Math.floor(num * 100) / 100
}

/**
 * Type definition for a reformatted log.
 */
type ReformattedLog = {
  x: Date
  y: number
  maleCount: number
  femaleCount: number
  adultCount: number
  childCount: number
}
/**
 * Type definition for a reformatted table log.
 */
type ReformattedTableLog = {
  timestamp: Date
  "Dwelling counts"?: number
  "Dwelling time"?: number
}

/**
 * Reformats the hourly average logs into a format suitable for a bar graph.
 *
 * @param {DwellingAreaHourlyAVGCounts[] | DwellingAreaWeekDaysCounts[]} logs - The hourly average logs to reformat.
 * @param {("Dwelling counts" | "Dwelling time")} dwellType - The type of dwelling data to reformat ("avg_dwell_time" or "avg_dwell_count").
 * @returns {BarGraphData[]} The reformatted logs suitable for a bar graph.
 */
export const reformatDwellingBarGraphCounts = (
  logs: (DwellingAreaHourlyAVGCounts | DwellingAreaWeekDaysCounts)[],
  dwellType: "Dwelling counts" | "Dwelling time"
): BarGraphData[] => {
  const reformattedLogs: BarGraphData[] = []

  if (logs && logs.length > 0) {
    // Sort logs based on whether they have "hour" or "day" property
    logs?.sort((a, b) => {
      if ("hour" in a && "hour" in b) {
        return a.hour - b.hour
      } else if ("day" in a && "day" in b) {
        return moment(a.day, "ddd").weekday() - moment(b.day, "ddd").weekday()
      } else {
        return 0 // Keep order unchanged if logs don't have "hour" or "day" property
      }
    })

    for (const log of logs) {
      const divisionFactor = dwellType === "Dwelling time" ? 60 : 1
      const dwellKey = dwellType === "Dwelling counts" ? "dwell_count" : "dwell_time"

      // Type guard to check if log is DwellingAreaHourlyAVGCounts
      if ("hour" in log) {
        const reformattedLog: BarGraphData = {
          Hour: moment(new Date().setHours(log.hour, 0, 0, 0)).format("hh A"),
          "Male Count": Math.round(log[`male_avg_${dwellKey}`] / divisionFactor),
          "Female Count": Math.round(log[`female_avg_${dwellKey}`] / divisionFactor),
          "Adult Count": Math.round(log[`adult_avg_${dwellKey}`] / divisionFactor),
          "Children Count": Math.round(log[`child_avg_${dwellKey}`] / divisionFactor),
        }

        reformattedLogs.push(reformattedLog)
      } else {
        // log is DwellingAreaWeekDaysCounts
        const reformattedLog: BarGraphData = {
          Day: log.day,
          "Male Count": Math.round(log[`male_${dwellKey}`] / divisionFactor),
          "Female Count": Math.round(log[`female_${dwellKey}`] / divisionFactor),
          "Adult Count": Math.round(log[`adult_${dwellKey}`] / divisionFactor),
          "Children Count": Math.round(log[`child_${dwellKey}`] / divisionFactor),
        }

        reformattedLogs.push(reformattedLog)
      }
    }
  }

  return reformattedLogs
}

/**
 * Calculate dwelling area statistics based on daily counts.
 * @param dailyLogs Array of dwelling area daily counts.
 * @param dwellingAnalytics Array of dwelling area analytics logs.
 * @returns Object containing aggregated statistics.
 */
export const calculateDwellingAreaStatistics = (
  dailyLogs: DwellingAreaDailyCount[],
  dwellingAnalytics: DwellingAreaAnalytics[]
): {
  maleCount: number
  femaleCount: number
  adultCount: number
  childCount: number
  totalDwellingCount: number
  averageDwellingTime: number
} => {
  // Initialize counters
  let maleCount = 0
  let femaleCount = 0
  let adultCount = 0
  let childCount = 0
  let totalDwellingCount = 0

  // Loop through each data entry
  dailyLogs.forEach((entry) => {
    // Sum counts
    maleCount += entry.male_dwell_count
    femaleCount += entry.female_dwell_count
    adultCount += entry.adult_dwell_count
    childCount += entry.child_dwell_count

    // Calculate total dwelling count
    totalDwellingCount += entry.male_dwell_count + entry.female_dwell_count
  })

  // Calculate average dwelling time and round it up
  const avgDwellingDuration = dwellingAnalytics[0]?.avg_dwelling_duration
  const averageDwellingTime = !!avgDwellingDuration ? truncateToTwoDecimals(avgDwellingDuration / 60) : 0

  // Return aggregated statistics
  return {
    maleCount,
    femaleCount,
    adultCount,
    childCount,
    totalDwellingCount,
    averageDwellingTime,
  }
}

/**
 * Convert data to hourly line graph format based on the data type.
 * @param {Array<DwellingAreaDailyCount>} logsSummedByTime - Array of logs summed by time.
 * @param {"Dwelling counts" | "Dwelling time"} dataType - Type of data: "Dwelling counts" or "Dwelling time".
 * @returns {Array<{ id: string; data: Array<ReformattedLog> }>} - Converted data suitable for a line graph.
 */
export const convertDwellingCountsToLineGraph = (
  data: DwellingAreaDailyCount[],
  dwellType: "Dwelling counts" | "Dwelling time" | "Gates counts"
): Array<{ id: string; data: Array<ReformattedLog> }> => {
  const reformattedData: Array<{ id: string; data: Array<ReformattedLog> }> = [{ id: dwellType, data: [] }]
  const dwellKey = dwellType === "Dwelling counts" ? "dwell_count" : "dwell_time"
  const divisionFactor = dwellType === "Dwelling time" ? 60 : 1

  // Sort data by timestamp
  data?.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())

  data?.forEach((log) => {
    const timestamp = new Date(log.date)
    const reformattedLog = {
      x: timestamp,
      y:
        dwellType === "Dwelling time"
          ? Math.round(log.total_dwell_time / 60)
          : Math.round(log[`male_${dwellKey}`]) + Math.round(log[`female_${dwellKey}`]),
      maleCount: Math.round(log[`male_${dwellKey}`] / divisionFactor),
      femaleCount: Math.round(log[`female_${dwellKey}`] / divisionFactor),
      adultCount: Math.round(log[`adult_${dwellKey}`] / divisionFactor),
      childCount: Math.round(log[`child_${dwellKey}`] / divisionFactor),
    }
    reformattedData[0].data.push(reformattedLog)
  })

  return reformattedData
}

/**
 * Convert DwellingAreaDailyCount[] to an array of ReformattedLog objects.
 * @param data DwellingAreaDailyCount[] The array of daily dwelling area counts.
 * @param dwellType "Dwelling counts" | "Dwelling time" The type of dwelling value.
 * @returns ReformattedLog[] The array of reformatted log objects.
 */
export const convertDailyDwellingToTableLogs = (
  data: DwellingAreaDailyCount[],
  dwellType: "Dwelling counts" | "Dwelling time"
): ReformattedTableLog[] => {
  // Define dwellKey and divisionFactor based on dwellType
  const dwellKey = dwellType === "Dwelling counts" ? "dwell_count" : "dwell_time"

  // Sort data by timestamp
  data?.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())

  // Initialize array to store reformatted logs
  const reformattedLogs: ReformattedTableLog[] = []

  // Iterate over each entry in the data array
  data?.forEach((log) => {
    // Calculate dwelling value based on dwellKey
    const dwellingValue =
      dwellType === "Dwelling time"
        ? Math.round(log.total_dwell_time / 60)
        : Math.round(log[`male_${dwellKey}`]) + Math.round(log[`female_${dwellKey}`])

    // Create reformatted log object and push it to the array
    const reformattedLog: ReformattedTableLog = {
      timestamp: new Date(log.date),
      [dwellType]: dwellingValue, // Square bracket notation to set property dynamically
    }
    reformattedLogs.push(reformattedLog)
  })

  // Return the resulting array of reformatted logs
  return reformattedLogs
}
