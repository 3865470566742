import React, { useState, FC, Fragment } from "react"
import { useMutation, useQueryClient } from "react-query"

import DeleteIcon from "@mui/icons-material/DeleteOutlined"
import EditIcon from "@mui/icons-material/EditOutlined"
import { Card, Grid } from "@mui/material"

import { Typography, Tag, NotificationUtils } from "@synapse-analytics/synapse-ui"

import { VisionAPI } from "../../../API/VisionAPI"
import WarningDialog from "../../../components/WarningDialog"
import { definitions } from "../../../types/Generated/apiTypes"

import styles from "./CategoryCard.module.scss"

type Category = definitions["Category"]

interface Props {
  category: Category
  handleOpenEdit: (categoryToEdit: Category) => void
}
const CategoryCard: FC<Props> = ({ category, handleOpenEdit }) => {
  const queryClient = useQueryClient()
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] = useState(false)

  const { mutate: deleteCategory, isLoading: isLoadingDeleteCategory } = useMutation(
    (id: number) => VisionAPI.deleteCategory(id),
    {
      onSuccess: () => {
        NotificationUtils.toast("Category deleted successfully", {
          snackBarVariant: "positive",
        })
        queryClient?.invalidateQueries("fetchCategories")
      },
    }
  )

  const handleOpenDeleteConfirmation = () => {
    setIsDeleteConfirmationOpen(true)
  }
  const handleCloseDeleteConfirmation = () => {
    setIsDeleteConfirmationOpen(false)
  }

  return (
    <Fragment>
      <WarningDialog
        confirmationText={`delete ${category.name} category?`}
        isOpen={isDeleteConfirmationOpen}
        onCancel={handleCloseDeleteConfirmation}
        onConfirm={() => deleteCategory(category?.id as number)}
        isLoading={isLoadingDeleteCategory}
        actionTitle="Delete"
        cancelTitle="Cancel"
        content="Be aware by deleting this category this action can't be undone."
        dialogTitle={`Delete “${category?.name!}” category?`}
      />
      <Card className={styles.wrapper}>
        <Typography variant="h1-bold" variantColor={2} className={styles.categoryName} title={category.name}>
          {category?.name?.length >= 18 ? category?.name?.slice(0, 15) + ".." : category?.name}
        </Typography>
        {category.subcategories && category.subcategories.length > 0 && (
          <Grid container spacing={1} className={styles.subcategoriesContainer}>
            {category.subcategories.slice(0, 2).map((subcategory, index) => (
              <Grid item key={index}>
                <Tag
                  title={subcategory.name}
                  variant={
                    index % 3 === 0 && index !== 0 ? "indigo" : index === 0 ? "mint" : index % 2 ? "orange" : "default"
                  }
                >
                  {subcategory?.name?.length >= 18 ? subcategory?.name?.slice(0, 15) + ".." : subcategory?.name}
                </Tag>
              </Grid>
            ))}
            {category?.subcategories?.length > 2 && (
              <Typography variant="span" display="inline" noWrap className={styles.subcategoriesCaption}>
                + {category?.subcategories?.length - 2} more
              </Typography>
            )}
          </Grid>
        )}
        <div className={styles.actionButton}>
          <EditIcon
            className={`${styles.icon} ${styles.editIcon}`}
            style={{ marginRight: 4 }}
            onClick={() => handleOpenEdit(category)}
          />
          <DeleteIcon className={`${styles.icon} ${styles.deleteIcon}`} onClick={handleOpenDeleteConfirmation} />
        </div>
      </Card>
    </Fragment>
  )
}
export default CategoryCard
