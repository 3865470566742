import React, { useState, Fragment } from "react"
import { useQuery } from "react-query"

import { SingleDayPicker, Typography, Select, InputChangeEvent } from "@synapse-analytics/synapse-ui"
import moment from "moment"
import { NumberParam, useQueryParam } from "use-query-params"
import { shallow } from "zustand/shallow"

import { VisionAPI } from "../../../API/VisionAPI"
import Placeholder from "../../../components/Placeholder"
import { useDateQuery } from "../../../hooks/useDateQuery"
import { useBranchesStore } from "../../../store"
import { definitions } from "../../../types/Generated/apiTypes"
import { getCurrentDay } from "../../../utils/mcpUtils"
import FloorsMCPContainer from "./FloorsMCPContainer"

import styles from "../styles/MCP.module.scss"

type Floors = definitions["FloorPlanList"]

const FloorsMCP = () => {
  const [selectedFloor, setSelectedFloor] = useQueryParam("floor", NumberParam)

  const [dayStart] = getCurrentDay()

  const [selectedDate, setSelectedDate] = useDateQuery({ isDayPicker: true, defaultStartDate: moment(dayStart) })

  const [isLoading, setIsLoading] = useState(true)
  const [disableDateRange, setDisableDateRange] = useState(false)
  const [selectedBranch] = useBranchesStore((state: { selectedBranch: number }) => [state.selectedBranch], shallow)

  const handleDateChange = (date: moment.Moment | null) => {
    if (date) {
      setSelectedDate(date)
    }
  }

  // Fetch floor plans
  const { data: floors, isLoading: isFloorsLoading } = useQuery<Floors[]>(
    ["fetchFloors", selectedBranch],
    ({ queryKey }) => VisionAPI.fetchFloorPlans({ branch: queryKey[1] as number }),
    {
      enabled: !!selectedBranch,
    }
  )

  const handleFloorChange = (event: InputChangeEvent) => {
    setSelectedFloor(event.target.value as number)
  }

  const handleLoading = (loadingState: boolean) => {
    setIsLoading(loadingState)
  }
  const handleDisableDateRange = (disableState: boolean) => {
    setDisableDateRange(disableState)
  }

  const mappedFloors =
    floors && floors?.length > 0
      ? floors.map((floor) => {
          return {
            label: floor.floor_name,
            value: floor.id!,
          }
        })
      : []

  return (
    <div className={styles.wrapper}>
      <Typography
        variant="h2-regular"
        tooltip="Visual representation of most common paths in selected floor"
        tooltipPlacement="right"
        tooltipIconSize={22}
        gutterBottom
        variantColor={2}
      >
        Floors MCP
      </Typography>
      <div className={styles.header}>
        <Select
          id="floor-select"
          placeholder="Select Floor"
          value={selectedFloor as number}
          handleChange={handleFloorChange}
          loading={isFloorsLoading}
          optionsWithValues={mappedFloors}
          width={235}
          isNotchedLabel
          label={selectedFloor ? "Selected Floor" : ""}
        />

        <div className={styles.datePicker}>
          <SingleDayPicker
            disabled={disableDateRange || isLoading}
            date={selectedDate}
            disableFuture
            onDateChange={handleDateChange}
          />
        </div>
      </div>
      {/* if no camera is selected yet , or no end date is selected , show placeholder */}
      <Fragment>
        {!!selectedFloor ? (
          <FloorsMCPContainer
            selectedDate={selectedDate ? selectedDate?.toDate() : dayStart}
            selectedFloor={selectedFloor}
            loadingSetter={handleLoading}
            disableSetter={handleDisableDateRange}
          />
        ) : (
          <Placeholder isScreenPlaceholder />
        )}
      </Fragment>
    </div>
  )
}
export default FloorsMCP
