// TODO:: Refactor this component into a generic component
import { FC, useEffect, useState, Fragment } from "react"

import GetAppOutlinedIcon from "@mui/icons-material/GetAppOutlined"
import { Tooltip } from "@mui/material"
import CircularProgress from "@mui/material/CircularProgress"
import Grid from "@mui/material/Grid"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"

import { Button } from "@synapse-analytics/synapse-ui"
import { CsvBuilder } from "filefy"

import { DateTimeFormatOptions } from "../../types/Custom/Interfaces"
import { convertSecondsToReadableTime } from "../../utils/genericHelpers"
import GraphTooltip from "../GraphTooltip"
import GraphEmptyState from "../graphs/GraphEmptyState"

import styles from "./CountTable.module.scss"

type tableData = {
  timestamp?: Date | string
  entity?: string
  entity_name?: string
  branch_name?: string
  total_count_in_sum?: number
  id?: string
  "Count In"?: number
  "Count Out"?: number
  Difference?: number
  "Male Count"?: number
  "Female Count"?: number
  "Missing Count In"?: number
  "Missing Count Out"?: number
  category?: string
  sub_category?: string
  male_count?: number
  female_count?: number
  adult_count?: number
  child_count?: number
  count?: number
  isModified?: boolean
  missingInfo?: {
    fileName: string
    entities: string
    countIn: number
    countOut: number
  }[]
  "Dwelling counts"?: number
  "Dwelling time"?: number
  date?: string
  duration?: number
}
interface Props {
  timeGrain?: "hour" | "day"
  data: tableData[]
  loading: boolean
  isGatesTable?: boolean
  dwellingType?: "Dwelling counts" | "Dwelling time"
  isTenantDetails?: boolean
  isTenants?: boolean
  isSubcategory?: boolean
  isCorridorsPerformance?: boolean
  shouldIncludeMissingData?: boolean
  isCarGates?: boolean
  isBranchesAnalytics?: boolean
  isDownTime?: boolean
}
type rowsData = {
  date?: Date | string
  entity?: string
  branch_name?: string
  entity_name?: string
  category?: string
  sub_category?: string
  male_count?: number
  female_count?: number
  adult_count?: number
  child_count?: number
  countIn?: number
  countOut?: number
  isModified?: boolean
  missingCountIn?: number
  missingCountOut?: number
  missingInfo?:
    | {
        fileName: string
        entities: string
        countIn: number
        countOut: number
      }[]
    | string
  count?: number
  total_count_in_sum?: number
  dwellingValue?: number
  duration?: number
}

const CountTable: FC<Props> = ({
  timeGrain,
  data,
  loading,
  isGatesTable,
  isTenants,
  isTenantDetails,
  isSubcategory,
  isCorridorsPerformance,
  isCarGates,
  shouldIncludeMissingData,
  dwellingType,
  isBranchesAnalytics,
  isDownTime,
}) => {
  const [rowsArray, setRowsArray] = useState<rowsData[]>([])

  function createTimeData(
    date: Date | string,
    countIn: number,
    countOut: number,
    isModified?: boolean,
    missingInfo?: {
      fileName: string
      entities: string
      countIn: number
      countOut: number
    }[]
  ) {
    return { date, countIn, countOut, isModified, missingInfo }
  }

  function createDwellingData(date: Date | string, dwellingValue: number) {
    return { date, dwellingValue }
  }

  function createGatesData(
    entity: string,
    countIn: number,
    countOut: number,
    isModified?: boolean,
    missingCountIn?: number,
    missingCountOut?: number,
    missingInfo?:
      | {
          fileName: string
          entities: string
          countIn: number
          countOut: number
        }[]
      | string
  ) {
    return { entity, countIn, countOut, isModified, missingCountIn, missingCountOut, missingInfo }
  }

  function createCategoriesData(
    male_count: number,
    female_count: number,
    adult_count: number,
    child_count: number,
    category?: string,
    sub_category?: string,
    entity?: string
  ) {
    return { entity, male_count, female_count, adult_count, child_count, category, sub_category }
  }
  function createCorridorsData(
    male_count: number,
    female_count: number,
    adult_count: number,
    child_count: number,
    entity_name?: string
  ) {
    return { entity_name, male_count, female_count, adult_count, child_count }
  }

  function createCarGateData(entity: string, count: number) {
    return { entity, count }
  }

  function createBranchData(branch_name: string, total_count_in_sum: number) {
    return { branch_name, total_count_in_sum }
  }

  function createDownTimeData(date: string, duration: number) {
    return { date, duration }
  }

  // tenants list rows formation
  useEffect(() => {
    const tableRows: rowsData[] = []
    if (data) {
      if (!!isTenants) {
        data.forEach((element) => {
          tableRows.push(
            createCategoriesData(
              element.male_count!,
              element.female_count!,
              element.adult_count!,
              element.child_count!,
              element.category && element.category!,
              element.sub_category && element.sub_category!,
              element.entity && element.entity!
            )
          )
        })
      } else if (!!isDownTime) {
        data.forEach((element) => {
          tableRows.push(createDownTimeData(element.date!, element.duration!))
        })
      } else if (!!isCarGates) {
        data.forEach((element) => {
          tableRows.push(createCarGateData(element.entity!, element.count!))
        })
      } else if (isBranchesAnalytics) {
        data.forEach((element) => {
          tableRows.push(createBranchData(element.branch_name!, element.total_count_in_sum!))
        })
      } else if (!!isCorridorsPerformance) {
        data.forEach((element) => {
          tableRows.push(
            createCorridorsData(
              element.male_count!,
              element.female_count!,
              element.adult_count!,
              element.child_count!,
              element.entity_name && element.entity_name!
            )
          )
        })
      } else if (!!isGatesTable) {
        data.forEach((element) => {
          tableRows.push(
            createGatesData(
              element.entity!,
              element["Count In"]!,
              element["Count Out"]!,
              element.isModified,
              element["Missing Count In"],
              element["Missing Count Out"],
              element.missingInfo
            )
          )
        })
      } else if (!!dwellingType) {
        data.forEach((element) => {
          tableRows.push(createDwellingData(element.timestamp!, element[dwellingType]!))
        })
      } else {
        data.forEach((element) => {
          tableRows.push(
            createTimeData(
              element.timestamp!,
              element["Count In"]!,
              element["Count Out"]!,
              element.isModified,
              element.missingInfo
            )
          )
        })
      }
    }

    setRowsArray(tableRows)
  }, [data, isGatesTable, isCarGates, isCorridorsPerformance, isTenants, dwellingType, isBranchesAnalytics, isDownTime])

  const formatDate = (date: Date | string, timeGrain?: string) => {
    const options: DateTimeFormatOptions =
      timeGrain === "hour"
        ? { hour12: true, hour: "2-digit" as const }
        : { hour12: true, weekday: "short", day: "numeric", month: "short" }

    return new Date(date).toLocaleString("en-US", options)
  }

  const getTableName = () => {
    if (isDownTime) return "Down Time"
    if (isTenants) return "Categories Performance"
    if (isBranchesAnalytics) return "Branches Performance"
    if (isCorridorsPerformance) return "Corridors"
    if (isGatesTable) return "Gates"
    return timeGrain === "hour" ? "Hourly" : "Daily"
  }

  const getTableColumns = () => {
    if (isDownTime) {
      return ["Day", "Duration"]
    }
    if (isTenants || isCorridorsPerformance) {
      return [
        isCorridorsPerformance ? "Corridor" : isSubcategory ? "Subcategory" : "Category",
        "Male Count",
        "Female Out",
        "Adult Count",
        "Child Count",
      ]
    }
    if (isCarGates) return ["Gate", "Count"]
    if (isBranchesAnalytics) return ["Branch", "Visitors Count"]
    if (dwellingType) return ["Date/Time", dwellingType]
    return [
      isGatesTable ? "Gate" : "Date/Time",
      "Total Count In",
      "Total Count Out",
      shouldIncludeMissingData ? "Modified Missing Data" : "",
    ]
  }

  const getTableData = () => {
    return rowsArray.map((row) => {
      if (isDownTime) {
        return [row.date!.toString(), convertSecondsToReadableTime(row.duration)]
      }
      if (isTenants || isCorridorsPerformance) {
        return [
          isCorridorsPerformance ? row.entity_name! : isSubcategory === true ? row.sub_category! : row.category!,
          row.male_count!.toString(),
          row.female_count!.toString(),
          row.adult_count!.toString(),
          row.child_count!.toString(),
        ]
      }
      if (isCarGates) return [row.entity!, row.count!.toString()]
      if (isBranchesAnalytics) return [row.branch_name!, row.total_count_in_sum!.toString()]
      if (isGatesTable) {
        return [
          row.entity!,
          (row.countIn! + (row.missingCountIn || 0)).toString(),
          (row.countOut! + (row.missingCountOut || 0)).toString(),
          shouldIncludeMissingData ? (!!row.isModified ? "Yes" : "No") : "",
        ]
      }
      if (dwellingType) {
        return [formatDate(row.date!, timeGrain), row.dwellingValue!.toString()]
      }
      return [
        formatDate(row.date!, timeGrain),
        row.countIn!.toString(),
        row.countOut!.toString(),
        shouldIncludeMissingData ? (!!row.isModified ? "Yes" : "No") : "",
      ]
    })
  }

  const handleExportCSV = () => {
    const tableName = getTableName()
    const builder = new CsvBuilder(`${tableName} Table.csv`)
    const csvFormattedData = getTableData()
    const tableColumns = getTableColumns()

    builder.setColumns(tableColumns).addRows(csvFormattedData).exportFile()
  }

  return (
    <Fragment>
      {(!data && !loading) || (data && data.length < 1 && !loading) ? (
        <GraphEmptyState />
      ) : loading ? (
        <Grid container direction="row" justifyContent="center" alignItems="center" className={styles.loadingContainer}>
          <Grid item alignItems="center">
            <CircularProgress />
          </Grid>
        </Grid>
      ) : (
        <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-end" }}>
          <TableContainer
            className={styles.container}
            sx={{
              marginTop: shouldIncludeMissingData ? "-35px !important" : "-20px",
              maxHeight: {
                lg: isTenantDetails ? 400 : isGatesTable ? 295 : isTenants ? 300 : 303,
                xs: isGatesTable ? 265 : 300,
              },
            }}
          >
            <Table
              stickyHeader
              aria-label="sticky table"
              classes={{
                root: styles.tableRoot,
              }}
            >
              <TableHead>
                <TableRow
                  classes={{
                    root: styles.rowRoot,
                  }}
                >
                  {isTenants ? (
                    <TableCell
                      align="left"
                      classes={{
                        root: styles.cellRoot,
                        body: styles.body,
                        head: styles.head,
                      }}
                    >
                      {isSubcategory ? "Sub-Category" : "Category"}
                    </TableCell>
                  ) : isCorridorsPerformance ? (
                    <TableCell
                      align="left"
                      classes={{
                        root: styles.cellRoot,
                        body: styles.body,
                        head: styles.head,
                      }}
                    >
                      Corridor
                    </TableCell>
                  ) : isBranchesAnalytics ? (
                    <TableCell
                      align="left"
                      classes={{
                        root: styles.cellRoot,
                        body: styles.body,
                        head: styles.head,
                      }}
                    >
                      Branch
                    </TableCell>
                  ) : !!isDownTime ? (
                    <TableCell
                      align="left"
                      classes={{
                        root: styles.cellRoot,
                        body: styles.body,
                        head: styles.head,
                      }}
                    >
                      Day
                    </TableCell>
                  ) : isGatesTable || isCarGates ? (
                    <TableCell
                      align="left"
                      classes={{
                        root: styles.cellRoot,
                        body: styles.body,
                        head: styles.head,
                      }}
                    >
                      Gate
                    </TableCell>
                  ) : (
                    <TableCell
                      align="left"
                      classes={{
                        root: styles.cellRoot,
                        body: styles.body,
                        head: styles.head,
                      }}
                    >
                      Date/Time
                    </TableCell>
                  )}
                  {isTenants || isCorridorsPerformance ? (
                    <Fragment>
                      <TableCell
                        align="left"
                        classes={{
                          root: styles.cellRoot,
                          body: styles.body,
                          head: styles.head,
                        }}
                      >
                        Male Count
                      </TableCell>
                      <TableCell
                        align="left"
                        classes={{
                          root: styles.cellRoot,
                          body: styles.body,
                          head: styles.head,
                        }}
                      >
                        Female Count
                      </TableCell>
                      <TableCell
                        align="left"
                        classes={{
                          root: styles.cellRoot,
                          body: styles.body,
                          head: styles.head,
                        }}
                      >
                        Adult Count
                      </TableCell>
                      <TableCell
                        align="left"
                        classes={{
                          root: styles.cellRoot,
                          body: styles.body,
                          head: styles.head,
                        }}
                      >
                        Children Count
                      </TableCell>
                    </Fragment>
                  ) : !!dwellingType ? (
                    <TableCell
                      align="left"
                      classes={{
                        root: styles.cellRoot,
                        body: styles.body,
                        head: styles.head,
                      }}
                    >
                      {dwellingType}
                      {dwellingType?.includes("time") ? " (minutes) " : ""}
                    </TableCell>
                  ) : !!isBranchesAnalytics ? (
                    <TableCell
                      align="left"
                      classes={{
                        root: styles.cellRoot,
                        body: styles.body,
                        head: styles.head,
                      }}
                    >
                      Visitors Count
                    </TableCell>
                  ) : !!isDownTime ? (
                    <TableCell
                      align="left"
                      classes={{
                        root: styles.cellRoot,
                        body: styles.body,
                        head: styles.head,
                      }}
                    >
                      Duration
                    </TableCell>
                  ) : (
                    <Fragment>
                      <TableCell
                        align="left"
                        classes={{
                          root: styles.cellRoot,
                          body: styles.body,
                          head: styles.head,
                        }}
                      >
                        Count In
                      </TableCell>
                      {!isCarGates && (
                        <TableCell
                          align="left"
                          classes={{
                            root: styles.cellRoot,
                            body: styles.body,
                            head: styles.head,
                          }}
                        >
                          Count Out
                        </TableCell>
                      )}
                    </Fragment>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {rowsArray.map((row, index) => (
                  <Tooltip
                    placement="top"
                    key={`${index}_tooltip`}
                    classes={{
                      tooltip: styles.tooltip,
                    }}
                    title={
                      row.isModified ? (
                        <GraphTooltip
                          missingInfo={row?.missingInfo!}
                          isTableTooltip
                          originalCountIn={row?.countIn}
                          originalCountOut={row?.countOut}
                          missingCountIn={row?.missingCountIn}
                          missingCountOut={row?.missingCountOut}
                        />
                      ) : (
                        ""
                      )
                    }
                  >
                    <TableRow
                      classes={{
                        root: styles.rowRoot,
                      }}
                      key={index}
                    >
                      {isDownTime ? (
                        <TableCell
                          align="left"
                          classes={{
                            root: styles.cellRoot,
                            body: styles.body,
                            head: styles.head,
                          }}
                          component="th"
                          style={{
                            color: row.isModified ? "var(--red-text-2)" : "",
                          }}
                          scope="row"
                        >
                          {row.date! as string}
                        </TableCell>
                      ) : isBranchesAnalytics ? (
                        <TableCell
                          align="left"
                          classes={{
                            root: styles.cellRoot,
                            body: styles.body,
                            head: styles.head,
                          }}
                          component="th"
                          style={{
                            color: row.isModified ? "var(--red-text-2)" : "",
                          }}
                          scope="row"
                        >
                          {row.branch_name}
                        </TableCell>
                      ) : isTenants ? (
                        <TableCell
                          align="left"
                          classes={{
                            root: styles.cellRoot,
                            body: styles.body,
                            head: styles.head,
                          }}
                          component="th"
                          scope="row"
                        >
                          {isSubcategory === true ? row.sub_category! : row.category!}
                        </TableCell>
                      ) : isGatesTable || isCorridorsPerformance || isCarGates ? (
                        <TableCell
                          align="left"
                          classes={{
                            root: styles.cellRoot,
                            body: styles.body,
                            head: styles.head,
                          }}
                          component="th"
                          style={{
                            color: row.isModified ? "var(--red-text-2)" : "",
                          }}
                          scope="row"
                        >
                          {isCorridorsPerformance ? row.entity_name! : row.entity!}
                        </TableCell>
                      ) : isDownTime ? (
                        <TableCell
                          align="left"
                          classes={{
                            root: styles.cellRoot,
                            body: styles.body,
                            head: styles.head,
                          }}
                          component="th"
                          style={{
                            color: row.isModified ? "var(--red-text-2)" : "",
                          }}
                          scope="row"
                        >
                          {row.date! as string}
                        </TableCell>
                      ) : (
                        <TableCell
                          align="left"
                          classes={{
                            root: styles.cellRoot,
                            body: styles.body,
                            head: styles.head,
                          }}
                          style={{
                            color: row.isModified ? "var(--red-text-2)" : "",
                          }}
                          component="th"
                          scope="row"
                        >
                          {new Date(row.date!).toLocaleString(
                            "en-US",
                            timeGrain === "hour"
                              ? {
                                  hour12: true,
                                  hour: "2-digit",
                                }
                              : {
                                  hour12: true,
                                  weekday: "short",
                                  day: "numeric",
                                  month: "short",
                                }
                          )}
                        </TableCell>
                      )}
                      {isDownTime ? (
                        <TableCell
                          align="left"
                          classes={{
                            root: styles.cellRoot,
                            body: styles.body,
                            head: styles.head,
                          }}
                        >
                          {convertSecondsToReadableTime(row.duration)}
                        </TableCell>
                      ) : isTenants || isCorridorsPerformance ? (
                        <Fragment>
                          <TableCell
                            align="left"
                            classes={{
                              root: styles.cellRoot,
                              body: styles.body,
                              head: styles.head,
                            }}
                          >
                            {row.male_count}
                          </TableCell>
                          <TableCell
                            align="left"
                            classes={{
                              root: styles.cellRoot,
                              body: styles.body,
                              head: styles.head,
                            }}
                          >
                            {row.female_count}
                          </TableCell>
                          <TableCell
                            align="left"
                            classes={{
                              root: styles.cellRoot,
                              body: styles.body,
                              head: styles.head,
                            }}
                          >
                            {row.adult_count}
                          </TableCell>
                          <TableCell
                            align="left"
                            classes={{
                              root: styles.cellRoot,
                              body: styles.body,
                              head: styles.head,
                            }}
                          >
                            {row.child_count}
                          </TableCell>
                        </Fragment>
                      ) : isCarGates ? (
                        <Fragment>
                          <TableCell
                            align="left"
                            classes={{
                              root: styles.cellRoot,
                              body: styles.body,
                              head: styles.head,
                            }}
                          >
                            {row.count || 0}
                          </TableCell>
                        </Fragment>
                      ) : isBranchesAnalytics ? (
                        <Fragment>
                          <TableCell
                            align="left"
                            classes={{
                              root: styles.cellRoot,
                              body: styles.body,
                              head: styles.head,
                            }}
                          >
                            {row.total_count_in_sum || 0}
                          </TableCell>
                        </Fragment>
                      ) : !!dwellingType ? (
                        <TableCell
                          align="left"
                          classes={{
                            root: styles.cellRoot,
                            body: styles.body,
                            head: styles.head,
                          }}
                        >
                          {row.dwellingValue}
                        </TableCell>
                      ) : (
                        <Fragment>
                          <TableCell
                            align="left"
                            style={{
                              color: row.isModified ? "var(--red-text-2)" : "",
                            }}
                            classes={{
                              root: styles.cellRoot,
                              body: styles.body,
                              head: styles.head,
                            }}
                          >
                            {(row.countIn || 0) + (row.missingCountIn || 0)}
                          </TableCell>
                          <TableCell
                            align="left"
                            style={{
                              color: row.isModified ? "var(--red-text-2)" : "",
                            }}
                            classes={{
                              root: styles.cellRoot,
                              body: styles.body,
                              head: styles.head,
                            }}
                          >
                            {(row.countOut || 0) + (row.missingCountOut || 0)}
                          </TableCell>
                        </Fragment>
                      )}
                    </TableRow>
                  </Tooltip>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Button startIcon={<GetAppOutlinedIcon />} onClick={handleExportCSV} className={styles.export}>
            Export table
          </Button>
        </div>
      )}
    </Fragment>
  )
}
export default CountTable
