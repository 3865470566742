import React, { useState, useEffect, Fragment } from "react"

import { Box } from "@mui/material"
import Checkbox from "@mui/material/Checkbox"
import FormControlLabel from "@mui/material/FormControlLabel"
import Grid from "@mui/material/Grid"

import { ResponsiveLine } from "@nivo/line"

import { findMissingDataEntry, graphColors, lineGraphLayers } from "../../utils/graphUtils"
import GenderAgeCard from "../GenderAgeCard"
import GraphTooltip from "../GraphTooltip"
import GraphEmptyState from "./GraphEmptyState"
import barChartLoading from "./assets/barChartLoading.mp4"
import { gridTheme } from "./gridTheme"

import styles from "./dailyLineGraph.module.scss"

function DailyLineGraph(props) {
  const { data, graphHeight, isLoading, cars, noCheckBox, interval, hasGenderAge } = props
  const [checkBoxStates, setCheckBoxStates] = React.useState({
    countIn: false,
    countOut: false,
  })
  const [slicedData, setSlicedData] = useState(data)
  // filter out data based on checkboxes state
  useEffect(() => {
    // Array to store indices based on checkbox states
    const indicesToInclude = []

    // Check if countIn checkbox is checked, and include corresponding indices
    if (checkBoxStates.countIn) {
      indicesToInclude.push(1)
    }

    // Check if countOut checkbox is checked, and include corresponding indices
    if (checkBoxStates.countOut) {
      indicesToInclude.push(0)
    }

    // Create newDataArr by mapping indices to corresponding values and filter out undefined values
    const newDataArr = indicesToInclude.map((index) => data[index]).filter((value) => value !== undefined)

    // Set slicedData based on whether newDataArr has any elements, else slice the original data
    setSlicedData(indicesToInclude.length > 0 ? newDataArr : data.filter((element) => element.id !== "Occupancy"))
  }, [checkBoxStates, data])

  const handleChange = (event) => {
    setCheckBoxStates({ ...checkBoxStates, [event.target.name]: event.target.checked })
  }

  return (
    <Fragment>
      <Box
        style={{
          height: graphHeight,
        }}
      >
        {data[0]?.data.length < 1 && data[0]?.data && !isLoading ? (
          <GraphEmptyState />
        ) : isLoading ? (
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            className={styles.loadingContainer}
          >
            <Grid item align="center">
              <video autoPlay={true} loop={true} width="300" height="200">
                <source src={barChartLoading} type="video/mp4" />
              </video>
            </Grid>
          </Grid>
        ) : (
          <ResponsiveLine
            data={slicedData}
            theme={gridTheme}
            margin={{ top: 60, right: 33, bottom: noCheckBox ? 120 : 70, left: 53 }}
            xScale={{ type: "time", format: "native", precision: "day", useUTC: false }}
            xFormat="time:%a %d %b"
            enablePoints={true}
            curve="cardinal"
            yScale={{ type: "linear", min: "auto", max: "auto", stacked: false, reverse: false }}
            areaOpacity={0.07}
            enableArea={true}
            layers={lineGraphLayers}
            sliceTooltip={({ slice }) => {
              return hasGenderAge ? (
                <GenderAgeCard
                  adultCount={slice.points[0].data.adultCount}
                  childCount={slice.points[0].data.childCount}
                  femaleStat={slice.points[0].data.femaleCount}
                  maleStat={slice.points[0].data.maleCount}
                  loading={false}
                  key={slice.id}
                  pointDate={slice.points[0].data.xFormatted}
                  graphTooltip
                />
              ) : (
                <GraphTooltip
                  slicePoints={slice.points}
                  timeStamp={slice.points[0].data.xFormatted}
                  key={slice.id}
                  missingInfo={findMissingDataEntry(slice.points)}
                />
              )
            }}
            axisTop={null}
            axisRight={null}
            axisBottom={{
              tickValues: interval && interval.months >= 1 ? "every 2 days" : "every 1 day",
              format: "%a %d %b",
              orient: "bottom",
              legend: cars ? "date" : "",
              tickSize: 6,
              tickRotation: -45,
              tickPadding: 9,
              legendPosition: "middle",
            }}
            axisLeft={{
              orient: "left",
              tickSize: 0,
              tickPadding: 8,
              tickRotation: 0,
              format: (e) => Math.floor(e) === e && e,
              legend: cars ? "Time Spent" : "",
              legendOffset: -50,
              legendPosition: "middle",
            }}
            legends={
              noCheckBox
                ? [
                    {
                      anchor: "bottom",
                      direction: "row",
                      justify: false,
                      translateX: 4,
                      translateY: 110,
                      itemsSpacing: 6,
                      itemDirection: "left-to-right",
                      itemWidth: 109,
                      itemHeight: 25,
                      itemOpacity: 0.75,
                      symbolSize: 14,
                      symbolShape: "circle",
                      symbolBorderColor: "rgba(0, 0, 0, .5)",
                      effects: [
                        {
                          on: "hover",
                          style: {
                            itemBackground: "rgba(0, 0, 0, .03)",
                            itemOpacity: 1,
                          },
                        },
                      ],
                    },
                  ]
                : []
            }
            colors={(line) => (cars ? "var(--green-background-1)" : graphColors[line.id])}
            pointSize={12}
            pointSymbol={(e) => {
              return (
                <circle
                  cx="0"
                  cy="0"
                  r="6"
                  strokeWidth="3"
                  fill={!!e.datum.missingInfo && e.datum.missingInfo?.length > 0 ? "#E5484D" : "transparent"}
                />
              )
            }}
            pointBorderWidth={0}
            pointBorderColor={{ from: "serieColor" }}
            enablePointLabel={false}
            pointLabel="y"
            pointLabelYOffset={-12}
            useMesh={true}
            enableSlices="x"
          />
        )}
      </Box>
      {!noCheckBox && (
        <Grid container spacing={2} width="100%" justifyContent="center">
          <Grid item>
            <FormControlLabel
              className={styles.checkBox}
              style={{ color: "var(--blue-text-2)" }}
              control={
                <Checkbox
                  sx={{
                    color: "var(--blue-text-2)",
                    "&.Mui-checked": {
                      color: "var(--blue-text-2)",
                    },
                  }}
                  checked={checkBoxStates.countIn}
                  onChange={handleChange}
                  name="countIn"
                />
              }
              label="Incount"
            />
          </Grid>
          <Grid item>
            <FormControlLabel
              className={styles.checkBox}
              style={{ color: "var(--pink-background-1)" }}
              control={
                <Checkbox
                  sx={{
                    color: "var(--pink-background-1)",
                    "&.Mui-checked": {
                      color: "var(--pink-background-1)",
                    },
                  }}
                  checked={checkBoxStates.countOut}
                  onChange={handleChange}
                  name="countOut"
                />
              }
              label="Outcount"
            />
          </Grid>
        </Grid>
      )}
    </Fragment>
  )
}

export default DailyLineGraph
