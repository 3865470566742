import React, { useContext, Fragment } from "react"

import { Card, CardMedia, CardActionArea, Box } from "@mui/material"

import { Typography } from "@synapse-analytics/synapse-ui"
import moment from "moment"

import { definitions } from "../../../../types/Generated/apiTypes"
import { getAuthBaseUrl } from "../../../../utils/auth"
import { FloorsContext } from "../../floorsContext/FloorsContext"

import styles from "./FloorVersionCard.module.scss"

type FloorPlanVersion = definitions["FloorPlanVersionList"]

const APIURL = getAuthBaseUrl()

const FloorVersionCard = ({ version }: { version: FloorPlanVersion }) => {
  const { floorVersionId, selectFloorVersion, formik } = useContext(FloorsContext)

  const handleSelectVersion = () => {
    selectFloorVersion(version.id ? version.id : 0)
    formik.setFieldValue("floorPreview", `${APIURL}${version?.image!}`)
  }
  return (
    <Fragment>
      <Box
        sx={{
          display: {
            sm: "block",
            xs: "none",
          },
        }}
      >
        <span className={styles.versionDates}>
          <span className={styles.versionDatesTimeline}>{moment(version?.created_at).format("MMMM Do  h:mm A")}</span>
        </span>
      </Box>
      {/* Version Card */}
      <Card
        className={styles.card}
        sx={{
          border: "3px solid",
          borderColor: floorVersionId === version.id ? "var(--indigo-background-1)" : "transparent",
        }}
        onClick={handleSelectVersion}
      >
        <CardActionArea>
          {/* floor image */}
          <CardMedia className={styles.floorImage} image={`${APIURL}${version?.image!}`} />
        </CardActionArea>
        <Fragment>
          <div className={styles.versionDetails}>
            <Typography variant="a" align="center">
              Version {version?.version_no}
            </Typography>
          </div>
          <div className={styles.footer}>
            <Typography variant="p" variantColor={2} className={styles.versionCameras} noWrap>
              {version?.placed_cameras!} Cameras
            </Typography>
            <div
              className={styles.versionStatus}
              style={{
                border: `1px solid ${
                  version?.placed_cameras! > version?.mapped_cameras!
                    ? "var(--red-background-1)"
                    : version?.mapped_cameras! !== 0 &&
                      version?.placed_cameras! !== 0 &&
                      version?.placed_cameras! === version?.mapped_cameras!
                    ? "#59CD90"
                    : "#A3A3A3CC"
                }`,
                color:
                  version?.placed_cameras! > version?.mapped_cameras!
                    ? "var(--red-background-1)"
                    : version?.mapped_cameras! !== 0 &&
                      version?.placed_cameras! !== 0 &&
                      version?.placed_cameras! === version?.mapped_cameras!
                    ? "#59CD90"
                    : "#A3A3A3CC",
              }}
            >
              <Typography
                variant="span"
                align="center"
                variantColor={2}
                color={
                  version?.placed_cameras! > version?.mapped_cameras!
                    ? "negative"
                    : version?.mapped_cameras! !== 0 &&
                      version?.placed_cameras! !== 0 &&
                      version?.placed_cameras! === version?.mapped_cameras!
                    ? "positive"
                    : "neutral"
                }
              >
                {version?.placed_cameras! > version?.mapped_cameras!
                  ? "Mapping Incomplete"
                  : version?.mapped_cameras! !== 0 &&
                    version?.placed_cameras! !== 0 &&
                    version?.placed_cameras! === version?.mapped_cameras!
                  ? "Mapping Complete"
                  : "Mapping Pending"}
              </Typography>
            </div>
          </div>
        </Fragment>
      </Card>
    </Fragment>
  )
}

export default FloorVersionCard
