import { Fragment } from "react"
import { useQueryClient } from "react-query"

import { Grid } from "@mui/material"

import { Typography } from "@synapse-analytics/synapse-ui"
import { DelimitedNumericArrayParam, NumberParam, useQueryParams, withDefault } from "use-query-params"

import CamerasLive from "../Cameras/CamerasLive"
import CamerasSelection from "./components/CamerasSelection"
import LiveFeed from "./components/LiveFeed"

import styles from "./LiveStreams.module.scss"

const LiveStream = () => {
  const [query, setQuery] = useQueryParams({
    selectedCameras: withDefault(DelimitedNumericArrayParam, []),
    activeTab: withDefault(NumberParam, 0),
  })

  const queryClient = useQueryClient()

  const handleChangeCamera = (selectedCamera: number) => {
    setQuery({ selectedCameras: [selectedCamera] })
    if (selectedCamera) {
      setTimeout(() => {
        queryClient?.invalidateQueries("fetchCamerasStream")
      }, 100)
    }
  }

  const handleChangeActiveTab = (tab: number) => {
    setQuery({ activeTab: tab })
  }

  return (
    <Fragment>
      <Typography
        variant="h2-regular"
        tooltip="Live cameras' streams with events feed"
        tooltipPlacement="right"
        tooltipIconSize={22}
        gutterBottom
        variantColor={2}
      >
        Live Streams
      </Typography>
      <Grid container spacing={2} mt={1}>
        <Grid item xs={12} sm={8} xl={9} className={styles.streamSectionWrapper}>
          <div className={styles.streamHeader}>
            <Typography variant="h3-bold" variantColor={2} gutterBottom>
              {(query.selectedCameras && query.selectedCameras.length > 0 && "Selected cameras live feed") ||
                "Select a camera to view live stream"}
            </Typography>
          </div>
          <CamerasLive selectedCameras={query.selectedCameras as number[]} cameraMode="full" />
          <CamerasSelection
            handleSelectCamera={handleChangeCamera}
            selectedCameras={query.selectedCameras as number[]}
            isCarsFeed={query.activeTab === 1}
          />
        </Grid>
        <Grid item xs={12} sm={4} xl={3}>
          <div className={styles.feedWrapper}>
            <div className={styles.streamHeader}>
              <Typography variant="h3-bold" variantColor={2} gutterBottom>
                Live Feed
              </Typography>
            </div>
            <LiveFeed activeTab={query.activeTab} handleChangeActiveTab={handleChangeActiveTab} />
          </div>
        </Grid>
      </Grid>
    </Fragment>
  )
}
export default LiveStream
