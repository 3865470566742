import { useState, Fragment, FC, useRef } from "react"
import { useQuery } from "react-query"

import { Typography } from "@synapse-analytics/synapse-ui"

import { VisionAPI } from "../../API/VisionAPI"
import CameraPlaceholder from "../../assets/noFrame.svg?react"
// ReactHlsPlayer for HTTP Live streaming videos [HLS]
import ReactHlsPlayer from "../../components/ReactHlsPlayer"
import Auth from "../../utils/auth"

import styles from "./CamerasLive.module.scss"

interface Props {
  isCars?: boolean
  isSecurity?: boolean
  isCameraDetails?: boolean
  debugService?: "counter" | "car"
  cameraMode?: "full" | "light" | "debug"
  selectedCameras?: number[]
}
const CamerasLive: FC<Props> = ({ isCameraDetails, cameraMode, debugService, selectedCameras }) => {
  const [refetchStreams, setRefetchStreams] = useState(true)
  const playerRef = useRef(null)
  // fetch cameras stream
  const { data } = useQuery(
    ["fetchCamerasStream", selectedCameras, cameraMode, debugService],
    ({ queryKey }) => {
      const selectedCameras = queryKey[1] as number[]
      //EX: camera=1&camera=2&camera=3&.....
      let allCameras = ""
      if (selectedCameras) {
        selectedCameras.forEach(
          (camera, index) => (allCameras += `camera=${camera}${index + 1 < selectedCameras.length ? "&" : ""}`)
        )
      }
      return VisionAPI.fetchCamerasStream({
        cameras: allCameras,
        mode: queryKey[2] as string,
        service: queryKey[2] !== "full" ? (queryKey[3] as string) : undefined,
      })
    },
    {
      enabled: selectedCameras && selectedCameras?.length > 0,
      // refetch stream every 1.5 sec until stream connection in on
      refetchInterval: refetchStreams ? 1500 : false,
    }
  )

  // pass Authorization token with stream url [via player]
  const config: any = {
    xhrSetup: function (xhr: XMLHttpRequest) {
      const authStreamToken = `Bearer ${Auth.getAccessToken()}`
      xhr.setRequestHeader("Authorization", authStreamToken)
      // if response stream is 404 [retry]
      setTimeout(() => {
        if (xhr?.status === 200) {
          setRefetchStreams(false)
        } else if (xhr?.status === 404) {
          console.clear()
          setRefetchStreams(true)
        }
      }, 1000)
    },
  }

  const renderStreams = () => {
    if (data && selectedCameras && selectedCameras?.length > 0) {
      return (
        <ReactHlsPlayer
          hlsConfig={config}
          loop={false}
          autoPlay={true}
          className={styles.cameraStream}
          style={{
            backgroundColor: isCameraDetails ? "var(--greyScale-text-2)" : "var(--greyScale-background-2)",
            maxHeight: isCameraDetails ? "290px" : "450px",
            minHeight: !isCameraDetails ? "400px" : "",
            borderRadius: isCameraDetails ? 4 : "",
          }}
          src={data?.hls_url}
          controls={true}
          playerRef={playerRef}
        />
      )
    } else {
      return (
        <div
          className={styles.noStream}
          style={{
            maxHeight: !isCameraDetails ? "460px" : "",
          }}
        >
          <CameraPlaceholder />
          {!isCameraDetails && (
            <Fragment>
              <Typography variant="h2-bold" variantColor={2} className={styles.notFoundCameraDesc}>
                No Cameras Selected
              </Typography>
              <Typography variant="p" variantColor={2}>
                select cameras to start watching live footage
              </Typography>
            </Fragment>
          )}
        </div>
      )
    }
  }

  return (
    <Fragment>
      {/* Camera stream */}
      {renderStreams()}
    </Fragment>
  )
}

export default CamerasLive
