import React, { useContext, useState, FC } from "react"
import Dropzone from "react-dropzone"

import { Box } from "@mui/material"

import { Typography } from "@synapse-analytics/synapse-ui"

import FileUpload from "../../assets/fileUpload.svg?react"
import UploadPlaceholder from "../../assets/uploadVersionPlaceholder.svg?react"
import { FloorsContext } from "../../floorsContext/FloorsContext"

import styles from "./DragDropFloor.module.scss"

interface Props {
  edit?: boolean
  textPlaceholder: string
}

const DragDropFloor: FC<Props> = ({ edit, textPlaceholder }) => {
  const { formik, handleDropFloor, floorVersionId } = useContext(FloorsContext)
  const [isHovering, setIsHovering] = useState(false)
  const handleMouseOver = () => {
    setIsHovering(true)
  }
  const handleMouseOut = () => {
    setIsHovering(false)
  }
  return (
    <Dropzone
      onDrop={handleDropFloor}
      accept={{
        "image/*": [],
      }}
      minSize={1024}
      maxSize={3072000}
    >
      {({ getRootProps, getInputProps, isDragAccept, isDragReject }) => {
        const additionalClass = isDragAccept ? styles.accept : isDragReject ? styles.reject : ""
        return (
          <div
            onMouseOver={handleMouseOver}
            onMouseOut={handleMouseOut}
            {...getRootProps({
              className: `${styles.floorDropZone} ${additionalClass}`,
              style: {
                height: edit ? "223px" : "auto",
                marginTop: edit ? 0 : "8px",
                margin: edit ? "0px 8px" : 0,
                padding: !edit && formik?.values.floorPreview === "" ? "12px" : 0,
                borderRadius: edit ? "10px" : "4px",
                border: !edit
                  ? "2px dashed var(--neutral-text-enabled)"
                  : floorVersionId === 0 && formik?.values.floorPreview
                  ? "3px solid var(--indigo-background-1)"
                  : "3px solid var(--greyScale-background-1)",
                backgroundColor: isHovering
                  ? "var(--neutral-background-hover)"
                  : !edit
                  ? "var(--greyScale-background-3)"
                  : "var(--important-text-enabled)",
                marginBottom: !edit ? "20px" : 0,
                minHeight: edit ? "0px" : "80px",
                "&:hover": {
                  backgroundColor: edit ? "var(--indigo-background-2)" : "var(--neutral-background-hover)",
                },
              },
            })}
          >
            <input {...getInputProps()} />
            {formik?.values.floorPreview !== "" && !floorVersionId ? (
              <img
                className={styles.floorPreview}
                style={{
                  borderRadius: edit ? "10px" : "2px",
                }}
                key={formik?.values.floorPreview}
                src={formik?.values.floorPreview!}
                alt="floorMap"
              />
            ) : (
              <Box
                className={styles.addIconWrapper}
                sx={{
                  border: edit ? "1px solid var(--greyScale-background-1)" : "0px",
                  background: edit ? "var(--important-text-enabled)" : "",
                  borderRadius: edit ? "8px" : "0px",
                  fontSize: edit ? "12px" : "14px",
                  fontWeight: edit ? 600 : 400,
                  "&:hover": {
                    backgroundColor: edit ? "var(--indigo-background-2)" : "var(--neutral-background-hover)",
                  },
                }}
              >
                {edit && <UploadPlaceholder className={styles.addIcon} />}
                <div className={styles.floorPlaceholder}>
                  {!edit && <FileUpload className={styles.uploadIcon} />}
                  <Typography variant={edit ? "label" : "p"} variantColor={2} color={edit ? "important" : "neutral"}>
                    {textPlaceholder}
                  </Typography>
                </div>
              </Box>
            )}
          </div>
        )
      }}
    </Dropzone>
  )
}

export default DragDropFloor
