import { Typography } from "@synapse-analytics/synapse-ui"

import CopyToClipboard from "../../components/CopyToClipboard"
import { getAuthBaseUrl } from "../../utils/auth"
import AccessTokensTable from "./components/AccessTokensTable"
import SwaggerDocs from "./components/SwaggerDocs"

import styles from "./APIIntegration.module.scss"

const APIUrl = `${getAuthBaseUrl()}/api/`
const APIIntegration = () => {
  return (
    <div className={styles.wrapper}>
      <Typography
        variant="h2-regular"
        tooltip="Manage your access tokens and integrate with Azka Vision API"
        tooltipPlacement="right"
        tooltipIconSize={22}
        variantColor={2}
      >
        API Integration
      </Typography>
      <div className={styles.container}>
        <Typography variant="h3-bold" variantColor={2}>
          Instructions to integrate
        </Typography>
        <ul className={styles.instructions}>
          <li>
            <Typography variant="p" variantColor={2}>
              Azkavision allows direct integration with our backend through API calls.
            </Typography>
          </li>
          <li>
            <Typography variant="p" variantColor={2}>
              This requires having an <span className={styles.bold}>Active User Account</span> or
              <span className={styles.bold}> An Access Token</span> .
            </Typography>
          </li>
        </ul>
      </div>
      <div className={styles.container}>
        <Typography variant="h3-bold" variantColor={2}>
          Connecting to our API
        </Typography>
        <Typography variant="p" variantColor={2}>
          Request to our api are sent on your Azkavision subdomain or Web portal IP address on port 8000 then followed
          by the required url from the api schema.
        </Typography>
        <Typography variant="p" variantColor={2}>
          For example
        </Typography>
        <CopyToClipboard value={`${APIUrl}`} name="base url" additionalNotToCopy="some-url" />
      </div>
      <AccessTokensTable />
      <SwaggerDocs />
    </div>
  )
}

export default APIIntegration
