import { FC } from "react"

import UnknownIcon from "@mui/icons-material/NoAccounts"
import { Tooltip } from "@mui/material"

import { Typography, Skeleton } from "@synapse-analytics/synapse-ui"

import styles from "./DistributionBar.module.scss"

interface BarData {
  percentage: number
  label: string
  value?: number | string
  color: string
  tooltipTitle: string
  icon?: string
}

interface DistributionBarProps {
  leftBar: BarData
  rightBar: BarData
  unknown?: number
  isLoading?: boolean
  title: string
  isRaceBar?: boolean
}

const DistributionBar: FC<DistributionBarProps> = ({ leftBar, rightBar, unknown, isLoading, title, isRaceBar }) => {
  const hasCounts = (!!leftBar?.value && !!rightBar.value) || isRaceBar

  return (
    <div>
      <Typography className={styles.distributionTitle} variant="label" variantColor={2}>
        {title}
      </Typography>
      {isLoading ? (
        <Skeleton variant="rectangular" width="100%" height={27} />
      ) : (
        <div className={styles.distributionBarContainer}>
          {!!leftBar?.value && (
            <Tooltip placement="right" title={leftBar.tooltipTitle}>
              <div
                className={styles.leftSideDivision}
                style={{
                  width: `${leftBar.percentage}%`,
                  background: leftBar.color,
                }}
              >
                <div className={styles.barContent}>
                  <Typography variant="span" variantColor={2}>
                    {leftBar.value?.toLocaleString() || "No Data Yet"}
                  </Typography>
                </div>
              </div>
            </Tooltip>
          )}
          <div
            className={styles.rightSideDivision}
            style={{
              width: `${hasCounts ? rightBar.percentage : 100}%`,
              background: hasCounts ? rightBar.color : "var(--indigo-background-2)",
            }}
          >
            <Tooltip placement="right" title={hasCounts ? rightBar.tooltipTitle : "No Data"}>
              <div className={styles.barContent}>
                <Typography variant="span" variantColor={2}>
                  {rightBar.value?.toLocaleString() || (!isRaceBar && "No Data Yet")}
                </Typography>
              </div>
            </Tooltip>
          </div>
          {!!unknown && (
            <div className={styles.rightSideDivision}>
              <Tooltip placement="right" title={`Unknown : ${unknown.toLocaleString()}`}>
                <div className={styles.barContent} style={{ background: "var(--greyScale-background-2)" }}>
                  <Typography variant="span" variantColor={2}>
                    {unknown.toLocaleString()}
                  </Typography>
                </div>
              </Tooltip>
            </div>
          )}
        </div>
      )}
      <div className={styles.barsDescription}>
        <Typography variantColor={2} variant="span">
          {leftBar.icon && <img src={leftBar.icon} alt={`${leftBar.label}_icon`} className={styles.icon} />}
          {leftBar.label}
        </Typography>
        <Typography variantColor={2} variant="span">
          {rightBar.icon && <img src={rightBar.icon} alt={`${rightBar.label}_icon`} className={styles.icon} />}
          {rightBar.label}
        </Typography>
        {!!unknown && (
          <Typography variant="span" variantColor={2} className={styles.unknown}>
            <UnknownIcon className={styles.icon} fontSize="small" />
            Unknown
          </Typography>
        )}
      </div>
    </div>
  )
}

export default DistributionBar
