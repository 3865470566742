import React, { FC, useContext } from "react"

import { Grid } from "@mui/material"

import { definitions } from "../../../types/Generated/apiTypes"
import { CampaignContext } from "../CampaignContext/CampaignContext"
import CampaignGeneral from "./CampaignGeneral"
import CampaignStatus from "./CampaignStatus"

type CampaignDetail = definitions["CampaignDetail"]

interface Props {
  chosenCampaign: boolean
  singleCampaign: CampaignDetail
  title: string
}

const CampaignDetails: FC<Props> = ({ singleCampaign, title, chosenCampaign }) => {
  const { compare } = useContext(CampaignContext)

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={compare ? 12 : 7}>
        <CampaignGeneral chosenCampaign={chosenCampaign} campaign={singleCampaign!} />
      </Grid>
      <Grid item xs={12} md={compare ? 12 : 5}>
        <CampaignStatus chosenCampaign={chosenCampaign} campaign={singleCampaign!} title={title} />
      </Grid>
    </Grid>
  )
}

export default CampaignDetails
