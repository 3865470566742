import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, AxiosError, InternalAxiosRequestConfig } from "axios"

import { browserHistory } from "../history"
import { storeApi } from "../store"
import Auth from "../utils/auth"
import { handleAxiosError } from "../utils/genericHelpers"

// Function to get API Base URL
export function getApiBaseUrl(): string {
  const [apiProtocol, apiHostname, apiPort] = [
    storeApi.getState().apiProtocol, // Get API protocol from the store
    storeApi.getState().apiHostname, // Get API hostname from the store
    storeApi.getState().apiPort, // Get API port from the store
  ]
  return `${apiProtocol}://${apiHostname}:${apiPort}` // Construct and return the base URL
}

// Extend Axios request config to include a custom property for retry logic and operation type
interface CustomAxiosRequestConfig extends InternalAxiosRequestConfig {
  _retry?: boolean // Custom property to track if the request has been retried
  _operationType?: "query" | "mutation" // Custom property to track the operation type (query or mutation)
}

class AxiosApi {
  public axiosInstance: AxiosInstance // Axios instance for making HTTP requests

  constructor() {
    this.axiosInstance = axios.create({
      responseType: "json", // Default response type as JSON
    })

    // Initialize request interceptor
    this.axiosInstance.interceptors.request.use(
      (config: CustomAxiosRequestConfig) => {
        const token = Auth.getAccessToken() // Get the access token from Auth utility
        if (token && config.headers) {
          config.headers["Authorization"] = `Bearer ${token}` // Add the Authorization header if the token exists
        }

        // Determine if the request is a query or mutation based on the method
        config._operationType = config.method === "get" ? "query" : "mutation" // Set custom property for operation type

        return config // Return the modified config
      },
      // Handle request error
      (error: AxiosError) => {
        return Promise.reject(error).catch(console.error) // Handle request error
      }
    )

    // Initialize response interceptor
    this.axiosInstance.interceptors.response.use(
      (response: AxiosResponse) => response, // Return response if successful
      async (error: AxiosError) => {
        const originalRequest = error.config as CustomAxiosRequestConfig // Cast error config to custom type
        // Check if the error is a 401 and if the request was for refreshing the token
        if (error.response?.status === 401 && originalRequest?.url === `${getApiBaseUrl()}/api/token/refresh/`) {
          Auth.logOut() // Log out the user if the refresh token fails
          browserHistory.push("/") // Redirect to home
          return Promise.reject(error) // Reject the error
        }
        // Check if the error is a 401 and the request has not been retried yet
        if (error.response?.status === 401 && !originalRequest?._retry) {
          originalRequest._retry = true // Mark the request as retried
          const refreshToken = Auth.getRefreshToken() // Get the refresh token
          try {
            // Try to refresh the access token using the refresh token
            const res = await this.axiosInstance.post(`${getApiBaseUrl()}/api/token/refresh/`, {
              refresh: refreshToken, // Send the refresh token to refresh the access token
            })
            if (res.status === 200) {
              Auth.setAccessToken(res.data.access) // Update the access token
              this.axiosInstance.defaults.headers.common["Authorization"] = `Bearer ${Auth.getAccessToken()}` // Set new token in headers
              document.cookie = `authorization=${Auth.getAccessToken()}; path=/` // Update cookie with new token
              return this.axiosInstance(originalRequest) // Retry the original request with new token
            }
          } catch (refreshError) {
            return Promise.reject(refreshError).catch(console.error) // If refresh fails, reject the promise
          }
        }

        // Use custom property to determine if the error is from a query or mutation
        const operationType = originalRequest?._operationType || "mutation" // Default to mutation if undefined
        return Promise.reject(handleAxiosError(error, operationType)).catch(console.error) // Handle the error and reject the promise
      }
    )
  }

  // Method to fetch a resource using GET request
  public fetchResource = (endpoint: string, params = {}): Promise<AxiosResponse> => {
    const paramsObj = { params } // Wrap parameters in an object
    return this.axiosInstance.get(getApiBaseUrl() + endpoint, paramsObj) // Make GET request
  }

  // Method to post a resource using POST request
  public postResource = (endpoint: string, params = {}, config: AxiosRequestConfig = {}): Promise<AxiosResponse> => {
    return this.axiosInstance.post(getApiBaseUrl() + endpoint, params, config) // Make POST request
  }

  // Method to delete a resource using DELETE request
  public deleteResource = (endpoint: string, config: AxiosRequestConfig = {}): Promise<AxiosResponse> => {
    return this.axiosInstance.delete(getApiBaseUrl() + endpoint, config) // Make DELETE request
  }

  // Method to update a resource using PATCH request
  public patchResource = (endpoint: string, params = {}, config: AxiosRequestConfig = {}): Promise<AxiosResponse> => {
    return this.axiosInstance.patch(getApiBaseUrl() + endpoint, params, config) // Make PATCH request
  }

  // Method to replace a resource using PUT request
  public putResource = (endpoint: string, params = {}, config: AxiosRequestConfig = {}): Promise<AxiosResponse> => {
    return this.axiosInstance.put(getApiBaseUrl() + endpoint, params, config) // Make PUT request
  }
}

export { AxiosApi }
