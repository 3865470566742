import { forwardRef, FC } from "react"

import HighlightOffIcon from "@mui/icons-material/Close"
import { Card, Dialog, DialogContent, Slide, SlideProps } from "@mui/material"

import { Typography } from "@synapse-analytics/synapse-ui"

import styles from "./ViewFrameDialog.module.scss"

const Transition = forwardRef(function Transition(props: SlideProps, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

interface Props {
  handleCloseDialog: () => void
  open: boolean
  frame: string
  name?: string
}

const ViewFrameDialog: FC<Props> = ({ handleCloseDialog, open, frame, name }) => {
  return (
    <Dialog open={open} maxWidth="md" TransitionComponent={Transition} onClose={handleCloseDialog}>
      <DialogContent className={styles.wrapper}>
        <div className={styles.header}>
          <Typography variant="h2-bold" className={styles.title}>
            {name}
          </Typography>
          <HighlightOffIcon onClick={handleCloseDialog} className={styles.iconContainer} />
        </div>

        <Card className={styles.frameCard}>
          <img src={frame} alt="frame" className={styles.frame} />
        </Card>
      </DialogContent>
    </Dialog>
  )
}
export default ViewFrameDialog
