import React, { FC, useContext, useEffect, Fragment } from "react"
import { useQuery } from "react-query"

import { CircularProgress, Snackbar, Box, Alert, Grid } from "@mui/material"

import { InputText, Typography } from "@synapse-analytics/synapse-ui"
import { AxiosError } from "axios"

import { VisionAPI } from "../../../../API/VisionAPI"
import TagsList from "../../../../components/TagsList"
import { definitions } from "../../../../types/Generated/apiTypes"
import { FloorsContext } from "../../floorsContext/FloorsContext"
import DragDropFloor from "./DragDropFloor"
import FloorVersions from "./FloorVersions"

import styles from "./FloorDetails.module.scss"

type FloorPlanVersionList = definitions["FloorPlanVersionList"]
type FloorList = definitions["FloorPlanList"]
type Tag = definitions["Tag"]

interface Props {
  handleClose: () => void
  open: boolean
  edit?: boolean
  floor?: FloorList
}

const FloorDetails: FC<Props> = ({ handleClose, open, edit, floor }) => {
  const { formik, floorEditSuccuss } = useContext(FloorsContext)
  const { data: floorVersions, isFetching } = useQuery<FloorPlanVersionList[], AxiosError>(
    ["fetchFloorVersions", floor?.id],
    async ({ queryKey }) => VisionAPI.fetchFloorPlanAllVersions(queryKey[1] as number),
    { enabled: Boolean(!!edit && !!open && !!floor?.id) }
  )

  // fetch all floors
  const { data: floorTags, isLoading: isLoadingTags } = useQuery<Tag[]>("fetchFloorTags", VisionAPI.fetchFloorTags)

  useEffect(() => {
    if (edit && open) {
      formik?.setFieldValue("floorName", floor?.floor_name!)
      formik?.setFieldValue("floorPreview", "")
      formik?.setFieldValue("floorId", floor?.id!)
      formik?.setFieldValue("tags", floor?.tags!)
      formik?.setFieldValue("floorEdit", true)
    }
    // eslint-disable-next-line
  }, [edit, open])

  useEffect(() => {
    if (edit && floorEditSuccuss) handleClose()
    // eslint-disable-next-line
  }, [edit, floorEditSuccuss])

  return (
    <Fragment>
      <div className={styles.topFields}>
        <Typography variant="a" variantColor={2} className={styles.inputTitle}>
          <span className={styles.step}>1</span>
          Details
        </Typography>
        {/* Floor name */}
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <InputText
              id="floorName"
              label="Floor Name"
              placeholder="E.g.Floor 1"
              fullWidth
              handleBlur={formik.handleBlur}
              error={formik.touched.floorName && Boolean(formik.errors.floorName) && formik.errors.floorName}
              handleChange={formik.handleChange}
              required
              value={formik?.values.floorName}
            />
          </Grid>
          <Grid item xs={6}>
            {/* Floor tags */}
            <TagsList
              formik={formik}
              isLoading={isLoadingTags}
              placeholder="Press enter to add tag"
              label="Floor Tags"
              options={floorTags!}
              size={420}
              selectedTags={formik.values.tags}
            />
          </Grid>
        </Grid>
      </div>

      {/* floor plan map preview */}
      <Box
        className={styles.versionWrapper}
        sx={{
          marginBottom: edit ? "12px" : "16px",
          minHeight: edit ? "305px" : "0px",
          padding: {
            xs: edit ? "16px 12px 12px" : "10px 12px",
            md:
              edit && floorVersions && floorVersions?.length > 2
                ? "50px 28px 32px"
                : edit && floorVersions && floorVersions?.length <= 2
                ? "50px 8px 16px"
                : "20px 24px",
          },
        }}
      >
        {isFetching ? (
          <CircularProgress size={30} className={styles.loading} />
        ) : !edit ? (
          <div>
            <Typography variant="a" variantColor={2} className={styles.inputTitle}>
              <span className={styles.step}>2</span>
              Upload Floor Plan
            </Typography>
            <DragDropFloor textPlaceholder="Drag & drop or Upload JPEG" />
          </div>
        ) : (
          <FloorVersions floorVersions={floorVersions!} />
        )}
      </Box>
      <Snackbar
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        open={floorEditSuccuss}
        autoHideDuration={5000}
        onClose={handleClose}
      >
        <div>
          <Alert onClose={handleClose} severity="success" variant="filled">
            Floor Edited successfully
          </Alert>
        </div>
      </Snackbar>
    </Fragment>
  )
}

export default FloorDetails
