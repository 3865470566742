import { FC } from "react"

import styles from "./LoadingDots.module.scss"

interface Props {
  count?: number
  minHeight?: number
  size?: "small" | "medium"
}

const LoadingDots: FC<Props> = ({ count = 3, minHeight, size = "medium" }) => {
  const loadingPlaceholders = new Array(count)
    .fill(null)
    .map((_, i) => <span className={`${styles.dot} ${styles[size]}`} key={i} />)

  return (
    <div
      className={styles.loadingDots}
      style={{
        minHeight: minHeight ?? undefined,
      }}
    >
      {loadingPlaceholders}
    </div>
  )
}
export default LoadingDots
