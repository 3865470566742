import { Fragment, useRef, useState, useEffect } from "react"

import { v4 as uuidv4 } from "uuid"

import ChatHead from "./assets/ChatHead.svg?react"
import ChatWindow from "./components/ChatWindow"
import LandingWindow from "./components/LandingWindow"

import styles from "./Chatbot.module.scss"

/**
 * Chatbot component that manages the state and behavior of a chatbot interface.
 * It toggles between a landing screen and a chat window, and handles the visibility
 * and animation of the chatbot icon.
 */
const Chatbot = () => {
  const chatHeadRef = useRef<HTMLDivElement>(null)
  const [chatbotAnchorEl, setChatbotAnchorEl] = useState<null | HTMLDivElement | undefined>(null)
  const [activeScreen, setActiveScreen] = useState<"chat" | "landing">("landing")
  const [sessionUUID, setSessionUUID] = useState<string>()

  const isOpen = Boolean(chatbotAnchorEl)

  /**
   * Toggles the chatbot's visibility by setting the chatbot anchor element.
   * If the chatbot is currently open, it will close it by setting the anchor element to null.
   * If the chatbot is currently closed, it will open it by setting the anchor element to the clicked element.
   *
   * @param e - The mouse event triggered by clicking the chatbot toggle button.
   */
  const handleToggleChatbot = (e: React.MouseEvent<HTMLDivElement>) => {
    if (chatbotAnchorEl) {
      setChatbotAnchorEl(null)
    } else {
      setChatbotAnchorEl(e.currentTarget)
    }
  }

  // useEffect to toggle the wiggle animation
  useEffect(() => {
    const interval = setInterval(() => {
      if (!isOpen && chatHeadRef.current) {
        chatHeadRef.current.classList.add(styles.wiggleActive)
        setTimeout(() => {
          if (chatHeadRef.current) {
            chatHeadRef.current.classList.remove(styles.wiggleActive)
          }
          // Stop the animation after 5 seconds
        }, 5000)
      }
      // Trigger every 10 seconds
    }, 10000)

    return () => clearInterval(interval)
  }, [isOpen])

  /**
   * Starts a new chat session by switching to the chat screen and generating a new session UUID.
   */
  const handleStartNewChat = () => {
    setActiveScreen("chat")
    const newUUID = uuidv4()
    setSessionUUID(newUUID)
  }

  return (
    <Fragment>
      {activeScreen === "chat" ? (
        <ChatWindow
          isOpen={isOpen}
          handleMinimizeChatbot={() => setChatbotAnchorEl(null)}
          handleStartNewChat={handleStartNewChat}
          sessionUUID={sessionUUID}
        />
      ) : (
        <LandingWindow
          handleStartNewChat={handleStartNewChat}
          handleMinimizeChatbot={() => setChatbotAnchorEl(null)}
          isOpen={isOpen}
        />
      )}
      <div
        className={`${styles.chatHead} ${styles.wiggleAnimation}`}
        ref={chatHeadRef}
        onClick={(e) => handleToggleChatbot(e)}
      >
        <ChatHead />
      </div>
    </Fragment>
  )
}

export default Chatbot
