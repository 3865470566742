export function findMissingDataEntry(points) {
  // Try to find the entry for "Count In - missing data"
  let entry = points.find((point) => point.serieId === "Count In")

  // If not found, try to find the entry for "Count Out - missing data"
  if (!entry) {
    entry = points.find((point) => point.serieId === "Count Out")
  }

  // Return missingInfo if found, otherwise, return null
  return !!entry?.data?.missingInfo && entry?.data?.missingInfo?.length > 0 ? entry.data.missingInfo : null
}
export function getLastDataForId(entries, id) {
  const foundEntry = entries.find((entry) => entry.id === id)
  return foundEntry ? foundEntry.data[foundEntry.data.length - 1] : undefined
}
export const missingIds = { countIn: "Count In - missing data", countOut: "Count Out - missing data" }
export const Areas = ({ series, areaGenerator, xScale, yScale }) =>
  series.map(({ id, data }) => {
    return (
      <path
        key={id}
        d={areaGenerator(
          data.map((d) =>
            d.data.missingInfo && d.data.missingInfo.length > 0
              ? {
                  x: xScale(d.data.x),
                  y: yScale(d.data.y),
                }
              : {
                  x: null,
                  y: null,
                }
          )
        )}
        fill="#FFE5E5"
        fillOpacity={0.4}
      />
    )
  })
export const lineGraphLayers = [
  "grid",
  "markers",
  "axes",
  Areas,
  "crosshair",
  "lines",
  "points",
  "slices",
  "mesh",
  "legends",
]
export const graphColors = {
  "Count In": "var(--blue-text-2)",
  "Count Out": "var(--pink-background-1)",
  Dwelling: "var(--blue-background-1)",
  Occupancy: "var(--green-background-1)",
  "Dwelling counts": "var(--blue-text-2)",
  "Dwelling time": "var(--blue-text-2)",
}

export const AverageBarColors = { Weekdays: "var(--blue-text-2)", Weekends: "var(--pink-background-1)" }

// Function to generate markers array based on input data
export const generateMarkers = (slicedData, missingIds, lastMissingCountIn, lastMissingCountOut) => {
  // Helper function to create a marker object
  const createMarker = (axisValue, textStyleFill) => ({
    axis: "x",
    value: axisValue,
    lineStyle: {
      stroke: "#EA9280",
    },
    textStyle: {
      fill: textStyleFill,
    },
  })

  // Create a marker for countIn with its corresponding x value and red text fill
  const countInMarker = createMarker(
    slicedData.find((item) => item.id === missingIds.countIn)?.data[0]?.x || false,
    "red"
  )

  // Create a marker for lastMissingCountIn with its corresponding x value and red text fill
  const lastCountInMarker = createMarker(!!lastMissingCountIn && lastMissingCountIn.x, "red")

  // Create a marker for countOut with its corresponding x value and red text fill
  const countOutMarker = createMarker(
    slicedData.find((item) => item.id === missingIds.countOut)?.data[0]?.x || false,
    "red"
  )

  // Create a marker for lastMissingCountOut with its corresponding x value and red text fill
  const lastCountOutMarker = createMarker(!!lastMissingCountOut && lastMissingCountOut.x, "red")

  // Return the array of generated markers
  return [countInMarker, lastCountInMarker, countOutMarker, lastCountOutMarker]
}
