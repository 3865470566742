import React, { useState, useEffect, Fragment } from "react"

import { Box, useMediaQuery } from "@mui/material"
import Checkbox from "@mui/material/Checkbox"
import FormControlLabel from "@mui/material/FormControlLabel"
import Grid from "@mui/material/Grid"
import { useTheme } from "@mui/material/styles"

import { ResponsiveLine } from "@nivo/line"

import { AverageBarColors, findMissingDataEntry, graphColors, lineGraphLayers } from "../../utils/graphUtils"
import GenderAgeCard from "../GenderAgeCard"
import GraphTooltip from "../GraphTooltip"
import GraphEmptyState from "./GraphEmptyState"
import barChartLoading from "./assets/barChartLoading.mp4"
import { gridTheme } from "./gridTheme"

import styles from "./hourlyLineGraph.module.scss"

function HourlyLineGraph(props) {
  const { data, graphHeight, isLoading, isAverageChart, noCheckBox, hasGenderAge } = props
  const [checkBoxStates, setCheckBoxStates] = React.useState(
    isAverageChart
      ? { Weekdays: false, Weekends: false }
      : {
          countIn: false,
          countOut: false,
          occupancy: false,
        }
  )
  const [slicedData, setSlicedData] = useState(data)

  // checkbox filtering logic
  useEffect(() => {
    // Array to store indices based on checkbox states
    const indicesToInclude = []

    // Check if countIn or Weekends checkbox is checked, and include corresponding indices
    if (checkBoxStates.countIn || checkBoxStates.Weekends) {
      indicesToInclude.push(1)
    }

    // Check if countOut or Weekdays checkbox is checked, and include corresponding indices
    if (checkBoxStates.countOut || checkBoxStates.Weekdays) {
      indicesToInclude.push(0)
    }

    // Check if occupancy checkbox is checked, and include corresponding index
    if (checkBoxStates.occupancy) {
      indicesToInclude.push(2)
    }

    // Create newDataArr by mapping indices to corresponding values and filter out undefined values
    const newDataArr = indicesToInclude.map((index) => data[index]).filter((value) => value !== undefined)

    // Set slicedData based on whether newDataArr has any elements, else slice the original data
    setSlicedData(indicesToInclude.length > 0 ? newDataArr : data)
  }, [checkBoxStates, data, isAverageChart])

  // handling checkbox click
  const handleChange = (event) => {
    setCheckBoxStates({ ...checkBoxStates, [event.target.name]: event.target.checked })
  }
  const theme = useTheme()
  const largeScreen = useMediaQuery(theme.breakpoints.down("xl"))
  const smallScreen = useMediaQuery(theme.breakpoints.down("md"))

  const commonLineProps = {
    data: slicedData,
    theme: gridTheme,
    curve: "cardinal",
    yScale: { type: "linear", min: "auto", max: "auto", stacked: false, reverse: false },
    axisTop: null,
    axisRight: null,
    enableSlices: "x",
    legends: noCheckBox
      ? [
          {
            anchor: "bottom",
            direction: "row",
            justify: false,
            translateX: 4,
            translateY: 60,
            itemsSpacing: 6,
            itemDirection: "left-to-right",
            itemWidth: 109,
            itemHeight: 20,
            itemOpacity: 0.75,
            symbolSize: 14,
            symbolShape: "circle",
            symbolBorderColor: "rgba(0, 0, 0, .5)",
            effects: [
              {
                on: "hover",
                style: {
                  itemBackground: "rgba(0, 0, 0, .03)",
                  itemOpacity: 1,
                },
              },
            ],
          },
        ]
      : [],
  }

  const averageChartConfig = {
    margin: { top: 30, right: 10, bottom: 80, left: 40 },
    xScale: { type: "point" },
    axisBottom: {
      tickSize: 5,
      tickPadding: 5,
      tickRotation: smallScreen ? -45 : 0,
      format: (v) => (v.substring(0, v.length - 2) % 2 === 0 ? v : ""),
      legendPosition: "middle",
    },
    colors: (line) => AverageBarColors[line.id],
    sliceTooltip: ({ slice }) => (
      <GraphTooltip slicePoints={slice.points} timeStamp={slice.points[0].data.xFormatted} key={slice.id} weekAvg />
    ),
    pointSize: 5,
    pointColor: { theme: "background" },
    pointBorderWidth: 2,
    enablePointLabel: false,
    pointLabel: "y",
    pointLabelYOffset: -12,
    useMesh: true,
  }

  const lineChartConfig = {
    margin: { top: 60, right: 30, bottom: noCheckBox ? 65 : 50, left: 68 },
    xScale: { type: "time", format: "native", precision: "hour", useUTC: false },
    xFormat: "time:%d %b %I %p",
    yScale: { type: "linear", min: "auto", max: "auto", stacked: false, reverse: false },
    areaOpacity: 0.07,
    enableArea: true,
    layers: lineGraphLayers,
    sliceTooltip: ({ slice }) =>
      hasGenderAge ? (
        <GenderAgeCard
          adultCount={slice.points[0].data.adultCount}
          childCount={slice.points[0].data.childCount}
          femaleStat={slice.points[0].data.femaleCount}
          maleStat={slice.points[0].data.maleCount}
          loading={false}
          key={slice.id}
          pointDate={slice.points[0].data.xFormatted}
          graphTooltip
        />
      ) : (
        <GraphTooltip
          slicePoints={slice.points}
          timeStamp={slice.points[0].data.xFormatted}
          key={slice.id}
          missingInfo={findMissingDataEntry(slice.points)}
        />
      ),
    axisBottom: {
      tickValues: "every 2 hour",
      format: "%I %p",
      orient: "bottom",
      tickSize: 6,
      tickPadding: 9,
      tickRotation: largeScreen ? -45 : 0,
      legendPosition: "middle",
      fill: "var(--secondary-text-light)",
    },
    axisLeft: {
      orient: "left",
      tickSize: 0,
      tickPadding: 8,
      tickRotation: 0,
      legend: "",
      format: (e) => Math.floor(e) === e && e,
      legendOffset: -50,
      legendPosition: "middle",
      fill: "var(--secondary-text-light)",
    },
    colors: (line) => graphColors[line.id],
    pointSize: 12,
    pointSymbol: (e) => {
      return (
        <circle
          cx="0"
          cy="0"
          r="6"
          strokeWidth="3"
          fill={!!e.datum.missingInfo && e.datum.missingInfo?.length > 0 ? "#E5484D" : "transparent"}
        />
      )
    },
    pointBorderWidth: 0,
    pointBorderColor: { from: "serieColor" },
    enablePointLabel: false,
    pointLabel: "y",
    pointLabelYOffset: -12,
    useMesh: true,
  }
  return (
    <Fragment>
      <Box
        style={{
          height: graphHeight,
        }}
      >
        {data[0].data.length < 1 && data[0].data && !isLoading ? (
          <GraphEmptyState />
        ) : isLoading ? (
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            className={styles.loadingContainer}
          >
            <Grid item align="center">
              <video autoPlay={true} loop={true} width="300" height="200">
                <source src={barChartLoading} type="video/mp4" />
              </video>
            </Grid>
          </Grid>
        ) : (
          <Fragment>
            {isAverageChart ? (
              <ResponsiveLine {...commonLineProps} {...averageChartConfig} />
            ) : (
              <ResponsiveLine {...commonLineProps} {...lineChartConfig} />
            )}
          </Fragment>
        )}
      </Box>
      {!noCheckBox && (
        <Grid container spacing={2} width="100%" justifyContent="center">
          <Grid item>
            <FormControlLabel
              className={styles.checkBox}
              style={{ color: "var(--blue-text-2)" }}
              control={
                <Checkbox
                  sx={{
                    color: "var(--blue-text-2)",
                    "&.Mui-checked": {
                      color: "var(--blue-text-2)",
                    },
                  }}
                  checked={isAverageChart ? checkBoxStates.Weekdays : checkBoxStates.countIn}
                  onChange={handleChange}
                  name={isAverageChart ? "Weekdays" : "countIn"}
                />
              }
              label={isAverageChart ? "Weekdays" : "Incount"}
            />
          </Grid>
          <Grid item>
            <FormControlLabel
              className={styles.checkBox}
              style={{ color: "var(--pink-background-1)" }}
              control={
                <Checkbox
                  sx={{
                    color: "var(--pink-background-1)",
                    "&.Mui-checked": {
                      color: "var(--pink-background-1)",
                    },
                  }}
                  checked={isAverageChart ? checkBoxStates.Weekends : checkBoxStates.countOut}
                  onChange={handleChange}
                  name={isAverageChart ? "Weekends" : "countOut"}
                />
              }
              label={isAverageChart ? "Weekends" : "Outcount"}
            />
          </Grid>
          <Grid item>
            {!isAverageChart && (
              <FormControlLabel
                className={styles.checkBox}
                style={{ color: "var(--green-background-1)" }}
                control={
                  <Checkbox
                    sx={{
                      color: "var(--green-background-1)",
                      "&.Mui-checked": {
                        color: "var(--green-background-1)",
                      },
                    }}
                    checked={checkBoxStates.occupancy}
                    onChange={handleChange}
                    name="occupancy"
                  />
                }
                label="Occupancy"
              />
            )}
          </Grid>
        </Grid>
      )}
    </Fragment>
  )
}

export default HourlyLineGraph
