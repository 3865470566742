import { FormikProps } from "formik"
import * as Yup from "yup"

import { definitions } from "../types/Generated/apiTypes"

type Category = definitions["Category"]
export const handleSubcategoryChange = (
  event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  index: number,
  formik: FormikProps<Category>
) => {
  const enteredValue = event.target.value
  const subcategories = formik.values.subcategories
  subcategories[index] = { name: enteredValue }
  formik.setFieldValue("subcategories", subcategories)
}

export const handleDeleteSubcategory = (index: number, formik: FormikProps<Category>) => {
  const subcategories = formik.values.subcategories
  if (index === 0 && subcategories.length === 1) {
    subcategories[0] = { name: "" }
  } else {
    subcategories.splice(index, 1)
  }
  formik.setFieldValue("subcategories", subcategories)
}

export const handleAddNewSubcategory = (formik: FormikProps<Category>) => {
  const subcategories = formik.values.subcategories
  const newSubcategory = { name: "" }
  subcategories.push(newSubcategory)
  formik.setFieldValue("subcategories", subcategories)
}

export const checkIsDisabled = (formik: FormikProps<Category>) => {
  if (formik?.values?.name === "" || formik?.values?.name?.length < 1) {
    return true
  } else {
    return false
  }
}

export const validationFormik = () => {
  return Yup.object({
    name: Yup.string().required("Category name is required"),
  })
}
export const filterEmptySubcategories = (categoryToFilter: Category) => {
  // remove empty subcategory values from the form values before submission
  const filteredSubcategories = categoryToFilter.subcategories.filter((subcategory) => subcategory.name !== "")
  const filteredCategory = { ...categoryToFilter, subcategories: filteredSubcategories }
  return filteredCategory
}
