import { FC, useMemo, Ref } from "react"

import { GroupsOutlined, PersonOutlineOutlined, TrendingDown, TrendingUp } from "@mui/icons-material"
import FunctionsOutlinedIcon from "@mui/icons-material/FunctionsOutlined"
import { Card, Divider, useMediaQuery } from "@mui/material"
import { useTheme } from "@mui/material/styles"

import { Skeleton, Typography } from "@synapse-analytics/synapse-ui"

import { definitions } from "../types/Generated/apiTypes"
import GenderAgeCard from "./GenderAgeCard"
import LoadingDots from "./Loaders/LoadingDots"

import styles from "./StatsCard.module.scss"

type EntityGroupsCounts = definitions["EntityGroupsCounts"]

interface Props {
  title: string
  numericStat: number
  historicalStat?: number
  femaleStat?: number
  maleStat?: number
  unknownGenderStat?: number
  childCount?: number
  adultCount?: number
  unknownAgeStat?: number
  dwellAndEnter?: number
  dwellAndLeft?: number
  stoodAndEnter?: number
  stoodAndLeft?: number
  categoryFairShare?: number
  subcategoryFairShare?: number
  fullHeight?: boolean
  marginBottom?: boolean
  isLoading?: boolean
  reference: Ref<HTMLDivElement>
  includeFairShare?: boolean
  includeDwelling?: boolean
  isSecondCard?: boolean
  unit?: string
  entityGroupsCounts?: EntityGroupsCounts
}

const StatsCard: FC<Props> = ({
  title,
  numericStat,
  historicalStat,
  femaleStat,
  unknownGenderStat,
  maleStat,
  fullHeight,
  marginBottom,
  childCount,
  adultCount,
  unknownAgeStat,
  dwellAndEnter,
  dwellAndLeft,
  stoodAndEnter,
  stoodAndLeft,
  categoryFairShare,
  subcategoryFairShare,
  isLoading,
  reference,
  includeFairShare,
  includeDwelling,
  entityGroupsCounts,
  isSecondCard,
  unit,
}) => {
  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.breakpoints.down("md"))

  const cardHeight = useMemo(() => {
    if (fullHeight || smallScreen) return "100%"
    if (includeFairShare) return "50%"
    return isSecondCard ? "20%" : "80%"
  }, [fullHeight, smallScreen, includeFairShare, isSecondCard])

  const differenceInPercentage = useMemo(() => {
    if (historicalStat && numericStat) {
      return ((numericStat - historicalStat) / historicalStat) * 100
    }
    return 0
  }, [numericStat, historicalStat])

  const renderDifference = () => {
    if (differenceInPercentage === 0 || !isFinite(differenceInPercentage)) return null
    const isPositive = differenceInPercentage > 0
    return (
      <div className={styles.changeWrapper}>
        <Typography variant="p" align="center" variantColor={2} color={isPositive ? "positive" : "negative"}>
          {Math.abs(differenceInPercentage).toFixed(0)}%
          {isPositive ? <TrendingUp fontSize="small" /> : <TrendingDown fontSize="small" />}
        </Typography>
      </div>
    )
  }

  return (
    <Card
      ref={reference}
      className={styles.statsCard}
      style={{
        height: cardHeight,
        marginBottom: marginBottom ? 12 : 0,
        width: smallScreen ? "100%" : "auto",
      }}
    >
      <div className={styles.cardHeader}>
        <Typography variant="p" align="left" variantColor={2} className={styles.cardTitle}>
          {title}
        </Typography>
        <div className={styles.countsWrapper}>
          {isLoading ? (
            <LoadingDots minHeight={36} />
          ) : (
            <div className={styles.currentCounts}>
              <Typography variant="h2-bold" align="center" noWrap variantColor={2} className={styles.countNumber}>
                {numericStat > 0 && numericStat !== Infinity
                  ? `${numericStat.toLocaleString()} ${unit || ""}`
                  : "No Data"}
              </Typography>
              {renderDifference()}
            </div>
          )}
          {historicalStat !== undefined && numericStat !== 0 && historicalStat !== 0 && (
            <Typography variant="p" align="center" variantColor={2}>
              Last Week {historicalStat.toLocaleString()}
            </Typography>
          )}
        </div>
        {!isSecondCard &&
          (isLoading ? (
            <Skeleton variant="rectangular" width="100%" height={36} />
          ) : (
            <div className={styles.groupCountsWrapper}>
              <Typography variant="span" variantColor={2} className={styles.group}>
                <GroupsOutlined fontSize="small" />
                <b>{entityGroupsCounts?.groups_count || 0}</b> Group(s)
              </Typography>
              <Typography variant="span" variantColor={2} className={styles.group}>
                <FunctionsOutlinedIcon fontSize="small" />
                Avg group : <b>{entityGroupsCounts?.avg_groups_size || 0}</b>
              </Typography>
              <Typography variant="span" variantColor={2} className={styles.group}>
                <PersonOutlineOutlined fontSize="small" />
                Singles : <b>{entityGroupsCounts?.counts_without_group || 0}</b>
              </Typography>
            </div>
          ))}
      </div>
      {!isSecondCard && <Divider className={styles.divider} />}
      {!isSecondCard && (
        <GenderAgeCard
          adultCount={adultCount}
          childCount={childCount}
          unknownGenderStat={unknownGenderStat}
          femaleStat={femaleStat}
          maleStat={maleStat}
          unknownAgeStat={unknownAgeStat}
          dwellAndEnter={dwellAndEnter}
          dwellAndLeft={dwellAndLeft}
          stoodAndEnter={stoodAndEnter}
          stoodAndLeft={stoodAndLeft}
          categoryFairShare={categoryFairShare}
          subcategoryFairShare={subcategoryFairShare}
          isLoading={isLoading}
          includeFairShare={includeFairShare}
          includeDwelling={includeDwelling}
        />
      )}
    </Card>
  )
}

export default StatsCard
