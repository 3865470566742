import { FC, Fragment } from "react"

import GetAppOutlinedIcon from "@mui/icons-material/GetAppOutlined"
import { Card } from "@mui/material"

import { Button, Typography } from "@synapse-analytics/synapse-ui"
import { CsvBuilder } from "filefy"
import moment from "moment"

import { ColoredGraphData } from "../../types/Custom/Types"
import { getDefaultCheckboxes } from "../../utils/genericHelpers"
import CategoriesLineGraph from "../graphs/CheckboxesLineGraph"

import styles from "./CheckboxesLineGraphCard.module.scss"

interface Props {
  graphData: ColoredGraphData[]
  title: string
  columnsKey: string
  rowsKey: string
  isLoading?: Boolean
  timeGrain?: "day" | "hour"
  isLargeInterval?: boolean
  graphHeight?: number
  startDate?: string
  endDate?: string
  ref?: React.MutableRefObject<any>
}

const CheckboxesLineGraphCard: FC<Props> = ({
  title,
  graphData,
  isLoading,
  timeGrain,
  isLargeInterval,
  columnsKey,
  rowsKey,
  startDate,
  endDate,
  graphHeight = 350,
  ref,
}) => {
  const defaultCheckboxes = getDefaultCheckboxes(graphData)
  const isEmpty = (graphData && graphData.length < 1) || !graphData

  const handleExportCSV = () => {
    const dateInTitle = startDate === endDate ? `${startDate} (hourly)` : `- from ${startDate} to ${endDate}`
    const builder = new CsvBuilder(`${title} ${dateInTitle}.csv`)
    let csvFormattedData: string[][] = []
    const datesArray: string[] = []

    if (!isLoading && graphData) {
      // Step 1: Collect all unique date/hour columns from the entire dataset
      graphData.forEach((element) => {
        element.data.forEach((log) => {
          const date = moment(log.x).format(timeGrain === "day" ? "MMM D, YYYY" : "h:mm a")
          if (!datesArray.includes(date)) {
            datesArray.push(date)
          }
        })
      })

      // Sort the datesArray to maintain order
      datesArray.sort(
        (a, b) =>
          moment(a, timeGrain === "day" ? "MMM D, YYYY" : "h:mm a").unix() -
          moment(b, timeGrain === "day" ? "MMM D, YYYY" : "h:mm a").unix()
      )

      // Step 2: Build CSV rows with data aligned to the corresponding date/hour columns
      graphData.forEach((element) => {
        const csvRow: string[] = [element.id] // Start the row with the element ID

        // Create a map of date/hour to y value for quick lookup
        const logMap = new Map()
        element.data.forEach((log) => {
          const logDate = moment(log.x).format(timeGrain === "day" ? "MMM D, YYYY" : "h:mm a")
          logMap.set(logDate, log.y.toString())
        })

        // Fill in the row with y values or 0 for each date/hour
        datesArray.forEach((logDate) => {
          csvRow.push(logMap.get(logDate) || "0") // Add the y value or 0 if missing
        })

        csvFormattedData.push(csvRow)
      })
    }

    // Step 3: Set columns and add rows to the CSV builder
    builder
      .setColumns([`${rowsKey} \\ ${columnsKey}`, ...datesArray])
      .addRows(csvFormattedData)
      .exportFile()
  }

  return (
    <Card className={styles.wrapper} ref={ref}>
      <Fragment>
        <div className={styles.header}>
          <Typography variant="h2-bold" variantColor={2}>
            {title}
          </Typography>
        </div>

        <CategoriesLineGraph
          data={graphData}
          isLoading={isLoading}
          graphHeight={graphHeight}
          defaultCheckboxes={defaultCheckboxes}
          timeGrain={timeGrain}
          isLargeInterval={isLargeInterval}
        />

        {!isEmpty && !isLoading && (
          <div
            style={{
              alignSelf: "flex-end",
            }}
          >
            <Button startIcon={<GetAppOutlinedIcon />} onClick={handleExportCSV} className={styles.export}>
              Export
            </Button>
          </div>
        )}
      </Fragment>
    </Card>
  )
}
export default CheckboxesLineGraphCard
