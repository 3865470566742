import { useEffect, useState, FC, useCallback } from "react"

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline"
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline"
import {
  Card,
  CardActions,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
} from "@mui/material"

import { Button, Typography } from "@synapse-analytics/synapse-ui"

import NoDataFound from "../../../assets/noListData.svg?react"
import Search from "../../../components/Search"
import { definitions } from "../../../types/Generated/apiTypes"

import styles from "./TransferList.module.scss"

type Permission = definitions["Permission"]
type Branch = definitions["Branch"]

interface TransferableData {
  id?: number
  name: string
  codename?: string
}
interface Props {
  handleChangeChosenData: (data: TransferableData[]) => void
  availableData?: TransferableData[]
  chosenData?: number[]
  edit?: boolean
  type: string
}

function not(a: TransferableData[], b: TransferableData[]) {
  return a.filter((value) => b.indexOf(value) === -1)
}

export const TransferList: FC<Props> = ({ handleChangeChosenData, availableData, edit, chosenData, type }) => {
  const [left, setLeft] = useState<TransferableData[]>([])
  const [right, setRight] = useState<TransferableData[]>([])

  const initializeLists = useCallback(() => {
    if (availableData) {
      setRight(availableData?.filter((item) => chosenData?.includes(item.id!)))
      setLeft(availableData?.filter((item) => !chosenData?.includes(item.id!)))
    }
  }, [availableData, chosenData, setRight, setLeft])

  // Set "left" list (initially) with available data items
  useEffect(() => {
    if (availableData) {
      setLeft(availableData)
    }
  }, [availableData])

  // Set "right" list (initially) with current chosen data (if in edit mode)
  useEffect(() => {
    if (edit && chosenData && chosenData?.length > 0) {
      initializeLists()
    }
  }, [edit, availableData, chosenData, initializeLists])

  // Set formik values when right items are changed
  useEffect(() => {
    handleChangeChosenData(right)
    // eslint-disable-next-line
  }, [right])

  // check side of item
  //  if left side -> add item to right side and remove it from left side
  // if click is coming from right side -> remove it from right side and add it to left side.
  const handleToggle = (value: Permission | Branch, side: "left" | "right") => () => {
    if (side === "left") {
      setLeft((prevLeft) => prevLeft.filter((item) => item !== value))
      setRight((prevRight) => [...prevRight, value])
    } else {
      setRight((prevRight) => prevRight.filter((item) => item !== value))
      setLeft((prevLeft) => [...prevLeft, value])
    }
  }

  const CustomList = ({ title, items, side }: { title: string; items: TransferableData[]; side: "left" | "right" }) => {
    const [searchValue, setSearchValue] = useState<string>("")

    const filteredItems: TransferableData[] = items?.filter(
      (item) =>
        item?.codename?.toLowerCase().includes(searchValue.toLowerCase()) ||
        item?.name?.toLowerCase().includes(searchValue.toLowerCase())
    )

    const handleSearch = (value: string) => {
      setSearchValue(value)
    }

    const handleMoveToOtherSide = (): void => {
      if (side === "left") {
        setLeft((prevLeft) => not(prevLeft, filteredItems))
        setRight((prevRight) => [...prevRight, ...filteredItems])
      } else {
        setRight((prevRight) => not(prevRight, filteredItems))
        setLeft((prevLeft) => [...prevLeft, ...filteredItems])
      }
      setSearchValue("")
    }

    return (
      <Card variant="outlined" className={styles.card}>
        <div className={styles.cardHeader}>
          <div className={styles.headerContent}>
            <div className={styles.titleAndSearch}>
              <Typography variant="a">{title}</Typography>
              <Search
                topHeader={true}
                placeholder={`Search by ${type} name`}
                handleSearch={handleSearch}
                searchValue={searchValue}
                variant="filled"
              />
            </div>
            <Typography
              variant="label"
              variantColor={2}
              display="block"
            >{`${items?.length}/${availableData?.length} ${type}`}</Typography>
          </div>
        </div>
        <List
          className={styles.list}
          dense
          autoFocus
          component="div"
          role="list"
          sx={{
            height: 350,
          }}
        >
          {filteredItems && filteredItems?.length > 0 ? (
            filteredItems.map((value) => (
              <ListItem role="button" key={value.id}>
                <ListItemButton onClick={handleToggle(value, side)}>
                  <ListItemIcon classes={{ root: styles.listItemIcon }}>
                    {side === "left" ? (
                      <AddCircleOutlineIcon className={styles.addIcon} />
                    ) : (
                      <RemoveCircleOutlineIcon className={styles.removeIcon} />
                    )}
                  </ListItemIcon>
                  <ListItemText
                    id={`${value.id!}`}
                    primary={<Typography variant="span">{value?.codename ? value?.codename : value.name}</Typography>}
                  />
                </ListItemButton>
              </ListItem>
            ))
          ) : (
            <div className={styles.emptyStateWrapper}>
              <NoDataFound />
              {searchValue ? (
                <Typography variant="a" variantColor={2} style={{ marginTop: 4 }}>
                  No {type} found
                </Typography>
              ) : (
                <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                  <Typography variant="a" variantColor={2} style={{ marginTop: 4 }}>
                    No {side === "left" && "available"} {type} {side === "right" && "selected"}
                  </Typography>
                  {side === "right" ? (
                    <Typography variant="span" variantColor={2} style={{ marginTop: 4 }}>
                      Press 'Add' to assign the {type} you want the user to access.
                    </Typography>
                  ) : (
                    <Typography variant="span" variantColor={2} style={{ marginTop: 4 }}>
                      The user already has access to all {type}.
                    </Typography>
                  )}
                </div>
              )}
            </div>
          )}
          <ListItem />
        </List>
        {
          <CardActions
            sx={{
              justifyContent: "flex-end",
              visibility: filteredItems && filteredItems?.length > 0 ? "visible" : "hidden",
            }}
          >
            <Button onClick={handleMoveToOtherSide}>
              {side === "left" ? "Move" : "Remove"} all {side === "left" ? "to" : "from"} the chosen {type}
            </Button>
          </CardActions>
        }
      </Card>
    )
  }
  return (
    <Paper className={styles.wrapper} elevation={0}>
      <div className={styles.header}>
        <Typography variant="h2-bold" variantColor={2}>
          {` User ${type} permissions`}
        </Typography>
        <Button onClick={initializeLists} variant="secondary">
          Reset Changes
        </Button>
      </div>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <CustomList key={`left_${type}`} items={left} side="left" title={`Available ${type}`} />
        </Grid>
        <Grid item xs={12} md={6}>
          <CustomList key={`right${type}`} items={right} side="right" title={`Chosen ${type}`} />
        </Grid>
      </Grid>
    </Paper>
  )
}

export default TransferList
