import { createBrowserHistory } from "history"

export const browserHistory = createBrowserHistory()

export const browserHistoryListener = (location) => {
  let pagePathName = location?.pathname?.split("/")

  // Check if pathname is 404
  if (pagePathName.includes("404")) {
    document.title = "Page Not Found"
  } else {
    // Assigning the first non-number string in the pathname to be the page title
    let pageTitle = pagePathName ? pagePathName.reverse().find((element) => !Number(element)) : "Azka Vision"

    // Remove hyphens from string & Capitalize the first character of the title
    let TrimPageTitle = pageTitle.charAt(0).toUpperCase() + pageTitle.slice(1).replace(/-/g, " ")

    // Changing the page title to match the route
    // Capitalize the first letter of each word in the title
    let formattedPageTitle = TrimPageTitle.split(" ")
    for (let i = 1; i < formattedPageTitle.length; i++) {
      formattedPageTitle[i] = formattedPageTitle[i].charAt(0).toUpperCase() + formattedPageTitle[i].slice(1)
    }
    formattedPageTitle =
      formattedPageTitle[0] +
      (formattedPageTitle[0] === "Admin" ||
      formattedPageTitle[0] === "Analytics" ||
      formattedPageTitle[0] === "Marketing" ||
      formattedPageTitle[0] === "Security"
        ? " | "
        : " ") +
      formattedPageTitle.slice(1).join(" ")

    // Set the page title
    document.title = pagePathName[0] === "" ? "Azka Vision" : formattedPageTitle
  }

  /*
    Used here window.gtag because react-ga package is not supported for latest google analytics behavior
    also gtag is already pre defined at index.html
  */
  // Send page view to Google Analytics
  return window?.gtag("config", import.meta.VITE_TRACKING_ID, {
    page_title: document.title,
    page_path: location.pathname + location.search,
    send_to: import.meta.VITE_TRACKING_ID,
  })
}
