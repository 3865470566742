import { FC, Fragment, forwardRef, useState } from "react"

import CloseIcon from "@mui/icons-material/Close"
import { Dialog, DialogTitle, DialogContent, Slide, SlideProps } from "@mui/material"

import { Button, InputText, Typography } from "@synapse-analytics/synapse-ui"
import { FormikProps } from "formik"

import { IUserAddEdit } from "../../../types/Custom/Interfaces"

import styles from "./TelegramId.module.scss"

interface Props {
  formik: FormikProps<IUserAddEdit>
}

const Transition = forwardRef(function Transition(props: SlideProps, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const TelegramId: FC<Props> = ({ formik }) => {
  const [isOpen, setIsOpen] = useState(false)
  const onCancel = () => setIsOpen(false)

  return (
    <Fragment>
      <InputText
        fullWidth
        id="telegram_id"
        label="Telegram user/group ID"
        error={formik.touched.telegram_id && Boolean(formik.errors.telegram_id) && formik.errors.telegram_id}
        placeholder="E.g. 998732458"
        value={formik.values?.telegram_id}
        handleChange={formik.handleChange}
        handleBlur={formik.handleBlur}
        helperText="How to get?"
        handleHelperClick={() => setIsOpen(true)}
        variant="filled"
      />
      <Dialog
        onClick={(event) => event.stopPropagation()}
        maxWidth="md"
        open={isOpen}
        onClose={onCancel}
        TransitionComponent={Transition}
        aria-labelledby="alert-dialog"
        aria-describedby="alert-dialog-close-entity-creation"
        classes={{
          paper: styles.wrapper,
        }}
      >
        <DialogTitle
          id="alert-dialog-title"
          sx={{
            padding: 0,
          }}
        >
          <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <Typography variant="h2-bold">Telegram ID tutorial</Typography>
            <CloseIcon onClick={onCancel} className={styles.iconContainer} />
          </div>
        </DialogTitle>
        <DialogContent
          sx={{
            padding: "10px 0px",
            paddingTop: "10px !important",
          }}
        >
          <div className={styles.videosCard}>
            <div className={styles.innerCard}>
              <Typography variant="h3-regular" gutterBottom>
                How to get telegram <span className={styles.bold}>user</span> id
              </Typography>
              <Button
                variant="primary"
                onClick={() => window.open("https://www.youtube.com/embed/KM6gu9V1gX8?mute=1", "_blank")}
              >
                User ID
              </Button>
            </div>
            <div className={styles.innerCard}>
              <Typography variant="h3-regular" gutterBottom>
                How to get telegram <span className={styles.bold}>group</span> id
              </Typography>
              <Button
                variant="primary"
                onClick={() => window.open("https://www.youtube.com/embed/oAuzHSDZ06I?mute=1", "_blank")}
              >
                Group ID
              </Button>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </Fragment>
  )
}
export default TelegramId
