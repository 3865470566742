import { FC } from "react"

import { Typography } from "@synapse-analytics/synapse-ui"

import { definitions } from "../../types/Generated/apiTypes"
import DownDaySlider from "../graphs/DownDaySlider"
import GraphEmptyState from "../graphs/GraphEmptyState"
import GraphLoadingState from "../graphs/GraphLoadingState"

import styles from "./DownDaysBreakdownCard.module.scss"

type DailyDownTimeLog = definitions["DailyDownTimeLog"]

interface Props {
  data?: DailyDownTimeLog[]
  isLoading?: boolean
}

const DownDaysBreakdownCard: FC<Props> = ({ data, isLoading }) => {
  const isEmpty = !data || (data && data.length < 1)

  return (
    <div className={`${styles.wrapper} ${isEmpty ? styles.emptyStateWrapper : ""}`}>
      <Typography variant="h2-bold" variantColor={2}>
        Days Breakdown
      </Typography>
      {isLoading ? (
        <GraphLoadingState />
      ) : isEmpty ? (
        <GraphEmptyState />
      ) : (
        data.map((log) => <DownDaySlider log={log} />)
      )}
    </div>
  )
}
export default DownDaysBreakdownCard
