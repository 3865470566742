import React, { FC } from "react"

import { ResponsivePie } from "@nivo/pie"

import { definitions } from "../../../../types/Generated/apiTypes"

type NewReturning = definitions["NewReturning"]

interface Props {
  carTotalCounts: NewReturning
}
const CarsNewReturnGraph: FC<Props> = ({ carTotalCounts }) => {
  return (
    <div style={{ height: 300, width: "100%" }}>
      <ResponsivePie
        data={[
          {
            id: "Unknown",
            label: "Unknown",
            value: carTotalCounts?.n_unknown,
          },
          {
            id: "New Cars",
            label: "New Cars",
            value: carTotalCounts?.n_new,
          },
          {
            id: "Returning Cars",
            label: "Returning Cars",
            value: carTotalCounts?.n_returning,
          },
        ]}
        margin={{ top: 20, right: 20, bottom: 45, left: 20 }}
        colors={["#F87C4F", "#A08CEB", "#7896D9"]}
        innerRadius={0.5}
        padAngle={3}
        cornerRadius={15}
        enableArcLinkLabels={false}
        activeOuterRadiusOffset={8}
        enableArcLabels={false}
        borderWidth={1}
        borderColor={{
          from: "color",
          modifiers: [["darker", 0.2]],
        }}
        arcLinkLabelsSkipAngle={10}
        startAngle={-22}
        arcLinkLabelsTextColor="var(--greyScale-text-2)"
        arcLinkLabelsThickness={2}
        arcLinkLabelsColor={{ from: "color" }}
        arcLabelsSkipAngle={10}
        arcLabelsTextColor={{
          from: "color",
          modifiers: [["darker", 2]],
        }}
        legends={[
          {
            anchor: "bottom",
            direction: "row",
            justify: false,
            translateX: 0,
            translateY: 40,
            itemsSpacing: 0,
            itemWidth: 80,
            itemHeight: 20,
            itemTextColor: "#999",
            itemDirection: "left-to-right",
            itemOpacity: 1,
            symbolSize: 14,
            symbolShape: "circle",
            effects: [
              {
                on: "hover",
                style: {
                  itemTextColor: "var(--neutral-text-enabled)",
                },
              },
            ],
          },
        ]}
      />
    </div>
  )
}

export default CarsNewReturnGraph
