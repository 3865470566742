import { intervalToDuration } from "date-fns"
import moment from "moment"

import { CamerasRegions, RegionsObject } from "../types/Custom/Interfaces"
import { definitions } from "../types/Generated/apiTypes"

type Region = definitions["counterentitiy_region_serializer"]

/**
 * Processes the regions from all cameras and filters them based on certain conditions.
 *
 * @param {CamerasRegions} allRegions - An object containing regions data for each camera and state.
 *                                      The structure is expected to be { [cameraId]: { [state]: { camera: CameraObject, regions: Region[], isFulfilled: boolean } } }.
 *
 * @returns {Region[]} An array of regions that meet the filtering criteria.
 *                     Each region in the array has the structure:
 *                     {
 *                       camera: CameraObject,
 *                       state: number,
 *                       points: Point[],
 *                       dwell_thresh?: number
 *                     }
 *
 * @description This function extracts regions from each camera and state, filters out regions
 *              that have fewer than three points, and calculates the `dwell_thresh`
 *              in seconds if the region state is 2 and `dwell_thresh` is provided
 *              as a duration object (not a number).
 */
export const processRegions = (allRegions: CamerasRegions): Region[] => {
  let regionsArray: Region[] = []

  for (let cameraId in allRegions) {
    const cameraStates = allRegions[cameraId] // Access the states for each camera

    for (let state in cameraStates) {
      const { regions } = cameraStates[state]

      for (const region of regions) {
        if (region.points.length > 2) {
          regionsArray.push({
            camera: region.camera,
            state: parseInt(state, 10) as 0 | 1 | 2 | 8,
            points: region.points,
            dwell_thresh:
              region.state === 2 && region.dwell_thresh && typeof region.dwell_thresh !== "number"
                ? region.dwell_thresh.getMinutes() * 60 + region.dwell_thresh.getSeconds()
                : undefined,
          })
        }
      }
    }
  }

  return regionsArray
}

/**
 * Helper function to map regions to a CamerasRegions structure.
 *
 * @param {RegionsObject[]} regions - Array of region objects to be mapped.
 * @returns {CamerasRegions} Mapped structure where regions are organized by camera and state.
 */
export const getEntityCamerasRegions = (regions: RegionsObject[]): CamerasRegions => {
  const camerasRegions = regions.reduce<CamerasRegions>((acc, region) => {
    const { camera, state, points, dwell_thresh } = region

    // If camera doesn't exist in the accumulator, initialize it
    if (!acc[camera]) {
      acc[camera] = {}
    }

    // If the state doesn't exist for the camera, initialize it
    if (!acc[camera][state]) {
      acc[camera][state] = {
        regions: [],
        isFulfilled: false, // Initially set fulfillment to false
      }
    }

    // Calculate dwelling threshold in form of minutes:seconds
    const dwellingDuration = intervalToDuration({
      start: 0,
      end: (dwell_thresh ? +dwell_thresh : 0) * 1000,
    })

    const dwellingThreshold = new Date()
    dwellingThreshold.setMinutes(dwellingDuration.minutes!, dwellingDuration.seconds)

    // Add the region to the specific state under the camera with the ref and updated dwell_thresh
    acc[camera][state].regions.push({
      ...region,
      dwell_thresh: dwellingThreshold,
    })

    // Set isFulfilled if points exist and there are more than 2 points
    if (points && points.length > 2) {
      acc[camera][state].isFulfilled = true
    }

    return acc
  }, {})

  // Ensure each camera has regions for all states (0, 1, 2, 8) with empty points if missing
  Object.keys(camerasRegions).forEach((cameraId) => {
    const states = [0, 1, 2, 8]
    states.forEach((state) => {
      if (!camerasRegions[cameraId][state]) {
        camerasRegions[cameraId][state] = {
          regions: [
            {
              camera: +cameraId,
              state: state as 0 | 1 | 2 | 8,
              points: [],
              dwell_thresh: state === 2 ? new Date().setMinutes(0, 5) : undefined,
            },
          ],
          isFulfilled: false,
        }
      }
    })
  })

  return camerasRegions
}

/**
 * Initializes camera regions for all states (0, 1, 2, 8) for a newly selected camera.
 *
 * @param {number} cameraId - The ID of the newly selected camera.
 * @returns {CamerasRegions} - The initialized camera regions structure.
 */
export const initializeCameraRegions = (cameraId: number): CamerasRegions => {
  const newCameraRegions: CamerasRegions = {
    [cameraId]: {
      0: {
        regions: [
          {
            camera: cameraId,
            state: 0,
            points: [],
          },
        ],
        isFulfilled: false,
      },
      1: {
        regions: [
          {
            camera: cameraId,
            state: 1,
            points: [],
          },
        ],
        isFulfilled: false,
      },
      2: {
        regions: [
          {
            camera: cameraId,
            state: 2,
            points: [],
            dwell_thresh: new Date().setMinutes(0, 5),
          },
        ],
        isFulfilled: false,
      },
      8: {
        regions: [
          {
            camera: cameraId,
            state: 8,
            points: [],
            dwell_thresh: new Date().setMinutes(0, 5),
          },
        ],
        isFulfilled: false,
      },
    },
  }

  return newCameraRegions
}

export const getAvailableRegions = (type: string): string[] => {
  switch (type) {
    case "Dwelling Area":
      return new Array(2).fill("").concat("Dwelling Area")
    case "Cashier":
      return new Array(8).fill("").concat("Cashier")
    case "Car Parking":
      return ["Area In", "Area Out", ""]
    default:
      return ["Area In", "Area Out", "Dwelling Area"]
  }
}

/**
 * Formats a given date object as a dwelling time in minutes and seconds.
 *
 * @param {Date} dwell_thresh - The threshold date from which to extract minutes and seconds.
 * @returns {string} - A formatted string representing the dwelling time in "X min Y sec" or "Y sec" format.
 *                     If both minutes and seconds are zero, it returns "0 sec".
 *
 * @example
 * // If the dwell_thresh is a date with 2 minutes and 30 seconds:
 * // formatDwellTime(new Date()) returns "2 min 30 sec"
 *
 * @example
 * // If the dwell_thresh only has 45 seconds:
 * // formatDwellTime(new Date()) returns "45 sec"
 *
 * @example
 * // If the dwell_thresh has no minutes or seconds:
 * // formatDwellTime(new Date()) returns "0 sec"
 */
export const formatDwellTime = (dwell_thresh: Date): string => {
  const dwellTime = moment(dwell_thresh) // Assuming dwell_thresh is a valid date
  const minutes = dwellTime.minutes()
  const seconds = dwellTime.seconds()

  if (minutes > 0) {
    return `${minutes} min ${seconds} sec`
  } else if (seconds > 0) {
    return `${seconds} sec`
  } else {
    return "0 sec"
  }
}

/**
 * Checks if the regions for a specific camera are fulfilled based on the entity type and specific conditions.
 *
 * @function isCameraFulfilled
 * @param {string} type - The type of the entity (e.g., "Entrance Gate", "Tenant", etc.).
 * @param {number} cameraId - The ID of the camera to check.
 * @param {CamerasRegions} camerasRegions - The object containing regions data for all cameras.
 * @returns {boolean} - Returns `true` if the conditions for fulfillment are met, `false` otherwise.
 */
export const isCameraFulfilled = (type: string, cameraId: number, camerasRegions?: CamerasRegions): boolean => {
  const cameraRegions = camerasRegions?.[cameraId]
  if (!cameraRegions) return false

  switch (type) {
    case "Entrance Gate":
    case "Tenant":
    case "Corridor":
      return cameraRegions[0]?.isFulfilled && cameraRegions[1]?.isFulfilled
    case "Dwelling Area":
      return cameraRegions[2]?.isFulfilled
    case "Car Parking":
      return cameraRegions[0]?.isFulfilled || cameraRegions[1]?.isFulfilled
    case "Cashier":
      return cameraRegions[8]?.isFulfilled
    default:
      return cameraRegions[0]?.isFulfilled
  }
}

export const areasColors = {
  "0": "rgba(26, 127, 55, 0.70)", // Green for Area In
  "1": "rgba(207, 34, 46, 0.50)", // Red for Area Out
  "2": "rgba(130, 80, 223, 0.70)", // Blue for Dwelling Area
  "8": " rgba(188, 76, 0, 0.50)", // Brown for Cashier
}
