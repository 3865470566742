import { useState, FC, Fragment } from "react"
import { useQuery, useMutation } from "react-query"

import AddIcon from "@mui/icons-material/AddCircle"
import DeleteIcon from "@mui/icons-material/DeleteOutline"
import {
  Grid,
  Paper,
  List,
  ListItem,
  ListItemText,
  IconButton,
  ListItemSecondaryAction,
  ListItemButton,
} from "@mui/material"

import { Typography, Button, NotificationUtils } from "@synapse-analytics/synapse-ui"
import { AxiosError } from "axios"
import { useFormik } from "formik"
import { NumberParam, StringParam, useQueryParams, withDefault } from "use-query-params"
import * as Yup from "yup"

import { VisionAPI } from "../../../API/VisionAPI"
import Search from "../../../components/Search"
import { definitions } from "../../../types/Generated/apiTypes"
import CarsAddPopup from "../components/CarsAddPopup"
import CarsDeletePopup from "./CarsDeletePopup"
import CarsLicenseTable from "./CarsLicenseTable"

import styles from "./CarsListsScreen.module.scss"

type ListsOfInterest = definitions["ListOfInterest"]

interface ListsOfLicensePlates {
  license_plate: string
}

const CarsListsScreen: FC = () => {
  const [open, setOpen] = useState(false)
  const [openDelete, setOpenDelete] = useState(false)
  const [openLicense, setOpenLicense] = useState(false)
  const [query, setQuery] = useQueryParams({
    searchValue: withDefault(StringParam, ""),
    listId: withDefault(NumberParam, 0),
  })
  const [listName, setListName] = useState("")
  const [deletedList, setDeletedList] = useState(false)

  const handleSearch = (value: string) => {
    setQuery({ searchValue: value })
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleCloseDelete = () => {
    setOpenDelete(false)
  }

  const handleOpenLicense = () => {
    setOpenLicense(true)
  }

  const handleCloseLicense = () => {
    setOpenLicense(false)
  }

  const { mutate: newList } = useMutation(
    (values: { newListName: string }) => VisionAPI.addCarListOfInterest({ name: values.newListName }),
    {
      onSuccess: (data) => {
        NotificationUtils.toast(`${formikLists.values?.newListName} has been added successfully`, {
          snackBarVariant: "positive",
        })
        setListName(data?.name)
        setQuery({ listId: data?.id as number })
        setOpen(false)
        refetch()
        refetchListOfInterest()
      },
    }
  )

  const validationFormikLists = () => {
    let validationSchema
    validationSchema = Yup.object({
      newListName: Yup.string().required("List name can't be empty"),
    })

    return validationSchema
  }

  const formikLists = useFormik({
    initialValues: {
      newListName: "",
    },
    validationSchema: validationFormikLists,
    validateOnChange: false,
    onSubmit: async (values, { resetForm }) => {
      newList(values)
      resetForm()
    },
  })

  const { mutate: newLicense } = useMutation(
    (values: { licensePlate: string }) =>
      VisionAPI.addCarLicensePlateToList({ license_plate: values.licensePlate, list_of_interest: query.listId }),
    {
      onSuccess: () => {
        NotificationUtils.toast(`${formikLicensePlate.values?.licensePlate} has been added successfully`, {
          snackBarVariant: "positive",
        })
        refetch()
        setOpenLicense(false)
      },
    }
  )

  const validationFormikLicense = () => {
    let validationSchema
    validationSchema = Yup.object({
      licensePlate: Yup.string().required("License plate name can't be empty"),
    })

    return validationSchema
  }

  const formikLicensePlate = useFormik({
    initialValues: {
      licensePlate: "",
    },
    validationSchema: validationFormikLicense,
    validateOnChange: false,
    onSubmit: async (values, { resetForm }) => {
      newLicense(values)
      resetForm()
    },
  })

  const handleChangeList = (listId: number, listName: string) => {
    setQuery({ listId: listId })
    setListName(listName)
    setDeletedList(false)
    setTimeout(() => {
      refetch()
    }, 300)
  }

  // fetch car lists of interest
  const { data: listsOfInterest, refetch: refetchListOfInterest } = useQuery<ListsOfInterest[], AxiosError>(
    "fetchCarsListOfInterest",
    () => VisionAPI.fetchCarListsOfInterest(),
    {
      onSuccess: (data) => {
        if (data && data.length > 0 && query.listId === 0) {
          setListName(data[0].name)
          setQuery({ listId: data[0].id as number })
        }
      },
    }
  )

  // fetch car lists of license plates
  const {
    data: listsOfLicensePlates,
    isFetching,
    refetch,
  } = useQuery<ListsOfLicensePlates[], AxiosError>(
    ["fetchCarLicensePlatesFromLists", deletedList ? listsOfInterest && listsOfInterest[0]?.id : query.listId],
    ({ queryKey }) => VisionAPI.fetchCarLicensePlatesFromLists({ list_of_interest: queryKey[1] as number }),
    {
      enabled: !!query.listId || !!(listsOfInterest && listsOfInterest[0]?.id),
    }
  )

  const { mutate: deleteList } = useMutation(() => VisionAPI.deleteCarListsOfInterest(query.listId), {
    onSuccess: () => {
      NotificationUtils.toast(`${listName} has been deleted successfully `, {
        snackBarVariant: "positive",
      })
      setOpenDelete(false)
      setDeletedList(true)
      setListName(listsOfInterest! && listsOfInterest[0].name)
      refetch()
      refetchListOfInterest()
    },
  })

  const { mutate: deleteLicense } = useMutation(
    (licensePlate: string) =>
      VisionAPI.deleteCarLicensePlateFromList({
        license_plate: licensePlate,
        list_of_interest: query.listId,
      }),
    {
      onSuccess: () => {
        NotificationUtils.toast("license plate been deleted successfully", {
          snackBarVariant: "positive",
        })
        refetch()
      },
    }
  )

  return (
    <Fragment>
      <Typography
        variant="h2-regular"
        tooltip="Cars License plate recognition with Logo detection"
        tooltipPlacement="right"
        tooltipIconSize={22}
        gutterBottom
        variantColor={2}
      >
        Cars watch list
      </Typography>

      {/* search and add new lists and license plates */}
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} md={6}>
          <Search
            topHeader={true}
            placeholder="Search using license plate"
            handleSearch={handleSearch}
            searchValue={query.searchValue}
          />
        </Grid>

        <Grid item xs={12} md={6} className={styles.add}>
          {/*New List */}
          <Button
            variant="secondary"
            className={styles.addListButton}
            onClick={() => setOpen(true)}
            startIcon={<AddIcon fontSize="small" className={styles.listIcon} />}
          >
            Add List
          </Button>

          {/* Add car to list */}
          <Button
            variant="primary"
            onClick={handleOpenLicense}
            startIcon={<AddIcon fontSize="small" className={styles.carIcon} />}
          >
            Add car
          </Button>
        </Grid>
      </Grid>

      {/* all car lists */}
      <Grid container spacing={2} className={styles.wrapper}>
        <Grid item xs={12} sm={6} md={3} className={styles.listsWrapper}>
          <Paper className={styles.listWrapper} elevation={0}>
            <ListItem>
              <ListItemText primary={<Typography variant="h2-bold">Cars Lists</Typography>} />
            </ListItem>

            {/* car lists */}
            <List
              component="nav"
              aria-label="Cars lists"
              disablePadding
              style={{ position: "relative", height: "100%" }}
            >
              {listsOfInterest && listsOfInterest.length > 0 ? (
                listsOfInterest?.map((list, i) => (
                  <ListItemButton
                    style={{ padding: "8px 0px 8px 33px", width: "100%" }}
                    selected={query.listId === list.id}
                    onClick={() => {
                      handleChangeList(list.id!, list.name)
                    }}
                    key={list.id}
                    className={query.listId === list.id ? styles.noHoverListItem : styles.listItem}
                    classes={{ selected: styles.selected, root: styles.root }}
                  >
                    <ListItemText
                      classes={{ root: styles.listItemRoot }}
                      style={{
                        color: query.listId === list.id ? "var(--indigo-background-1)" : "var(--neutral-text-enabled)",
                      }}
                      primary={
                        <Typography variant="p">
                          {list.name.length > 25 ? `${list.name.slice(0, 25)}...` : list.name}
                        </Typography>
                      }
                    />
                    <ListItemSecondaryAction>
                      <IconButton aria-label="add" color="primary" onClick={() => setOpenDelete(true)} size="large">
                        <DeleteIcon className={styles.deleteIcon} />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItemButton>
                ))
              ) : (
                <div className={styles.noData}>
                  <Typography variant="h2-bold">No car lists yet!</Typography>
                </div>
              )}
            </List>
          </Paper>
        </Grid>

        <Grid item xs={12} sm={6} md={9}>
          <CarsLicenseTable
            data={listsOfInterest && listsOfInterest.length > 0 ? listsOfLicensePlates! : []}
            loading={isFetching}
            searchValue={query.searchValue}
            deleteLicense={deleteLicense}
          />
        </Grid>
      </Grid>

      {/* add car list */}
      <CarsAddPopup type="list" formikLists={formikLists} open={open} handleClose={handleClose} />
      <CarsAddPopup
        type="licensePlate"
        formikLicensePlate={formikLicensePlate}
        open={openLicense}
        handleClose={handleCloseLicense}
      />

      {/* delete car list */}
      <CarsDeletePopup
        open={openDelete}
        handleClose={handleCloseDelete}
        handleDelete={() => {
          deleteList()
          setOpenDelete(false)
        }}
        name={listName}
      />
    </Fragment>
  )
}

export default CarsListsScreen
