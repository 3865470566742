import React, { FC, ChangeEvent } from "react"

import { Checkbox } from "@synapse-analytics/synapse-ui"

import SlidingData from "../../../components/SlidingData"
import { definitions } from "../../../types/Generated/apiTypes"

import styles from "./CampaignEntity.module.scss"

type Gate = definitions["Gate"]

interface Props {
  entities: any[]
  allEntities?: any
  handleEntities?: (e: ChangeEvent<HTMLInputElement>, id: number, name: string) => void
  filterEntities?: any[]
  entityName?: string
  searchEntities?: string
}

const CampaignEntity: FC<Props> = ({
  entities,
  filterEntities,
  handleEntities,
  entityName,
  allEntities,
  searchEntities,
}) => {
  const checkFilterEntities = entityName ? filterEntities : entities
  return (
    <SlidingData
      type={entityName ? "floors" : "entities"}
      count={searchEntities ? filterEntities!?.length : entities?.length}
      slidesToScroll={3}
      slidesPerRow={1}
      dots={false}
      infinite={false}
      rows={entities?.length <= 3 ? 1 : 2}
      slidesToShow={3}
    >
      {entities &&
        entities?.length > 0 &&
        checkFilterEntities!?.map((ent: Gate) => (
          <div key={ent?.id} className={entityName ? styles.entity : styles.selectedEntity}>
            <Checkbox
              checked={entityName ? !!allEntities![ent?.name] : true}
              name={`${ent?.name}${!entityName ? "_Selected" : ""}`}
              label={ent?.name}
              onChange={entityName ? (e: any) => handleEntities!(e, ent?.id!, ent?.name!) : undefined}
            />
          </div>
        ))}
    </SlidingData>
  )
}

export default CampaignEntity
