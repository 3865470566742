import React, { FC } from "react"
import { UseMutateFunction } from "react-query"

import { Dialog, DialogActions, DialogContentText, DialogTitle, DialogContent, CircularProgress } from "@mui/material"

import { Typography, Button, InputText, InputChangeEvent } from "@synapse-analytics/synapse-ui"
import { AxiosResponse } from "axios"

import styles from "./CampaignPopup.module.scss"

interface Props {
  name: string
  type: string
  open: boolean
  loading?: boolean
  noteValue?: string
  handleClose: () => void
  handleNoteValue?: (e: InputChangeEvent) => void
  handleAction: UseMutateFunction<AxiosResponse<any, any>, unknown, void, unknown>
}

const CampaignPopup: FC<Props> = ({
  name,
  open,
  type,
  handleAction,
  handleClose,
  loading,
  handleNoteValue,
  noteValue,
}) => {
  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      {type === "addNote" ? (
        <DialogTitle className={styles.deleteTitle} id="alert-dialog-title">
          <Typography variant="h3-regular">Add new note</Typography>
        </DialogTitle>
      ) : (
        <DialogTitle className={styles.deleteTitle} id="alert-dialog-title">
          <Typography variant="h3-regular">Are you sure to delete note {name}?</Typography>
        </DialogTitle>
      )}

      <DialogContent>
        {type === "addNote" ? (
          <InputText
            id="name"
            fullWidth
            handleChange={handleNoteValue}
            value={noteValue}
            description="Add a note from 8 to 10000 characters"
            placeholder="E.g. New note"
          />
        ) : (
          <DialogContentText id="alert-dialog-description">
            <Typography variant="p" variantColor={2}>
              {" "}
              Be aware when deleting {type} this action can't be undone.
            </Typography>
          </DialogContentText>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} variant="secondary" size="small">
          Cancel
        </Button>
        <Button
          disabled={loading ? true : false}
          onClick={() => handleAction()}
          size="small"
          variant={type === "addNote" ? "primary" : "dangerous"}
          autoFocus
        >
          {loading ? (
            <CircularProgress size={25} style={{ padding: "0px 10px" }} />
          ) : type === "addNote" ? (
            "Add"
          ) : (
            "Delete"
          )}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default CampaignPopup
