import React, { useState, useEffect, FC, Fragment } from "react"
import { useQuery } from "react-query"

import { Grid } from "@mui/material"
import CircularProgress from "@mui/material/CircularProgress"

import { NotificationUtils } from "@synapse-analytics/synapse-ui"
import moment from "moment"

import { VisionAPI } from "../../../API/VisionAPI"
import Placeholder from "../../../components/Placeholder"
import { definitions } from "../../../types/Generated/apiTypes"
import { dayMarks } from "../../../utils/heatmapUtils"
import MCPTimeline from "../components/MCPTimeline"

import styles from "../styles/MCP.module.scss"

type FloorPlan = definitions["FloorPlanVersionList"]
type MCPHourly = definitions["FloorVersionMCPLogs"]
interface Props {
  selectedDate: Date
  floorPlanVersions: FloorPlan[]
  floorId: number
  loadingSetter: (loadingState: boolean) => void
}
// future work : set date diff based on selected date range => specifying slider marks
// until now backend accepts only one day to be picked.
const dateDiff = 1

const FloorsMCPLogs: FC<Props> = ({ selectedDate, floorId, floorPlanVersions, loadingSetter }) => {
  const [mcpLogs, setMcpLogs] = useState<MCPHourly[]>()
  const [versionsCount, setVersionsCount] = useState<number[]>()

  const { data: fetchedFloorMCPLogs, isLoading: mcpLogsLoading } = useQuery<MCPHourly[]>(
    ["fetchFloorMCPLogs", floorId, moment(selectedDate).format("YYYY-MM-DD")],
    ({ queryKey }) =>
      VisionAPI.fetchFloorMCPLogs({ floor_plan_id: queryKey[1] as number, date: queryKey[2] as string }),
    {
      onSuccess: (data) => {
        if (data && data.length < 1) {
          NotificationUtils.toast("Floor's MCP Logs Not Found For Selected Date Range", {
            snackBarVariant: "warning",
          })
        }
      },
      enabled: !!floorId && !!selectedDate,
    }
  )
  // set loading state to control parent's MCP component's floor select loading state
  useEffect(() => {
    if (!mcpLogsLoading) {
      loadingSetter(false)
    } else {
      loadingSetter(true)
    }
    //eslint-disable-next-line
  }, [mcpLogsLoading])
  // set MCP logs after fetching to persist state during picking new date
  // and open error snackbar if no logs are found
  useEffect(() => {
    if (!mcpLogsLoading && selectedDate !== null) {
      if (fetchedFloorMCPLogs && fetchedFloorMCPLogs.length > 0) {
        // extract unique number of versions and sort them
        // this is mapped later into chips and displayed in top of player tools
        // indicating active version number
        const uniqueVersions = Array.from(new Set(fetchedFloorMCPLogs.map((v) => v.version))).sort((a, b) => a - b)
        setMcpLogs(fetchedFloorMCPLogs)
        setVersionsCount(uniqueVersions)
      }
    }
  }, [fetchedFloorMCPLogs, mcpLogsLoading, selectedDate])

  return (
    <Fragment>
      {mcpLogsLoading ? (
        <Grid container alignItems="center" direction="row" justifyContent="center" className={styles.loadingWrapper}>
          <Grid item alignItems="center">
            <CircularProgress
              size={70}
              className={styles.loading}
              style={{ color: "var(--important-background-default-1)" }}
            />
          </Grid>
        </Grid>
      ) : mcpLogs && mcpLogs.length > 0 ? (
        <MCPTimeline
          dateDiff={dateDiff}
          marks={dayMarks}
          mcpLogs={mcpLogs}
          floorPlanVersions={floorPlanVersions}
          versionsCount={versionsCount}
        />
      ) : (
        <Placeholder selectionType="history" isScreenPlaceholder />
      )}
    </Fragment>
  )
}
export default FloorsMCPLogs
