import { FC, ChangeEvent, useEffect, useCallback } from "react"
import { useQuery } from "react-query"
import { useLocation, useNavigate } from "react-router-dom"

import CampaignIcon from "@mui/icons-material/Campaign"
import AnalyticsIcon from "@mui/icons-material/InsertChart"
import LogoutIcon from "@mui/icons-material/Logout"
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts"
import MenuIcon from "@mui/icons-material/Menu"
import ShieldOutlinedIcon from "@mui/icons-material/ShieldOutlined"
import { Box, Divider, IconButton, useMediaQuery, useTheme } from "@mui/material"

import {
  Typography,
  Tabs,
  Avatar,
  Button,
  RoundedTab,
  Select,
  InputChangeEvent,
  NotificationUtils,
  Switch,
  Tooltip,
} from "@synapse-analytics/synapse-ui"
import { NumberParam, useQueryParam } from "use-query-params"

import { VisionAPI } from "../../../API/VisionAPI"
import { routes } from "../../../routes/routes"
import { setSelectedBranch, useBranchesStore, setIsMultipleAnalytics, storeApi } from "../../../store"
import { definitions } from "../../../types/Generated/apiTypes"
import auth from "../../../utils/auth"
import { getNameInitials } from "../../../utils/genericHelpers"
import NotificationsMenu from "./NotificationsMenu"

import styles from "./TopBar.module.scss"

type TabInfo = {
  label: string
  value: string
  icon: React.ReactElement
}
type Branch = definitions["Branch"]

interface Props {
  persona: string
  togglePersonas: (persona: string) => void
  availablePersonas?: {
    hasAnalytics: boolean
    hasAdmin: boolean
    hasMarketing: boolean
    hasSecurity: boolean
  }
  handleToggleDrawer: () => void
}
const tabsInfo: Array<TabInfo> = [
  { label: "Analytics", value: "analytics", icon: <AnalyticsIcon fontSize="small" className={styles.tabIcon} /> },
  { label: "Marketing", value: "marketing", icon: <CampaignIcon fontSize="small" className={styles.tabIcon} /> },
  { label: "Security", value: "security", icon: <ShieldOutlinedIcon fontSize="small" className={styles.tabIcon} /> },
  { label: "Admin", value: "admin", icon: <ManageAccountsIcon fontSize="small" className={styles.tabIcon} /> },
]
const TopBar: FC<Props> = ({ togglePersonas, persona, availablePersonas, handleToggleDrawer }) => {
  const [branchParam, setBranchParam] = useQueryParam("branch", NumberParam)
  const [selectedBranch, isMultipleAnalytics] = useBranchesStore(
    (state: { selectedBranch: number; isMultipleAnalytics: boolean }) => [
      state.selectedBranch,
      state.isMultipleAnalytics,
    ]
  )
  const [clientName] = [storeApi.getState().clientName]

  const navigate = useNavigate()
  const theme = useTheme()

  const smallScreen = useMediaQuery(theme.breakpoints.down("sm"))
  const xSmallScreen = useMediaQuery("(max-width:400px)")
  const largeScreen = useMediaQuery("(min-width:1400px)")

  const location = useLocation()

  const userName = auth.getFullName()

  // filter out tabs depending on the available personas
  const filteredTabs = tabsInfo.filter((tab) => {
    switch (tab.value) {
      case "analytics":
        return availablePersonas?.hasAnalytics
      case "admin":
        return availablePersonas?.hasAdmin
      case "marketing":
        return availablePersonas?.hasMarketing
      case "security":
        return availablePersonas?.hasSecurity
      default:
        return false
    }
  })
  const userId = auth.getUserId()

  const handleSelectBranch = useCallback(
    (branch: number) => {
      setSelectedBranch(branch)

      setBranchParam(branch)

      // store selected branch in local storage , to select it by default in next app visit
      auth.setSelectedBranch(branch)
    },
    [setBranchParam]
  )

  const handleChangeBranch = (event: InputChangeEvent) => {
    handleSelectBranch(event.target.value as number)
    const urlSegments = window.location.pathname.split("/").filter((segment) => segment !== "") // Split URL by '/' and remove empty segments
    if (urlSegments.length > 2) {
      // If there are more than 2 segments, navigate to home
      navigate(`/${routes?.home}`, { replace: true })
    }
  }

  const { data: branches, isLoading: branchesLoading } = useQuery<Branch[]>(
    ["fetchUserBranches", userId],
    ({ queryKey }) => VisionAPI?.fetchUserBranches({ user_id: queryKey[1] as string }),
    {
      onSuccess: handleSuccess,
      enabled: !!userId,
    }
  )

  function handleSuccess(data: Branch[]) {
    // If no branches are found, show a warning notification and exit
    if (!data || data.length === 0) {
      NotificationUtils.toast("No branches found for this user! Please contact admin.", {
        snackBarVariant: "warning",
      })
      return
    }

    // If a branchParam exists, handle branch selection based on it
    if (branchParam) {
      handleBranchParam(data, branchParam)
      return
    }

    // Get the user's previously selected branch from localStorage
    const userSelectedBranch = auth.getSelectedBranch()

    // If a valid user-selected branch exists, select it; otherwise, select the first branch from the data
    if (userSelectedBranch && Number(userSelectedBranch)) {
      handleSelectBranch(+userSelectedBranch)
    } else {
      handleSelectBranch(data[0].id!)
    }
  }

  const handleBranchParam = useCallback(
    (data: Branch[], branchParam: number) => {
      // Check if the branchParam exists in the fetched branches
      const branchExists = data?.some((branch) => branch.id === branchParam)

      // If the branch exists, select it; otherwise, navigate to 404 route
      if (branchExists) {
        handleSelectBranch(+branchParam)
      } else {
        navigate(`/${routes?.[404]}`)
      }
    },
    [handleSelectBranch, navigate]
  )

  // branches mapped to a form SUI Select optionsWithValues accept
  const mappedBranches =
    branches?.map((branch) => {
      return {
        label: branch.name,
        value: branch.id!,
      }
    }) || []

  const handleSwitchMultipleAnalytics = (event: ChangeEvent<HTMLInputElement>) => {
    setIsMultipleAnalytics(event.target.checked)
    if (event.target.checked) {
      navigate(`/${routes?.branchesAnalytics}`)
    } else {
      navigate(`/${routes?.home}`)
    }
  }

  // to ensure that multiple analytics mode is on when user navigates to the screen from outside
  useEffect(() => {
    if (location.pathname === `/${routes?.branchesAnalytics}`) {
      setIsMultipleAnalytics(true)
    } else {
      setIsMultipleAnalytics(false)
    }
  }, [location])

  // the effect ensures to select branch based on branch param in url (when navigating to another screen with a branch query param)
  useEffect(() => {
    if (branches && branchParam) handleBranchParam(branches, branchParam as number)
  }, [branchParam, branches, handleBranchParam])

  return (
    <div
      className={styles.container}
      style={{
        boxShadow: isMultipleAnalytics ? "0px 2px 5px rgba(0, 0, 0, 0.1)" : undefined,
      }}
    >
      <div className={styles.barSection}>
        <Box sx={{ display: { lg: "none", xs: "block" } }}>
          <IconButton className={styles.menuButton} onClick={handleToggleDrawer} size={largeScreen ? "large" : "small"}>
            <MenuIcon className={styles.menuIcon} />
          </IconButton>
        </Box>

        <div className={styles.titleContainer}>
          <Typography
            variant="h3-bold"
            color="indigo"
            variantColor={2}
            textTransform="uppercase"
            className={styles.azkaVision}
            onClick={() => navigate(`/${routes?.home}`)}
          >
            Azkavision
          </Typography>
        </div>

        {!smallScreen && <Divider orientation="vertical" flexItem className={styles.divider} />}
        <Tooltip title={clientName} placement="bottom">
          <Typography variant="a" textTransform="capitalize" variantColor={2} className={styles.clientName} noWrap>
            {clientName?.length > 12 ? getNameInitials(clientName) : clientName}
          </Typography>
        </Tooltip>

        <Tooltip title={xSmallScreen ? "Switch multiple branches analytics" : ""} placement="bottom">
          <Switch checked={isMultipleAnalytics} onChange={handleSwitchMultipleAnalytics} />
        </Tooltip>
        {!xSmallScreen && (
          <Typography variant="p" noWrap className={styles.switchTitle}>
            Multiple Branches
          </Typography>
        )}

        {!isMultipleAnalytics && (
          <Select
            width={smallScreen ? 111 : 180}
            value={selectedBranch}
            label="Branch"
            isNotchedLabel
            placeholder="Branch"
            loading={branchesLoading}
            optionsWithValues={mappedBranches}
            menuProps={{
              menuMaxContent: !largeScreen,
            }}
            handleChange={handleChangeBranch}
          />
        )}

        {smallScreen && <Divider orientation="vertical" flexItem className={styles.divider} />}
      </div>

      <div className={styles.barSection} style={{ justifyContent: "center" }}>
        <Tabs rounded activeTab={persona}>
          {filteredTabs?.map((tab) => (
            <RoundedTab
              disabled={isMultipleAnalytics}
              icon={() => tab.icon}
              label={!largeScreen ? "" : tab.label}
              onClick={() => togglePersonas(tab.value)}
              value={tab.value}
              selected={persona === tab.value}
              id={tab.value}
              className={styles.tab}
            />
          ))}
        </Tabs>
      </div>

      <div className={styles.barSection} style={{ justifyContent: "flex-end" }}>
        {!largeScreen && <Divider orientation="vertical" flexItem className={styles.divider} />}

        <NotificationsMenu />

        {!xSmallScreen && userName && !!userName.trim() && (
          <div className={styles.userAvatar}>
            <Avatar name={userName} isLoading={false} isNameInitials size="regular" isTextVisible={!smallScreen} />
          </div>
        )}

        {largeScreen && <Divider orientation="vertical" flexItem className={styles.divider} />}

        <Button
          className={styles.logout}
          size="small"
          startIcon={<LogoutIcon sx={{ fontSize: "16px" }} />}
          onClick={() => auth.logOut(true, navigate)}
        >
          {largeScreen ? "Logout" : ""}
        </Button>
      </div>
    </div>
  )
}
export default TopBar
