import { FC, useState } from "react"
import { useMutation, useQueryClient } from "react-query"

import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward"
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt"
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward"
import EastIcon from "@mui/icons-material/East"
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace"
import ThreeSixtyIcon from "@mui/icons-material/ThreeSixty"
import { CircularProgress, Grid } from "@mui/material"

import { Button, Tooltip, Typography } from "@synapse-analytics/synapse-ui"
import moment from "moment"

import { VisionAPI } from "../../../API/VisionAPI"
import RegionsCanvas from "../../../components/RegionsCanvas"
import { definitions } from "../../../types/Generated/apiTypes"
import FramesComparisonDialog from "./FramesComparisonDialog"

import styles from "./UnresolvedCard.module.scss"

type CVCLog = definitions["CameraViewChangeLog"]
interface Props {
  cvc: CVCLog
  handleOpenSnackbar: () => void
}

/**
 * Get the appropriate directional arrow icon or string representation based on the provided coordinates.
 * @param {Object} options - The options object.
 * @param {number} [options.x] - The x-coordinate. Positive values represent movement to the right, negative values represent movement to the left.
 * @param {number} [options.y] - The y-coordinate. Positive values represent movement upwards, negative values represent movement downwards.
 * @param {boolean} isIcon - If true, returns the corresponding icon for the direction. If false, returns a string representation of the direction.
 * @returns {JSX.Element|string} The JSX element representing the directional arrow icon or string representation of the direction.
 */
const getDirection = ({ x, y }: { x?: number; y?: number }, isIcon?: boolean): JSX.Element | string => {
  if (!!x && !y) {
    if (x > 0) {
      return isIcon ? <EastIcon /> : "right"
    } else if (x < 0) {
      return isIcon ? <KeyboardBackspaceIcon /> : "left"
    }
  } else if (!!y && !x) {
    if (y > 0) {
      return isIcon ? <ArrowUpwardIcon /> : "up"
    } else if (y < 0) {
      return isIcon ? <ArrowDownwardIcon /> : "down"
    }
  }
  return isIcon ? <></> : "" // Default case
}

const UnresolvedCard: FC<Props> = ({ cvc, handleOpenSnackbar }) => {
  const [isPreviewDialogOpen, setIsPreviewDialogOpen] = useState(false)

  const queryClient = useQueryClient()

  const { mutate: resolveCVCLog, isLoading: isResolvingLog } = useMutation(
    () => VisionAPI.resolveCameraViewChange(cvc.id as number),
    {
      onSuccess: async () => {
        await queryClient?.invalidateQueries("fetchCameraViewChangeLogs")
        handleOpenSnackbar()
      },
    }
  )

  return (
    <Grid item xs={12} md={6}>
      <FramesComparisonDialog
        open={isPreviewDialogOpen}
        handleCloseDialog={() => setIsPreviewDialogOpen(false)}
        cameraName={cvc.camera_name}
        frameBefore={cvc.comparison_reference_frame}
        frameAfter={cvc.comparison_image}
      />
      <div className={styles.wrapper}>
        <div className={styles.cardHeader}>
          <Typography variant="h2-bold">{cvc.camera_name}</Typography>
          {cvc.timestamp && (
            <Tooltip title={moment(cvc.timestamp).format("DD/MM/YYYY, h:mm A")} placement="right">
              <Typography variant="p" color="neutral" variantColor={2} style={{ width: "fit-content" }}>
                {moment(cvc.timestamp).fromNow()}
              </Typography>
            </Tooltip>
          )}
        </div>
        <div className={styles.infoWrapper}>
          <div className={styles.direction}>
            <Typography variant="p" variantColor={2}>
              Camera Moved
            </Typography>
            <Typography variant="p" variantColor={2} color="negative" textTransform="uppercase">
              {getDirection({ x: cvc.x })} {getDirection({ y: cvc.y })}
            </Typography>
          </div>
          <div className={styles.displacementAndRotation}>
            <Typography variant="span" variantColor={2}>
              Where
            </Typography>
            <div className={styles.rotationInfoWrapper}>
              <div className={styles.rotationInfo}>
                {getDirection({ x: cvc.x }, true)}
                <Typography variant="label" variantColor={2}>
                  X =
                </Typography>
                <Typography variant="label" variantColor={2} color="important">
                  {cvc.x ? `${Math.abs(cvc.x)} pixels ${getDirection({ x: cvc.x })}` : "No data"}
                </Typography>
              </div>
              <div className={styles.rotationInfo}>
                {getDirection({ y: cvc.y }, true)}
                <Typography variant="label" variantColor={2}>
                  Y =
                </Typography>
                <Typography variant="label" variantColor={2} color="important">
                  {cvc.y ? `${Math.abs(cvc.y)} pixels ${getDirection({ y: cvc.y })}` : "No data"}
                </Typography>
              </div>
              <div className={styles.rotationInfo}>
                <ThreeSixtyIcon />
                <Typography variant="label" variantColor={2}>
                  Rotation =
                </Typography>
                <Typography variant="label" variantColor={2} color="important">
                  {`${cvc.rotation} degrees` || "No data"}
                </Typography>
              </div>
            </div>
          </div>
        </div>

        {/* Frames Before VS After  */}
        <div className={styles.framesSection}>
          <div className={styles.frameWrapper}>
            <Typography variant="a" variantColor={2} gutterBottom>
              Camera before movement
            </Typography>
            <RegionsCanvas
              imageSrc={cvc.comparison_reference_frame}
              height={192}
              width={340}
              handleClick={() => setIsPreviewDialogOpen(true)}
            />
          </div>
          <ArrowRightAltIcon color="disabled" />
          <div className={styles.frameWrapper}>
            <Typography variant="a" variantColor={2} gutterBottom>
              Camera after movement
            </Typography>
            <RegionsCanvas
              imageSrc={cvc.comparison_image}
              height={192}
              width={340}
              handleClick={() => setIsPreviewDialogOpen(true)}
            />
          </div>
        </div>
        {/* TODO: bring description back when regions are put on frames */}
        {/* <Typography variant="p" variantColor={2} color="warning" className={styles.regionsDesc}>
          <InfoOutlinedIcon fontSize="small" />
          Squares on the cam frame represent the camera region
        </Typography> */}

        {/* Action Buttons */}
        <div className={styles.actionButtons}>
          {/* Redirect user to entities and regions screen with edit dialog opened  */}
          {/* TODO:: bring update regions button back when its logic and flow is finalized */}
          {/* <Tooltip
          title="Press “update regions” and go to regions to adjust changes happened to the camera movement."
          placement="left"
        >
          <Button variant="secondary" onClick={handleUpdateRegion}>
            Update Regions
          </Button>
        </Tooltip> */}
          {/* Sends Request to backend that this CVC Issue is resolved */}
          <Tooltip title="Press “resolve” when you move your camera and re-adjust it manually." placement="top">
            <Button variant="primary" onClick={() => resolveCVCLog()} disabled={isResolvingLog}>
              Resolve
              {isResolvingLog && <CircularProgress size={20} style={{ marginLeft: 4 }} />}
            </Button>
          </Tooltip>
        </div>
      </div>
    </Grid>
  )
}
export default UnresolvedCard
