import { FC } from "react"

import AccessTimeIcon from "@mui/icons-material/AccessTime"
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker"

import moment from "moment"

interface Props {
  time?: moment.Moment | Date
  handleTimeChange: (value: moment.Moment | Date | null) => void
  onlyHours?: boolean
}
const TimePicker: FC<Props> = ({ time, onlyHours, handleTimeChange }) => {
  const timeValue = moment.isMoment(time) ? time?.toDate() : time
  return (
    <MobileTimePicker
      value={timeValue}
      format={onlyHours ? "hh a" : "hh:mm a"}
      onChange={handleTimeChange}
      minutesStep={onlyHours ? 60 : 1}
      views={onlyHours ? ["hours"] : ["hours", "minutes"]}
      slotProps={{
        textField: {
          InputProps: {
            startAdornment: <AccessTimeIcon sx={{ marginRight: "10.5px", color: "var(--greyScale-text-2)" }} />,
            sx: {
              height: "32px",
              width: onlyHours ? "110px" : "140px",
              marginLeft: "12px",
              fontWeight: 700,
              fontSize: "14px",
              background: "var(--greyScale-background-1)",
              color: "var(--neutral-text-enabled)",
              boxShadow: "var(--box-shadow)",
              cursor: "pointer",
            },
          },
        },
      }}
    />
  )
}
export default TimePicker
