import * as React from "react"

import Box from "@mui/material/Box"
import CircularProgress from "@mui/material/CircularProgress"

import { Typography } from "@synapse-analytics/synapse-ui"

import styles from "./ArcProgress.module.scss"

/**
 * Parses a size value represented as a string into a tuple containing a numeric value and a unit.
 * @param {string} value - The size value to parse, e.g., "100px", "50%".
 * @returns {[number, string]} - A tuple containing the numeric value and the unit extracted from the input string.
 * @throws {Error} - If the input size value is invalid.
 */
function parse(value: string): [number, string] {
  // Regular expression to match a size value consisting of a number followed by a unit (e.g., "100px", "50%")
  const match = value.match(/^(\d+)([a-zA-Z%]+)$/)

  // If the value doesn't match the expected format, throw an error
  if (!match) {
    throw new Error(`Invalid size value: ${value}`)
  }

  // Extract the numeric value from the first capturing group of the match
  const numericValue = parseInt(match[1], 10)

  // Extract the unit from the second capturing group of the match
  const unit = match[2]

  // Return the numeric value and the unit as a tuple
  return [numericValue, unit]
}

interface ArcProgressProps {
  size: string
  value: number
  label: string
}

export function ArcProgress({ size, value, label, ...props }: ArcProgressProps): JSX.Element {
  const [sizeValue, sizeUnit] = parse(size)
  const halfSize = `${sizeValue / 2}${sizeUnit}`
  const scaledValue = value / 2

  const getColor = (value: number) => {
    if (value >= 90) {
      return "var(--green-background-1)" // healthy
    } else if (value < 90 && value >= 50) {
      return "var(--yellow-background-1)" // warning unhealthy
    } else if (value < 50) {
      return "var(--red-background-1)" // danger
    } else {
      return "#F0F4FF"
    }
  }

  return (
    <Box
      className={styles.container}
      sx={{
        height: halfSize,
        width: size,
      }}
    >
      <Box className={styles.bar}>
        <CircularProgress
          variant="determinate"
          sx={{
            color: "var(--indigo-background-2)",
          }}
          size={size}
          value={100}
          {...props}
        />
        <CircularProgress
          {...props}
          size={size}
          value={scaledValue}
          className={styles.progress}
          variant="determinate"
          sx={{
            color: getColor(value),
          }}
        />
      </Box>

      <Box className={styles.content}>
        <Typography variant="span" variantColor={2} className={styles.label}>
          {label}
        </Typography>
        <Typography variant="h1-bold" variantColor={2} className={styles.value}>
          {Math.round(value)} %
        </Typography>
      </Box>
    </Box>
  )
}
