import React, { useState, Fragment, FC } from "react"
import { useMutation, useQueryClient } from "react-query"
import { useNavigate } from "react-router-dom"

import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline"
import DnsIcon from "@mui/icons-material/Dns"
import EditOutlinedIcon from "@mui/icons-material/EditOutlined"
import LinkOffIcon from "@mui/icons-material/LinkOff"
import LoyaltyIcon from "@mui/icons-material/Loyalty"
import NearbyErrorIcon from "@mui/icons-material/NearbyError"
import { Divider } from "@mui/material"
import Grid from "@mui/material/Grid"

import { Button, Chip, NotificationUtils, Skeleton, Tooltip, Typography } from "@synapse-analytics/synapse-ui"
import { format } from "date-fns"
import moment from "moment"

import { VisionAPI } from "../../../API/VisionAPI"
import ServiceCard from "../../../components/ServiceEventTag"
import WarningDialog from "../../../components/WarningDialog"
import { routes } from "../../../routes/routes"
import { definitions } from "../../../types/Generated/apiTypes"
import EditCamera from "../components/CameraAddEdit"
import CameraHealth from "../components/CameraHealth"
import CameraStatus from "../components/CameraStatus"

import styles from "./CameraInfo.module.scss"

type Camera = definitions["CameraRetrieveUpdate"]

interface Props {
  camera: Camera
  isLoading?: boolean
}

// Placeholders
const loadingPlaceholders = new Array(5).fill(null).map((_, i) => (
  <Grid item xs={12} key={i}>
    <Skeleton variant="rectangular" height={44} width="100%" />
  </Grid>
))

const CameraInfo: FC<Props> = ({ camera, isLoading }) => {
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false)
  const [isEditCameraOpen, setIsEditCameraOpen] = useState(false)
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  const handleCloseEdit = () => {
    setIsEditCameraOpen(false)
  }

  const handleOpenDeleteDialog = () => {
    setIsDeleteDialogOpen(true)
  }

  const handleCloseDeleteDialog = () => {
    setIsDeleteDialogOpen(false)
  }

  const { mutate: deleteCamera, isLoading: isLoadingCameraDelete } = useMutation(
    () => VisionAPI.deleteCamera({ id: camera?.id }),
    {
      onSuccess: async () => {
        await queryClient?.invalidateQueries("fetchCamerasPaginated")
        NotificationUtils.toast("Camera deleted successfully", {
          snackBarVariant: "positive",
        })
        navigate(`/${routes?.cameraList}`, { replace: true })
      },
    }
  )

  return (
    <Fragment>
      <div className={styles.wrapper}>
        {isLoading ? (
          <Grid container spacing={1.5}>
            <Grid item xs={12}>
              <Typography variant="h3-bold" variantColor={2}>
                General details
              </Typography>
            </Grid>
            {loadingPlaceholders}
          </Grid>
        ) : (
          <Fragment>
            <div className={styles.header}>
              {/* camera actions */}
              <Typography variant="h3-bold" variantColor={2}>
                General details
              </Typography>

              <div className={styles.buttons}>
                <Button
                  onClick={() => setIsEditCameraOpen(true)}
                  size="small"
                  variant="secondary"
                  style={{ marginRight: 4 }}
                >
                  <EditOutlinedIcon fontSize="small" />
                </Button>
                <Button variant="dangerous" onClick={handleOpenDeleteDialog} size="small">
                  <DeleteOutlineIcon fontSize="small" />
                </Button>
                <Divider orientation="vertical" flexItem className={styles.divider} />
                <CameraStatus camera={camera} />
              </div>
            </div>

            {/* name and status */}

            {/* URL */}
            <Grid container spacing={1}>
              <Grid item md={7} xs={12}>
                <div className={styles.cameraNameWrapper}>
                  <Typography variant="h3-bold" title={camera?.name!} noWrap>
                    “{camera?.name!}”
                  </Typography>
                  {camera?.updated_at && (
                    <Tooltip title={format(new Date(camera?.updated_at ?? ""), "dd/MM/yyyy, p")} placement="right">
                      <Typography variant="p" color="neutral" variantColor={2} style={{ width: "fit-content" }}>
                        Updated {moment(camera?.updated_at).fromNow()}
                      </Typography>
                    </Tooltip>
                  )}
                </div>

                <div className={styles.section}>
                  <Typography variant="span" variantColor={2} className={styles.halfGutter}>
                    Camera tags
                  </Typography>
                  {camera?.tags && camera?.tags?.length > 0 ? (
                    <Grid container spacing={1} alignItems="center">
                      {camera?.tags?.map((tag, i) => (
                        <Grid item>
                          <Chip key={i} size="small" clickable={false}>
                            {tag}
                          </Chip>
                        </Grid>
                      ))}
                    </Grid>
                  ) : (
                    <Typography variant="h3-bold" variantColor={2} className={styles.noData}>
                      <LoyaltyIcon className={styles.noDataIcon} />
                      No tags added yet
                    </Typography>
                  )}
                </div>
                <div className={styles.section}>
                  <Typography variant="span" variantColor={2}>
                    Node
                  </Typography>
                  {camera?.node_info ? (
                    <Typography variant="h3-bold">{camera?.node_info.name}</Typography>
                  ) : (
                    <Typography variant="h3-bold" variantColor={2} className={styles.noData}>
                      <DnsIcon className={styles.noDataIcon} />
                      No node selected yet
                    </Typography>
                  )}
                </div>
              </Grid>
              {/* camera Health */}
              {camera?.id && (
                <Grid item md={5} xs={12} sx={{ margin: "auto" }}>
                  <CameraHealth cameraId={camera?.id} />
                </Grid>
              )}
            </Grid>
            {/* URL */}
            <div className={styles.section}>
              <Typography variant="span" variantColor={2} className={styles.halfGutter}>
                Camera URL
              </Typography>
              {camera?.url ? (
                <Typography variant="h3-bold" noWrap title={camera?.url}>
                  {camera?.url}
                </Typography>
              ) : (
                <Typography variant="h3-bold" variantColor={2} className={styles.noData}>
                  <LinkOffIcon className={styles.noDataIcon} />
                  No URL added yet
                </Typography>
              )}
            </div>
            {/* services */}
            <div className={styles.section}>
              <Typography variant="span" variantColor={2} className={styles.halfGutter}>
                Services
              </Typography>
              {camera?.services && camera?.services?.length > 0 ? (
                <Grid container className={styles.services} spacing={1}>
                  {camera?.services?.map((service) => (
                    <Grid item style={{ marginBottom: 2 }}>
                      <ServiceCard key={service} serviceEventType={service} />
                    </Grid>
                  ))}
                </Grid>
              ) : (
                <Typography variant="h3-bold" variantColor={2} className={styles.noData}>
                  <NearbyErrorIcon className={styles.noDataIcon} />
                  No services selected yet
                </Typography>
              )}
            </div>
            {/* Edit Camera */}
            {isEditCameraOpen && (
              <EditCamera isEdit handleClose={handleCloseEdit} isOpen camera={camera} key={camera?.id} />
            )}
            {/* Delete Camera */}
          </Fragment>
        )}

        <WarningDialog
          isOpen={isDeleteDialogOpen}
          isLoading={isLoadingCameraDelete}
          actionTitle="Delete"
          content="Be aware by deleting this camera this action can't be undone."
          onConfirm={deleteCamera}
          onCancel={handleCloseDeleteDialog}
          dialogTitle={`Delete “${camera?.name!}” camera?`}
        />
      </div>
    </Fragment>
  )
}

export default CameraInfo
