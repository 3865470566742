import React, { ChangeEvent, FC, useEffect } from "react"
import { useQuery } from "react-query"

import { Grid } from "@mui/material"

import { Typography, Switch } from "@synapse-analytics/synapse-ui"
import { Moment } from "moment"
import { BooleanParam, useQueryParam, withDefault } from "use-query-params"

import { VisionAPI } from "../../../API/VisionAPI"
import PaginatedBarGraph from "../../../components/GraphCards/PaginatedBarGraphCard"
import { ExportingRefs } from "../../../types/Custom/Interfaces"
import { definitions } from "../../../types/Generated/apiTypes"
import CategoriesOverTime from "./CategoriesOverTime"
import TenantsSubcategories from "./TenantsSubcategories"

import styles from "./ShopStatistics.module.scss"

type CategoryCount = definitions["CategoryCount"]
type ShopsCategoryLogs = definitions["ShopsCategoryLogs"]

interface Props {
  startDate: Moment | null
  endDate?: Moment | null
  interval: Duration
  timeGrain: "hour" | "day"
  setLoadingState: (isLoading: boolean) => void
  refs: ExportingRefs
}
const ShopStatistics: FC<Props> = ({ startDate, endDate, interval, setLoadingState, timeGrain, refs }) => {
  const [shouldIncludeStaff, setShouldIncludeStaff] = useQueryParam("staff", withDefault(BooleanParam, false))

  const { categoriesOverTimeRef, categoriesPerformanceRef, subcategoriesPerformanceRef, hourlyAvgRef, weekDayAvgRef } =
    refs

  const handleSwitchStaffInclusion = (event: ChangeEvent<HTMLInputElement>) => {
    setShouldIncludeStaff(event.target.checked)
  }

  const { data: dailyCategoriesCountData, isLoading: dailyCategoryCountLoading } = useQuery<ShopsCategoryLogs[]>(
    ["fetchDailyCategoriesCount", startDate?.format("YYYY-MM-DD"), endDate?.format("YYYY-MM-DD"), shouldIncludeStaff],
    ({ queryKey }) =>
      VisionAPI.fetchDailyCategoriesCount({
        from_date: queryKey[1] as string,
        to_date: queryKey[2] as string,
        include_staff: shouldIncludeStaff as boolean,
      }),
    {
      enabled: !!endDate,
    }
  )
  const { data: categoriesCountData, isLoading: categoriesCountLoading } = useQuery<CategoryCount[]>(
    ["fetchCategoriesCount", startDate?.format("YYYY-MM-DD"), endDate?.format("YYYY-MM-DD"), shouldIncludeStaff],
    ({ queryKey }) =>
      VisionAPI.fetchCategoriesCount({
        from_date: queryKey[1] as string,
        to_date: queryKey[2] as string,
        include_staff: shouldIncludeStaff as boolean,
      }),
    {
      enabled: !!endDate,
    }
  )

  useEffect(() => {
    if (categoriesCountLoading || dailyCategoryCountLoading) {
      setLoadingState(true)
    } else {
      setLoadingState(false)
    }
  }, [categoriesCountLoading, setLoadingState, dailyCategoryCountLoading])
  return (
    <div className={styles.wrapper}>
      <div className={styles.titleWrapper}>
        <Typography variant="a" variantColor={2} className={styles.title}>
          Stats
        </Typography>
        <Switch checked={shouldIncludeStaff} onChange={handleSwitchStaffInclusion} label="Include staff" />
      </div>
      {/* Categories Performance */}
      <Grid container spacing={2}>
        <Grid item md={12}>
          <PaginatedBarGraph
            categoriesLogsData={categoriesCountData}
            loading={categoriesCountLoading}
            startDate={startDate?.format("YYYY-MM-DD")}
            endDate={endDate?.format("YYYY-MM-DD")}
            isTenants={true}
            reference={categoriesPerformanceRef}
          />
        </Grid>
        <Grid item md={12} style={{ width: "100%" }}>
          <CategoriesOverTime
            logsData={dailyCategoriesCountData!}
            loading={dailyCategoryCountLoading}
            interval={interval}
            categoriesOverTimeRef={categoriesOverTimeRef}
          />
        </Grid>
      </Grid>

      {/* Temporal parts of the analytics */}

      {/* Tenants Sub Categories Section */}
      <TenantsSubcategories
        categoriesLogsData={categoriesCountData}
        dailyCategoriesCountData={dailyCategoriesCountData}
        loading={categoriesCountLoading || dailyCategoryCountLoading}
        startDate={startDate}
        endDate={endDate}
        timeGrain={timeGrain}
        subcategoriesPerformanceRef={subcategoriesPerformanceRef}
        weekDayAvgRef={weekDayAvgRef}
        hourlyAvgRef={hourlyAvgRef}
        interval={interval}
        shouldIncludeStaff={shouldIncludeStaff}
      />
    </div>
  )
}
export default ShopStatistics
