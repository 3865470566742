import React, { FC, useEffect, useState } from "react"

import GetAppOutlinedIcon from "@mui/icons-material/GetAppOutlined"
import { Box } from "@mui/material"
import CircularProgress from "@mui/material/CircularProgress"
import Grid from "@mui/material/Grid"
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"

import { Button } from "@synapse-analytics/synapse-ui"
import { CsvBuilder } from "filefy"

import GraphEmptyState from "../../../../components/graphs/GraphEmptyState"
import { definitions } from "../../../../types/Generated/apiTypes"

import styles from "./DetectionsTable.module.scss"

type SecurityLogs = definitions["SecurityViolationAnalytics"]

interface Props {
  data: SecurityLogs[]
  loading: boolean
}
type rowsData = {
  day?: string
  motionCount?: number
  fireCount?: number
  violenceCount?: number
  intrusionCount?: number
  blacklistCarCount?: number
  blacklistPersonCount?: number
}

const DetectionsTable: FC<Props> = ({ data, loading }) => {
  const [rowsArray, setRowsArray] = useState<rowsData[]>([])

  function createSeucirtyData(
    day: string,
    motionCount: number,
    fireCount: number,
    violenceCount: number,
    intrusionCount: number,
    blacklistCarCount: number,
    blacklistPersonCount: number
  ) {
    return { day, motionCount, fireCount, violenceCount, intrusionCount, blacklistCarCount, blacklistPersonCount }
  }

  useEffect(() => {
    const tableRows: rowsData[] = []
    if (data) {
      data.map((element) =>
        tableRows.push(
          createSeucirtyData(
            element.day!,
            element.motion_count!,
            element.fire_count!,
            element.violence_count!,
            element.intrusion_count!,
            element.blacklist_car_count!,
            element.blacklist_person_count!
          )
        )
      )
    }
    setRowsArray(tableRows)
  }, [data])
  const handleExportCSV = () => {
    const builder = new CsvBuilder(`SecurityEventsTable.csv`)
    let csvFormattedData: string[][] = [[]]
    csvFormattedData.pop()

    rowsArray.map((row) =>
      csvFormattedData.push([
        new Date(row.day!).toLocaleString("en-US", {
          hour12: true,
          weekday: "short",
          day: "numeric",
          month: "short",
        }),
        row.motionCount ? row.motionCount?.toString() : "0",
        row.fireCount ? row.fireCount?.toString() : "0",
        row.violenceCount ? row.violenceCount?.toString() : "0",
        row.intrusionCount ? row.intrusionCount?.toString() : "0",
        row.blacklistCarCount ? row.blacklistCarCount?.toString() : "0",
        row.blacklistPersonCount ? row.blacklistPersonCount?.toString() : "0",
      ])
    )
    builder
      .setColumns([
        "Date/Time",
        "Motion Count",
        "Fire Count",
        "Violence Count",
        "Intrusion Count",
        "Blacklist Cars Count",
        "Blacklist Person Count",
      ])
      .addRows(csvFormattedData)
      .exportFile()
  }

  return (
    <Box>
      {(!data && !loading) || (data && data.length < 1 && !loading) ? (
        <GraphEmptyState />
      ) : loading ? (
        <Grid container direction="row" justifyContent="center" alignItems="center" className={styles.loadingContainer}>
          <Grid item alignItems="center">
            <CircularProgress />
          </Grid>
        </Grid>
      ) : (
        <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-end" }}>
          <TableContainer
            className={styles.container}
            sx={{
              maxHeight: { lg: 254, xs: 265 },
            }}
          >
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow
                  classes={{
                    root: styles.rowRoot,
                  }}
                >
                  <TableCell
                    classes={{
                      root: styles.cellRoot,
                      body: styles.body,
                      head: styles.head,
                    }}
                  >
                    Date/Time
                  </TableCell>
                  <TableCell
                    classes={{
                      root: styles.cellRoot,
                      body: styles.body,
                      head: styles.head,
                    }}
                    align="right"
                  >
                    Motion Count
                  </TableCell>
                  <TableCell
                    classes={{
                      root: styles.cellRoot,
                      body: styles.body,
                      head: styles.head,
                    }}
                    align="right"
                  >
                    Fire Count
                  </TableCell>
                  <TableCell
                    classes={{
                      root: styles.cellRoot,
                      body: styles.body,
                      head: styles.head,
                    }}
                    align="right"
                  >
                    Violence Count
                  </TableCell>
                  <TableCell
                    classes={{
                      root: styles.cellRoot,
                      body: styles.body,
                      head: styles.head,
                    }}
                    align="right"
                  >
                    Intrusion Count
                  </TableCell>
                  <TableCell
                    classes={{
                      root: styles.cellRoot,
                      body: styles.body,
                      head: styles.head,
                    }}
                    align="right"
                  >
                    Blacklist Cars Count
                  </TableCell>
                  <TableCell
                    classes={{
                      root: styles.cellRoot,
                      body: styles.body,
                      head: styles.head,
                    }}
                    align="right"
                  >
                    Blacklist Person Count
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rowsArray.map((row, index) => (
                  <TableRow
                    classes={{
                      root: styles.rowRoot,
                    }}
                    key={index}
                  >
                    <TableCell
                      classes={{
                        root: styles.cellRoot,
                        body: styles.body,
                        head: styles.head,
                      }}
                      component="th"
                      scope="row"
                    >
                      {new Date(row.day!).toLocaleString("en-US", {
                        hour12: true,
                        weekday: "short",
                        day: "numeric",
                        month: "short",
                      })}
                    </TableCell>
                    <TableCell
                      classes={{
                        root: styles.cellRoot,
                        body: styles.body,
                        head: styles.head,
                      }}
                      align="center"
                    >
                      {row.motionCount || 0}
                    </TableCell>
                    <TableCell
                      classes={{
                        root: styles.cellRoot,
                        body: styles.body,
                        head: styles.head,
                      }}
                      align="center"
                    >
                      {row.fireCount || 0}
                    </TableCell>
                    <TableCell
                      classes={{
                        root: styles.cellRoot,
                        body: styles.body,
                        head: styles.head,
                      }}
                      align="center"
                    >
                      {row.violenceCount || 0}
                    </TableCell>
                    <TableCell
                      classes={{
                        root: styles.cellRoot,
                        body: styles.body,
                        head: styles.head,
                      }}
                      align="center"
                    >
                      {row.intrusionCount || 0}
                    </TableCell>
                    <TableCell
                      classes={{
                        root: styles.cellRoot,
                        body: styles.body,
                        head: styles.head,
                      }}
                      align="center"
                    >
                      {row.blacklistCarCount || 0}
                    </TableCell>
                    <TableCell
                      classes={{
                        root: styles.cellRoot,
                        body: styles.body,
                        head: styles.head,
                      }}
                      align="center"
                    >
                      {row.blacklistPersonCount || 0}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Button startIcon={<GetAppOutlinedIcon />} onClick={handleExportCSV} className={styles.export}>
            Export table
          </Button>
        </div>
      )}
    </Box>
  )
}
export default DetectionsTable
