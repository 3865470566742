import { useRef, FC, useEffect, useState } from "react"
import { useParams } from "react-router-dom"

import StatsCard from "../../../../components/StatsCard"
import { definitions } from "../../../../types/Generated/apiTypes"
import { calculateSumPerTime, calculateCountTotals } from "../../../../utils/counterUtils"
import { calculateShopsTotalCounts, calculateTableData, getEntityLogs } from "../../../../utils/shopUtils"

import styles from "./NumericStats.module.scss"

type CounterLogs = definitions["CounterLogs"]
type EntityGroupsCounts = definitions["EntityGroupsCounts"]
type shopData = definitions["Shop"] & {
  "Count In": number
  area_of_category: number
  area_of_subcategory: number
  category_count: number
  dwelling: number
  subcategory_count: number
}

type Shop = definitions["Shop"]

type tableData = {
  timestamp: Date
  "Count In": number
  "Count Out": number
  Difference: number
  "Male Count": number
  "Female Count": number
}
interface Props {
  shopsCounterData?: CounterLogs[]
  entityGroupsCounts?: EntityGroupsCounts
  loading: boolean
  tenantId?: number
  shopEntities: Shop | undefined
  shopEntitiesLoading: boolean
}

type ShopCounts = {
  entity: any
  count_in: number
}
interface MatchParams {
  id?: string
}

type NumericStatsTypes = {
  count_in_sum: number
  count_out_sum: number
  dwell_left: number
  dwell_entered: number
  stood_left: number
  stood_entered: number
  male_count_in: number
  female_count_in: number
  male_count_out: number
  female_count_out: number
  inside: number
  inside_male: number
  inside_female: number
  adult_count: number
  child_count: number
}
const NumericStats: FC<Props> = ({
  shopsCounterData,
  loading,
  tenantId,
  shopEntities,
  shopEntitiesLoading,
  entityGroupsCounts,
}) => {
  const visitorsRef = useRef(null)
  let params: MatchParams = useParams()

  const [logsSumPerTime, setLogsSumPerTime] = useState<tableData[]>()
  const [numericStat, setNumericStat] = useState<NumericStatsTypes>({
    count_in_sum: 0,
    count_out_sum: 0,
    dwell_left: 0,
    dwell_entered: 0,
    stood_left: 0,
    stood_entered: 0,
    male_count_in: 0,
    female_count_in: 0,
    male_count_out: 0,
    female_count_out: 0,
    inside: 0,
    inside_male: 0,
    inside_female: 0,
    adult_count: 0,
    child_count: 0,
  })
  const [shopCounts, setShopCounts] = useState<ShopCounts[]>([])
  const [fairShareData, setFairShareData] = useState<shopData[]>([])
  const [fairShareOfCategory, setFairShareOfCategory] = useState(0)
  const [fairShareOfSubcategory, setFairShareOfSubcategory] = useState(0)

  // processing data and calculating specific tenant numeric stats
  useEffect(() => {
    if (!loading && shopsCounterData) {
      setLogsSumPerTime(calculateSumPerTime(getEntityLogs(shopsCounterData, parseInt(params?.id!))))
      setShopCounts(calculateShopsTotalCounts(shopsCounterData))
    }
  }, [loading, shopsCounterData, tenantId, params])
  useEffect(() => {
    if (logsSumPerTime && !loading && !shopEntitiesLoading) {
      setFairShareData(calculateTableData(shopEntities, shopCounts, [{}]))
      setNumericStat(calculateCountTotals(logsSumPerTime, "tenantDetails"))
    }
  }, [logsSumPerTime, shopEntities, loading, shopCounts, shopEntitiesLoading])

  // calculating fair share of category/subcategory
  useEffect(() => {
    if (fairShareData && fairShareData.length > 0 && !loading) {
      for (let i = 0; i < fairShareData.length; i++) {
        if (fairShareData[i].id === parseInt(params?.id!) && fairShareData[i].area) {
          setFairShareOfCategory(
            fairShareData[i]["Count In"] /
              fairShareData[i].category_count /
              (fairShareData[i].area! / fairShareData[i].area_of_category)
          )
          setFairShareOfSubcategory(
            fairShareData[i]["Count In"] /
              fairShareData[i].subcategory_count /
              (fairShareData[i].area! / fairShareData[i].area_of_subcategory)
          )
        }
      }
    }
  }, [fairShareData, loading, params])

  return (
    <div className={styles.statsCardsWrapper}>
      {/* Visitors In Today Stats Card */}
      <StatsCard
        title="Total Count"
        numericStat={numericStat?.count_in_sum!}
        femaleStat={numericStat?.female_count_in!}
        childCount={numericStat?.child_count}
        adultCount={numericStat?.adult_count}
        maleStat={numericStat?.male_count_in!}
        dwellAndEnter={numericStat?.dwell_entered}
        dwellAndLeft={numericStat?.dwell_left}
        stoodAndEnter={numericStat?.stood_entered}
        stoodAndLeft={numericStat?.stood_left}
        categoryFairShare={+fairShareOfCategory.toFixed(2)}
        subcategoryFairShare={+fairShareOfSubcategory.toFixed(2)}
        isLoading={loading}
        includeDwelling
        includeFairShare
        reference={visitorsRef}
        entityGroupsCounts={entityGroupsCounts}
      />
    </div>
  )
}
export default NumericStats
