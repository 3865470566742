import { useNavigate } from "react-router-dom"

import auth from "../../../utils/auth"
import StatusScreen from "../StatusScreen"
import Authentication from "./assets/Authentication.svg?react"

const NotAllowed = () => {
  const navigate = useNavigate()
  return (
    <StatusScreen
      ArtComponent={<Authentication />}
      message="You have no available permissions"
      subMessage="Please contact your administrator"
      buttonText="Retry Login"
      buttonAction={() => auth.logOut(true, navigate)}
    />
  )
}
export default NotAllowed
