import React, { FC, useEffect, useState, Fragment } from "react"
import { useQuery } from "react-query"

import { Grid, CircularProgress } from "@mui/material"

import { NotificationUtils } from "@synapse-analytics/synapse-ui"

import { VisionAPI } from "../../../API/VisionAPI"
import Placeholder from "../../../components/Placeholder"
import { definitions } from "../../../types/Generated/apiTypes"
import CamerasMCPLogs from "./CamerasMCPLogs"

import styles from "../styles/MCP.module.scss"

type Camera = definitions["CameraRetrieveUpdate"]

interface Props {
  selectedDate: Date
  selectedCamera: number
  loadingSetter: (loadingState: boolean) => void
  disableSetter: (disableState: boolean) => void
}
const CamerasMCPContainer: FC<Props> = ({ selectedDate, selectedCamera, loadingSetter, disableSetter }) => {
  const [interval, setInterval] = useState<string>("")

  const { data: cameraData, isLoading: cameraDataLoading } = useQuery<Camera>(
    ["fetchSingleCamera", selectedCamera],
    ({ queryKey }) => VisionAPI.fetchSingleCamera(queryKey[1] as number),
    {
      onSuccess: (data) => {
        if (!data?.sample_frame) {
          NotificationUtils.toast("Camera Frame Not Found", {
            snackBarVariant: "warning",
          })
          disableSetter(true)
        } else {
          disableSetter(false)
        }
      },
      enabled: !!selectedCamera,
    }
  )

  // setting interval key to make sure component is re rendered on every date change
  useEffect(() => {
    if (selectedDate) {
      const intervalString = `start_date=${selectedDate.toISOString()}`
      setInterval(intervalString)
    }
  }, [selectedDate])
  // set loading state to control parent's MCP component's camera select loading state

  useEffect(() => {
    if (cameraDataLoading) {
      loadingSetter(true)
    } else {
      loadingSetter(false)
    }
    //eslint-disable-next-line
  }, [cameraDataLoading])
  return (
    <Fragment>
      {cameraDataLoading ? (
        <Grid
          container={true}
          alignItems="center"
          direction="row"
          justifyContent="center"
          className={styles.loadingWrapper}
        >
          <Grid item alignItems="center">
            <CircularProgress
              size={70}
              className={styles.loading}
              style={{ color: "var(--important-background-default-1)" }}
            />
          </Grid>
        </Grid>
      ) : cameraData && cameraData?.sample_frame && !!cameraData.id ? (
        <CamerasMCPLogs
          selectedDate={selectedDate}
          selectedCamera={cameraData}
          loadingSetter={loadingSetter}
          key={`${selectedCamera}_Logs_in_${interval}`}
        />
      ) : (
        <Placeholder alertMessage="Camera Frame Not Found" selectionType="camera" isScreenPlaceholder />
      )}
    </Fragment>
  )
}
export default CamerasMCPContainer
