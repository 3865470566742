import React, { FC } from "react"
import { useNavigate } from "react-router-dom"

import { SvgIcon } from "@mui/material"

import { MenuItem } from "@synapse-analytics/synapse-ui"

import { checkActiveScreen } from "../../../utils/DashboardUtils"

import styles from "./SidebarItem.module.scss"

interface Props {
  ItemIcon?: typeof SvgIcon
  activeScreen?: string
  route?: string
  subRoutes?: Array<string>
  itemLabel?: string
  accordionItem?: boolean
  togglePersonas?: (persona: string) => void
  activePersona?: string
  itemPersona?: string
  singleItem?: boolean
}

const SidebarItem: FC<Props> = ({
  ItemIcon,
  activeScreen,
  route,
  subRoutes = [],
  itemLabel,
  accordionItem,
  togglePersonas,
  activePersona,
  itemPersona,
  singleItem,
}) => {
  const navigate = useNavigate()
  const isSelected = itemPersona
    ? itemPersona === activePersona
    : [route, ...subRoutes].map((route) => checkActiveScreen(activeScreen!, route!)).some((isActive) => isActive)
  return (
    <MenuItem
      isSelected={isSelected}
      className={`${styles.sidebarItem} ${singleItem && !isSelected ? styles.singleItem : ""}`}
      onClick={() => {
        if (togglePersonas && itemPersona) {
          togglePersonas(itemPersona)
        } else {
          navigate(`/${route}`)
        }
      }}
    >
      {ItemIcon && <ItemIcon className={styles.icon} />}
      {itemPersona || itemLabel}
    </MenuItem>
  )
}

export default SidebarItem
