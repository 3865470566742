import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"

import moment from "moment"
import queryString from "query-string"

type DateType = moment.Moment | Date

interface UseDateQueryParams {
  defaultStartDate?: DateType
  defaultEndDate?: DateType
  isDayPicker?: boolean
}

/**
 * Calculates start and end dates based on query params
 * & updates the params according to changes in the page
 * NOTE: all expected date formats are ISO date formats
 * @returns [startDate, setStartDate, endDate, setEndDate]
 */
export function useDateQuery({ defaultStartDate, defaultEndDate, isDayPicker = false }: UseDateQueryParams = {}): [
  moment.Moment | null,
  Dispatch<SetStateAction<moment.Moment | null>>,
  moment.Moment | null,
  Dispatch<SetStateAction<moment.Moment | null>>
] {
  const navigate = useNavigate()
  const location = useLocation()

  // Fetch query search params from URL
  const queryParams = useMemo(() => queryString.parse(location.search), [location.search])

  // Helper to convert DateType to moment.Moment
  const convertToMoment = (date: DateType | undefined, defaultDate: moment.Moment): moment.Moment => {
    if (date) {
      return moment(date)
    }
    return defaultDate
  }

  const paramName = isDayPicker ? "day" : "startDate"

  // Use query params or defaults for start date or day
  const [startDate, setStartDate] = useState<moment.Moment | null>(
    queryParams[paramName] && typeof queryParams[paramName] === "string"
      ? moment(queryParams[paramName])
      : convertToMoment(defaultStartDate, moment().subtract(7, "days"))
  )

  // Use query params or defaults for end date if not using day picker
  const [endDate, setEndDate] = useState<moment.Moment | null>(
    !isDayPicker && queryParams.endDate && typeof queryParams.endDate === "string"
      ? moment(queryParams.endDate)
      : convertToMoment(defaultEndDate, moment())
  )

  // Merge existing query parameters with the new ones
  const mergedQueryParams = useMemo(() => {
    const params = { ...queryParams, [paramName]: startDate?.toISOString() }
    if (!isDayPicker) {
      params.endDate = endDate?.toISOString()
    } else {
      delete params.endDate
    }
    return params
  }, [endDate, queryParams, startDate, isDayPicker, paramName])

  // Function to compare two query objects and check if they are the same
  const areQueryParamsEqual = (params1: any, params2: any): boolean => {
    return JSON.stringify(params1) === JSON.stringify(params2)
  }

  // Modify the URL when the dates or page value change, preserving state
  useEffect(() => {
    const dateQuery = queryString.stringify(mergedQueryParams)

    // Prevent navigation if the query params haven't changed
    if (!areQueryParamsEqual(queryParams, mergedQueryParams)) {
      navigate(
        {
          pathname: location.pathname,
          search: dateQuery,
        },
        {
          replace: true,
          state: location.state, // Preserve the existing state object
        }
      )
    }
  }, [location.pathname, mergedQueryParams, navigate, location.state, queryParams])

  return [
    startDate,
    setStartDate,
    isDayPicker ? null : endDate,
    isDayPicker ? ((() => {}) as Dispatch<SetStateAction<moment.Moment | null>>) : setEndDate,
  ]
}
