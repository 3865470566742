import React, { FC } from "react"

import CameraEnhanceIcon from "@mui/icons-material/CameraEnhanceOutlined"
import QueryBuilderOutlinedIcon from "@mui/icons-material/QueryBuilderOutlined"
import { Card, CardMedia, CardContent } from "@mui/material"

import { Typography } from "@synapse-analytics/synapse-ui"
import moment from "moment"

import { definitions } from "../../../types/Generated/apiTypes"
import EventTypeChip from "./EventTypeChip"

import styles from "./EventCard.module.scss"

type EventsPaginated = definitions["SecurityViolationList"]

// Event Type :: Integer Representation
// FIRE             = 0
// VIOLENCE         = 1
// MOTION           = 2
// INTRUSION        = 3
// BLACKLIST_CAR    = 4
// BLACKLIST_PERSON = 5
// SMOKE            = 6
interface Props {
  eventLog: EventsPaginated
  isLive: boolean
}
const EventCard: FC<Props> = ({ eventLog, isLive }) => {
  return (
    <Card
      className={styles.eventCard}
      sx={{
        minWidth: isLive ? "300px" : 0,
        height: isLive ? "100%" : "auto",
        border: isLive
          ? `2px solid ${
              eventLog.type === 0
                ? "var(--blue-text-1)"
                : eventLog.type === 1
                ? "var(--red-background-1)"
                : eventLog.type === 2
                ? "var(--warning-background-default)"
                : eventLog.type === 3 || eventLog.type === 5
                ? "var(--neutral-border-default)"
                : eventLog.type === 4
                ? "var(--important-text-disabled)"
                : eventLog.type === 6
                ? "var(--teal-background-2)"
                : ""
            }`
          : "0",
      }}
    >
      <div>
        {!eventLog?.image ? (
          <div className={styles.media}>
            <Typography variant="label" variantColor={2} className={styles.noFrame}>
              No Frame Captured
            </Typography>
          </div>
        ) : (
          <CardMedia className={styles.media} image={eventLog?.image!} title={eventLog?.camera_name!} />
        )}
      </div>
      <CardContent style={{ padding: 0 }}>
        <div className={styles.chipDateWrapper}>
          <EventTypeChip type={eventLog?.type!} />
        </div>
        <Typography variant="a" variantColor={2} className={styles.detail}>
          <QueryBuilderOutlinedIcon style={{ marginRight: 2 }} />
          {isLive ? moment(eventLog?.timestamp!).fromNow() : moment(eventLog?.timestamp!).format("DD/MM/YYYY")}
        </Typography>
        <Typography variant="a" variantColor={2} className={styles.detail} title={eventLog?.camera_name!}>
          <CameraEnhanceIcon style={{ marginRight: 2 }} />
          {eventLog?.camera_name! && eventLog?.camera_name!.length >= 17
            ? eventLog?.camera_name.slice(0, 17) + ".."
            : eventLog?.camera_name!}
        </Typography>
      </CardContent>
    </Card>
  )
}
export default EventCard
