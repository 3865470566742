import { Fragment, useState, forwardRef } from "react"
import { useMutation, useQueryClient } from "react-query"

import AddCircleIcon from "@mui/icons-material/AddCircle"
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline"
import HighlightOffIcon from "@mui/icons-material/Close"
import { Card, CircularProgress, Dialog, DialogContent, Slide, SlideProps } from "@mui/material"

import {
  Button,
  InputText,
  NotificationUtils,
  SingleDayPicker,
  Snackbar,
  Typography,
} from "@synapse-analytics/synapse-ui"
import { FormikProps, useFormik } from "formik"
import moment, { Moment } from "moment"
import * as Yup from "yup"

import { VisionAPI } from "../../../API/VisionAPI"
import CopyToClipboard from "../../../components/CopyToClipboard"
import WarningDialog from "../../../components/WarningDialog"
import { definitions } from "../../../types/Generated/apiTypes"

import styles from "./AddNewTokenDialog.module.scss"

type AccessToken = definitions["AuthToken"]

type TokenFormikFields = {
  name: string
  expires_at: Moment
  token?: string
}

const validationFormik = () => {
  return Yup.object({
    name: Yup.string().min(3).required("Access token name is required"),
    expires_at: Yup.string().required("Expiration date is required"),
  })
}

const Transition = forwardRef(function Transition(props: SlideProps, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const AddNewTokenDialog = () => {
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const [activeStep, setActiveStep] = useState(0)
  const [isCopied, setIsCopied] = useState(false)
  const [isCancelMessageOpen, setIsCancelMessageOpen] = useState(false)

  const queryClient = useQueryClient()

  const { mutate: generateToken, isLoading: isGeneratingToken } = useMutation(
    (values: AccessToken) => VisionAPI.generateAccessToken(values),
    {
      onSuccess: (data) => {
        formik.setFieldValue("token", data.key)

        setActiveStep(1)
      },
      onError() {
        NotificationUtils.toast("Error generating access token", {
          snackBarVariant: "negative",
        })
      },
    }
  )

  const formik: FormikProps<TokenFormikFields> = useFormik<TokenFormikFields>({
    initialValues: {
      name: "",
      expires_at: moment().add(1, "month"),
    },
    validationSchema: validationFormik,
    onSubmit: (values) => generateToken({ name: values.name, expires_at: values.expires_at.format("YYYY-MM-DD") }),
  })

  // Closing confirmation message pop-up
  const handleTriggerCloseConfirmation = () => {
    const shouldShowCancelMessage = (activeStep === 0 && formik.dirty) || (activeStep === 1 && !isCopied)

    if (shouldShowCancelMessage) {
      setIsCancelMessageOpen(true)
    } else {
      handleCloseDialog()
    }
  }

  const handleCancelCloseConfirmation = () => {
    setIsCancelMessageOpen(false)
  }

  // reset all states
  const handleCloseDialog = () => {
    formik.resetForm()
    setIsCancelMessageOpen(false)
    setIsDialogOpen(false)
    setIsCopied(false)
    setActiveStep(0)
    handleCancelCloseConfirmation()
    queryClient.invalidateQueries("fetchAccessTokensList")
  }

  const handleDateChange = (date: moment.Moment | null) => {
    if (date) {
      formik.setFieldValue("expires_at", date)
    }
  }

  const isDisabledDate = (date: moment.Moment): boolean => {
    const startOfNextYear = moment().add(1, "year")
    // Disable dates before today or after a year
    return moment(date)?.isAfter(moment(), "day") && moment(date)?.isBefore(startOfNextYear)
  }

  return (
    <Fragment>
      <Button variant="primary" startIcon={<AddCircleIcon fontSize="small" />} onClick={() => setIsDialogOpen(true)}>
        Add new token
      </Button>

      <WarningDialog
        dialogTitle={activeStep === 0 ? "Unsaved Changes" : "Access Token Not Copied"}
        actionTitle={activeStep === 0 ? "Discard Changes" : "Close"}
        confirmationText={
          activeStep === 0
            ? "You have unsaved changes. Do you want to discard them?"
            : "You haven't copied the access generated token. Are you sure you want to close dialog?"
        }
        isOpen={isCancelMessageOpen}
        onCancel={handleCancelCloseConfirmation}
        onConfirm={handleCloseDialog}
      />
      <Dialog
        open={isDialogOpen}
        maxWidth={"sm"}
        fullWidth
        TransitionComponent={Transition}
        onClose={handleTriggerCloseConfirmation}
        disableEscapeKeyDown
        classes={{ paper: styles.dialogPaper }}
      >
        {activeStep === 0 ? (
          <DialogContent className={styles.wrapper}>
            <div className={styles.header}>
              <Typography variant="h2-bold" className={styles.title}>
                Create new token
              </Typography>
              <HighlightOffIcon onClick={handleTriggerCloseConfirmation} className={styles.iconContainer} />
            </div>

            <Card className={styles.formCard}>
              {/* Name Input */}
              <InputText
                id="name"
                label="Token Name"
                placeholder="E.g. Token-1"
                value={formik?.values.name}
                required
                fullWidth
                handleBlur={formik.handleBlur}
                error={formik.touched.name && Boolean(formik.errors.name) && formik.errors.name}
                description="Please enter token name"
                handleChange={formik.handleChange}
              />
              <div className={styles.datePicker}>
                <Typography variant="span">Expiration date *</Typography>
                <SingleDayPicker
                  date={formik?.values?.expires_at}
                  onDateChange={handleDateChange}
                  isOutsideRange={isDisabledDate}
                />
                <Typography variant="small">Expires in {moment(formik?.values?.expires_at).toNow(true)}</Typography>
              </div>
            </Card>
            <div className={styles.actionButton}>
              <Button
                disabled={!formik.values.name}
                onClick={() => formik.handleSubmit()}
                variant="primary"
                type="submit"
              >
                <Fragment>
                  <span style={{ marginRight: isGeneratingToken ? 10 : 0 }}>Generate</span>
                  {isGeneratingToken && <CircularProgress size={20} />}
                </Fragment>
              </Button>
            </div>
          </DialogContent>
        ) : (
          <Card className={styles.formCard}>
            <div className={styles.header}>
              <Typography variant="h2-bold" className={styles.title}>
                <CheckCircleOutlineIcon color="success" fontSize="medium" />
                New key generated
              </Typography>
              <HighlightOffIcon onClick={handleTriggerCloseConfirmation} className={styles.iconContainer} />
            </div>
            <Typography variant="p" variantColor={2}>
              Be sure to copy your new key below. It won't be shown in full again.
            </Typography>
            <CopyToClipboard
              value={formik.values.token ?? "Token should appear here"}
              name="generated access token"
              actionAfterCopy={() => setIsCopied(true)}
            />
            <Snackbar variant="warning" fullWidth>
              This key will expire on {moment(formik.values.expires_at).format("MMM DD, YYYY")}. If you'll then want to
              continue using an auth key, you'll need to generate a new one
            </Snackbar>
          </Card>
        )}
      </Dialog>
    </Fragment>
  )
}

export default AddNewTokenDialog
