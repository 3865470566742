import React, { FC } from "react"

import { Grid, Paper } from "@mui/material"

import { Typography } from "@synapse-analytics/synapse-ui"

import { definitions } from "../../../types/Generated/apiTypes"
import HourlyData from "../../EntranceGates/components/HourlyData"
import CarsNewReturning from "./CarsNewRetruning"

import styles from "./CarsAverageVisits.module.scss"

type NewReturning = definitions["NewReturning"]
type CarCounterLogs = definitions["CarCounterLogs"]

interface Props {
  carTotalCounts: NewReturning
  carsTotalCountsLoading: boolean
  carCounterLogs: CarCounterLogs[]
  carCounterLogsLoading: boolean
  interval?: Duration
  timeGrain: "hour" | "day"
  refCountsVisits: React.MutableRefObject<any>
  refAverageOccupancy: React.MutableRefObject<any>
}

const CarsAverageVisits: FC<Props> = ({
  carTotalCounts,
  carsTotalCountsLoading,
  carCounterLogs,
  carCounterLogsLoading,
  timeGrain,
  interval,
  refCountsVisits,
  refAverageOccupancy,
}) => {
  return (
    <div className={styles.wrapper}>
      <Typography variant="a" variantColor={2} gutterBottom className={styles.title}>
        New Vs. Returning Stats
      </Typography>

      {/* New Vs. Returning Stats */}
      <Grid container spacing={2}>
        <CarsNewReturning
          carTotalCounts={carTotalCounts}
          isLoading={carsTotalCountsLoading}
          refCountsVisits={refCountsVisits}
        />
        <Grid item xs={12} md={9}>
          <Paper className={styles.newReturnGraph} elevation={0} ref={refAverageOccupancy}>
            <HourlyData
              timeGrain={timeGrain}
              interval={interval!}
              loading={carCounterLogsLoading}
              carsData={carCounterLogs}
              isCars={true}
              reference={refAverageOccupancy}
            />
          </Paper>
        </Grid>
      </Grid>
    </div>
  )
}

export default CarsAverageVisits
