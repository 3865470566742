import { InputOptionValue } from "@synapse-analytics/synapse-ui"
import { QueryParamConfig } from "use-query-params"

import { CameraHealthFilterStatus, CameraServicesFilterOptions } from "../types/Custom/Interfaces"

/**
 * QueryParamConfig for managing an array of InputOptionValue objects in the URL.
 * Each InputOptionValue object is encoded as 'label:value' and separated by commas.
 */
export const InputOptionValueArrayParamConfig: QueryParamConfig<InputOptionValue[], InputOptionValue[]> = {
  /**
   * Encodes an array of InputOptionValue objects into a comma-separated string.
   * @param values - The array of InputOptionValue objects to encode.
   * @returns A string representing the array of objects.
   */
  encode: (values) => values.map((item) => `${item.label}:${item.value}`).join(","),

  /**
   * Decodes a comma-separated string into an array of InputOptionValue objects.
   * @param value - The string to decode.
   * @returns An array of InputOptionValue objects.
   */
  decode: (value: string) =>
    value
      ? value.split(",").map((item) => {
          const [label, value] = item.split(":")
          return { label, value: parseInt(value) }
        })
      : [],
}

/**
 * QueryParamConfig for managing car color selection in the URL.
 * Each car color object is encoded as 'color:value'.
 */
export const CarColorParamConfig: QueryParamConfig<{ color: string; value: string }, { color: string; value: string }> =
  {
    /**
     * Encodes a car color object into a string.
     * @param item - The car color object to encode.
     * @returns A string representing the car color object.
     */
    encode: (item) => `${item.color}:${item.value}`,

    /**
     * Decodes a string into a car color object.
     * @param item - The string to decode.
     * @returns A car color object.
     */
    decode: (item: string) => {
      if (item) {
        const [color, value] = item.split(":")
        return { color, value }
      } else {
        return { value: "", color: "All Colors" }
      }
    },
  }

/**
 * Default values for the camera health filter status.
 */
export const defaultFilterStatus: CameraHealthFilterStatus = {
  camerasHealthy: false,
  camerasDown: false,
  camerasAttention: false,
}

/**
 * Default values for the camera services filter options.
 */
export const defaultServiceFilterOptions: CameraServicesFilterOptions = {
  none: false,
  mcp: false,
  heatmap: false,
  car: false,
  counter: false,
  face: false,
  violence: false,
  motion: false,
  fire: false,
}

/**
 * QueryParamConfig for managing camera health filter status in the URL.
 * Only true values are included in the URL.
 */
export const FilterStatusParamConfig: QueryParamConfig<CameraHealthFilterStatus, CameraHealthFilterStatus> = {
  /**
   * Encodes the camera health filter status into a comma-separated string.
   * Only true values are included.
   * @param status - The camera health filter status to encode.
   * @returns A string representing the filter status.
   */
  encode: (status) =>
    Object.entries(status)
      .filter(([_, value]) => value) // Only include true values
      .map(([key, value]) => `${key}:${value}`)
      .join(","),

  /**
   * Decodes a comma-separated string into a camera health filter status object.
   * Missing keys are assumed to be false.
   * @param str - The string to decode.
   * @returns A camera health filter status object.
   */
  decode: (str: string) => {
    const status: CameraHealthFilterStatus = {
      camerasHealthy: false,
      camerasDown: false,
      camerasAttention: false,
    }

    if (str) {
      str.split(",").forEach((item) => {
        const [key, value] = item.split(":")
        if (key in status) {
          status[key as keyof CameraHealthFilterStatus] = value === "true"
        }
      })
    }
    return status
  },
}

/**
 * QueryParamConfig for managing camera services filter options in the URL.
 * Only true values are included in the URL.
 */
export const ServiceFilterOptionsParamConfig: QueryParamConfig<
  CameraServicesFilterOptions,
  CameraServicesFilterOptions
> = {
  /**
   * Encodes the camera services filter options into a comma-separated string.
   * Only true values are included.
   * @param services - The camera services filter options to encode.
   * @returns A string representing the filter options.
   */
  encode: (services) =>
    Object.entries(services)
      .filter(([_, value]) => value) // Only include true values
      .map(([key, value]) => `${key}:${value}`)
      .join(","),

  /**
   * Decodes a comma-separated string into camera services filter options.
   * Missing keys are assumed to be false.
   * @param str - The string to decode.
   * @returns A camera services filter options object.
   */
  decode: (str: string) => {
    const services: CameraServicesFilterOptions = {
      none: false,
      mcp: false,
      heatmap: false,
      car: false,
      counter: false,
      face: false,
      violence: false,
      motion: false,
      fire: false,
    }

    if (str) {
      str.split(",").forEach((item) => {
        const [key, value] = item.split(":")
        if (key in services) {
          services[key as keyof CameraServicesFilterOptions] = value === "true"
        }
      })
    }
    return services
  },
}
