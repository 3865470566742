import React, { FC, Fragment } from "react"

import { Box } from "@mui/material"

import { ICameraMappingCoors } from "../../../../types/Custom/Interfaces"

import styles from "./SingleCameraPoint.module.scss"

interface Props {
  cameraMappingCoor: ICameraMappingCoors
  coorsPosition: number
  mappingType: string
  isEntities: boolean
  pointColor?: string
}

const SingleCameraPoint: FC<Props> = ({ cameraMappingCoor, coorsPosition, mappingType, isEntities, pointColor }) => {
  return (
    <Fragment>
      <Box
        className={styles.pointNumber}
        sx={{
          top:
            mappingType === "camera"
              ? `${cameraMappingCoor.y_camera_resized! - (isEntities ? -2 : 19)}px`
              : `${cameraMappingCoor.y_floor_resized! - (isEntities ? -2 : 19)}px`,
          left:
            mappingType === "camera"
              ? `${cameraMappingCoor.x_camera_resized! - (isEntities ? -19 : 9)}px`
              : `${cameraMappingCoor.x_floor_resized! - (isEntities ? -19 : 9)}px`,
        }}
      >
        {coorsPosition}
      </Box>
      <div
        className={styles.point}
        style={{
          top:
            mappingType === "camera"
              ? `${cameraMappingCoor.y_camera_resized! - (isEntities ? -16 : 6)}px`
              : `${cameraMappingCoor.y_floor_resized! - (isEntities ? -16 : 6)}px`,
          left:
            mappingType === "camera"
              ? `${cameraMappingCoor.x_camera_resized! - (isEntities ? -19 : 9)}px`
              : `${cameraMappingCoor.x_floor_resized! - (isEntities ? -19 : 9)}px`,
        }}
      >
        <div
          className={styles.pointInside}
          style={{
            backgroundColor: pointColor ? pointColor : "var(--green-background-2)",
          }}
        ></div>
      </div>
    </Fragment>
  )
}

export default SingleCameraPoint
