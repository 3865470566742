import { FC } from "react"

import Timeline from "@mui/lab/Timeline"
import TimelineConnector from "@mui/lab/TimelineConnector"
import TimelineContent from "@mui/lab/TimelineContent"
import TimelineDot from "@mui/lab/TimelineDot"
import TimelineItem from "@mui/lab/TimelineItem"
import TimelineSeparator from "@mui/lab/TimelineSeparator"
import { Grid, Paper } from "@mui/material"

import { Skeleton, Tooltip, Typography } from "@synapse-analytics/synapse-ui"
import { format } from "date-fns"
import moment from "moment"

import NoLogs from "../../../assets/NoHistoryLogs.svg?react"
import { definitions } from "../../../types/Generated/apiTypes"

import styles from "./CameraLogs.module.scss"

type CamerasLogsHistory = definitions["LogsHistory"]

interface Props {
  logs: CamerasLogsHistory[]
  isLoading?: boolean
}

const loadingPlaceholders = new Array(11).fill(null).map((_, i) => (
  <Grid container spacing={1} item xs={12} key={i} alignItems="center">
    <Grid item xs={2}>
      <Skeleton variant="circular" height={40} width={40} />
    </Grid>
    <Grid item xs={10} container spacing={1}>
      <Grid item xs={12}>
        <Skeleton variant="rectangular" height={15} width="100%" />
      </Grid>
      <Grid item xs={12}>
        <Skeleton variant="rectangular" height={15} width="100%" />
      </Grid>
    </Grid>
  </Grid>
))

const CameraLogs: FC<Props> = ({ logs, isLoading }) => {
  const formatLog = (logString: string): string => {
    let formattedLog = ""
    if (logString.trim().length === 0) {
      formattedLog = "Camera Created"
    } else {
      formattedLog = logString
        .toLowerCase()
        .replaceAll("{", "")
        .replaceAll("'", "")
        .replaceAll("}", "")
        .replaceAll("[", "")
        .replaceAll("]", "")
        .replaceAll("mcp", "common paths")
        .replaceAll("active", "active status ")
        .replaceAll("false", "inactive")
        .replaceAll("true", "active")
        .replaceAll("none", "")
        .replaceAll("set()", "no services")
    }

    return formattedLog[0].toUpperCase() + formattedLog.slice(1)
  }

  return (
    <Paper className={styles.wrapper} elevation={0}>
      <div className={styles.header}>
        <Typography variant="h3-bold" variantColor={2}>
          Activity logs
        </Typography>
      </div>
      <div className={styles.content}>
        {isLoading ? (
          <Grid container spacing={2} sx={{ width: "100%" }}>
            {loadingPlaceholders}
          </Grid>
        ) : logs && logs?.length > 0 ? (
          logs?.map((log) => (
            <Timeline classes={{ root: styles.root }} key={log?.id}>
              <TimelineItem>
                <TimelineSeparator className={styles.separator}>
                  <TimelineDot classes={{ root: styles.dotRoot }} />
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent className={styles.dotContent}>
                  {log && log.changes && log.changes?.length > 0 ? (
                    <Typography variant="p">
                      {log?.changes?.map(
                        (logString, index) =>
                          formatLog(logString) + (index + 1 !== log?.changes!.length ? ", also " : " ")
                      )}
                    </Typography>
                  ) : (
                    <Typography variant="p">Camera Created</Typography>
                  )}
                  {(log?.actor?.full_name || log?.actor?.username) && (
                    <Typography variant="label">by {log?.actor?.full_name || log?.actor?.username}</Typography>
                  )}
                  {log?.timestamp && (
                    <Tooltip title={format(new Date(log?.timestamp), "dd/MM/yyyy, p")} placement="right">
                      <Typography variant="span" style={{ width: "fit-content" }} variantColor={2}>
                        {moment(log?.timestamp).fromNow()}
                      </Typography>
                    </Tooltip>
                  )}
                </TimelineContent>
              </TimelineItem>
            </Timeline>
          ))
        ) : (
          <div className={styles.noLogs}>
            <NoLogs width="80%" height="80%" />
            <Typography variant="span">Nothing to show here</Typography>
          </div>
        )}
      </div>
    </Paper>
  )
}

export default CameraLogs
