import React, { useContext, useEffect, Fragment } from "react"

import { CircularProgress, useMediaQuery } from "@mui/material"

import { FloorsContext } from "../../floorsContext/FloorsContext"
import SingleCameraIcon from "./SingleCameraIcon"

import styles from "./LocateCameraFloor.module.scss"

const LocateCameraFloor = ({ versionId, floorImage }: { versionId?: number; floorImage?: string }) => {
  const {
    formik,
    cameraProperties,
    canvasRef,
    handlePlaceCamera,
    isLoadingCameraCoors,
    setLocatingFloorImage,
    floorImageSizeLocating,
  } = useContext(FloorsContext)
  const smallScreens = useMediaQuery("(max-width:1610px)")
  // specifying width and height of canvas
  useEffect(() => {
    const image = new Image()
    image.src = formik?.values.floorPreview
    image.onload = () => {
      setLocatingFloorImage({
        height: image.height,
        width: image.width,
      })
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    const image = new Image()
    image.src = formik?.values.floorPreview
    image.onload = () => {
      const canvas = canvasRef?.current
      const ctx = canvas?.getContext("2d")
      if (ctx) {
        ctx.drawImage(image, 0, 0, canvas?.width, canvas?.height)
      }
    }
  }, [canvasRef, formik, cameraProperties])

  return (
    <div className={styles.wrapper}>
      {isLoadingCameraCoors ? (
        <CircularProgress className={styles.loading} />
      ) : (
        <Fragment>
          {/* camera icon placement */}
          <canvas
            ref={canvasRef}
            className={styles.canvas}
            width={smallScreens ? 620 : 860}
            height={smallScreens ? 520 : 660}
            onMouseDown={(e) =>
              handlePlaceCamera(e, { width: floorImageSizeLocating.width, height: floorImageSizeLocating.height })
            }
          />
          {cameraProperties?.map((cameraProps, i) => (
            <SingleCameraIcon key={i} cameraProps={cameraProps} />
          ))}
        </Fragment>
      )}
    </div>
  )
}

export default LocateCameraFloor
