import React, { useState, useEffect, FC, Fragment } from "react"
import { useQuery } from "react-query"

import { Grid } from "@mui/material"

import { Typography } from "@synapse-analytics/synapse-ui"
import { DateRangePicker } from "@synapse-analytics/synapse-ui"
import intervalToDuration from "date-fns/intervalToDuration"
import moment, { Moment } from "moment"

import { VisionAPI } from "../../../API/VisionAPI"
import GraphEmptyState from "../../../components/graphs/GraphEmptyState"
import PaginatedBarGraph from "../../../components/graphs/PaginatedBarGraph"
import TotalDownTime from "../../../components/graphs/TotalDownTime"
import barGraphLoading from "../../../components/graphs/assets/barChartLoading.mp4"
import { definitions } from "../../../types/Generated/apiTypes"

import styles from "./CameraDownTime.module.scss"

type DownTimeLogs = definitions["CameraDownTime"]
type DownTimeLog = definitions["CameraDownTimeLog"]

interface Props {
  cameraId?: number
}
type GraphData = {
  date: string
  duration: number
  color?: string
}

interface FormattedLog {
  date: string
  duration: number
  color: string
}

/**
 * Formats logs fetched from the backend to be in the form of actual date and duration in seconds.
 * Also sorts the logs by date and highlights the log with the highest duration.
 *
 * @param {DownTimeLog[]} logs - Array of logs containing date and duration.
 * @returns {FormattedLog[]} - Array of formatted logs with date, duration, and color.
 */
const formatGraphData = (logs: DownTimeLog[]): FormattedLog[] => {
  /** @type {FormattedLog[]} */
  let arr: FormattedLog[] = []

  if (logs) {
    // Format dates and durations
    logs.forEach((obj) => {
      const date = moment(obj.date).format("DD/MM/YY")
      const totalDownTimeInSeconds = obj.duration
      const row: FormattedLog = {
        date: date,
        duration: totalDownTimeInSeconds as number,
        color: "var(--indigo-background-2)",
      }
      arr.push(row)
    })

    // Sort by date
    arr.sort((a, b) => {
      const dateA = moment(a.date, "DD/MM/YY")
      const dateB = moment(b.date, "DD/MM/YY")
      return dateA.diff(dateB)
    })

    // Assign colors
    if (arr.length > 0) {
      const maxDuration = Math.max(...arr.map((log) => log.duration))

      arr.forEach((log) => {
        log.color = log.duration === maxDuration ? "var(--indigo-background-1)" : "var(--indigo-background-2)"
      })
    }
  }
  return arr
}

const weekAgo = moment().subtract(7, "days")
const now = moment()

const CameraDownTime: FC<Props> = ({ cameraId }) => {
  const [startDate, setStartDate] = useState<Moment | null>(weekAgo)
  const [endDate, setEndDate] = useState<Moment | null>(now)
  const [totalDownTime, setTotalDownTime] = useState<Duration>({
    days: 0,
    hours: 0,
    minutes: 0,
  })
  const [downTimeLogs, setDownTimeLogs] = useState<DownTimeLogs>()
  const [barGraphData, setBarGraphData] = useState<GraphData[]>([{ date: "", duration: 0 }])
  const maxPages = Math.ceil(barGraphData.length / 10)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalPickedInterval, setTotalPickedInterval] = useState<Duration>({
    days: 0,
    hours: 0,
    minutes: 0,
  })

  // setting picked time interval after any start/end date changes
  // used later in total down time circular progress
  // to have a ratio between total picked duration in seconds vs total down time in seconds
  useEffect(() => {
    if (endDate !== null && startDate !== null) {
      const interval = intervalToDuration({
        start: startDate!.toDate(),
        end: endDate!.toDate(),
      })
      if (interval.days! <= 1 && interval.months! < 1) {
        setTotalPickedInterval({
          days: 0,
          hours: 24,
          minutes: 0,
        })
      } else {
        setTotalPickedInterval(interval)
      }
    }
  }, [startDate, endDate])

  const { data: fetchedDownTimeLogs, isLoading: isDownTimeLogsLoading } = useQuery<DownTimeLogs>(
    ["fetchCameraDownTime", cameraId, startDate?.format("YYYY-MM-DD"), endDate?.format("YYYY-MM-DD")],
    ({ queryKey }) =>
      VisionAPI.fetchCameraDownTime({
        id: queryKey[1] as number,
        start_date: queryKey[2] as string,
        end_date: queryKey[3] as string,
      }),
    {
      enabled: !!startDate && !!endDate && !!cameraId,
    }
  )

  // setting total down time duration state (in seconds) after logs are fetched from endpoint
  // used later in total down time circular progress
  // to have a ratio between total picked duration in seconds vs total down time in seconds
  useEffect(() => {
    if (downTimeLogs && !isDownTimeLogsLoading) {
      const totalDownTimeInMilliSeconds = (downTimeLogs.total_down_time as number) * 1000
      const interval = intervalToDuration({ start: 0, end: totalDownTimeInMilliSeconds })
      setTotalDownTime(interval)
      setBarGraphData(formatGraphData(downTimeLogs.logs))
    }
  }, [downTimeLogs, isDownTimeLogsLoading])

  // preserving logs state , to keep last valid logs (before choosing new date range)
  // so that component doesn't show empty state WHILE user is changing start/end date
  useEffect(() => {
    if (!isDownTimeLogsLoading && !!endDate && !!startDate) {
      if (fetchedDownTimeLogs) {
        setDownTimeLogs(fetchedDownTimeLogs)
      }
    }
  }, [fetchedDownTimeLogs, isDownTimeLogsLoading, endDate, startDate])

  // resetting current page indicator to 1 after picking a new start || end date
  useEffect(() => {
    if (startDate && endDate) {
      setCurrentPage(1)
    }
  }, [endDate, startDate])

  return (
    <Fragment>
      <div className={styles.header}>
        <Typography variant="h2-bold" variantColor={2}>
          Down Time
        </Typography>
        <DateRangePicker
          startDate={startDate}
          endDate={endDate}
          onStartDateChange={setStartDate}
          onEndDateChange={setEndDate}
          disableFuture
        />
      </div>
      <Grid container spacing={2}>
        <Grid item xs={12} md={3}>
          <TotalDownTime
            totalDownTime={totalDownTime}
            totalDuration={totalPickedInterval}
            isLoading={isDownTimeLogsLoading}
          />
        </Grid>
        <Grid item xs={12} md={9}>
          <div className={styles.wrapper}>
            <div className={styles.graphHeader}>
              <Typography variant="h3-bold" variantColor={2} className={styles.title}>
                Daily Down-Time Duration
              </Typography>
              <Typography variant="h2-bold" variantColor={2} className={styles.title}>
                {!isDownTimeLogsLoading && barGraphData.length > 10 && `: Page ${currentPage}/${maxPages}`}
              </Typography>
            </div>
            {!isDownTimeLogsLoading && (!downTimeLogs || (downTimeLogs && downTimeLogs.logs.length < 1)) ? (
              <div>
                <GraphEmptyState isCameraDown />
              </div>
            ) : isDownTimeLogsLoading ? (
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                className={styles.loadingContainer}
              >
                <Grid item alignItems="center">
                  <video autoPlay={true} loop={true} width="300" height="200">
                    <source src={barGraphLoading} type="video/mp4" />
                  </video>
                </Grid>
              </Grid>
            ) : (
              <PaginatedBarGraph
                barGraphData={barGraphData}
                isLoading={isDownTimeLogsLoading}
                setCurrentPage={setCurrentPage}
                key={barGraphData}
                isDownTime
              />
            )}
          </div>
        </Grid>
      </Grid>
    </Fragment>
  )
}
export default CameraDownTime
