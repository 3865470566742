import { FC, useContext, useEffect, useState } from "react"

import AccessTimeIcon from "@mui/icons-material/AccessTime"
import DeleteIcon from "@mui/icons-material/Delete"
import UndoIcon from "@mui/icons-material/Undo"
import { Divider } from "@mui/material"
import { MobileTimePicker } from "@mui/x-date-pickers"

import { Button, Chip, Tooltip, Typography } from "@synapse-analytics/synapse-ui"

import { IEntitiesSetup, RegionsObject } from "../../../../../../types/Custom/Interfaces"
import { Point } from "../../../../../../types/Custom/Types"
import { formatDwellTime } from "../../../../../../utils/EntitiesSetupUtils"
import { EntitiesContext } from "../../../EntitiesContext/EntitiesContext"

import styles from "./AreaSelection.module.scss"

interface Props {
  points: Point[]
  setPoints: (points: Point[]) => void
}

const AreaSelection: FC<Props> = ({ points, setPoints }) => {
  const {
    availableRegions,
    allRegions,
    selectedDrawingCamera,
    setAllRegions,
    selectedRegion,
    setSelectedRegion,
    selectedState,
  } = useContext<IEntitiesSetup>(EntitiesContext)

  // filter out regions to include only the regions of selected state
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const stateRegions = allRegions[`${selectedDrawingCamera}`]?.[selectedState]?.regions || []

  const [timePickerOpen, setTimePickerOpen] = useState(false)
  const [dwellValue, setDwellValue] = useState<Date | number>()

  /**
   * Updates the dwell threshold for the selected region of the selected camera and state.
   *
   * @param {Date | number | null} newDateValue - The new value for the dwell threshold. If null, defaults to 5 seconds.
   */
  const handleDwellThresholdChange = (newDateValue: Date | number | null) => {
    const allCamerasRegions = { ...allRegions } // Clone allRegions to avoid direct mutation
    const cameraStateRegions = allCamerasRegions[`${selectedDrawingCamera}`]?.[selectedState]?.regions

    if (!cameraStateRegions) return // If regions do not exist, exit the function

    let regionObj: RegionsObject = { ...cameraStateRegions[selectedRegion] }
    regionObj = {
      ...regionObj,
      dwell_thresh: newDateValue || new Date().setMinutes(0, 5),
    }

    // Update the region at the specific selectedRegion
    cameraStateRegions[selectedRegion] = regionObj

    // Set the updated regions back in allCamerasRegions for the correct camera and state
    allCamerasRegions[`${selectedDrawingCamera}`][selectedState].regions = [...cameraStateRegions]

    // Update the state
    setAllRegions(allCamerasRegions)
  }

  /**
   * Removes a region layer from the selected camera and state.
   * Updates the form data and re-calculates indexes for specific region states.
   *
   * @param {number} index - The index of the region to be removed.
   */
  const handleDeleteArea = (index: number) => {
    const allCamerasRegions = { ...allRegions } // Clone allRegions to avoid direct mutation
    const cameraRegionsArray = allCamerasRegions[`${selectedDrawingCamera}`]?.[selectedState]?.regions

    if (!cameraRegionsArray) return // If no regions exist for this camera/state, exit

    // Check if the area to delete is the last area of all areas
    if (cameraRegionsArray?.length < 2) {
      // If it is, reset the regions of that area and the points state array
      cameraRegionsArray[index] = { ...cameraRegionsArray[index], points: [] }
    } else {
      // If it's not the last area, remove the region at the specified index
      cameraRegionsArray.splice(index, 1)
    }

    // Check if any remaining regions have at least two points
    const hasRegionWithTwoPoints = cameraRegionsArray.some((region) => region.points.length >= 2)

    // Set isFullfilled to false if no region with two points exists
    if (!hasRegionWithTwoPoints) {
      allCamerasRegions[`${selectedDrawingCamera}`][selectedState].isFulfilled = false
    }

    // Update the regions for the camera and state
    allCamerasRegions[`${selectedDrawingCamera}`][selectedState].regions = [...cameraRegionsArray]

    // Update allRegions in the form state
    setAllRegions(allCamerasRegions)

    // Handle region selection after deletion
    if (index === 0) setSelectedRegion(0)
    else if (index === cameraRegionsArray.length) setSelectedRegion(index - 1)
    else setSelectedRegion(index)

    setPoints([]) // Reset the points after deletion
  }

  // set points to match selected region points
  useEffect(() => {
    if (stateRegions[selectedRegion]?.points?.length > 0) {
      setPoints([...stateRegions[selectedRegion]?.points])
    } else {
      setPoints([])
    }
  }, [selectedRegion, setPoints, stateRegions])

  return (
    <div className={styles.wrapper}>
      <div className={styles.areasContainer}>
        <Typography variant="p" noWrap>
          {availableRegions[selectedState]} :
        </Typography>
        {stateRegions?.map((_region, index) => (
          <Chip
            clickable
            onClick={() => setSelectedRegion(index)}
            isSelected={selectedRegion === index}
            size="small"
            className={styles.areaChip}
            tabIndex={index}
            key={index}
          >
            Area ({index + 1})
          </Chip>
        ))}
      </div>
      <div className={styles.actionButtons}>
        {selectedState === 2 && (
          <Tooltip title="Select dwelling time threshold in minutes : seconds (default 5 seconds)" placement="top">
            <div
              className={styles.dwellTimePicker}
              onClick={() => {
                // specify dwelling time
                setTimePickerOpen(true)
                setDwellValue(stateRegions[selectedRegion].dwell_thresh)
              }}
            >
              <AccessTimeIcon sx={{ color: "var(--gray-background-1)", padding: "4px 10px" }} fontSize="small" />
              <Divider orientation="vertical" flexItem className={styles.divider} />
              <Typography variant="p" noWrap style={{ width: "100%", padding: "4px 10px" }}>
                {formatDwellTime(stateRegions[selectedRegion].dwell_thresh as Date)}
              </Typography>
            </div>
          </Tooltip>
        )}

        <Button
          variant="dangerous"
          startIcon={<DeleteIcon fontSize="small" />}
          size="small"
          className={styles.actionButton}
          tooltipPlacement="left"
          onClick={() => handleDeleteArea(selectedRegion)}
        >
          Delete area
        </Button>
        <Button
          variant="secondary"
          startIcon={<UndoIcon fontSize="small" />}
          size="small"
          className={styles.actionButton}
          disabled={points.length < 1}
          onClick={() => setPoints(points.slice(0, points.length - 1))}
        >
          Undo
        </Button>
      </div>
      {selectedState === 2 && (
        <MobileTimePicker
          ampm={false}
          sx={{ display: "none" }}
          open={timePickerOpen}
          onClose={() => {
            setTimePickerOpen(false)
            setDwellValue(undefined)
          }}
          openTo="minutes"
          key={selectedRegion}
          views={["minutes", "seconds"]}
          format="mm:ss"
          value={dwellValue || new Date().setMinutes(0, 5)}
          onAccept={(date) => handleDwellThresholdChange(date)}
        />
      )}
    </div>
  )
}
export default AreaSelection
