import React, { createContext, useState, useEffect, useRef, ReactNode, useCallback } from "react"
import { useQuery } from "react-query"

import { createFFmpeg, fetchFile, FFmpeg } from "@ffmpeg/ffmpeg"
import { useItemProgressListener, useUploadyContext } from "@rpldy/chunked-uploady"
import { AxiosError } from "axios"
import { useFormik, FormikProps } from "formik"
import { v4 as uuidv4 } from "uuid"

import { VisionAPI } from "../../../API/VisionAPI"
import { getApiBaseUrl } from "../../../lib/axios"
import { IFootageData, IFootageMediaInfo, IUploadFootage } from "../../../types/Custom/Interfaces"
import { definitions } from "../../../types/Generated/apiTypes"
import Auth from "../../../utils/auth"

type FootageServices = definitions["ServiceLogsExist"]

// set createContext [Default value]
export const FootageContext = createContext({} as IFootageData)

const FootageContextProvider = ({ children }: { children: ReactNode }) => {
  const [progress, setProgress] = useState(0)
  const [footagePreview, setFootagePreview] = useState("")
  const [footagePreviewLoading, setFootagePreviewLoading] = useState(false)
  const [duration, setDuration] = useState("")
  const [outputFootage, setOutputFootage] = useState<File>()
  const [mediaInfo, setMediaInfo] = useState<IFootageMediaInfo>({
    file: new File([""], "filename"),
    name: "",
    originalFootageSrc: "",
  })
  const [alert, setAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState("")
  const [footageUploadProgress, setFootageUploadProgress] = useState(0)
  const [uploadStatus, setUploadStatus] = useState(0)
  const [ffmpeg, setFFmpeg] = useState<FFmpeg>()
  const session_id = uuidv4()
  const uploadyContext = useUploadyContext()

  const videoRef = useRef<HTMLVideoElement>(null)

  const resetUploadFootage = () => {
    formik.resetForm()
    uploadyContext.abort()
    uploadyContext.clearPending()
    setProgress(0)
    setFootagePreview("")
    setFootagePreviewLoading(false)
    setDuration("")
    setMediaInfo({
      file: new File([""], "filename"),
      name: "",
      originalFootageSrc: "",
    })
    setOutputFootage(undefined)
    setAlert(false)
    setAlertMessage("")
    setFootageUploadProgress(0)
    setUploadStatus(0)
  }
  const defaultFootagePreview = () => {
    setFootagePreview("")
  }
  const closeAlert = () => {
    setAlert(false)
    setAlertMessage("")
  }

  async function initFFmpeg() {
    setFFmpeg(
      createFFmpeg({
        log: true,
        corePath: "https://unpkg.com/@ffmpeg/core@0.10.0/dist/ffmpeg-core.js",
      })
    )
  }

  //handle drag and drop footage file
  const handleDropFootage = (acceptedFiles: any) => {
    defaultFootagePreview()
    const file = acceptedFiles[0]
    if (file?.size! > 5000000000) {
      setAlert(true)
      setAlertMessage("Camera Footage size must not be more than 5GB")
    } else if (file?.size === 0) {
      setAlert(true)
      setAlertMessage("Please choose a camera footage!")
    } else {
      handleFootagePreview(file!)
    }
  }
  // load ffmpeg utility
  useEffect(() => {
    // get logging message
    if (ffmpeg !== undefined) {
      ffmpeg.setLogger(({ type, message }: { type: string; message: string }) => {
        if (type === "fferr" && message.includes("Duration")) {
          // set Duration
          setDuration(message.split(" ")[3].replace(",", ""))
        }
      })
      // get progress value
      ffmpeg.setProgress(({ ratio }: { ratio: number }) => {
        if (ratio >= 0 && ratio <= 1) {
          setProgress(ratio * 100)
        }
      })
    }
  }, [ffmpeg])

  // get snapshot from footage before upload [Preview]
  const handleFootagePreview = async (file: File): Promise<void> => {
    setFootagePreviewLoading(true)
    // load ffmpeg
    if (!ffmpeg!.isLoaded()) {
      await ffmpeg!.load()
    }
    setMediaInfo({
      ...mediaInfo,
      // file
      file,
      // name of the file
      name: file.name,
      // get original url of the video before process
      originalFootageSrc: URL.createObjectURL(file),
    })

    // read output image
    ffmpeg!.FS("writeFile", file.name, await fetchFile(file))
    // read image
    await ffmpeg!.run("-ss", "00:00:02", "-i", file.name, "-frames:v", "1", "res.jpg")
    //get image data
    const data = ffmpeg!.FS("readFile", "res.jpg")
    // convert to data buffer array into file object
    let footagePreviewResult = URL.createObjectURL(
      new Blob([data.buffer], {
        type: "image/jpg",
      })
    )
    setFootagePreview(footagePreviewResult)
    if (footagePreviewResult) {
      setProgress(0)
    }
  }

  // handle processing video
  const handleFootageProcess = async (): Promise<void> => {
    if (!ffmpeg!.isLoaded()) {
      await ffmpeg!.load()
    }
    // read original video
    ffmpeg!.FS("writeFile", mediaInfo.file?.name, await fetchFile(mediaInfo?.file))

    await ffmpeg!.run(
      "-i",
      mediaInfo.file?.name,
      "-c:v",
      "libx264",
      "-r",
      "3",
      "-crf",
      "28",
      "-preset",
      "superfast",
      "-an",
      "resVideo.mp4"
    )

    // read output video
    const data = ffmpeg!.FS("readFile", "resVideo.mp4")
    // convert to data buffer array into file object
    const resVideoFile = new File([data], "resVideo", { type: "video/mp4" })
    setOutputFootage(resVideoFile)
  }

  const formik: FormikProps<IUploadFootage> = useFormik<IUploadFootage>({
    initialValues: {
      cameraId: "",
      cameraName: "",
      startDate: new Date(),
      endDate: new Date(),
      startTime: new Date(),
      endTime: new Date(),
      // preview
      cameraUrlPreviewSrc: "",
      cameraUrlPreview: "",
    },
    // onSubmit: (values) => mutate(values)
    onSubmit: (values) => console.log(values),
  })

  // fetch footage overlapped services
  const { data: services } = useQuery<FootageServices, AxiosError>(
    ["fetchFootageOverlap", formik?.values.startDate.toISOString(), formik?.values.endDate.toISOString()],
    ({ queryKey }) =>
      VisionAPI.fetchServicesFootage({
        from_dt: queryKey[1] as string,
        to_dt: queryKey[2] as string,
      }),
    {
      enabled: footagePreview && duration ? true : false,
    }
  )

  useEffect(() => {
    if (services) setFootagePreviewLoading(false)
  }, [services])

  const startUpload = useCallback(
    async (cameraId: string, startDate: string, endDate: string, outputFootage: File, originalFootageName: string) => {
      try {
        uploadyContext.upload(outputFootage, {
          destination: {
            url: getApiBaseUrl() + `/api/footage/upload/?camera=${cameraId}&start_dt=${startDate}&end_dt=${endDate}`,
            headers: {
              Authorization: "Bearer " + Auth.getAccessToken(),
              // "X-Progress-ID": uuidv4(),
              "Session-ID": session_id,
              "Content-Disposition": `attachment, filename="${originalFootageName}"`,
              "Content-Type": outputFootage?.type,
            },
          },
          method: "POST",
          sendWithFormData: false,
          formatServerResponse: (response, status) => {
            setUploadStatus(status)
            if (status === 500 || status === 503) {
              setAlert(true)
              setAlertMessage("Error with uploading camera footage!")
            }
          },
        })
      } catch (error) {
        console.log("Start Uploading Error : ", error)
      }
    },
    //eslint-disable-next-line
    [uploadyContext]
  )
  useItemProgressListener((item) => setFootageUploadProgress(item.completed))

  return (
    <FootageContext.Provider
      value={{
        formik,
        services,
        duration,
        alert,
        closeAlert,
        alertMessage,
        progress,
        videoRef,
        originalFootageSrc: mediaInfo.originalFootageSrc,
        originalFootageName: mediaInfo.name,
        outputFootage,
        handleFootageProcess,
        handleFootagePreview,
        footagePreview,
        footagePreviewLoading,
        defaultFootagePreview,
        handleDropFootage,
        startUpload,
        footageUploadProgress,
        uploadStatus,
        resetUploadFootage,
        initFFmpeg,
      }}
    >
      {children}
    </FootageContext.Provider>
  )
}
export default FootageContextProvider
