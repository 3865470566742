import React, { FC, useState, Fragment } from "react"
import { useMutation, useQueryClient } from "react-query"

import AddCircleIcon from "@mui/icons-material/AddCircle"
import HighlightOffIcon from "@mui/icons-material/Close"
import DeleteForeverIcon from "@mui/icons-material/DeleteForever"
import {
  TextField,
  CircularProgress,
  Dialog,
  Slide,
  SlideProps,
  DialogContent,
  Card,
  InputAdornment,
} from "@mui/material"

import { Typography, Button, InputText, NotificationUtils } from "@synapse-analytics/synapse-ui"
import { useFormik, FormikProps } from "formik"

import { VisionAPI } from "../../../API/VisionAPI"
import WarningDialog from "../../../components/WarningDialog"
import { definitions } from "../../../types/Generated/apiTypes"
import {
  handleDeleteSubcategory,
  handleSubcategoryChange,
  handleAddNewSubcategory,
  checkIsDisabled,
  validationFormik,
  filterEmptySubcategories,
} from "../../../utils/categoryUtils"

import styles from "./AddCategory.module.scss"

type Category = definitions["Category"]

const Transition = React.forwardRef(function Transition(props: SlideProps, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

interface Props {
  handleClose: () => void
  open: boolean
  isEdit?: boolean
  categoryData?: Category
}

const AddCategory: FC<Props> = ({ handleClose, open, isEdit, categoryData }) => {
  const [isCancelMessageOpen, setIsCancelMessageOpen] = useState(false)
  const queryClient = useQueryClient()

  const formik: FormikProps<Category> = useFormik<Category>({
    initialValues: {
      id: categoryData?.id ?? undefined,
      name: categoryData?.name ?? "",
      subcategories: categoryData?.subcategories ?? [{ name: "" }],
    },
    validationSchema: validationFormik,
    onSubmit: (values) => {
      const filteredCategory = filterEmptySubcategories(values)
      if (isEdit) updateCategory(filteredCategory)
      else addCategory(filteredCategory)
    },
  })

  const { mutate: addCategory, isLoading: isAddCategoryLoading } = useMutation(
    (values: Category) => VisionAPI.createCategory(values),
    {
      onSuccess: () => {
        NotificationUtils.toast("Category created successfully", {
          snackBarVariant: "positive",
        })
        // refetch categories
        queryClient.invalidateQueries("fetchCategories")
        // close dialog
        handleCloseDialog()
      },
      onError() {
        NotificationUtils.toast("Error creating category", {
          snackBarVariant: "negative",
        })
        // close dialog
        handleCloseDialog()
      },
    }
  )

  const { mutate: updateCategory, isLoading: isUpdatingCategoryLoading } = useMutation(
    (values: Category) => VisionAPI.updateCategory(values.id as number, values),
    {
      onSuccess: () => {
        NotificationUtils.toast("Category updated successfully", {
          snackBarVariant: "positive",
        })
        // refetch categories
        queryClient.invalidateQueries("fetchCategories")
        // close dialog
        handleCloseDialog()
      },
      onError() {
        NotificationUtils.toast("Error updating category", {
          snackBarVariant: "negative",
        })
        // close dialog
        handleCloseDialog()
      },
    }
  )

  // closing confirmation message pop up
  const handleTriggerCloseConfirmation = () => {
    setIsCancelMessageOpen(true)
  }

  const handleCancelCloseConfirmation = () => {
    setIsCancelMessageOpen(false)
  }

  // Empty All States of all the chosen inputs
  const handleCloseDialog = () => {
    formik.resetForm()
    formik.setFieldValue("subcategories", [{ name: "" }])
    setIsCancelMessageOpen(false)
    handleClose()
    handleCancelCloseConfirmation()
  }

  const isLoading = isAddCategoryLoading || isUpdatingCategoryLoading
  return (
    <Fragment>
      <WarningDialog
        confirmationText={`cancel category ${isEdit ? "editing" : "creation"} process?`}
        isOpen={isCancelMessageOpen}
        onCancel={handleCancelCloseConfirmation}
        onConfirm={handleCloseDialog}
      />

      <Dialog
        open={open}
        maxWidth="md"
        fullWidth
        TransitionComponent={Transition}
        onClose={handleClose}
        disableEscapeKeyDown
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent className={styles.wrapper} style={{ paddingTop: 32, overflow: "visible" }}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="h2-bold" className={styles.title}>
              {isEdit && categoryData ? `Edit ${categoryData?.name}` : "Add New Category"}
            </Typography>
            <HighlightOffIcon onClick={handleTriggerCloseConfirmation} className={styles.iconContainer} />
          </div>

          <Card className={styles.categoryFormCard}>
            {/* Name Input */}
            <div style={{ marginBottom: "12px" }}>
              <Typography variant="a" variantColor={2} className={styles.inputTitle}>
                <span className={styles.step}>1</span>
                Name Category
              </Typography>
              <InputText
                id="name"
                label="Category Name"
                placeholder="E.g. Electronics"
                width={420}
                value={formik?.values.name}
                required
                handleBlur={formik.handleBlur}
                error={formik.touched.name && Boolean(formik.errors.name) && formik.errors.name}
                description="Please enter category name"
                handleChange={formik.handleChange}
              />
            </div>
            <Fragment>
              <Typography variant="a" variantColor={2} className={styles.inputTitle}>
                <span className={styles.step}>2</span>
                Sub-Categories
              </Typography>
              {formik.values.subcategories.map((subcategory, index) => (
                <TextField
                  key={index}
                  placeholder="E.g. Laptops"
                  id={subcategory.name}
                  sx={{
                    border: "1px solid var(--neutral-text-enabled)",
                  }}
                  className={styles.subcategoryField}
                  value={subcategory.name}
                  InputProps={{
                    classes: {
                      disabled: styles.disabled,
                      input: styles.fieldPadding,
                      notchedOutline: styles.noBorder,
                    },
                    disableUnderline: true,
                    endAdornment: (
                      <InputAdornment position="end">
                        <DeleteForeverIcon
                          className={styles.deleteIcon}
                          onClick={() => handleDeleteSubcategory(index, formik)}
                        />
                      </InputAdornment>
                    ),
                  }}
                  onChange={(event) => handleSubcategoryChange(event, index, formik)}
                />
              ))}
              <AddCircleIcon className={styles.addSubcategoryIcon} onClick={() => handleAddNewSubcategory(formik)} />
            </Fragment>
          </Card>
          <div className={styles.actionButton}>
            <Button
              disabled={checkIsDisabled(formik)}
              onClick={() => {
                formik.handleSubmit()
              }}
              variant="primary"
            >
              <Fragment>
                <span style={{ marginRight: isLoading ? 10 : 0 }}>{isEdit ? "Update" : "Create"}</span>
                {isLoading && <CircularProgress size={20} />}
              </Fragment>
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </Fragment>
  )
}

export default AddCategory
