import { ChangeEvent, FC } from "react"

import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined"
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined"
import TelegramIcon from "@mui/icons-material/Telegram"
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material"

import { Checkbox, Typography } from "@synapse-analytics/synapse-ui"

import { definitions } from "../../../types/Generated/apiTypes"

import styles from "./NotificationsChannels.module.scss"

type ChannelData = definitions["UserNotificationSetting"]
type NotificationsType = definitions["UserNotificationSetting"]["notification_type"]

const availableNotifications: { type: NotificationsType; label: string }[] = [
  { type: "CVC", label: "Camera View Change" },
  { type: "CD", label: "Camera Down" },
  { type: "UC", label: "Unserved Customer" },
  { type: "NSC", label: "Node State Change" },
]

const availableChannels: { label: string; channel: "AV" | "MAIL" | "TEL"; icon: JSX.Element }[] = [
  {
    label: "Azkavision",
    channel: "AV",
    icon: <NotificationsNoneOutlinedIcon className={`${styles.icon} ${styles.bell}`} />,
  },
  {
    label: "Email",
    channel: "MAIL",
    icon: <EmailOutlinedIcon className={`${styles.icon} ${styles.email}`} />,
  },
  {
    label: "Telegram",
    channel: "TEL",
    icon: <TelegramIcon className={`${styles.icon} ${styles.telegram}`} />,
  },
]

interface Props {
  subscriptions?: ChannelData[]
  handleUpdateChannel: (type: NotificationsType, event: ChangeEvent<HTMLInputElement>) => void
}

/**
 * Checks if the `notification_setting` array contains an entry with the specified type
 * and if that entry contains the specified channel.
 *
 * @param {ChannelData[]} notification_setting - The array of channel data.
 * @param {(NotificationsType)} type - The type to look for in the channel data.
 * @param {("AV" | "MAIL" | "TEL")} channel - The channel to check for in the channels array.
 * @returns {boolean} - Returns true if the type and channel are found, otherwise false.
 */
const isChecked = (type: NotificationsType, channel: "AV" | "MAIL" | "TEL", data?: ChannelData[]): boolean => {
  const entry = data?.find((item) => item.notification_type === type)
  return entry ? entry.notification_channels.includes(channel) : false
}

const NotificationsChannels: FC<Props> = ({ subscriptions, handleUpdateChannel }) => {
  return (
    <TableContainer className={styles.container}>
      <Table aria-label="notification-channels">
        <TableHead>
          <TableRow
            classes={{
              root: styles.rowRoot,
            }}
          >
            {["Notification", "Channel", "Channel", "Channel"].map((title) => (
              <TableCell
                classes={{
                  root: styles.cellRoot,
                  body: styles.body,
                  head: styles.head,
                }}
                align="center"
              >
                {title}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {availableNotifications.map((row, notificationIndex) => {
            return (
              <TableRow
                classes={{
                  root: styles.rowRoot,
                }}
                key={notificationIndex}
              >
                <TableCell
                  classes={{
                    root: styles.cellRoot,
                    body: styles.body,
                    head: styles.head,
                  }}
                  align="center"
                >
                  {row.label}
                </TableCell>
                {availableChannels.map((availableChannel, channelIndex) => (
                  <TableCell
                    classes={{
                      root: styles.cellRoot,
                      body: styles.body,
                      head: styles.head,
                    }}
                    key={channelIndex}
                    align="center"
                  >
                    <div className={styles.channel}>
                      <Checkbox
                        name={`${notificationIndex}_${channelIndex}`}
                        value={availableChannel.channel}
                        checked={isChecked(row.type, availableChannel.channel, subscriptions)}
                        onChange={(event) => handleUpdateChannel(row.type, event as ChangeEvent<HTMLInputElement>)}
                      />
                      {availableChannel.icon}
                      <Typography variant="h3-regular" textTransform="capitalize">
                        {availableChannel.label}
                      </Typography>
                    </div>
                  </TableCell>
                ))}
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
export default NotificationsChannels
