import React, { FC, useState, useEffect } from "react"
import { useQuery } from "react-query"

import { Grid } from "@mui/material"

import { Tag, Typography } from "@synapse-analytics/synapse-ui"
import { AxiosError } from "axios"
import { Moment } from "moment"

import { VisionAPI } from "../../../API/VisionAPI"
import { definitions } from "../../../types/Generated/apiTypes"
import EventsOverTime from "./Analytics/EventsOverTime"
import NumericStat from "./Analytics/NumericStats"

import styles from "./EventsSummary.module.scss"

type SecurityLogs = definitions["SecurityViolationAnalytics"]

interface Props {
  startDate: Moment | null
  endDate: Moment | null
  selectedCameras: number[]
  issueType: string
  interval: Duration
  setLoadingState: (isLoading: boolean) => void
}

const EventsSummary: FC<Props> = ({ startDate, endDate, selectedCameras, issueType, setLoadingState, interval }) => {
  const [logsData, setLogsData] = useState<SecurityLogs[]>([])

  const { data: analyticsLogs, isLoading: analyticsLogsLoading } = useQuery<SecurityLogs[], AxiosError>(
    [
      "fetchSecurityAnalyticsLogs",
      issueType,
      selectedCameras,
      startDate?.format("YYYY-MM-DD"),
      endDate?.format("YYYY-MM-DD"),
    ],
    ({ queryKey }) => {
      const selectedCameras = queryKey[2] as number[]
      const cameras = selectedCameras.join(",")

      return VisionAPI.fetchSecurityEventsAnalyticsLogs({
        type: queryKey[1] as string,
        cameras,
        from_date: queryKey[3] as string,
        to_date: queryKey[4] as string,
      })
    },
    {
      enabled: !!startDate && !!endDate,
    }
  )
  useEffect(() => {
    if (!analyticsLogsLoading) {
      setLoadingState(false)
      if (analyticsLogs && !!startDate && !!endDate) {
        setLogsData(analyticsLogs)
      }
    } else {
      setLoadingState(true)
    }
  }, [analyticsLogs, analyticsLogsLoading, setLoadingState, startDate, endDate])
  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <Typography variant="a" variantColor={2}>
          Events Summary
        </Typography>
        <Tag variant="grass">Beta</Tag>
      </div>
      <Grid container spacing={2}>
        {/* numeric stats section */}
        <Grid item md={3} xs={12}>
          <NumericStat logs={logsData!} isLoading={analyticsLogsLoading} />
        </Grid>
        {/* graph */}
        <Grid item md={9} xs={12}>
          <EventsOverTime logsData={analyticsLogs!} loading={analyticsLogsLoading} interval={interval} />
        </Grid>
      </Grid>
    </div>
  )
}

export default EventsSummary
