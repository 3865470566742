import { FC, useContext, useEffect, useRef } from "react"
import { useQuery } from "react-query"

import { VisionAPI } from "../../../../../../API/VisionAPI"
import Placeholder from "../../../../../../components/Placeholder"
import { IEntitiesSetup } from "../../../../../../types/Custom/Interfaces"
import { Point } from "../../../../../../types/Custom/Types"
import { definitions } from "../../../../../../types/Generated/apiTypes"
import { areasColors } from "../../../../../../utils/EntitiesSetupUtils"
import { drawGridLines, drawPointCircles, drawTooltip, getRegionLabel } from "../../../../../../utils/drawingUtils"
import { EntitiesContext } from "../../../EntitiesContext/EntitiesContext"

import styles from "./RegionsArtboard.module.scss"

type Camera = definitions["CameraRetrieveUpdate"]

interface Props {
  points: Point[]
  setPoints: (points: Point[]) => void
}
const RegionsArtboard: FC<Props> = ({ points, setPoints }) => {
  const drawingCanvasRef = useRef<HTMLCanvasElement>(null)
  const { formik, selectedDrawingCamera, allRegions, selectedState, selectedRegion } =
    useContext<IEntitiesSetup>(EntitiesContext)

  const { data: cameraData } = useQuery<Camera>(
    ["fetchSingleCamera", selectedDrawingCamera],
    ({ queryKey }) => VisionAPI.fetchSingleCamera(queryKey[1] as number),
    {
      enabled: !!selectedDrawingCamera,
    }
  )

  /**
   * This useEffect is responsible for rendering the camera image on the canvas and overlaying region-specific data.
   * It loads the camera's sample frame, draws grid lines on the canvas, and then iterates over regions associated
   * with the selected camera. For each region, it renders the region's boundaries on the canvas with specific fill
   * colors based on its state (In, Out, Dwelling) and draws a tooltip label above the region.
   * The effect depends on several variables, including the selected camera, the active step in the workflow, and
   * the regions' state data, ensuring the canvas is updated dynamically whenever these values change.
   */
  useEffect(() => {
    if (!selectedDrawingCamera || !allRegions[selectedDrawingCamera] || !cameraData?.sample_frame) return

    const image = new Image()
    image.src = `${cameraData?.sample_frame}`

    image.onload = () => {
      const canvas = drawingCanvasRef.current
      if (!canvas) return // Guard clause for canvas
      const ctx = canvas.getContext("2d")
      if (!ctx) return // Guard clause for context

      // Check if scaling is needed (for Car Parking type)
      const isCarParking = formik.values.type === "Car Parking"
      const scaleX = isCarParking ? 736 / 1280 : 1
      const scaleY = isCarParking ? 416 / 720 : 1

      // Draw the camera image onto the canvas (scaled if necessary)
      ctx.drawImage(image, 0, 0, 736, 416)

      // Draw grid lines using the utility function
      drawGridLines(ctx, canvas)

      // Iterate over the states in allRegions[selectedDrawingCamera]
      Object.entries(allRegions[selectedDrawingCamera]).forEach(([state, { regions }]) => {
        regions.forEach((region, index) => {
          if (region.points && region.points.length > 0) {
            const isSelectedRegion = index === selectedRegion && region.state === selectedState

            const fillStyle = areasColors[state as "0" | "1" | "2" | "8"]

            ctx.fillStyle = fillStyle
            ctx.strokeStyle = isSelectedRegion ? "#3E58EF" : fillStyle
            ctx.lineWidth = isSelectedRegion ? 4 : 2

            // Start drawing the region (scaled if necessary)
            ctx.beginPath()
            const scaledPoints = region.points.map((point) => ({
              x: point.x * scaleX, // Scale X coordinate
              y: point.y * scaleY, // Scale Y coordinate
            }))

            ctx.moveTo(scaledPoints[0].x, scaledPoints[0].y)

            for (let i = 1; i < scaledPoints.length; i++) {
              ctx.lineTo(scaledPoints[i].x, scaledPoints[i].y)
            }

            ctx.closePath()
            ctx.stroke()
            ctx.fill()

            // Draw the label tooltip on top of the region (scaled if necessary)
            const label = getRegionLabel(Number(state), index + 1) // Index is 1-based
            const textX = scaledPoints[0].x // Position tooltip near the first point of the region
            const textY = scaledPoints[0].y - 10 // Adjust Y position slightly above the region
            drawTooltip(ctx, label, textX, textY, state === "2" || state === "8" ? 75 : 62) // Draw the tooltip

            // Draw small numbered circles on each point if this is the selected region (scaled)
            if (isSelectedRegion) {
              drawPointCircles(ctx, scaledPoints)
            }
          }
        })
      })
    }
  }, [
    selectedState,
    allRegions,
    cameraData?.sample_frame,
    points,
    selectedDrawingCamera,
    selectedRegion,
    formik.values.type,
  ])

  return (
    <div className={styles.wrapper}>
      {cameraData?.sample_frame ? (
        <canvas
          ref={drawingCanvasRef}
          onClick={(e) => {
            const rect = drawingCanvasRef!.current!.getBoundingClientRect()

            // Check if the type is "Car Parking"
            const scaleX = formik.values.type === "Car Parking" ? 1280 / 736 : 1
            const scaleY = formik.values.type === "Car Parking" ? 720 / 416 : 1

            const newPoint = {
              x: Math.round((e.clientX - rect.x) * scaleX),
              y: Math.round((e.clientY - rect.y) * scaleY),
            }

            setPoints([...points, newPoint])
          }}
          className={styles.drawingBoard}
          width={736}
          height={416}
        />
      ) : (
        <Placeholder
          description="Camera has no frame to draw on"
          selectionType="camera"
          svgWidth="130px"
          svgHeight="65px"
        />
      )}
    </div>
  )
}
export default RegionsArtboard
