import React, { FC } from "react"
import { UseMutateFunction } from "react-query"

import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline"
import { Dialog, DialogActions, DialogContent } from "@mui/material"

import { Button, Typography } from "@synapse-analytics/synapse-ui"
import { AxiosResponse } from "axios"

import styles from "./DeleteFloor.module.scss"

interface Props {
  floorName: string
  open: boolean
  handleDeleteFloor: UseMutateFunction<AxiosResponse<any, any>, unknown, void, unknown>
  handleClose: () => void
}

const DeleteFloor: FC<Props> = ({ floorName, open, handleDeleteFloor, handleClose }) => {
  return (
    <Dialog open={open} onClose={handleClose} maxWidth="lg">
      <DialogContent className={styles.wrapper} style={{ paddingTop: 32, overflow: "visible" }}>
        <ErrorOutlineIcon className={styles.errorIcon} />
        <Typography variant="h2-bold" className={styles.title}>
          Are you sure?
        </Typography>
        <Typography variant="p" className={styles.warningDescription} align="center">
          You're about to delete floor {floorName} ,Be aware when deleting floors this action can't be undone.
        </Typography>
        <DialogActions className={styles.actionButtons}>
          <Button onClick={handleClose} variant="primary" className={styles.cancelButton}>
            Cancel
          </Button>
          <Button onClick={() => handleDeleteFloor()} variant="dangerous" className={styles.confirmButton}>
            Delete
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  )
}

export default DeleteFloor
