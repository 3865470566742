import { create } from "zustand"
import { devtools } from "zustand/middleware"
import { createWithEqualityFn } from "zustand/traditional"

const deepEqual = (prev, next) => {
  return JSON.stringify(prev) === JSON.stringify(next)
}

// Function to determine WebSocket (WS) protocol based on the provided API protocol
export const getWsProtocol = (apiProtocol) => {
  let wsProtocol = undefined
  if (apiProtocol === "http:") {
    wsProtocol = "ws:"
  } else if (apiProtocol === "https:") {
    wsProtocol = "wss:"
  } else {
    throw Error("Invalid apiProtocol Set")
  }
  return wsProtocol
}

// Explanation:
// This module creates a Zustand store named `storeApi` to manage API configuration settings.
// It exports a store instance that includes initial state values for WebSocket protocol, API protocol, hostname, and port.
// These values are retrieved from the `window.__RUNTIME_CONFIG__` object during runtime.
// Additionally, a method `setApiSettings` is provided (commented out) to update API settings dynamically if needed.

// Define and export the store instance
export const storeApi = create((set) => ({
  // Initialize store state with API configuration settings
  // These values are retrieved from `window.__RUNTIME_CONFIG__` object which is expected to be provided during runtime

  // WebSocket (WS) protocol based on the API protocol retrieved from runtime config
  apiWsProtocol: getWsProtocol(`${window.__RUNTIME_CONFIG__.API_PROTOCOL}:`),

  // API protocol retrieved from runtime config
  apiProtocol: `${window.__RUNTIME_CONFIG__.API_PROTOCOL}`,

  // API hostname retrieved from runtime config
  apiHostname: window.__RUNTIME_CONFIG__.API_HOSTNAME,

  // API port retrieved from runtime config
  apiPort: window.__RUNTIME_CONFIG__.API_PORT,

  // Client Name
  clientName: window.__RUNTIME_CONFIG__.CLIENT_NAME,
}))

export const useCredentialsStore = create()((set) => ({
  currentUser: "",
  loginError: false,
  networkError: false,
  setCurrenUser: (user) => set((state) => ({ currentUser: user }), "setCurrenUser"),
  setLoginError: (loginError) => set((state) => ({ loginError: loginError }), "setLoginError"),
  setNetworkError: (networkError) => set((state) => ({ networkError: networkError }), "setNetworkError"),
}))

export const useBranchesStore = createWithEqualityFn()(
  devtools(
    () => ({
      selectedBranch: null,
      isMultipleAnalytics: false,
    }),
    deepEqual
  )
)
export const setSelectedBranch = (branch) => useBranchesStore.setState(() => ({ selectedBranch: branch }))

export const setIsMultipleAnalytics = (value) => useBranchesStore.setState(() => ({ isMultipleAnalytics: value }))
