import React, { FC, useState, useEffect, Fragment } from "react"

import { Card } from "@mui/material"

import { Typography } from "@synapse-analytics/synapse-ui"

import DailyLineGraph from "../../../../components/graphs/dailyLineGraph"
import HourlyLineGraph from "../../../../components/graphs/hourlyLineGraph"
import { definitions } from "../../../../types/Generated/apiTypes"
import { calculateSumPerTime, convertDataToHourlyLineGraph } from "../../../../utils/counterUtils"

import styles from "./CorridorOverTime.module.scss"

type CounterLogs = definitions["CounterLogs"]
type Corridor = definitions["Corridor"]

interface Props {
  timeGrain: "hour" | "day"
  logsData?: CounterLogs[]
  loading?: boolean
  reference: any
  selectedCorridor?: Corridor
  interval: Duration
}
type graphPoint = {
  x: Date
  y: number
}
type GraphData = {
  id: string
  data: graphPoint[]
}
const CorridorOverTime: FC<Props> = ({ timeGrain, logsData, loading, reference, selectedCorridor, interval }) => {
  const [lineGraphData, setLineGraphData] = useState<GraphData[]>([
    {
      id: "",
      data: [{ x: new Date(), y: 0 }],
    },
  ])
  // convert data fetched from backend to graph acceptable data form
  useEffect(() => {
    if (loading === false && logsData) {
      const logsSumByTimestamp = calculateSumPerTime(logsData)
      setLineGraphData(convertDataToHourlyLineGraph(logsSumByTimestamp))
    }
  }, [logsData, loading])
  return (
    <Card className={styles.wrapper} ref={reference}>
      <Typography variant="h2-bold" variantColor={2} noWrap>
        Corridor {selectedCorridor?.name} Over Time
      </Typography>
      <Fragment>
        {timeGrain === "hour" ? (
          <HourlyLineGraph data={lineGraphData} graphHeight={300} isLoading={loading} />
        ) : (
          <DailyLineGraph data={lineGraphData} graphHeight={300} isLoading={loading} interval={interval} />
        )}
      </Fragment>
    </Card>
  )
}
export default CorridorOverTime
