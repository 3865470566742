import React, { FC } from "react"

import { Grid, Paper } from "@mui/material"

import { Typography, InputText, InputPassword, Switch } from "@synapse-analytics/synapse-ui"
import { FormikProps } from "formik"

import { IUserAddEdit } from "../../types/Custom/Interfaces"
import TelegramId from "./components/TelegramId"

import styles from "./UserInformation.module.scss"

interface Props {
  edit: boolean
  formik: FormikProps<IUserAddEdit>
}

const UserInformation: FC<Props> = ({ edit, formik }) => {
  return (
    <Paper elevation={0} className={styles.paper}>
      <div className={styles.header}>
        <Typography variant="h2-bold" variantColor={2}>
          User data
        </Typography>
        {edit && (
          <div className={styles.userStatus}>
            <Switch
              checked={formik.values.is_active}
              label={formik.values.is_active ? "Active" : "Inactive"}
              onChange={() => formik.setFieldValue("is_active", !formik.values.is_active)}
              labelPlacement="right"
            />
          </div>
        )}
      </div>
      <Grid container spacing={1} className={styles.content}>
        {/* username */}
        <Grid item xs={12}>
          <InputText
            fullWidth
            autofocus={!edit}
            id="username"
            label="User name"
            error={formik.touched.username && Boolean(formik.errors.username) && formik.errors.username}
            hideDescription={!(formik.touched.username && Boolean(formik.errors.username) && formik.errors.username)}
            placeholder="E.g. user1234"
            value={formik.values?.username.trim()}
            handleChange={formik.handleChange}
            handleBlur={formik.handleBlur}
            required
            variant="filled"
          />
        </Grid>
        {/* password */}
        <Grid item xs={12} md={edit ? 12 : 6}>
          <InputPassword
            fullWidth
            id="password"
            value={formik.values.password}
            handleChange={formik.handleChange}
            handleBlur={formik.handleBlur}
            error={formik.touched.password && Boolean(formik.errors.password) && formik.errors.password}
            variant="filled"
          />
        </Grid>
        {!edit && (
          <Grid item xs={12} md={6}>
            <InputPassword
              fullWidth
              id="confirm_password"
              confirmPassword
              value={formik.values.confirm_password}
              handleChange={formik.handleChange}
              handleBlur={formik.handleBlur}
              error={
                formik.touched.confirm_password &&
                Boolean(formik.errors.confirm_password) &&
                formik.errors.confirm_password
              }
              variant="filled"
            />
          </Grid>
        )}
        <Grid item xs={12} md={6}>
          <InputText
            fullWidth
            id="first_name"
            label="Employee First Name"
            placeholder="E.g Mohamed"
            error={formik.touched.first_name && Boolean(formik.errors.first_name) && formik.errors.first_name}
            value={formik.values?.first_name}
            hideDescription
            handleChange={formik.handleChange}
            handleBlur={formik.handleBlur}
            variant="filled"
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <InputText
            fullWidth
            id="last_name"
            label="Employee Last Name"
            placeholder="E.g Ali"
            error={formik.touched.last_name && Boolean(formik.errors.last_name) && formik.errors.last_name}
            value={formik.values?.last_name}
            handleChange={formik.handleChange}
            handleBlur={formik.handleBlur}
            variant="filled"
            hideDescription
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <InputText
            fullWidth
            id="email"
            label="Email Address"
            error={formik.touched.email && Boolean(formik.errors.email) && formik.errors.email}
            placeholder="example@domain.com"
            value={formik.values?.email}
            handleChange={formik.handleChange}
            handleBlur={formik.handleBlur}
            variant="filled"
          />
        </Grid>
        {/* Telegram ID Input field with demonstration video */}
        <Grid item xs={12} md={6}>
          <TelegramId formik={formik} />
        </Grid>
      </Grid>
    </Paper>
  )
}

export default UserInformation
