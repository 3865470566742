import { Navigate, Route, BrowserRouter as Router, Routes, Outlet, useLocation } from "react-router-dom"

import queryString from "query-string"
import { QueryParamProvider } from "use-query-params"
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6"

import { routes } from "../routes/routes"
import Auth from "../utils/auth"
import Dashboard from "./Dashboard/Dashboard"
import Login from "./Login/Login"

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
function PrivateRoute() {
  const isAuthenticated = Auth.isAuthenticated()
  const location = useLocation()
  return isAuthenticated ? (
    <Outlet />
  ) : (
    <Navigate
      to={{
        pathname: "/login",
      }}
      state={{ from: location }}
    />
  )
}

function App() {
  return (
    <Router>
      <QueryParamProvider
        adapter={ReactRouter6Adapter}
        options={{
          searchStringToObject: queryString.parse,
          objectToSearchString: queryString.stringify,
        }}
      >
        <Routes>
          {/*
           * <Outlet/> component defined earlier is representing the <Dashboard/>
           * component in this example because <Dashboard/> contains all the child routes
           * of the <PrivateRoute/>
           */}
          <Route element={<PrivateRoute />}>
            <Route path="*" element={<Dashboard />} />
          </Route>
          <Route path="/login" element={<Login redirectPath={`/${routes?.home}`} />} />
        </Routes>
      </QueryParamProvider>
    </Router>
  )
}

export default App
