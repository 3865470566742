import { useState, useEffect, useCallback, FC, Fragment } from "react"
import { useLocation, Location } from "react-router-dom"

import { Drawer, Box } from "@mui/material"

import { splitPersonaAndScreen } from "../../../utils/DashboardUtils"
import SynapseLogo from "../assets/synapseLogo.svg?react"
import UserData from "./UserData"

import styles from "./Sidebar.module.scss"

interface Props {
  availablePersonas: {
    hasAnalytics: boolean
    hasAdmin: boolean
    hasMarketing: boolean
    hasSecurity: boolean
  }
  persona: string
  setPersona: (persona: string) => void
  handleToggleDrawer: () => void
  handleCloseDrawer: () => void
  isDrawerOpen: boolean
}
const Sidebar: FC<Props> = ({ handleCloseDrawer, handleToggleDrawer, persona, setPersona, isDrawerOpen }) => {
  const location = useLocation()

  // capture current active screen from url pathname
  const [activeScreen, setActiveScreen] = useState(
    splitPersonaAndScreen(location?.pathname.split("/").slice(-1).toString()).screen
  )

  // this function uses all util functions defined before ,to handle every location change
  const handleLocationChange = useCallback(
    (location: Location) => {
      const pagePathName = location?.pathname?.split("/")
      const screenRoute = pagePathName.reverse().find((element) => !Number(element))
      // extract persona and screen route
      const personaAndScreen = splitPersonaAndScreen(screenRoute!)
      if (personaAndScreen.persona !== "notifications") {
        setPersona(personaAndScreen.persona)
        setActiveScreen(personaAndScreen.screen)
      }
      // close drawer if on mobile after successful navigation.
      handleCloseDrawer()
    },
    [setPersona, handleCloseDrawer]
  )
  // triggered to handle each location change
  useEffect(() => {
    handleLocationChange(location)
  }, [location, handleLocationChange])

  const handleOpenSynapseSite = () => window.open("https://www.synapse-analytics.io/", "_blank")

  const drawer = (
    <div className={styles.wrapper}>
      {/* User Data */}
      <UserData persona={persona} activeScreen={activeScreen} />

      {/* copyright */}
      <SynapseLogo className={styles.synapseLogo} onClick={handleOpenSynapseSite} />
    </div>
  )
  if (persona !== "") {
    return (
      <nav className={styles.drawer} aria-label="navigation">
        {/* Desktop navigation */}

        <Box sx={{ display: { lg: "block", xs: "none" }, height: "100%" }}>
          <Drawer
            classes={{
              paper: styles.drawerPaper,
              root: styles.drawerRoot,
            }}
            variant="permanent"
            anchor="left"
          >
            {drawer}
          </Drawer>
        </Box>

        {/* Mobile navigation */}

        <Box sx={{ display: { lg: "none", xs: "block" } }}>
          <Drawer
            variant="temporary"
            open={isDrawerOpen}
            anchor="left"
            onClose={handleToggleDrawer}
            classes={{
              paper: styles.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true,
            }}
          >
            {drawer}
          </Drawer>
        </Box>
      </nav>
    )
  } else {
    return <Fragment></Fragment>
  }
}

export default Sidebar
