import React, { useRef, FC, useEffect, useState } from "react"

import StatsCard from "../../../../components/StatsCard"
import { definitions } from "../../../../types/Generated/apiTypes"
import { calculateDwellingAreaStatistics } from "../../../../utils/dwellingUtils"

import styles from "./NumericStats.module.scss"

type DwellingAreaDailyCounts = definitions["DwellingAreaDailyCounts"]
type DwellingAreaAnalytics = definitions["DwellingAreaAnalytics"]
type EntityGroupsCounts = definitions["EntityGroupsCounts"]

interface Props {
  dwellingDailyCounts?: DwellingAreaDailyCounts[]
  dwellingAreaAnalytics?: DwellingAreaAnalytics[]
  groupsCount?: EntityGroupsCounts
  isLoading?: boolean
}

type NumericStatsTypes = {
  totalDwellingCount: number
  averageDwellingTime: number
  maleCount: number
  femaleCount: number
  adultCount: number
  childCount: number
}
const NumericStats: FC<Props> = ({ dwellingDailyCounts, dwellingAreaAnalytics, groupsCount, isLoading }) => {
  const visitorsRef = useRef<HTMLDivElement>(null)
  const dwellTimeRef = useRef(null)

  const [numericStat, setNumericStat] = useState<NumericStatsTypes>({
    totalDwellingCount: 0,
    averageDwellingTime: 0,
    maleCount: 0,
    femaleCount: 0,
    adultCount: 0,
    childCount: 0,
  })

  useEffect(() => {
    if (dwellingDailyCounts && dwellingAreaAnalytics && !isLoading) {
      setNumericStat(calculateDwellingAreaStatistics(dwellingDailyCounts, dwellingAreaAnalytics))
    }
  }, [isLoading, dwellingDailyCounts, dwellingAreaAnalytics])

  return (
    <div className={styles.statsCardsWrapper}>
      {/* Dwelling Count Stats Card */}
      <StatsCard
        title="Total Count"
        numericStat={numericStat?.totalDwellingCount!}
        femaleStat={numericStat?.femaleCount!}
        childCount={numericStat?.childCount}
        adultCount={numericStat?.adultCount}
        maleStat={numericStat?.maleCount!}
        isLoading={isLoading}
        reference={visitorsRef}
        marginBottom
        entityGroupsCounts={groupsCount}
      />
      {/* Occupancy In Card */}
      <StatsCard
        title="Average dwelling time"
        numericStat={numericStat?.averageDwellingTime!}
        isLoading={isLoading}
        reference={dwellTimeRef}
        isSecondCard
        unit="Minutes"
      />
    </div>
  )
}
export default NumericStats
