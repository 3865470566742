import React, { FC, ReactNode } from "react"

import { Button } from "@synapse-analytics/synapse-ui"

import styles from "./ActionButton.module.scss"

interface Props {
  children: ReactNode
  icon?: any
  deletable?: boolean
  comparable?: boolean
  handleClick?: () => void
}

const ActionButton: FC<Props> = ({ children, icon, handleClick, deletable, comparable }) => {
  return (
    <Button
      onClick={handleClick}
      variant={deletable ? "dangerous" : "secondary"}
      className={styles.button}
      endIcon={comparable ? icon : null}
      startIcon={!comparable ? icon : null}
    >
      {children}
    </Button>
  )
}

export default ActionButton
