import React, { FC, useContext, useState, useEffect, Fragment } from "react"
import { useQueryClient } from "react-query"
import { useNavigate } from "react-router"

import HighlightOffIcon from "@mui/icons-material/Close"
import { Dialog, DialogContent, MobileStepper } from "@mui/material"

import { Typography, Button } from "@synapse-analytics/synapse-ui"

import WarningDialog from "../../../../components/WarningDialog"
import { routes } from "../../../../routes/routes"
import { IFootageData } from "../../../../types/Custom/Interfaces"
import { definitions } from "../../../../types/Generated/apiTypes"
import { FootageContext } from "../../footageContext/FootageContext"
import ConfirmFootage from "./ConfirmFootage"
import SelectFootage from "./SelectFootage"
import UploadStatus from "./UploadStatus"

import styles from "./UploadFootage.module.scss"

type Camera = definitions["CameraRetrieveUpdate"]
type PaginatedCamera = definitions["PaginatedCamerasList"]

interface Props {
  open: boolean
  handleClose: () => void
  camera?: Camera | PaginatedCamera
}

const UploadFootage: FC<Props> = ({ open, handleClose, camera }) => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()
  const [activeStep, setActiveStep] = useState(0)
  const [isCancelMessageOpen, setIsCancelMessageOpen] = useState(false)
  const {
    formik,
    services,
    startUpload,
    outputFootage,
    originalFootageName,
    originalFootageSrc,
    resetUploadFootage,
    handleFootageProcess,
    progress: processingProgress,
  } = useContext<IFootageData>(FootageContext)
  const { cameraId, startDate, endDate } = formik?.values || {}

  useEffect(() => {
    if (activeStep === 0) {
      formik?.setFieldValue("cameraId", camera?.id)
      formik?.setFieldValue("cameraName", camera?.name)
    }

    //eslint-disable-next-line
  }, [activeStep])

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1)
  }

  const handleUpload = () => {
    if (originalFootageSrc) handleFootageProcess()
    setActiveStep((prevActiveStep) => prevActiveStep + 1)
  }

  const handleSuccess = () => {
    navigate(`/${routes?.cameraList}/admin-camera-details/${camera?.id}`, {
      state: camera,
    })
    queryClient?.invalidateQueries("fetchCamerasPaginated")
    queryClient?.invalidateQueries("fetchSingleCamera")
  }

  const uploadFootage = () => {
    startUpload(cameraId, startDate?.toISOString(), endDate?.toISOString(), outputFootage, originalFootageName)
  }

  useEffect(() => {
    if (processingProgress === 100 && outputFootage) {
      uploadFootage()
    }
    //eslint-disable-next-line
  }, [processingProgress, outputFootage])

  // closing confirmation message pop up
  const handleTriggerCloseConfirmation = () => {
    setIsCancelMessageOpen(true)
  }

  const handleCancelCloseConfirmation = () => {
    setIsCancelMessageOpen(false)
  }

  //after confirm to close , close dialog and reset states
  const handleCloseDialog = () => {
    // Empty All States of all the chosen inputs
    if (activeStep === 2 && processingProgress > 0 && processingProgress < 100) {
      navigate(0)
    } else {
      handleCancelCloseConfirmation()
      handleClose()
      resetUploadFootage()
      setIsCancelMessageOpen(false)
      setActiveStep(0)
    }
  }

  return (
    <Fragment>
      {/* updated camera status confirmation */}
      <WarningDialog
        confirmationText="Cancel upload process?"
        isOpen={isCancelMessageOpen}
        onCancel={handleCancelCloseConfirmation}
        onConfirm={handleCloseDialog}
      />
      <Dialog
        onClick={(event) => event.stopPropagation()}
        open={open}
        onClose={handleClose}
        disableEscapeKeyDown
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{ style: { overflow: "visible", width: activeStep === 0 ? 492 : activeStep === 1 ? 797 : "auto" } }}
      >
        <DialogContent className={styles.wrapper} style={{ paddingTop: 32, overflow: "visible" }}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="h2-bold" className={styles.title}>
              {activeStep === 0 ? "Upload Footage" : activeStep === 1 ? "Confirm Footage" : "Upload Status"}
            </Typography>
            <HighlightOffIcon onClick={handleTriggerCloseConfirmation} className={styles.iconContainer} />
          </div>

          {/* Start of First Scren*/}
          {activeStep === 0 ? (
            <SelectFootage />
          ) : activeStep === 1 ? (
            // start of second screen
            <ConfirmFootage />
          ) : (
            //start of 3rd and final screen
            <UploadStatus
              close={handleSuccess}
              cancel={handleTriggerCloseConfirmation}
              retry={() => {
                resetUploadFootage()
                setActiveStep(0)
              }}
            />
          )}

          {/*Start of Second Screen*/}
          {/* Mobile Stepper pagination*/}
          <MobileStepper
            variant="dots"
            steps={3}
            position="static"
            activeStep={activeStep}
            className={styles.stepper}
            nextButton={
              <Button
                size="regular"
                variant="primary"
                onClick={activeStep === 1 ? handleUpload : handleNext}
                style={{ visibility: activeStep === 2 ? "hidden" : "visible" }}
                disabled={services ? false : true}
              >
                {activeStep === 1 ? "Upload" : "Next"}
              </Button>
            }
            backButton={
              <Button
                size="regular"
                variant="primary"
                onClick={handleBack}
                disabled={activeStep === 0}
                style={{ visibility: activeStep === 1 ? "visible" : "hidden" }}
              >
                Back
              </Button>
            }
          />
        </DialogContent>
      </Dialog>
    </Fragment>
  )
}
export default UploadFootage
