import React from "react"

import Pagination from "@mui/material/Pagination"

import styles from "./CustomPagination.module.scss"

const CustomPagination = (props: any) => {
  const { count, page, onPageChange, rowsPerPage } = props

  return (
    <td
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        background: "var(--greyScale-background-1)",
      }}
    >
      <Pagination
        classes={{ root: styles.rootPagination }}
        count={Math.ceil(count / rowsPerPage)}
        page={page + 1}
        onChange={(event, value) => {
          onPageChange(event, value - 1)
        }}
        siblingCount={2}
        color="standard"
        shape="rounded"
      />
    </td>
  )
}
export default CustomPagination
