import { Fragment } from "react"

import UnknownIcon from "@mui/icons-material/NoAccounts"
import { Box } from "@mui/material"

import { ResponsiveBar } from "@nivo/bar"
import { Typography } from "@synapse-analytics/synapse-ui"

import AdultIcon from "../../assets/adultsIcon.svg?react"
import ChildrenIcon from "../../assets/childrenIcon.svg?react"
import FemaleIcon from "../../assets/femaleIcon.svg?react"
import MaleIcon from "../../assets/maleIcon.svg?react"
import GraphEmptyState from "./GraphEmptyState"
import GraphLoadingState from "./GraphLoadingState"
import { gridTheme } from "./gridTheme"

import styles from "./avgCountBarGraph.module.scss"

const colors = {
  "Male Count": "var(--surface-background-blue-light-purple)",
  "Female Count": "var(--pink-background-2)",
  "Adult Count": "var(--purple-background-2)",
  "Children Count": "var(--teal-background-2)",
  // FIXME: Add color variable when new SUI Colors are integrated
  "Unknown Age Count": "#EAEEF2",
  "Unknown Gender Count": "#EAEEF2",
}
const getColor = (bar) => colors[bar.id]
const AvgCountBarGraph = (props) => {
  const { barGraphData, isLoading, interval, isGender, hourlyAvg, isEmpty, dwellType } = props

  const hasUnknownAge = barGraphData.some((element) => element["Unknown Age Count"])
  const hasUnknownGender = barGraphData.some((element) => element["Unknown Gender Count"])

  const invalidInterval = interval && interval.days < 7 && interval.months < 1
  return (
    <Fragment>
      {isLoading ? (
        <GraphLoadingState />
      ) : invalidInterval || isEmpty ? (
        <GraphEmptyState isRangeWarning={invalidInterval} />
      ) : (
        <Box
          style={{
            height: "300px",
          }}
        >
          <ResponsiveBar
            theme={gridTheme}
            data={barGraphData}
            keys={
              isGender
                ? ["Male Count", "Female Count", hasUnknownGender ? "Unknown Gender Count" : ""]
                : ["Adult Count", "Children Count", hasUnknownAge ? "Unknown Age Count" : ""]
            }
            indexBy={hourlyAvg ? "Hour" : "Day"}
            margin={{ top: 30, right: hourlyAvg ? -10 : -20, bottom: hourlyAvg ? 60 : 48, left: hourlyAvg ? 20 : 40 }}
            padding={0.4}
            valueScale={{ type: "linear" }}
            tooltip={(slice) => {
              return (
                <div>
                  <span>{slice.data[slice.value]}</span>
                  <table
                    style={{
                      background: "white",
                      color: "inherit",
                      fontSize: "14px",
                      borderRadius: "2px",
                      boxShadow: "rgba(0, 0, 0, 0.25) 0px 1px 2px",
                      padding: "5px 9px",
                    }}
                  >
                    <tbody>
                      <tr>
                        <td style={{ padding: "3px 5px" }}>
                          <span
                            key="chip"
                            style={{
                              display: "block",
                              width: "12px",
                              height: "12px",
                              background: slice.color,
                            }}
                          />
                        </td>
                        <td style={{ padding: "3px 5px" }}>{slice.label} : </td>
                        <td style={{ padding: "3px 5px" }}>
                          <strong key="value">
                            {slice.value} {dwellType === "Dwelling time" && "Minute(s)"}
                          </strong>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              )
            }}
            colors={getColor}
            animate={true}
            enableLabel={false}
            axisTop={null}
            axisRight={null}
            axisLeft={{
              tickSize: 0,
              tickPadding: 0,
              tickRotation: 0,
              legend: "",
              legendPosition: "middle",
              legendOffset: 0,
            }}
            axisBottom={{
              tickSize: 0,
              tickPadding: 12,
              tickRotation: hourlyAvg ? -45 : 0,
              legend: "",
              legendPosition: "middle",
              legendOffset: 0,
            }}
            enableGridX={false}
            enableGridY={true}
            labelSkipWidth={0}
            labelSkipHeight={12}
            labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
            motionStiffness={90}
            motionDamping={15}
            legends={[]}
          />
          <div className={styles.legendContainer}>
            {isGender ? (
              <Fragment>
                <Typography className={`${styles.legend} ${styles.maleLegend}`} variant="span">
                  <MaleIcon className={styles.legendIcon} />
                  Male
                </Typography>
                <Typography className={`${styles.legend} ${styles.femaleLegend}`} variant="span">
                  <FemaleIcon className={styles.legendIcon} />
                  Female
                </Typography>
                {hasUnknownGender && (
                  <Typography variant="span" variantColor={2} className={`${styles.legend} ${styles.unknownLegend}`}>
                    <UnknownIcon className={styles.legendIcon} fontSize="small" />
                    Unknown
                  </Typography>
                )}
              </Fragment>
            ) : (
              <Fragment>
                <Typography variant="span" className={`${styles.legend} ${styles.childLegend}`}>
                  <ChildrenIcon className={styles.legendIcon} />
                  Children
                </Typography>
                <Typography variant="span" className={`${styles.legend} ${styles.adultLegend}`}>
                  <AdultIcon className={styles.legendIcon} />
                  Adults
                </Typography>
                {hasUnknownAge && (
                  <Typography variant="span" variantColor={2} className={`${styles.legend} ${styles.unknownLegend}`}>
                    <UnknownIcon className={styles.legendIcon} fontSize="small" />
                    Unknown
                  </Typography>
                )}
              </Fragment>
            )}
          </div>
        </Box>
      )}
    </Fragment>
  )
}
export default AvgCountBarGraph
