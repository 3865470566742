import { Grid } from "@mui/material"

import barGraphLoading from "./assets/barChartLoading.mp4"

const GraphLoadingState = () => {
  return (
    <Grid
      container
      item
      direction="row"
      justifyContent="center"
      alignItems="center"
      sx={{
        height: "100%",
      }}
    >
      <video autoPlay={true} loop={true} width="300" height="200">
        <source src={barGraphLoading} type="video/mp4" />
      </video>
    </Grid>
  )
}
export default GraphLoadingState
